import STRINGS from "./Strings";

export const HOME = "/";
export const SIGN_IN = "/signin";
export const FORGOT_PASSWORD = "/signin/forgot-password";
export const REGISTER = "/register";
export const VERIFICATION_CODE = "/register/verification-code";
export const VAULT_PASSWORD = "/register/verification-code/vault-password";
export const EXISTING_MEMBER = "/existing-member";
export const DEBIT_CARD = "/debit-card";
export const RECOVER_PASSWORD = "/recoverpassword";
export const RECOVER_PASSWORD_BY_EMAIL = `${RECOVER_PASSWORD}/:id`;
export const MEMBER_CREATION = "/terms-and-conditions";
export const ABOUT_YOU = "/about-you";
export const SIGNIN_FORGOT_VERIFICATION =
  "/signin/forgot-password/verification-code";
export const SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD =
  "/signin/forgot-password/verification-code/vault-password";
export const EMPLOYMENT_AND_INCOME = "/employment-and-income";
export const CONFIRM_ACCOUNT_CREATE = "/confirm-account-create";
export const FUNDING_AMOUNT = "/fund-amount";
export const FUND_ACCOUNT = "/fund-account";
export const GETTING_STARTED = "/getting-started";
export const COMPLIANCE = "/compliance";
export const ACCOUNT_INFORMATION = "/account-information";
export const FUND_METHOD = "/fund-method";
export const MARKETPLACE = "/marketplace";
export const VAULT_DASHBOARD = "/dashboard";
export const BENEFICIARIES = "/beneficiaries";
export const OVERDRAFT_PROTECTION = "/overdraft-protection";
// joint-applicant
export const JOINT_APPLICANT = "/joint-applicant";
export const JOINT_APPLICANT_STATUS = "/joint-applicant-status";
export const ONLINE_BANKING = "/online-banking";
export const SPOUSAL_INFO = "/spousal-info";
export const SIGNATURE = "/signature";
export const INVITE_APPLY = "/inviteapply/:id";

export const LOAN_DETAILS = "/loan-details";
export const LOAN_PURPOSE = "/loan-purpose";
export const PROPERTY_DETAILS = "/property-details";
export const SECURITY_VEHICLE = "/security-vehicle";
export const INCOME = "/income";
export const EXPENSE = "/expense";
export const ASSET = "/asset";
export const LIABILITIES = "/liabilities";
export const DOCUMENT_UPLOAD = "/documents";
export const FEES = "/fees";
export const COST_OF_BORROWING = "/cost-of-borrowing";
export const LOAN_SUMMARY = "/loan-summary";
export const LOAN_ADJUDICATION_RESULTS = "/loan-result";
export const BEFORE_WE_START = "/before-we-start";
export const DISBURSEMENT = "/disbursement";
export const REPAYMENT = "/repayment";

export const BREADCRUMBS = {
  [HOME]: STRINGS.MARKETPLACE.TITILE,
  [MARKETPLACE]: STRINGS.MARKETPLACE.TITILE,
  [VAULT_DASHBOARD]: STRINGS.HOME_BREADCRUMB,
  [SIGN_IN]: STRINGS.SIGNIN.SIGN_IN_BREADCRUMB,
  [FORGOT_PASSWORD]: STRINGS.FORGOT_PASSWORD_BREADCRUMB,
  [REGISTER]: STRINGS.REGISTRATION.REGISTER_BREADCRUMB,
  [VERIFICATION_CODE]: STRINGS.VERIFICATIONCODE.VERIFICATION_CODE_BREADCRUMB,
  [VAULT_PASSWORD]: STRINGS.VAULT.VAULT_PASSWORD_BREADCRUMB,
  [EXISTING_MEMBER]: STRINGS.EXISTING_MEMBER_BREADCRUMB,
  [DEBIT_CARD]: STRINGS.DEBIT_CARD_BREADCRUMB,
  [SIGNIN_FORGOT_VERIFICATION]:
    STRINGS.VERIFICATIONCODE.VERIFICATION_CODE_BREADCRUMB,
  [SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD]:
    STRINGS.VAULT.VAULT_PASSWORD_BREADCRUMB,
  [RECOVER_PASSWORD]: STRINGS.RECOVER_PASSWORD_BREADCRUMB,
  [MEMBER_CREATION]: STRINGS.MEMBER_CREATION_BREADCRUMB,
  [ABOUT_YOU]: STRINGS.MEMBER_CREATION_BREADCRUMB,
  [SIGNATURE]: STRINGS.SIGNATURE_BREADCRUMB,
  [RECOVER_PASSWORD_BY_EMAIL]: STRINGS.RECOVER_PASSWORD_BREADCRUMB,
  [EMPLOYMENT_AND_INCOME]: STRINGS.EMPLOYMENT_AND_INCOME_BREDCRUM,
  [GETTING_STARTED]: STRINGS.GETTING_STARTED_BREADCRUMB,
  [CONFIRM_ACCOUNT_CREATE]: STRINGS.CONFIRM_ACCOUNT_CREATE_BREADCRUMB,
  [FUNDING_AMOUNT]: STRINGS.FUND_ACCOUNT_BREADCRUMB,
  [COMPLIANCE]: STRINGS.COMPLIANCE_BREADCRUMB,
  [ACCOUNT_INFORMATION]: STRINGS.ACCOUNT_INFORMATION_BREADCRUMB,
  [FUND_METHOD]: STRINGS.FUND_ACCOUNT_BREADCRUMB,
  [BENEFICIARIES]: STRINGS.BENEFICIARIES_BREADCRUMB,
  [OVERDRAFT_PROTECTION]: STRINGS.OVERDRAFT_PROTECTION_BREADCRUMB,
  [JOINT_APPLICANT]: STRINGS.JOINT_APPLICANT.JOINT_APPLICANT_BREADCRUMB,
  [JOINT_APPLICANT_STATUS]:
    STRINGS.JOINT_APPLICANT_STATUS.JOINT_APPLICANT_STATUS_BREADCRUMB,
  [ONLINE_BANKING]: STRINGS.ONLINE_BANKING.ONLINE_BANKING_BREADCRUMB,
  [SPOUSAL_INFO]: STRINGS.SPOUSAL_INFO.SCREENTITLE,
  [LOAN_DETAILS]: STRINGS.LOAN_DETAILS.BREADCRUMB,
  [LOAN_PURPOSE]: STRINGS.LOAN_PURPOSE.BREADCRUMB,
  [PROPERTY_DETAILS]: STRINGS.PROPERTY_DETAILS.BREADCRUMB,
  [SECURITY_VEHICLE]: STRINGS.SECURITY_VEHICLE.BREADCRUMB,
  [INCOME]: STRINGS.INCOME.BREADCRUMB,
  [EXPENSE]: STRINGS.EXPENSE.BREADCRUMB,
  [ASSET]: STRINGS.ASSET.BREADCRUMB,
  [LIABILITIES]: STRINGS.LIABILITIES.BREADCRUMB,
  [DOCUMENT_UPLOAD]: STRINGS.DOCUMENT_UPLOAD.BREADCRUMB,
  [FEES]: STRINGS.FEES.BREADCRUMB,
  [COST_OF_BORROWING]: STRINGS.COST_OF_BORROWING.BREADCRUMB,
  [LOAN_SUMMARY]: STRINGS.LOAN_SUMMARY.BREADCRUMB,
  [LOAN_ADJUDICATION_RESULTS]: STRINGS.LOAN_ADJUDICATION_RESULTS.BREADCRUMB,
  [BEFORE_WE_START]: STRINGS.BEFORE_WE_START.BREADCRUMB,
  [DISBURSEMENT]: STRINGS.DISBURSEMENT.BREADCRUMB,
  [REPAYMENT]: STRINGS.REPAYMENT.BREADCRUMB,
  [INVITE_APPLY]: STRINGS.REGISTRATION.REGISTER_BREADCRUMB,
};

export const PUBLIC_ROUTES = [
  HOME,
  SIGN_IN,
  FORGOT_PASSWORD,
  REGISTER,
  VERIFICATION_CODE,
  VAULT_PASSWORD,
  SIGNIN_FORGOT_VERIFICATION,
  SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD,
  MARKETPLACE,
  INVITE_APPLY,
];

export const PRIVATE_ROUTES = [
  VAULT_DASHBOARD,
  EXISTING_MEMBER,
  MEMBER_CREATION,
  GETTING_STARTED,
  ABOUT_YOU,
  EMPLOYMENT_AND_INCOME,
  COMPLIANCE,
  ACCOUNT_INFORMATION,
  OVERDRAFT_PROTECTION,
  SPOUSAL_INFO,
  DEBIT_CARD,
  FUNDING_AMOUNT,
  FUND_METHOD,
  CONFIRM_ACCOUNT_CREATE,
  LOAN_ADJUDICATION_RESULTS,
];
