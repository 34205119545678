import { call, put, takeLatest } from "redux-saga/effects";
import GetLendingProductRequest from "Communication/ApiClasses/GetLendingProductRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// worker
function* handleGetLendingProductSaga(action) {
  yield put(doLoadingStart(STRINGS.LOAN_DETAILS.API_KEYS.GET_LOAN_PRODUCT));
  console.log(action);
  const getLendingProduct = new GetLendingProductRequest(
    action.data.applicationId,
    action.data.doximProductId
  );
  try {
    const result = yield call(getLendingProduct.getData);
    yield put({
      type: actionTypes.GET_LENDING_PRODUCT_SUCCESS,
      response: result.data,
    });
    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_LENDING_PRODUCT_ERROR,
      error: error.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.LOAN_DETAILS.API_KEYS.GET_LOAN_PRODUCT));
  }
}
// watcher
export default function* getGetLendingProductWatcherSaga() {
  yield takeLatest(
    actionTypes.GET_LENDING_PRODUCT,
    handleGetLendingProductSaga
  );
}
