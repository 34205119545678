import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import SignaturePad from "react-signature-canvas";
import { HTTP_STATUS } from "Communication/Constants";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
class Signature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.SIGNATURE, (response) => {
      this.setFormData(response);
    });
  }

  setFormData = () => {};

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.SIGNATURE);
  };

  handleChange = (choice) => {
    this.setState({ choice });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { choice } = this.state;
    this.clearChoice();
    handleContinue(choice);
  };

  clearChoice = () => {
    this.setState({ choice: "" });
  };

  render() {
    const {
      steps,
      activeStepID,
      showPage1,
      showPage2,
      showPage3,
      showModal,
      type,
      modal,
      toggleModal,
      handlePopupModalBtnClick,
    } = this.props;
    const actionComponent = (
      <div className="[ signature-container ]">
        <Stepper
          steps={AppConstants.SIGNATURE.STEPS}
          active={showPage2 ? 2 : 1}
        />
        <div className="[ form-title ]">{STRINGS.SIGNATURE.TITLE}</div>

        <div className="[ form-container ]">
          {showPage1 && (
            <div>
              <div className="[ mb-3 ] [ alert alert-secondary ]" role="alert">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <h3 className="[ alert-heading ]">
                      {STRINGS.SIGNATURE.INSTRUCTION_PAGE1_TITLE}
                    </h3>
                  </div>
                </div>
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <p>
                      <strong>
                        {STRINGS.SIGNATURE.INSTRUCTION_PAGE1_SUBTITLE}
                      </strong>
                    </p>
                    {/* EDWIN: NEED to put the product name in string below */}
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE1_DESCRIPTION}</p>
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE1_DESCRIPTION1}</p>
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE1_DESCRIPTION2}</p>
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE1_DESCRIPTION3}</p>
                  </div>
                </div>
              </div>
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div class="[ col-lg-6 ] [ offset-lg-3 ]">
                    <div
                      className=" [ e-sig ] "
                      onClick={this.handleContinue}
                      onKeyDown={this.handleKeyDown}
                      role="button"
                      tabIndex="0"
                    >
                      <div className="[ flex-row justify-content-center text-center ]">
                        <i className="[ fas fa-file-signature ]" />
                      </div>
                      <div className="[ e-sig__text ] ">
                        {STRINGS.SIGNATURE.CLICKTOBEGIN}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showPage2 && (
            <div>
              <div className="[ mb-3 ] [ alert alert-info ]" role="alert">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <h3 className="[ alert-heading ]">
                      {STRINGS.SIGNATURE.INSTRUCTION_PAGE2_TITLE}
                    </h3>
                  </div>
                </div>
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <p>
                      <strong>
                        {STRINGS.SIGNATURE.INSTRUCTION_PAGE2_SUBTITLE}
                      </strong>
                    </p>
                    {/* EDWIN: NEED to put the product name in string below */}
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE2_DESCRIPTION}</p>
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE2_DESCRIPTION1}</p>
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE2_DESCRIPTION2}</p>
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE2_DESCRIPTION3}</p>
                  </div>
                </div>
              </div>
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                    <button
                      type="button"
                      className="[ btn  submit-btn ] [ w-100 ]"
                      onClick={this.handleContinue}
                    >
                      {STRINGS.COMMON.CONTINUEBTN}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showPage3 && (
            <div>
              <div className="[ mb-3 ] [ alert alert-warning ]" role="alert">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <h3 className="[ alert-heading ]">
                      <i className="[ fas fa-exclamation-circle ]" />
                      <span>{STRINGS.SIGNATURE.INSTRUCTION_PAGE3_TITLE}</span>
                    </h3>
                  </div>
                </div>
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <p>
                      <strong>
                        {STRINGS.SIGNATURE.INSTRUCTION_PAGE3_SUBTITLE}
                      </strong>
                    </p>
                    {/* EDWIN: NEED to put the product name in string below */}
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE3_DESCRIPTION}</p>
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE3_DESCRIPTION1}</p>
                    <p>{STRINGS.SIGNATURE.INSTRUCTION_PAGE3_DESCRIPTION2}</p>
                  </div>
                </div>
              </div>
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                    <button
                      type="button"
                      className="[ btn  submit-btn ] [ w-100 ]"
                      onClick={this.handleContinue}
                    >
                      {STRINGS.COMMON.CONTINUEBTN}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

Signature.propTypes = {
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  showPage1: PropTypes.bool.isRequired,
  showPage2: PropTypes.bool.isRequired,
  showPage3: PropTypes.bool.isRequired,
  handlePopupModalBtnClick: PropTypes.func,
};

Signature.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  handlePopupModalBtnClick: () => {},
};

export default Signature;
