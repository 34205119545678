import React, { Component } from "react";
import PropTypes from "prop-types";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";

import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";

import checkErrors from "Utils/InputValidator";

class VerificationCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      errors: {
        VerificationCodeErrorList: [],
      },
    };
    this.inputFocus = React.createRef();
  }

  componentDidMount() {
    this.inputFocus.current.focus();
  }

  verifyData = () => {
    const { password, errors } = this.state;
    let formIsValid = true;

    const otpRes = checkErrors(
      password,
      VALIDATIONS.VERIFICATIONCODE.verificationcode
    );

    this.setState({
      errors: {
        ...errors,
        VerificationCodeErrorList: otpRes,
      },
    });

    if (otpRes.length > 0) {
      formIsValid = false;
    }
    return formIsValid;
  };

  changeHandler = (event) => {
    const { errors } = this.state;
    this.setState(
      {
        [event.target.name]: event.target.value,
        errors: {
          ...errors,
          VerificationCodeErrorList: [],
        },
      },
      () => {}
    );
  };

  resendCodeClick = (event) => {
    const { handleSendVerificationCode } = this.props;
    event.preventDefault();
    handleSendVerificationCode();
  };

  submitData = (event) => {
    const {
      registerState,
      forgotPasswordState,
      handlVerificationCodeSubmit,
      otpVerify,
      inSessionJointApplicant,
    } = this.props;
    const { password } = this.state;
    event.preventDefault();
    const formIsValid = this.verifyData();
    if (formIsValid) {
      let otpId = "";
      if (inSessionJointApplicant) {
        otpId = inSessionJointApplicant.otpId;
      } else {
        if (otpVerify && otpVerify.success) {
          otpId = otpVerify.data.otpId;
        } else {
          otpId = registerState.success
            ? registerState.success.data.otpId
            : forgotPasswordState.success.data.otpId;
        }
      }

      const userDetail = {
        otpId,
        otp: password,
        newPassword: "",
      };

      handlVerificationCodeSubmit(userDetail);
    }
  };

  render() {
    const { password, errors } = this.state;
    const {
      showModal,
      showVaultClicked,
      modalType,
      modal,
      handleBack,
      forgotPasswordState,
      inSessionJointApplicant,
      steps,
      activeStepID,
    } = this.props;
    const VerificationCodeComponent = (
      <form onSubmit={this.submitData} className="[ verification-container ]">
        {inSessionJointApplicant && (
          <Stepper steps={steps} active={activeStepID} />
        )}
        <div className="[ form-title-verification ]">
          {STRINGS.VERIFICATIONCODE.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.VERIFICATIONCODE.FORM.VERIFICATIONCODELABEL}
            </div>

            <input
              className={
                errors?.VerificationCodeErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              maxLength="6"
              name="password"
              onChange={this.changeHandler}
              value={password}
              placeholder={
                STRINGS.VERIFICATIONCODE.FORM.VERIFICATIONCODEPLACEHOLDER
              }
              ref={this.inputFocus}
            />
            <div className="[ error-msg ]">
              {errors?.VerificationCodeErrorList}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button type="submit" className="[ btn  submit-btn ] [ w-100 ]">
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </form>
    );

    const infoComponent = (
      <div className="">
        <div className="[ info-title ]">
          {STRINGS.VERIFICATIONCODE.INFOBLOCK.TITLE}
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          {STRINGS.VERIFICATIONCODE.INFOBLOCK.SENTCODE}

          {STRINGS.VERIFICATIONCODE.INFOBLOCK.ENTERCODE}
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          {STRINGS.VERIFICATIONCODE.INFOBLOCK.RECEIVEDCODE}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ] [ col-xl-12 ] [ offset-xl-0 ]">
              <button
                type="button"
                onClick={this.resendCodeClick}
                className="[ btn info-btn ] [ w-100 ]"
              >
                {STRINGS.VERIFICATIONCODE.INFOBLOCK.RESENDCODEBTN}
              </button>
            </div>
          </div>
        </div>
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
      </div>
    );
    return (
      <AuthBody
        actionComponent={VerificationCodeComponent}
        infoComponent={infoComponent}
        securityText={STRINGS.INFOBANNER.SECURESESSIONTEXT}
        showVaultClicked={() => showVaultClicked}
        handleBack={handleBack}
      />
    );
  }
}

VerificationCode.propTypes = {
  registerState: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  forgotPasswordState: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  showVaultClicked: PropTypes.func,
  handleSendVerificationCode: PropTypes.func,
  handlVerificationCodeSubmit: PropTypes.func,
  showModal: PropTypes.bool,
  modalType: PropTypes.string,
  modal: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleBack: PropTypes.func.isRequired,
  otpVerify: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  inSessionJointApplicant: PropTypes.object,
};
VerificationCode.defaultProps = {
  registerState: "",
  forgotPasswordState: "",
  showVaultClicked: () => {},
  handleSendVerificationCode: () => {},
  handlVerificationCodeSubmit: () => {},
  showModal: false,
  modalType: "",
  modal: {
    title: "",
    description: "",
  },
  otpVerify: "",
};

export default VerificationCode;
