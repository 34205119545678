import * as actionTypes from "Redux/Actions/actionTypes";

export const getApplication = (id, callback = () => {}) => ({
  type: actionTypes.CALL_APPLICANT_API,
  id,
  callback,
});

export const getGettingStartedData = (callback = () => {}) => ({
  type: actionTypes.CALL_GETTING_STARTED_API,
  callback,
});

export const toggleEditFlag = (flag) => ({
  type: actionTypes.TOGGLE_EDIT_FLAG,
  flag,
});

export const deleteApplication = (applicationId, callback = () => {}) => ({
  type: actionTypes.APPLICATION_DELETE,
  data: applicationId,
  callback,
});

export const getApplicationList = (callback = () => {}) => ({
  type: actionTypes.APPLICATION_LIST,
  callback,
});

export const toggleScanFlag = (flag) => {
  return {
    type: actionTypes.TOGGLE_SCAN_FLAG,
    flag,
  };
};

export const updateProductTemplate = (activeProduct) => ({
  type: actionTypes.UPDATE_PRODUCT_TEMPLATE,
  activeProduct,
});

export const setNotFirstFlag = (flag) => ({
  type: actionTypes.SET_NOT_FIRST_FLAG,
  flag,
});

export const storeCompletedProductInASession = (product) => ({
  type: actionTypes.STORE_COMPLETED_PRODUCT_IN_A_SESSION,
  product,
});

export const setESignatureEnabled = (flag) => ({
  type: actionTypes.SET_E_SIGNATURE_ENABLED,
  flag,
});

export const toggleBackFlag = (flag) => ({
  type: actionTypes.TOGGLE_BACK_FLAG,
  flag,
});
