import BaseApiRequest from "./BaseApiRequest";
import { CREATE_APPLICATION } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class CreateApplication extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    firstName: "",
    middleName: "",
    surname: "",
    dateOfBirth: "",
    city: "",
    state: "",
    postalCode: "",
    fingerprint: "",
    branch: "",
    bundleProductId: "",
  };

  constructor(applicationDetails) {
    super();
    this.body = {
      ...this.body,
      ...applicationDetails,
    };
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return CREATE_APPLICATION;
  };

  getBody = () => {
    return this.body;
  };
}
