import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import constants
import * as ROUTES from "Constants/Routes";
import MODALMESSAGES from "Constants/Messages";
import AppConstants from "Constants/AppConstants";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";

// import common actions
import {
  toggleEditFlag,
  getApplicationList,
} from "Redux/Actions/ApplicationAction";

import { getProductInformation } from "Redux/Actions/ProductInformationAction";

import { HTTP_STATUS } from "Communication/Constants";

import { fetchGettingStartedBranch } from "Components/GettingStarted/Actions/GettingStartedActions";

import { doGetProvinceList } from "Components/AboutYou/Actions/AboutYouActions";

import { getBeneficiaries } from "Components/Beneficiaries/Action/BeneficiariesAction";
import { updateProductToVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import {
  getIncomeSourcesList,
  getStatusList,
} from "Components/EmploymentAndIncome/Actions/EmploymentAndIncomeAction";

import {
  getAccountPurposeList,
  getIndicateWhoList,
} from "Components/Compliance/Actions/ComplianceActions";

import { getFinancialAnalysisFinancialList } from "Components/LoanDetails/Actions/LoanDetailsAction";

import {
  getApplicantType,
  getApplicationProperty,
} from "Components/JointApplicant/Actions/JointApplicantAction";
import { getSpousalInfo } from "Components/SpousalInfo/Actions/SpousalInfoAction";
import ConfirmAccountCreate from "./ConfirmAccountCreate";
// import actions
import doConfirmApplication from "./Actions/ConfirmAccountCreateAction";
// import component styles
import "./ConfirmAccountCreate.scss";
import { getDocumentList } from "../Documents/Actions/DocumentAction";
import { doConsent } from "../TermsAndConditions/Actions/TermsActions";
import { LOANPRODUCT } from "../../Communication/Constants";
import updateApplicantData from "../../Redux/Actions/UpdateApplicantAction";
import { getLoanLoanSecurity } from "../LoanDetails/Actions/LoanDetailsAction";

class ConfirmAccountCreateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      erroCode: null,
    };
  }

  /**
   * Populate data for select if data does not exist in reducers
   */
  componentDidMount() {
    const {
      provinceList,
      incomeSourcesList,
      statusList,
      accountPurposeList,
      indicateWhoList,
      gettingStartedBranchData,
    } = this.props;

    if (gettingStartedBranchData && gettingStartedBranchData.length <= 0) {
      const { dofetchGettingStartedBranchs } = this.props;
      dofetchGettingStartedBranchs();
    }

    // province list is needed on about you page details
    if (provinceList && provinceList.length <= 0) {
      const { doGetProvinceData } = this.props;
      doGetProvinceData();
    }

    // income source list is needed on Employment and income page details
    if (incomeSourcesList && incomeSourcesList.length <= 0) {
      const { doGetIncomeSourcesList } = this.props;
      doGetIncomeSourcesList();
    }

    // Status list is needed for employment and income page details
    if (statusList && statusList.length <= 0) {
      const { doGetStatusList } = this.props;
      doGetStatusList();
    }

    if (accountPurposeList && accountPurposeList.length <= 0) {
      const { doGetAccountPurposeList } = this.props;
      doGetAccountPurposeList();
    }

    if (indicateWhoList && indicateWhoList.length <= 0) {
      const { doGetIndicateWhoList } = this.props;
      doGetIndicateWhoList();
    }
  }

  componentDidUpdate() {}

  /**
   * toggleModal handles popup modal
   * show / hide state
   */

  toggleModal = () => {
    const { showModal, erroCode } = this.state;
    this.setState({
      showModal: !showModal,
    });
    if (erroCode) {
      const { history } = this.props;
      history.push(ROUTES.EXISTING_MEMBER);
    }
  };

  handleSubmitDetails = () => {
    const {
      doConfirmApp,
      getActiveProduct,
      vaultProductList,
      fillApplicationSlot,
      templates,
      doGetApplicationList,
      doGetApplicationProperty,
      inSessionJointApplicant,
      history,
      doFillApplicationSlot,
      doSetInSessionJointApplicant,
      doConsents,
      doUpdateApplicant,
    } = this.props;

    const activeProduct = getActiveProduct(vaultProductList);
    let applicationId = {
      applicationId: activeProduct.applicationId,
    };
    if (fillApplicationSlot && fillApplicationSlot.applicantId) {
      applicationId = {
        applicationId: fillApplicationSlot.applicationId,
        applicantId: fillApplicationSlot.applicantId,
      };
    } else if (inSessionJointApplicant) {
      applicationId = {
        applicationId: activeProduct.applicationId,
        applicantId: inSessionJointApplicant.applicantId,
      };
    }

    const { continuehandler } = this.props;
    if (inSessionJointApplicant) {
      doFillApplicationSlot(
        {
          applicantId: inSessionJointApplicant.applicantId,
          slotId: inSessionJointApplicant.id,
          applicationId: activeProduct.applicationId,
        },
        () => {
          doConfirmApp(applicationId, (res) => {
            if (res.status === HTTP_STATUS.OK) {
              doSetInSessionJointApplicant(null);
              history.push(ROUTES.JOINT_APPLICANT_STATUS);
            }
          });
        }
      );
    } else if (activeProduct.isJointProduct) {
      doConfirmApp(applicationId, (res) => {
        if (res.status === HTTP_STATUS.OK) {
          this.setState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_JOINT_TITLE,
              description: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_JOINT_MSG,
            },
          });
        }
      });
    } else {
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (appPropertyRes) => {
          if (appPropertyRes.appliedAt || appPropertyRes.slots[0].appliedAt) {
            continuehandler({}, AppConstants.APPLICATIONSTEP.CONFIRM);
          } else {
            doUpdateApplicant(
              {
                regulatory: {
                  consentToCreditCheck: true,
                },
              },
              activeProduct.applicantId,
              () => {
                doConfirmApp(applicationId, (res) => {
                  if (res.status === HTTP_STATUS.OK) {
                    if (activeProduct.type === LOANPRODUCT) {
                      continuehandler({}, AppConstants.APPLICATIONSTEP.CONFIRM);
                    } else {
                      doGetApplicationProperty(
                        {
                          applicationId: activeProduct.applicationId,
                          delayTime: 5000,
                        },
                        (res) => {
                          if (res.inReview) {
                            this.setState({
                              showModal: true,
                              modalType: AppConstants.MODALTYPE.SUCCESS,
                              modal: {
                                title: MODALMESSAGES.ON_REVIEW.TITLE,
                                description: MODALMESSAGES.ON_REVIEW.MSG,
                              },
                            });
                          } else if (res.uploadedAt) {
                            this.setState({
                              showModal: true,
                              modalType: AppConstants.MODALTYPE.SUCCESS,
                              modal: {
                                title: MODALMESSAGES.CONFIRM_PAGE.SUCCESS_TITLE,
                                description:
                                  MODALMESSAGES.CONFIRM_PAGE.SUCCESS_MSG,
                              },
                            });
                          } else {
                            continuehandler(
                              {},
                              AppConstants.APPLICATIONSTEP.CONFIRM
                            );
                          }
                        }
                      );
                    }
                  }
                });
              }
            );
          }
        }
      );
    }
  };

  /**
   *
   * @param {string} redirectToPage
   * handleEditPage makes URL navigation
   * using key as value
   */

  handleEditPage = (redirectToPage) => {
    const { history, doToggleEdit } = this.props;
    doToggleEdit(true);
    history.push(redirectToPage);
  };

  handlePopupModalBtnClick = () => {
    const {
      continuehandler,
      products,
      getActiveProduct,
      doUpdateProductToVault,
      history,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    if (activeProduct.isJointProduct) {
      const updatedProductList = products.slice(1, products.length);
      doUpdateProductToVault(updatedProductList, () => {
        history.push(ROUTES.VAULT_DASHBOARD);
      });
    } else {
      continuehandler({}, AppConstants.APPLICATIONSTEP.CONFIRM);
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.CONFIRM);
  };

  render() {
    const { modalType, modal, showModal } = this.state;
    const {
      gettingStartedBranchData,
      incomeSourcesList,
      statusList,
      provinceList,
      accountPurposeList,
      indicateWhoList,
      getDataFromServer,
      steps,
      activeStepID,
      doGetProductInformation,
      getActiveProduct,
      doGetBeneficiaryData,
      doGetSpousalInformation,
      doGetApplicationProperty,
      doGetApplicantType,
      inSessionJointApplicant,
      doGetFinancialAnalysisFinancialList,
      products,
      applicationCompleteList,
      doGetDocumentList,
      doGetLoanLoanSecurity,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    return (
      <ConfirmAccountCreate
        key={1}
        handleEditPage={this.handleEditPage}
        type={modalType}
        modal={modal}
        showModal={showModal}
        toggleModal={this.toggleModal}
        handleSubmitDetails={this.handleSubmitDetails}
        gettingStartedBranchData={gettingStartedBranchData}
        incomeSourcesList={incomeSourcesList}
        statusList={statusList}
        provinceList={provinceList}
        accountPurposeList={accountPurposeList}
        indicateWhoList={indicateWhoList}
        handlePopupModalBtnClick={this.handlePopupModalBtnClick}
        handleBack={this.handleBack}
        getDataFromServer={(step, callback) =>
          getDataFromServer(step, callback)
        }
        steps={steps}
        activeStepID={activeStepID}
        doGetProductInformation={doGetProductInformation}
        getActiveProduct={getActiveProduct}
        doGetBeneficiaryData={doGetBeneficiaryData}
        doGetSpousalInformation={doGetSpousalInformation}
        doGetApplicationProperty={doGetApplicationProperty}
        doGetApplicantType={doGetApplicantType}
        inSessionJointApplicant={inSessionJointApplicant}
        doGetFinancialAnalysisFinancialList={
          doGetFinancialAnalysisFinancialList
        }
        activeProduct={activeProduct}
        applicationCompleteList={applicationCompleteList}
        doGetDocumentList={doGetDocumentList}
        doGetLoanLoanSecurity={doGetLoanLoanSecurity}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  gettingStartedBranchData: state.GettingStartedBranchReducer.jsonData,
  incomeSourcesList: state.EmploymentAndIncomeReducer.incomeSourcesList,
  statusList: state.EmploymentAndIncomeReducer.statusList,
  indicateWhoList: state.ComplianceReducer.indicateWhoList,
  accountPurposeList: state.ComplianceReducer.accountPurposeList,
  provinceList: state.AboutYouReducer.provinceList,
  // confirmApplicationState represents form data confirm application page actions
  confirmApplicationState: state.ConfirmApplicationReducer,
  vaultProductList: state.VaultReducer.vaultProductList,
  fillApplicationSlot:
    state.JoinApplicantReducer.fillApplicationSlot?.requestData,
  templates: state.ApplicationReducer.templates,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // doConfirmApplication takes applicationId
    // and calls confirm account create API
    doConfirmApp: (applicationId, callback) =>
      dispatch(doConfirmApplication(applicationId, callback)),
    dofetchGettingStartedBranchs: () => dispatch(fetchGettingStartedBranch()),
    doGetProvinceData: () => dispatch(doGetProvinceList()),
    doGetIncomeSourcesList: () => dispatch(getIncomeSourcesList()),
    doGetStatusList: () => dispatch(getStatusList()),
    doGetAccountPurposeList: () => dispatch(getAccountPurposeList()),
    doGetIndicateWhoList: () => dispatch(getIndicateWhoList()),
    doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
    doGetProductInformation: (
      applicationId,
      productType,
      doximProductId,
      callback
    ) =>
      dispatch(
        getProductInformation(
          applicationId,
          productType,
          doximProductId,
          callback
        )
      ),

    doGetBeneficiaryData: (applicationId, reporductNo, callback) =>
      dispatch(getBeneficiaries(applicationId, reporductNo, callback)),
    doGetSpousalInformation: (
      applicationId,
      regidteredcontractproduct,
      reporductNo,
      callback
    ) =>
      dispatch(
        getSpousalInfo(
          applicationId,
          regidteredcontractproduct,
          reporductNo,
          callback
        )
      ),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doGetApplicantType: (callback) => dispatch(getApplicantType(callback)),
    doGetApplicationList: (callback) => dispatch(getApplicationList(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doGetFinancialAnalysisFinancialList: (request, callback) =>
      dispatch(getFinancialAnalysisFinancialList(request, callback)),
    doGetDocumentList: (applicationId, callback) =>
      dispatch(getDocumentList(applicationId, callback)),
    doConsents: (consentDetails, callback) =>
      dispatch(doConsent(consentDetails, callback)),
    doGetLoanLoanSecurity: (applicationId, callback) =>
      dispatch(getLoanLoanSecurity(applicationId, callback)),
  };
};
ConfirmAccountCreateContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any), // roter history object
  doConfirmApp: PropTypes.func,
  gettingStartedBranchData: PropTypes.arrayOf(PropTypes.any),
  incomeSourcesList: PropTypes.arrayOf(PropTypes.any),
  statusList: PropTypes.arrayOf(PropTypes.any),
  provinceList: PropTypes.arrayOf(PropTypes.any),
  accountPurposeList: PropTypes.arrayOf(PropTypes.any),
  indicateWhoList: PropTypes.arrayOf(PropTypes.any),
  dofetchGettingStartedBranchs: PropTypes.func,
  doGetProvinceData: PropTypes.func,
  doGetIncomeSourcesList: PropTypes.func,
  doGetStatusList: PropTypes.func,
  doGetAccountPurposeList: PropTypes.func,
  doGetIndicateWhoList: PropTypes.func,
  doToggleEdit: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  vaultProductList: PropTypes.arrayOf(PropTypes.any),
  doGetProductInformation: PropTypes.func.isRequired,
  doGetSpousalInformation: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  doGetBeneficiaryData: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  doGetApplicationList: PropTypes.func.isRequired,
  inSessionJointApplicant: PropTypes.object,
};
ConfirmAccountCreateContainer.defaultProps = {
  history: {},
  doConfirmApp: () => {},
  gettingStartedBranchData: [],
  incomeSourcesList: [],
  statusList: [],
  provinceList: [],
  accountPurposeList: [],
  indicateWhoList: [],
  dofetchGettingStartedBranchs: () => {},
  doGetProvinceData: () => {},
  doGetIncomeSourcesList: () => {},
  doGetStatusList: () => {},
  doGetAccountPurposeList: () => {},
  doGetIndicateWhoList: () => {},
  steps: [],
  activeStepID: -1,
  vaultProductList: [],
};

const ConfirmAccountWrapper = withApplicantHOC(ConfirmAccountCreateContainer);

const wrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAccountWrapper);

export default withVaultHOC(wrapper);
