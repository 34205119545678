import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import "./PopupModal.scss";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import { PRODUCT_IMAGE_URL, COMMON_BUCKET_URL } from "Communication/ApiUrls";

class PopupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.showModal,
      showLoading: true,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleClose = () => {
    this.setState({ show: false });
  };

  onIframeLoad = () => {
    setInterval(() => {
      this.setState({ showLoading: false });
    }, 500);
  };

  render() {
    const { modalType } = this.props;
    const popUpHeaderClassNames = ["popup-icon"];
    if (modalType === AppConstants.MODALTYPE.SUCCESS) {
      popUpHeaderClassNames.push("popup-modal-success-icon");
    } else if (modalType === AppConstants.MODALTYPE.FAILURE) {
      popUpHeaderClassNames.push("popup-modal-failure-icon");
    } else if (modalType === AppConstants.MODALTYPE.INFORMATION) {
      popUpHeaderClassNames.push("popup-modal-info-icon");
    }

    const {
      product,
      title,
      description,
      description2,
      type,
      toggleModal,
      popupBtnClick,
      btnText,
      showPopupModalButtons,
      iframeModal,
      iframeUrl,
      footer,
      closeBtnClick,
      subtitle,
      addToVault,
      tag,
      seeDetailsClick,
      shortDescription,
      productLogo,
    } = this.props;

    const { show, showLoading } = this.state;

    let productStatusContainerClass = "";
    let productStatusTitleClass = "";
    let productStatusText = "";
    if (product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IN_REVIEW) {
      productStatusContainerClass = "[ review-container ]";
      productStatusTitleClass = "[ review-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.IN_REVIEW;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IS_COMPLETED
    ) {
      productStatusContainerClass = "[ completed-container ]";
      productStatusTitleClass = "[ completed-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.COMPLETED;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IS_REJECTED
    ) {
      productStatusContainerClass = "[ rejected-container ]";
      productStatusTitleClass = "[ rejected-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.REJECTED;
    }

    return (
      <>
        <Modal
          show={show}
          onHide={() => {
            this.handleClose();
            toggleModal();
            closeBtnClick();
          }}
          className={
            seeDetailsClick
              ? "[ popup-modal__container product-description ]"
              : "[ popup-modal__container ]"
          }
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // this prevents the pop up from being closed when clicked outside of the pop up
          keyboard={false}
        >
          <Modal.Header
            className="[ popup-modal__header ]"
            closeButton={showPopupModalButtons}
          >
            {tag && (
              <div className="[ added-container ]">
                <span className="[ added-title ]">
                  {STRINGS.MARKETPLACE.BODY.ADDED}
                </span>
              </div>
            )}

            <div className={`${productStatusContainerClass}`}>
              <span className={`${productStatusTitleClass}`}>
                {productStatusText}
              </span>
            </div>

            <Modal.Title className={popUpHeaderClassNames.join(" ")}>
              {iframeModal || modalType === AppConstants.MODALTYPE.CUSTOM
                ? `${title}`
                : `${type}`}
            </Modal.Title>
          </Modal.Header>

          {iframeModal ? (
            <Modal.Body className="[ text-left ] [ overflow-auto ] [ iframe-popup-modal__body ]">
              {showLoading ? (
                <div className="[ progress-indicator-container ]">
                  <div className="[ icon loading-icon fa-spin ]" />
                </div>
              ) : null}
              <iframe
                title="iframe"
                src={iframeUrl}
                className="[ iframe-popup-modal__iframe ]"
                onLoad={this.onIframeLoad}
              />
            </Modal.Body>
          ) : (
            <Modal.Body className="[ overflow-auto ] [ popup-modal__body ]">
              <div className="[ popup-modal-message__sub-title ]">
                {/* CHRIS: This should be the category the product belongs to */}
                {subtitle}
              </div>

              {!seeDetailsClick && (
                <div
                  className={
                    modalType === AppConstants.MODALTYPE.CUSTOM
                      ? "[ popup-modal-custom-message__title ]"
                      : "[ popup-modal-message__title ]"
                  }
                >
                  {modalType === AppConstants.MODALTYPE.CUSTOM ? (
                    shortDescription
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  )}
                </div>
              )}

              <div
                className={
                  modalType === AppConstants.MODALTYPE.CUSTOM && seeDetailsClick
                    ? "[ popup-modal-custom-message__description ]"
                    : "[ popup-modal-message__description ]"
                }
              >
                {seeDetailsClick && (
                  <div className="[ row ]">
                    <div className="[ col-md-4 ]">
                      <img
                        src={`${PRODUCT_IMAGE_URL}${
                          productLogo ? productLogo : product.productLogo
                        }`}
                        className="[ w-100 ] [ product-icon-container__img ]"
                        alt="Image for `${title}`"
                      />
                    </div>
                    <div className="[ col-md-8 ]">
                      {description}
                      {description2 && (
                        <div className={"[ popup-modal-message__description ]"}>
                          {description2} not sure what is here
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!seeDetailsClick && (
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <p>{description}</p>
                    </div>
                    {description2 && (
                      <div className={"[ col-12 ]"}>
                        <p>{description2}</p>
                      </div>
                    )}
                  </div>
                )}

                {/* CHRIS: JOINT APPLICANT/STATUS BLOCK START 
NOTE: This block only shows when the application for a product opening is a joint account and the joint applicants have been invited to complete their portions of the application via the email invite.
See 'if' comments within code block. 
This block will only be seen when 'see details' link is clicked on the product card 1) when product card is in progress or pending. It is not seen when the application moves to "completed" or "review"

EDWIN: will work on this as part of jira enhancement ticket */}
                {true === false && (
                  <>
                    <hr />
                    <div className="[ mb-3 ]">
                      <div className="[ form-subtitle ]">
                        {STRINGS.JOINT_APPLICANT_STATUS.TITLE}
                      </div>

                      <div className="[ details-section ] [ summary-tab ]">
                        {/* EDWIN: Loop this div block for each co-applicant that is in the application */}
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-12 ]">
                            <div className="[ details-section__item-label ]">
                              {/* EDWIN:This is where the applicant title goes, like in the progressive disclosure drawer or jointApplicationStatus page */}
                              CO-APPLICANT NO. 1
                            </div>
                          </div>
                          <div className="[ col-sm-3 ]">
                            <div className="[ details-section__item-description ]">
                              {/* EDWIN:This is where the applicant emial goes */}
                              john@gmail.com
                            </div>
                          </div>
                          <div className="[ col-sm-3 ]">
                            <div className="[ details-section__item-description ]">
                              {/* EDWIN:This is where the applicant mobile number goes */}
                              555-555-5555
                            </div>
                          </div>
                          <div className="[ col-sm-3 ]">
                            <div className="[ details-section__item-description ]">
                              {/* EDWIN:This is where the applicant type goes */}
                              Guarantor
                            </div>
                          </div>
                          <div className="[ col-sm-3 ]">
                            <div className="[ details-section__item-label ]">
                              {/* EDWIN:This is where status goes */}
                              STATUS: PENDING
                            </div>
                          </div>
                        </div>
                        {/* END LOOP */}
                      </div>
                    </div>
                  </>
                )}

                {/* CHRIS: ACCOUNT PRODUCT STATUS AND FUNDING BLOCK START 
NOTE: This block only shows when the application for an account product opening is successfully pushed to core banking in which the account info is relayed to us, and the user has been exposed to paysafe.
See 'if' comments within code block. 
This block will only be seen when 'see details' link is clicked on the product card 1) when product card is in the 'applications completed' section and not the cart 3) whe product is a fundable account type that has successfully uploaded to core banking*/}
                {true === false && (
                  <>
                    <div className="[ mb-3 ]">
                      <div className="[ form-subtitle ]">
                        Account Information
                      </div>
                      <div className="[ details-section ] [ summary-tab ]">
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Account status:
                          </div>
                          <div className="[ details-section__item-description ]">
                            Active
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Transit no.:
                          </div>
                          <div className="[ details-section__item-description ]">
                            03680
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Institution no.:
                          </div>
                          <div className="[ details-section__item-description ]">
                            809
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Full account no.:
                          </div>
                          <div className="[ details-section__item-description ]">
                            100011303999
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            *Initial deposit:
                          </div>
                          <div className="[ details-section__item-description ]">
                            $ 50.00
                          </div>
                        </div>

                        {/* HIDE this 'row' if initial deposit is $0 */}
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Funding method:
                          </div>
                          <div className="[ details-section__item-description ]">
                            CREDIT CARD **** 0789
                          </div>
                        </div>

                        {/* HIDE this 'row' if initial deposit is $0 */}
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            Date and time:
                          </div>
                          <div className="[ details-section__item-description ]">
                            2021-05-26 13:55:55
                          </div>
                        </div>

                        {/* HIDE this 'row' if initial deposit is $0 */}
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ details-section__item-label ]">
                            **PaySafe&trade; transaction ref no.:
                          </div>
                          <div className="[ details-section__item-description ]">
                            ADBD38392DJ
                          </div>
                        </div>

                        <div className="[ row ] [ mt-3 ]">
                          <div className="[ col-12 ]">
                            <small className="[ footnotes form-text text-muted ]">
                              *Your initial deposit is the amount you deposited
                              when you created the account. This is not an
                              account balance.
                            </small>
                          </div>

                          {/* HIDE this 'col' if initial deposit is $0 */}
                          <div className="[ col-12 ]">
                            <small className="[ footnotes form-text text-muted ]">
                              **Deposit transactions are facilitated via
                              PaySafe&trade;. Should you have a question about
                              your deposit, or need assistance, use the
                              PaySafe&trade; transaction reference above when
                              speaking with customer service.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
              </div>

              {/* CHRIS: END...ACCOUNT PRODUCT STATUS AND FUNDING BLOCK */}
            </Modal.Body>
          )}
          {showPopupModalButtons && (
            <>
              {iframeModal ? (
                <Modal.Footer className="[ iframe-popup-modal-footer ]">
                  <div className="[ iframe-popup-modal-footer__text ]">
                    {footer}
                  </div>
                </Modal.Footer>
              ) : (
                <Modal.Footer className="[ popup-modal-footer ]">
                  {addToVault && (
                    <button
                      type="button"
                      onClick={() => {
                        this.handleClose();
                        toggleModal();
                        popupBtnClick();
                      }}
                      className="[ btn ] [ popup-modal-footer__okBtn ]"
                    >
                      {btnText}
                    </button>
                  )}
                </Modal.Footer>
              )}
            </>
          )}
        </Modal>
      </>
    );
  }
}

PopupModal.propTypes = {
  showModal: PropTypes.bool,
  type: PropTypes.string,
  modalType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  btnText: PropTypes.string,
  toggleModal: PropTypes.func,
  popupBtnClick: PropTypes.func,
  closeBtnClick: PropTypes.func,
  showPopupModalButtons: PropTypes.bool,
  iframeModal: PropTypes.bool,
  iframeUrl: PropTypes.string,
  footer: PropTypes.string,
  subtitle: PropTypes.string,
  addToVault: PropTypes.bool,
  tag: PropTypes.bool,
  seeDetailsClick: PropTypes.bool,
  shortDescription: PropTypes.string,
};

PopupModal.defaultProps = {
  showModal: false,
  type: "success",
  modalType: "",
  title: "Test title",
  description: "Test description",
  btnText: "OK",
  toggleModal: () => {},
  popupBtnClick: () => {},
  closeBtnClick: () => {},
  showPopupModalButtons: true,
  iframeModal: false,
  iframeUrl: "",
  footer: "",
  subtitle: "",
  addToVault: true,
  tag: false,
  seeDetailsClick: false,
  shortDescription: "",
  product: {},
};

export default withRouter(PopupModal);
