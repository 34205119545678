/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import VALIDATIONS from "Constants/ValidationList";
import checkErrors from "Utils/InputValidator";
import AppConstants from "Constants/AppConstants";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AuthBody from "../Common/AuthBody/AuthBody";
import PopupModal from "../Common/PopupModal/PopupModal";
import "./Compliance.scss";
import Stepper from "../Common/Stepper/Stepper";

class Compliance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountPurpose: "",
      isThirdPartyAccount: "",
      thirdPartyName: "",
      thirdpPartyRelationship: "",
      areYouPEPorHIO: "",
      memberPEPDomestic: false,
      memberPEPForeign: false,
      memberHIO: false,
      memberOfficeHeld: "",
      memberJurisdiction: "",
      memberSourceOfFunds: "",
      isAnyoneConsideredPEPHIO: "",
      indicateName: "",
      indicateRelationship: "",
      PEPDomestic: false,
      PEPForeign: false,
      HIO: false,
      jurisdictionType: "",
      OfficeHeld: "",
      Jurisdiction: "",
      SourceOfFunds: "",
      isThirdPartyAccountError: "",
      thirdPartyNameError: "",
      indicateNameError: "",
      accountPurposeError: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.COMPLIANCE, (response) => {
      this.setFormData(response);
    });
  }

  setFormData = (response) => {
    const { regulatory, member, peps, thirdParties } = response.data;
    const jurisdictionType = peps ? peps[0]?.jurisdictionType : "";
    this.setState({
      accountPurpose: member?.intendedUseOfMembership || "",
      isThirdPartyAccount: regulatory?.memberHasThirdParty,
      thirdPartyName: thirdParties ? thirdParties[0]?.name : "",
      thirdpPartyRelationship: thirdParties
        ? thirdParties[0]?.relationship
        : "",
      areYouPEPorHIO:
        regulatory?.memberIsPedp ||
        regulatory?.memberIsPefp ||
        regulatory?.memberIsHio,
      memberPEPDomestic: regulatory?.memberIsPedp || false,
      memberPEPForeign: regulatory?.memberIsPefp || false,
      memberHIO: regulatory?.memberIsHio || false,
      memberOfficeHeld: regulatory?.memberOfficeHeld || "",
      memberJurisdiction: regulatory?.memberJurisdiction || "",
      memberSourceOfFunds: regulatory?.memberFundsSource || "",
      isAnyoneConsideredPEPHIO: !!(peps && peps.length > 0),
      indicateName: peps ? peps[0]?.name : "",
      indicateRelationship: peps ? peps[0]?.relationship : "",
      PEPDomestic:
        jurisdictionType ===
          AppConstants.COMPLIANCE.JURISDICTION_TYPE.DOMESTIC || "",
      PEPForeign:
        jurisdictionType ===
          AppConstants.COMPLIANCE.JURISDICTION_TYPE.FOREIGN || "",
      HIO: peps ? peps[0]?.hio : "",
      jurisdictionType: peps ? peps[0]?.jurisdictionType : "",
      OfficeHeld: peps ? peps[0]?.officeHeld : "",
      Jurisdiction: peps ? peps[0]?.jurisdiction : "",
      SourceOfFunds: peps ? peps[0]?.fundsSource : "",
    });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.COMPLIANCE);
  };

  changeHandler = (event) => {
    const complianceData = this.state;
    complianceData[event.target.name] = event.target.value;
    complianceData[`${event.target.name}Error`] = "";
    this.setState({ complianceData });
  };

  handleChoice = (name, choice) => {
    const complianceData = this.state;
    complianceData[name] = choice;
    this.setState({ complianceData });

    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT) {
      this.setState({ isThirdPartyAccountError: [] });
    }
  };

  handleMemberPEPHIOChoice = (name) => {
    const { memberPEPDomestic, memberPEPForeign, memberHIO } = this.state;
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERPEPDOMESTIC) {
      this.setState({
        memberPEPDomestic: !memberPEPDomestic,
      });
    }
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERPEPFOREIGN) {
      this.setState({
        memberPEPForeign: !memberPEPForeign,
      });
    }
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERHIO) {
      this.setState({
        memberHIO: !memberHIO,
      });
    }
  };

  handlePEPHIOChoice = (name) => {
    const { PEPDomestic, PEPForeign, HIO } = this.state;
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.PEPDOMESTIC) {
      this.setState({
        PEPDomestic: !PEPDomestic,
        PEPForeign: false,
      });
    } else if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.PEPFOREIGN) {
      this.setState({
        PEPDomestic: false,
        PEPForeign: !PEPForeign,
      });
    }
    if (name === STRINGS.COMPLIANCE.FORM.FIELDNAME.HIO) {
      this.setState({
        HIO: !HIO,
      });
    }
  };

  handlePEPChoice = (name, choice) => {
    const complianceData = this.state;
    complianceData[name] = choice;
    this.setState({ complianceData });
  };

  handleSubmit = (e) => {
    const { handleUpdateComplianceData } = this.props;

    e.preventDefault();

    const formIsValid = this.verifyData();
    if (formIsValid) {
      const formdata = this.state;
      handleUpdateComplianceData(formdata);
    }
  };

  verifyData = () => {
    let formIsValid = true;
    const {
      isThirdPartyAccount,
      thirdPartyName,
      isAnyoneConsideredPEPHIO,
      indicateName,
      accountPurpose,
    } = this.state;
    let thirdPartyNameRes = "";
    let indicateNameRes = "";

    const isThirdPartyAccountRes = checkErrors(
      isThirdPartyAccount,
      VALIDATIONS.COMPLIANCE.isThirdPartyAccount
    );

    this.setState({
      isThirdPartyAccountError: isThirdPartyAccountRes,
    });

    if (isThirdPartyAccount) {
      thirdPartyNameRes = checkErrors(
        thirdPartyName,
        VALIDATIONS.COMPLIANCE.thirdPartyName
      );

      this.setState({
        thirdPartyNameError: thirdPartyNameRes,
      });
    }

    if (isAnyoneConsideredPEPHIO) {
      indicateNameRes = checkErrors(
        indicateName,
        VALIDATIONS.COMPLIANCE.indicateName
      );

      this.setState({
        indicateNameError: indicateNameRes,
      });
    }

    if (
      isThirdPartyAccountRes.length > 0 ||
      thirdPartyNameRes.length > 0 ||
      indicateNameRes.length > 0
    ) {
      formIsValid = false;
    }

    const accountPurposeError = checkErrors(
      accountPurpose,
      VALIDATIONS.COMPLIANCE.selectAccountPurpose
    );
    if (accountPurposeError.length > 0) {
      formIsValid = false;
    }
    this.setState({ accountPurposeError });
    return formIsValid;
  };

  render() {
    const {
      accountPurpose,
      isThirdPartyAccount,
      thirdPartyName,
      thirdpPartyRelationship,
      areYouPEPorHIO,
      memberPEPDomestic,
      memberPEPForeign,
      memberHIO,
      memberOfficeHeld,
      memberJurisdiction,
      memberSourceOfFunds,
      isAnyoneConsideredPEPHIO,
      indicateName,
      indicateRelationship,
      PEPDomestic,
      PEPForeign,
      HIO,
      OfficeHeld,
      Jurisdiction,
      SourceOfFunds,
      isThirdPartyAccountError,
      thirdPartyNameError,
      indicateNameError,
      accountPurposeError,
    } = this.state;

    const {
      accountPurposeArray,
      indicateWhoArray,
      showModal,
      type,
      modal,
      toggleModal,
      steps,
      activeStepID,
    } = this.props;

    const actionComponent = (
      <div className="[ compliance-container ]">
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? this.handlePopupModalBtnClick
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}

        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.COMPLIANCE.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.ACCOUNT_PURPOSE_LABEL}
            </div>
            <select
              className={
                accountPurposeError && accountPurposeError.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              id="exampleFormControlSelect1"
              name="accountPurpose"
              placeholder={STRINGS.COMPLIANCE.FORM.ACCOUNT_PURPOSE_PLACEHOLDER}
              value={accountPurpose}
              onChange={this.changeHandler}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.COMPLIANCE.FORM.ACCOUNT_PURPOSE_PLACEHOLDER}
              </option>
              {accountPurposeArray?.map((obj) => {
                return (
                  <option key={obj.code} value={obj.code}>
                    {obj.description}
                  </option>
                );
              })}
            </select>
            <div className="[ error-msg ] ">{accountPurposeError[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.THIRDPARTY_ACCOUNT}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isThirdPartyAccount
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handleChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT,
                      true
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isThirdPartyAccount === false
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handleChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.ISTHIRDPARTACCOUNT,
                      false
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]">{isThirdPartyAccountError}</div>

            {isThirdPartyAccount ? (
              <div className="[ drawer-content-container ]">
                <div className="[ info-title ]">
                  {STRINGS.COMPLIANCE.FORM.THIRDPARTY_TITLE}
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.THIRDPARTY_NAME}
                  </div>
                  <input
                    type="text"
                    id="thirdPartyName"
                    name="thirdPartyName"
                    className={
                      thirdPartyNameError.length > 0
                        ? "[ form-control ] [ invalid-input ]"
                        : "[ form-control ]"
                    }
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.THIRDPARTY_NAME_PLACEHOLDER
                    }
                    value={thirdPartyName}
                    onChange={this.changeHandler}
                  />
                  <div className="[ error-msg ] ">{thirdPartyNameError}</div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.THIRDPARTY_RELATIONSHIP}
                  </div>
                  <select
                    className="[ form-control ] [ select__dropdown ]"
                    id="thirdpPartyRelationship"
                    name="thirdpPartyRelationship"
                    value={thirdpPartyRelationship}
                    onChange={this.changeHandler}
                  >
                    <option
                      value=""
                      className="[ default ] [ dropdown__option ]"
                    >
                      {
                        STRINGS.COMPLIANCE.FORM
                          .THIRDPARTY_RELATIONSHIP_PLACEHOLDER
                      }
                    </option>
                    {indicateWhoArray?.map((obj) => {
                      return (
                        <option
                          key={obj.code}
                          value={obj.description}
                          className="[ dropdown__option ]"
                        >
                          {obj.description}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : null}
          </div>

          {/* Start PEP block */}
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.MEMBER_PEP_HIO_CHOICE}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    areYouPEPorHIO
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handlePEPChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.AREYOUPEPORHIO,
                      true
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    areYouPEPorHIO === false
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handlePEPChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME.AREYOUPEPORHIO,
                      false
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]" />
            {/* Start PEP Inner block */}
            {areYouPEPorHIO ? (
              <div className="[ drawer-content-container ]">
                <div className="[ info-title ]">
                  {STRINGS.COMPLIANCE.FORM.PERSON_TITLE}
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.HOI_CHOICE}
                  </div>

                  <div className="[ row ] [ custom-radios ]">
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          memberPEPDomestic
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handleMemberPEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERPEPDOMESTIC
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.PEP_DOMESTIC}
                      </button>
                    </div>
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          memberPEPForeign
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handleMemberPEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERPEPFOREIGN
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.PEP_FOREIGN}
                      </button>
                    </div>
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          memberHIO
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handleMemberPEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.MEMBERHIO
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.HOI}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.OFFICE_HELD_LABEL}
                  </div>
                  <input
                    type="text"
                    id="memberOfficeHeld"
                    name="memberOfficeHeld"
                    className="form-control"
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.OFFICE_HELD_PLACEHOLDER
                    }
                    value={memberOfficeHeld}
                    onChange={(event) => this.changeHandler(event)}
                  />
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.JURISDICTION_LABEL}
                  </div>
                  <input
                    type="text"
                    id="memberJurisdiction"
                    name="memberJurisdiction"
                    className="form-control"
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.JURISDICTION_PLACEHOLDER
                    }
                    value={memberJurisdiction}
                    onChange={(event) => this.changeHandler(event)}
                  />
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.SOURCE_OF_FUNDS_LABEL}
                  </div>
                  <input
                    type="text"
                    id="memberSourceOfFunds"
                    name="memberSourceOfFunds"
                    className="form-control"
                    maxLength={40}
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.SOURCE_OF_FUNDS_PLACEHOLDER
                    }
                    value={memberSourceOfFunds}
                    onChange={(event) => this.changeHandler(event)}
                  />
                </div>
              </div>
            ) : null}

            {/* End PEP Inner block */}
            {/* End PEP block */}
          </div>

          {/* Start HIO block */}

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.COMPLIANCE.FORM.PEP_HIO_CHOICE}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isAnyoneConsideredPEPHIO
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handlePEPChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME
                        .ISANYONECONSIDEREDPEPORHIO,
                      true
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isAnyoneConsideredPEPHIO === false
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() =>
                    this.handlePEPChoice(
                      STRINGS.COMPLIANCE.FORM.FIELDNAME
                        .ISANYONECONSIDEREDPEPORHIO,
                      false
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
              <div className="[ error-msg ]" />
            </div>
            {/* Start HIO inner block */}

            {isAnyoneConsideredPEPHIO ? (
              <div className="[ drawer-content-container ]">
                <div className="[ info-title ]">
                  {STRINGS.COMPLIANCE.FORM.PERSON_TITLE}
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.PERSON_NAME}
                  </div>
                  <input
                    type="text"
                    id="indicateName"
                    name="indicateName"
                    className={
                      indicateNameError.length > 0
                        ? "[ form-control ] [ invalid-input ]"
                        : "[ form-control ]"
                    }
                    placeholder={STRINGS.COMPLIANCE.FORM.PERSON_NAME_PLAEHOLDER}
                    value={indicateName}
                    onChange={this.changeHandler}
                  />
                  <div className="[ error-msg ]">{indicateNameError}</div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.THIRDPARTY_RELATIONSHIP}
                  </div>
                  <select
                    className="[ form-control ] [ select__dropdown ]"
                    id="indicateRelationship"
                    name="indicateRelationship"
                    value={indicateRelationship}
                    onChange={this.changeHandler}
                  >
                    <option
                      value=""
                      className="[ default ] [ dropdown__option ]"
                    >
                      {
                        STRINGS.COMPLIANCE.FORM
                          .THIRDPARTY_RELATIONSHIP_PLACEHOLDER
                      }
                    </option>
                    {indicateWhoArray?.map((obj) => {
                      return (
                        <option
                          key={obj.code}
                          value={obj.description}
                          className="[ dropdown__option ]"
                        >
                          {obj.description}
                        </option>
                      );
                    })}
                  </select>
                  <div className="[ error-msg ]" />
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.HOI_CHOICE}
                  </div>
                  <div className="[ row ] [ custom-radios ]">
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          PEPDomestic
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handlePEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.PEPDOMESTIC
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.PEP_DOMESTIC}
                      </button>
                    </div>
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          PEPForeign
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handlePEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.PEPFOREIGN
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.PEP_FOREIGN}
                      </button>
                    </div>
                    <div className="[ col-md-4 col-12 ]">
                      <button
                        type="button"
                        className={
                          HIO
                            ? "[ btn custom-radio-selected ]"
                            : "[ btn custom-radio-unselected ]"
                        }
                        onClick={() =>
                          this.handlePEPHIOChoice(
                            STRINGS.COMPLIANCE.FORM.FIELDNAME.HIO
                          )
                        }
                      >
                        {STRINGS.COMPLIANCE.CHOICE.HOI}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.OFFICE_HELD_LABEL}
                  </div>

                  <input
                    type="text"
                    id="OfficeHeld"
                    name="OfficeHeld"
                    className="form-control"
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.OFFICE_HELD_PLACEHOLDER
                    }
                    value={OfficeHeld}
                    onChange={(event) => this.changeHandler(event)}
                  />
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.JURISDICTION_LABEL}
                  </div>
                  <input
                    type="text"
                    id="Jurisdiction"
                    name="Jurisdiction"
                    className="form-control"
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.JURISDICTION_PLACEHOLDER
                    }
                    value={Jurisdiction}
                    onChange={(event) => this.changeHandler(event)}
                  />
                </div>

                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.COMPLIANCE.FORM.SOURCE_OF_FUNDS_LABEL}
                  </div>
                  <input
                    type="text"
                    id="SourceOfFunds"
                    name="SourceOfFunds"
                    className="form-control"
                    maxLength={40}
                    placeholder={
                      STRINGS.COMPLIANCE.FORM.SOURCE_OF_FUNDS_PLACEHOLDER
                    }
                    value={SourceOfFunds}
                    onChange={(event) => this.changeHandler(event)}
                  />
                </div>
              </div>
            ) : null}

            {/* End HIO inner block */}
            {/* End HIO block */}
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

Compliance.propTypes = {
  accountPurposeArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  indicateWhoArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleUpdateComplianceData: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

Compliance.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
};

export default Compliance;
