/* eslint-disable no-console */
import React, { Component } from "react";
import PropTypes from "prop-types";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import checkErrors from "Utils/InputValidator";

class ScanIdBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanError: "",
      capture: null,
      capturedImage: null,
    };
  }

  componentDidMount() {
    this.setState({ capture: window.IDMetricsCaptureFramework });
  }

  scanBackID = () => {
    const { capture } = this.state;
    const captureResult = new window.IDMetricsCaptureFramework.CaptureResult();
    const docSettings = new window.IDMetricsCaptureFramework.DocumentSettings();
    docSettings.documentSide =
      STRINGS.ABOUTYOU.PARTTHREE.DOCUMENTSETTING.DOCUMENTSIDE;
    docSettings.documentType =
      STRINGS.ABOUTYOU.PARTTHREE.DOCUMENTSETTING.DOCUMENTTYPE;
    docSettings.captureMode = STRINGS.ABOUTYOU.COMMONPART.CAPTUREMODE;
    docSettings.backCaptureMode =
      STRINGS.ABOUTYOU.PARTTHREE.DOCUMENTSETTING.CAPTUREMODE;
    docSettings.backFocusThreshold =
      window.navigator.userAgent.search(/Edge/g) >= 0 ? 10 : 30;
    docSettings.backGlareThreshold =
      STRINGS.ABOUTYOU.PARTTHREE.DOCUMENTSETTING.GLARETHRESHOLD;
    docSettings.compressionType =
      STRINGS.ABOUTYOU.PARTTHREE.DOCUMENTSETTING.COMPRESSIONTYPE;
    docSettings.backCompressionQuality =
      STRINGS.ABOUTYOU.PARTTHREE.DOCUMENTSETTING.COMPRESSIONQUALITY;
    docSettings.backCaptureAttempts =
      STRINGS.ABOUTYOU.PARTTHREE.DOCUMENTSETTING.CAPTUREATTEMPTS;
    docSettings.setManualTimeout =
      STRINGS.ABOUTYOU.PARTTHREE.DOCUMENTSETTING.MANUALTIMEOUT;
    docSettings.isBarcodeDetectedEnabled = false;
    docSettings.enableLocationDetection = true;
    capture.scanDocument(docSettings, captureResult);
    captureResult.setOnFinish(() => this.scanResult(captureResult));
    captureResult.setOnEvent((statusCode, description, data) => {
      console.debug(
        `DOC EVENT! Code=${statusCode}, Description=${description}`
      );
      console.debug(data);
      console.debug(docSettings);
      captureResult.continue();
    });
  };

  scanResult = (captureResult) => {
    this.setState({ capturedImage: captureResult.result, scanError: "" });
  };

  submitData = (imgSrc) => {
    const { scanIdBackHandler } = this.props;
    if (this.verifyData(imgSrc)) {
      scanIdBackHandler(imgSrc);
    }
  };

  verifyData = (imgSrc) => {
    const imageSource = imgSrc || "";
    const idErrorsList = checkErrors(imageSource, VALIDATIONS.ABOUTYOU.IDBACK);
    if (idErrorsList && idErrorsList.length > 0) {
      this.setState({ scanError: idErrorsList });
      return false;
    }
    return true;
  };

  render() {
    const { scanError, capturedImage } = this.state;
    const { idBack, idName } = this.props;
    let scanErrorDOM = null;
    if (scanError) {
      scanErrorDOM = (
        <div className="[ scan-error__card ]">
          {scanError.map((error) => (
            <div className="[ error-msg ]" key="error-msg">
              {error}
            </div>
          ))}
          <div className="[ row ] [ info-row ]" />
          <div className="[ row ] [ info-row ] " />
        </div>
      );
    }
    let imageDiv = (
      <div
        className="[ scan-id__card ]"
        onClick={this.scanBackID}
        onKeyDown={this.handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <div className="[ flex-row justify-content-center text-center ]">
          <i className="[ fas fa-credit-card ]" />
        </div>
        <div className="[ scan-id__text] ">{STRINGS.ABOUTYOU.CLICKTOBEGIN}</div>
      </div>
    );

    let imgSrc = null;
    if (idBack) {
      imgSrc = idBack;
    }
    if (capturedImage) {
      imgSrc = capturedImage;
    }
    if (imgSrc) {
      imageDiv = (
        <div className="[ scan-id__card ]">
          <img
            className="[ scan-id__card__img ]"
            src={imgSrc}
            alt="scanned back side of ID"
          />
        </div>
      );
    }
    return (
      <>
        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTTHREE.SUBTITILE}
          </div>
          <div className="[ form-description ]">
            {/* {STRINGS.ABOUTYOU.PARTTHREE.ABOUTYOUINFO + idName} */}
            {STRINGS.ABOUTYOU.PARTTHREE.ABOUTYOUINFO}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ] [ d-flex ] [ justify-content-center ]">
              <div className="[ col-12 ]">{imageDiv}</div>
              <div className="[ col-12 ]">{scanErrorDOM}</div>
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <button
                  type="button"
                  className="[ btn ] [ retake ]"
                  onClick={this.scanBackID}
                  disabled={capturedImage === null}
                >
                  {STRINGS.ABOUTYOU.RETAKE}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={() => this.submitData(imgSrc)}
              >
                <span className="[ d-md-none d-block ]">
                  {STRINGS.COMMON.CONTINUEBTN}
                </span>
                <span className="[ d-none d-md-block ]">
                  {STRINGS.ABOUTYOU.UPLOADANDCONTINUE}
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ScanIdBack.propTypes = {
  scanIdBackHandler: PropTypes.func,
  idBack: PropTypes.string,
  idName: PropTypes.string,
};

ScanIdBack.defaultProps = {
  scanIdBackHandler: () => {},
  idBack: null,
  idName: null,
};

export default ScanIdBack;
