import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import STRINGS from "Constants/Strings";
import ProductCard from "./ProductCard";

class ProductCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isCardAddedToVault = (card) => {
    const { vaultProductList } = this.props;
    if (vaultProductList && vaultProductList.length !== 0) {
      const index = vaultProductList.findIndex(
        (elem) => elem.productId === card.productId
      );
      if (index !== -1) return true;
    }
    return false;
  };

  render() {
    const { productList, handleAddToVault, handleSeeDetailsClick } = this.props;

    const renderCard = (cardArray) => {
      return (
        <div className="[ col-12 ]">
          <div className="[ overflow-auto ] [ text-nowrap ] [ carousel-container ]">
            {cardArray.map((product) => {
              return (
                product.show && (
                  <ProductCard
                    key={product.productId}
                    product={product}
                    handleAddToVault={handleAddToVault}
                    addToVault
                    isCardAddedToVault={this.isCardAddedToVault}
                    handleSeeDetailsClick={handleSeeDetailsClick}
                  />
                )
              );
            })}
          </div>
        </div>
      );
    };

    const bankingList = productList.map((elem) => (
      <div
        className={elem.show ? "[ product-card-container ]" : "[ d-none ]"}
        key={elem.id}
      >
        <div className="[ row ]">
          <div className="[ col-12 ] [ form-subtitle ]">{elem.title}</div>
        </div>
        <div className="[ row ]">{renderCard(elem.products)}</div>
      </div>
    ));

    return (
      <>
        <div className="[ form-title ]">
          {STRINGS.MARKETPLACE.BODY.PERSONAL_BANKING}
        </div>
        {bankingList}
      </>
    );
  }
}

ProductCardContainer.propTypes = {
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  handleAddToVault: PropTypes.func,
  handleSeeDetailsClick: PropTypes.func,
};

ProductCardContainer.defaultProps = {
  productList: [],
  vaultProductList: [],
  handleAddToVault: () => {},
  handleSeeDetailsClick: () => {},
};

const mapStateToProps = (state) => ({
  productList: state.MarketplaceReducer.productList,
  vaultProductList: state.VaultReducer.vaultProductList,
});

export default connect(mapStateToProps)(ProductCardContainer);
