import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import {
  validateSIN,
  validateAmount,
} from "Utils/EmploymentAndIncomeUtilities";

const VALIDATIONSLIST = {
  SIGNIN: {
    username: {
      fieldName: STRINGS.SIGNIN.FORM.USERNAMELABEL,
      required: true,
      pattern: AppConstants.SIGNIN.EMAILPATTERN,
    },
    password: {
      fieldName: STRINGS.SIGNIN.FORM.PASSWORDLABEL,
      required: true,
    },
  },
  REGISTRATION: {
    email: {
      fieldName: STRINGS.REGISTRATION.FORM.EMAILLABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.EMAILPATTERN,
    },
    mobileno: {
      fieldName: STRINGS.REGISTRATION.FORM.MOBILENOLABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.MOBILENOPATTERN,
    },
    termscondition: {
      required: true,
    },
  },
  VERIFICATIONCODE: {
    verificationcode: {
      fieldName: STRINGS.VERIFICATIONCODE.FORM.VERIFICATIONCODELABEL,
      required: true,
    },
  },
  EMPLOYMENT_AND_INCOME: {
    ssn: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.SSN,
      required: true,
      equalLength: AppConstants.EMPLOYMENTANDINCOME.SSNLENGTH,
    },
    sin: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.SIN,
      required: true,
      pattern: validateSIN,
    },
    listOfCountries: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.LIST_OF_COUNTRIES,
      required: true,
    },
    taxrecidentconfirmation: {
      fieldName:
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.TAX_RESIDENT_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
    taxrecidentofothercountryconfirmation: {
      fieldName:
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR
          .TAX_RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
    country: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.COUNTRY,
      fieldType: "select",
      required: true,
    },
    taxidentificatinnumber: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.TAX_IDENTITY_NO,
      required: true,
    },
    citizenshipconfirmation: {
      fieldName:
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR
          .RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION,
      fieldType: "select",
      required: true,
    },
    employmentStatus: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.EMPLOYMENT_STATUS,
      fieldType: "select",
      required: true,
    },
    jobTitle: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.JOB_TITLE,
      required: true,
    },
    employerName: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.EMPLOYER_NAME,
      required: true,
    },
    employerContactNumber: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.EMPLOYER_CONTACT_NO,
      pattern: AppConstants.REGISTRATION.MOBILENOPATTERN,
    },
    monthlyGrossIncome: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.MONTHLY_GROSS_INCOME,
      pattern: validateAmount,
    },
    sourceOfIncome: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.SOURCE_OF_INCOME,
      fieldType: "select",
      required: true,
    },
  },

  TERMS_AND_CONDITIONS: {
    tearmAndCondition: {
      fieldName: STRINGS.MEMBERCREATION.TERMS_ERROR_TEXT,
      fieldType: "checkbox",
      required: true,
    },
  },

  GETTING_STARTED: {
    coapplicantName: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.NAME_ERROR,
      required: true,
    },
    canadianResidencyStatus: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.RESIDENT_STATUS,
      fieldType: "select",
    },
    confirmJointAccount: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.CONFIRM_JOINT_ACCOUNT,
      fieldType: "select",
      required: true,
    },
    selectBranch: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.SELECT_BRANCH,
      fieldType: "select",
      required: true,
    },
    selectCreditRequired: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.SELECT_CREDIT_REQUIRED,
      fieldType: "select",
      required: true,
    },
    selectAccountStatementRequired: {
      fieldName:
        STRINGS.GETTING_STARTED.ERROR
          .SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT_REQUIRED,
      fieldType: "select",
      required: true,
    },
    overdraftProtectionRequired: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.OVERDRAFT_PROTECTION_REQUIRED,
      fieldType: "select",
      required: true,
    },
  },
  COMPLIANCE: {
    isThirdPartyAccount: {
      fieldName: STRINGS.COMPLIANCE.FORM.THIRDPARTY_ACCOUNT_ERROR,
      fieldType: "select",
      required: true,
    },
    thirdPartyName: {
      fieldName: STRINGS.COMPLIANCE.FORM.THIRDPARTY_NAME_ERROR,
      fieldType: "textbox",
      required: true,
    },
    indicateName: {
      fieldName: STRINGS.COMPLIANCE.FORM.PERSON_NAME_ERROR,
      fieldType: "textbox",
      required: true,
    },
    selectAccountPurpose: {
      fieldName: STRINGS.COMPLIANCE.FORM.ACCOUNT_PURPOSE_ERROR,
      fieldType: "select",
      required: true,
    },
  },
  ABOUTYOU: {
    ID: {
      fieldName: STRINGS.ABOUTYOU.PARTONE.IDERROR,
      fieldType: "select",
      required: true,
    },
    IDFRONT: {
      fieldName: STRINGS.ABOUTYOU.PARTTWO.IDFRONTLABEL,
      required: true,
    },
    IDBACK: {
      fieldName: STRINGS.ABOUTYOU.PARTTHREE.IDBACKLABEL,
      required: true,
    },
    SELFIE: {
      fieldName: STRINGS.ABOUTYOU.PARTFOUR.SELFIELABEL,
      required: true,
    },
    FIRSTNAME: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL,
      required: true,
      pattern: AppConstants.FIRSTNAMEPATTERN,
    },
    LASTNAME: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL,
      required: true,
      pattern: AppConstants.LASTNAMEPATTERN,
    },
    DOB: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL,
      required: true,
      pattern: AppConstants.ABOUT_YOU.DOBPATTERN,
    },
    ADDRESS: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS1,
      required: true,
    },
    CITY: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY,
      required: true,
    },
    PROVINCE: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE,
      fieldType: "select",
      required: true,
    },
    CA_POSTALCODE: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE,
      required: true,
      pattern: AppConstants.ABOUT_YOU.CA_POSTALPATTERN,
    },
    US_POSTALCODE: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE,
      required: true,
      pattern: AppConstants.ABOUT_YOU.US_POSTALPATTERN,
    },
    SCANCONFIRMATION: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.SCANLABEL,
      fieldType: "select",
      required: true,
    },
    ADDRESSCONFIRMATION: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESSCONFIRMLABEL,
      fieldType: "select",
      required: true,
    },
    COUNTRY: {
      fieldName: STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY,
      required: true,
    },
  },

  ACCOUNT_INFORMATION: {
    INTENDEDUSE: {
      fieldName: STRINGS.ACCOUNT_INFORMATION.ERROR.SELECT_INTENDED_USE,
      fieldType: "select",
      required: true,
    },
  },
  OVERDRAFT_PROTECTION: {
    overdraftProtection: {
      fieldName: STRINGS.GETTING_STARTED.ERROR.OVERDRAFT_PROTECTION_REQUIRED,
      fieldType: "select",
      required: true,
    },
  },
  BENEFICIARIES: {
    BENEFICIARY: {
      fieldName: STRINGS.BENEFICIARIES.ERROR.BENEFICIARY_ERROR,
      fieldType: "select",
      required: true,
    },
    FIRSTNAME: {
      fieldName: STRINGS.BENEFICIARIES.FORM.FIRSTNAMELABEL,
      required: true,
      pattern: AppConstants.FIRSTNAMEPATTERN,
    },
    LASTNAME: {
      fieldName: STRINGS.BENEFICIARIES.FORM.LASTNAMELABEL,
      required: true,
      pattern: AppConstants.LASTNAMEPATTERN,
    },
    DOB: {
      fieldName: STRINGS.BENEFICIARIES.FORM.DOBLABEL,
      required: true,
      pattern: AppConstants.ABOUT_YOU.DOBPATTERN,
    },
    RELATION: {
      fieldName: STRINGS.BENEFICIARIES.FORM.RELATIONSHIP,
      fieldType: "select",
      required: true,
    },
    BENEFICIARY_TYPE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.BENEFICIARY_TYPE,
      fieldType: "select",
      required: true,
    },
    ADDRESS: {
      fieldName: STRINGS.BENEFICIARIES.FORM.ADDRESS1,
      required: true,
    },
    CITY: {
      fieldName: STRINGS.BENEFICIARIES.FORM.CITY,
      required: true,
    },
    PROVINCE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.PROVINCE,
      fieldType: "select",
      required: true,
    },
    CA_POSTALCODE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.POSTALCODE,
      required: true,
      pattern: AppConstants.ABOUT_YOU.CA_POSTALPATTERN,
    },
    US_POSTALCODE: {
      fieldName: STRINGS.BENEFICIARIES.FORM.POSTALCODE,
      required: true,
      pattern: AppConstants.ABOUT_YOU.US_POSTALPATTERN,
    },
    MOBILE_NO: {
      fieldName: STRINGS.BENEFICIARIES.FORM.MOBILENOLABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.MOBILENOPATTERN,
    },
    COUNTRY: {
      fieldName: STRINGS.BENEFICIARIES.FORM.COUNTRY,
      fieldType: "select",
      required: true,
    },
    SIN: {
      fieldName: STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.SIN,
      required: true,
      pattern: validateSIN,
    },
  },
  JOINT_APPLICANT: {
    EMAIL: {
      fieldName: STRINGS.JOINT_APPLICANT.ERROR.EMAIL_ERROR,
      required: true,
      pattern: AppConstants.REGISTRATION.EMAILPATTERN,
    },
    MOBILE: {
      fieldName: STRINGS.JOINT_APPLICANT.ERROR.MOBILE_ERROR,
      required: true,
    },
    TYPE: {
      fieldName: STRINGS.JOINT_APPLICANT.ERROR.TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
  },
  LOANS: {
    DETAIL: {
      MORTGAGE_PROPERTY: {
        fieldName: STRINGS.LOAN_DETAILS.FORM.MORTGAGE.ERROR.VALUE_REQUIRED,
        amount: true,
      },
      VEHICLE: {
        fieldName: STRINGS.LOAN_DETAILS.FORM.AUTO.ERROR.VEHICLE_PRICE_REQUIRED,
        amount: true,
      },
      PROPERTY: {
        fieldName:
          STRINGS.LOAN_DETAILS.FORM.HELOC.ERROR.ASSESSED_VALUE_REQUIRED,
        amount: true,
      },
      MORTAGE_BALANCE: {
        fieldName:
          STRINGS.LOAN_DETAILS.FORM.HELOC.ERROR.MORTGAGE_BALANCE_REQUIRED,
        amount: true,
      },
      HELOC_AMOUNT: {
        fieldName: STRINGS.LOAN_DETAILS.FORM.HELOC.ERROR.HELOC_AMOUNT_REQUIRED,
        amount: true,
      },
    },
    INCOME: {
      INCOME_TYPE: {
        fieldName: STRINGS.INCOME.ERROR.INCOME_TYPE_REQUIRED,
        fieldType: "select",
        required: true,
      },
      DESCRIPTION: {
        fieldName: STRINGS.INCOME.ERROR.DESCRIPTION_REQUIRED,
        required: true,
      },
      MONTHLY_AMOUNT: {
        fieldName: STRINGS.INCOME.ERROR.MONTHLY_AMOUNT_REQUIRED,
        required: true,
      },
    },
    EXPENSE: {
      EXPENSE_TYPE: {
        fieldName: STRINGS.EXPENSE.ERROR.EXPENSE_TYPE_REQUIRED,
        fieldType: "select",
        required: true,
      },
      DESCRIPTION: {
        fieldName: STRINGS.EXPENSE.ERROR.DESCRIPTION_REQUIRED,
        required: true,
      },
      MONTHLY_AMOUNT: {
        fieldName: STRINGS.EXPENSE.ERROR.MONTHLY_AMOUNT_REQUIRED,
        required: true,
      },
    },
    VEHICLE: {
      YEAR: {
        fieldName: STRINGS.SECURITY_VEHICLE.ERROR.YEAR_REQUIRED,
        fieldType: "select",
        required: true,
      },
      MAKE: {
        fieldName: STRINGS.SECURITY_VEHICLE.ERROR.MAKE_REQUIRED,
        required: true,
      },
      MODEL: {
        fieldName: STRINGS.SECURITY_VEHICLE.ERROR.MAKE_REQUIRED,
        required: true,
      },
      VIN: {
        fieldName: STRINGS.SECURITY_VEHICLE.ERROR.VIN_REQUIRED,
        required: true,
      },
    },
  },
  ASSET: {
    ASSET_TYPE: {
      fieldName: STRINGS.ASSET.ERROR.ASSET_TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    DESCRIPTION: {
      fieldName: STRINGS.ASSET.ERROR.DESCRIPTION_REQUIRED,
      required: true,
    },
    VALUE: {
      fieldName: STRINGS.ASSET.ERROR.VALUE_REQUIRED,
      required: true,
    },
    INSTITUTION: {
      fieldName: STRINGS.ASSET.ERROR.ORGANIZATION_REQUIRED,
      fieldType: "select",
      required: true,
    },
  },
  LIABILITIES: {
    SUB_TYPE: {
      fieldName: STRINGS.LIABILITIES.ERROR.SUB_TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    DESCRIPTION: {
      fieldName: STRINGS.LIABILITIES.ERROR.DESCRIPTION_REQUIRED,
      required: true,
    },
    VALUE: {
      fieldName: STRINGS.LIABILITIES.ERROR.VALUE_REQUIRED,
      required: true,
    },
    INSTITUTION: {
      fieldName: STRINGS.LIABILITIES.ERROR.ORGANIZATION_REQUIRED,
      fieldType: "select",
      required: true,
    },
    SECURED_TYPE: {
      fieldName: STRINGS.LIABILITIES.ERROR.SECURED_TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    ADJUSTMENT: {
      fieldName: STRINGS.LIABILITIES.ERROR.ADJUSTMENT_REQUIRED,
      fieldType: "select",
      required: true,
    },
    LIMIT: {
      fieldName: STRINGS.LIABILITIES.ERROR.LIMIT_REQUIRED,
      required: true,
    },
    AVG_PAYMENT: {
      fieldName: STRINGS.LIABILITIES.ERROR.AVG_PAYMENT_REQUIRED,
      required: true,
    },
    RATE: {
      fieldName: STRINGS.LIABILITIES.ERROR.RATE_REQUIRED,
      required: true,
    },
    PAYMENT_TYPE: {
      fieldName: STRINGS.LIABILITIES.ERROR.PAYMENT_TYPE_REQUIRED,
      fieldType: "select",
      required: true,
    },
    LOAN_FOR_DEBT_AMOUNT: {
      fieldName: STRINGS.LIABILITIES.ERROR.LOAN_FOR_DEBT_AMOUNT_REQUIRED,
      required: true,
    },
  },
};

export default VALIDATIONSLIST;
