import React, { Component } from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import { TOKEN_KEY } from "Communication/Constants";
import ConfirmPopupModal from "Components/Common/ConfirmPopupModal/ConfirmPopupModal";
import AppConstants from "Constants/AppConstants";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import * as ROUTES from "Constants/Routes";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import {
  COMMON_BUCKET_URL,
  PRODUCT_IMAGE_URL,
} from "../../Communication/ApiUrls";
import { getVaultCardProductList } from "../../Utils/CommonUtilities";
import "./Vault.scss";

class Vault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modal: {},
      selectCard: {},
      confirmShowModal: false,
      confirmModal: {},
    };
  }

  componentDidMount() {
    const { doGetProductFromVault } = this.props;
    const accessToken = sessionStorage.getItem(TOKEN_KEY);
    if (accessToken) {
      doGetProductFromVault();
    }
  }

  toggleModal = () => {
    const { showModal } = this.state;
    const modal = {};
    this.setState({ showModal: !showModal, modal });
  };

  detailsClick = (card) => {
    const modal = {};
    modal.header = card.productName;
    modal.productDescription = card.productDescription;
    modal.title = card.productName;
    modal.subtitle = card.productCategory;
    modal.productLogo = card.productLogo;
    modal.addedToVault = true;
    modal.description = (
      <div dangerouslySetInnerHTML={{ __html: card.productDetails }} />
    );
    this.setState({
      showModal: true,
      modal,
      selectCard: card,
    });
  };

  isActiveProduct = (index) => {
    const accessToken = sessionStorage.getItem(TOKEN_KEY);
    const { history } = this.props;
    const { location } = history;
    return (
      index === 0 &&
      accessToken &&
      !(
        location.pathname === ROUTES.VAULT_DASHBOARD ||
        location.pathname === ROUTES.VAULT_PASSWORD ||
        location.pathname === ROUTES.SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD
      )
    );
  };

  renderCards = () => {
    const { confirmShowModal } = this.state;
    const { productList, vaultProductList } = this.props;
    const vaultCardProductList = getVaultCardProductList(
      productList,
      vaultProductList
    );

    return (
      <div className="[ overflow-auto ] [ px-2 ] [ vault-card-body ]">
        {vaultCardProductList.map((card, index) => (
          <div
            className={`[ vault-container__card ]  ${
              this.isActiveProduct(index)
                ? " [ active-vault-container__card_bg-color ]"
                : " [ vault-container__card_bg-color ]"
            }`}
            key={card.productIndex}
          >
            <div className="[ vault-container__card__header ]">
              <div className="[ product-icon-container ]">
                <img
                  src={`${PRODUCT_IMAGE_URL}${card.productLogo}`}
                  className=" [ product-icon-container__img ]"
                  alt="Product name"
                />
              </div>
            </div>
            <div className="[ vault-container__card__body ]">
              <Card.Title
                className={`[ vault-container__card__body__title-container ] [ d-flex justify-content-between ] ${
                  this.isActiveProduct(index)
                    ? " [ active-container__card_color ]"
                    : " [ vault-container__card__body__title_color ]"
                }`}
              >
                <span className="[ vault-container__card__body__title ]">
                  {card.productName}
                </span>
                <span
                  role="presentation"
                  className={`[ fas fa-trash ] ${
                    this.isActiveProduct(index)
                      ? " [ active-container__card_color ]"
                      : ""
                  }`}
                  onClick={() => {
                    const modal = {};
                    modal.title = STRINGS.VAULT.DELETE_TITLE;
                    modal.description = STRINGS.VAULT.DELETE_DESCRIPTION;
                    this.setState({
                      confirmShowModal: !confirmShowModal,
                      confirmModal: modal,
                      selectCard: card,
                    });
                  }}
                />
              </Card.Title>
              <Card.Subtitle
                className={`[ pb-2 ] [ vault-container__card__body__subtitle ] ${
                  this.isActiveProduct(index)
                    ? " [ active-container__card_color ]"
                    : " [ vault-container__card__body__subtitle_color ]"
                }`}
              >
                {card.productDescription}
              </Card.Subtitle>
              <Card.Link
                href="#"
                className={`[ vault-container__card__body__link ] ${
                  this.isActiveProduct(index)
                    ? " [ active-container__card_color ]"
                    : ""
                }`}
                onClick={() => this.detailsClick(card)}
              >
                {STRINGS.VAULT.PRODUCTDETAILSLINK}
                <span
                  className={`[ icon ] [ product-navigation-icon ] ${
                    this.isActiveProduct(index)
                      ? " [ active-container__card_color ]"
                      : ""
                  }`}
                />
              </Card.Link>
            </div>
          </div>
        ))}
      </div>
    );
  };

  handleSignIn = () => {
    const {
      showVaultClicked,
      proceedWithApplication,
      navigateToNextProduct,
      sessionCompletedProducts,
    } = this.props;
    showVaultClicked();
    proceedWithApplication(() => {
      if (sessionCompletedProducts && sessionCompletedProducts.length > 0) {
        navigateToNextProduct();
      }
    });
  };

  /**
   * Handled Yes clicked event of delete product popup
   */

  handleYesClick = () => {
    const {
      deleteApplication,
      showVaultClicked,
      history,
      vaultProductList,
    } = this.props;
    const { selectCard } = this.state;
    deleteApplication(selectCard);
    const activeRoute = history.location?.pathname;
    if (
      (activeRoute === ROUTES.EXISTING_MEMBER ||
        activeRoute === ROUTES.MEMBER_CREATION ||
        activeRoute === ROUTES.ABOUT_YOU ||
        activeRoute === ROUTES.GETTING_STARTED ||
        activeRoute === ROUTES.COMPLIANCE ||
        activeRoute === ROUTES.EMPLOYMENT_AND_INCOME ||
        activeRoute === ROUTES.CONFIRM_ACCOUNT_CREATE) &&
      vaultProductList[0].productIndex === selectCard.productIndex
    ) {
      showVaultClicked();
    }
  };

  /**
   * Handled No clicked event of  delete product popup
   */
  handleNoClick = () => {};

  handleBtnClick = () => {
    const { confirmShowModal } = this.state;
    const modal = {};
    modal.title = STRINGS.VAULT.DELETE_TITLE;
    modal.description = STRINGS.VAULT.DELETE_DESCRIPTION;
    this.setState({
      confirmShowModal: !confirmShowModal,
      confirmModal: modal,
    });
  };

  confirmToggleModal = () => {
    const { confirmShowModal } = this.state;
    this.setState({
      confirmShowModal: !confirmShowModal,
      confirmModal: {},
    });
  };

  render() {
    const { showProceedBtn, vaultProductList, showVaultClicked } = this.props;
    const { showModal, modal, confirmShowModal, confirmModal } = this.state;
    const productCountText = STRINGS.VAULT.PRODUCT_COUNT_TITLE;
    console.log(modal);
    return (
      <>
        {confirmShowModal && (
          <ConfirmPopupModal
            title={confirmModal.title}
            description={confirmModal.description}
            toggleModal={this.confirmToggleModal}
            showModal={confirmShowModal}
            noBtnClick={this.handleNoClick}
            yesBtnClick={this.handleYesClick}
            closeBtnClick={this.handleNoClick}
          />
        )}
        {showModal && (
          <PopupModal
            modalType={AppConstants.MODALTYPE.CUSTOM}
            title={modal.title}
            subtitle={modal.subtitle}
            description={modal.description}
            productLogo={modal.productLogo}
            showModal={showModal}
            closeBtnClick={this.toggleModal}
            toggleModal={this.toggleModal}
            addedToVault={modal.addedToVault}
            btnText={STRINGS.MARKETPLACE.BODY.REMOVE_FROM_VAULT}
            popupBtnClick={this.handleBtnClick}
            shortDescription={modal.productDescription}
            showPopupModalButtons
            seeDetailsClick
          />
        )}
        <div className="[ vault-container ]">
          <div className="[ vault-container__inner-container ]">
            <div className="[ vault-header__container ]">
              <h4 className="[ form-subtitle ]">
                <i className="fas fa-shopping-cart" />

                {vaultProductList.length !== 0
                  ? productCountText.replace("$", vaultProductList.length)
                  : STRINGS.VAULT.VAULTTITLE}

                <button
                  type="button"
                  className="[ close ]"
                  onClick={showVaultClicked}
                >
                  <span aria-hidden="true">×</span>
                  <span className="[ sr-only ]">Close</span>
                </button>
              </h4>
            </div>

            <div className="[]" />
            {vaultProductList.length !== 0 ? (
              this.renderCards()
            ) : (
              <div className="[ text-center ] [ p-5 ] [ mt-5 ]">
                <div className="">{STRINGS.VAULT.EMPTY_VAULT_TEXT}</div>
              </div>
            )}
            {showProceedBtn && (
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col ] [ proceed-btn__container ]">
                    <button
                      type="button"
                      className="[ submit-btn btn ]"
                      onClick={this.handleSignIn}
                      disabled={vaultProductList.length === 0}
                    >
                      {STRINGS.VAULT.PROCEED}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

Vault.propTypes = {
  showProceedBtn: PropTypes.bool,
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]).isRequired,
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]).isRequired,
  showVaultClicked: PropTypes.func,
  deleteApplication: PropTypes.func,
  proceedWithApplication: PropTypes.func,
  doGetProductFromVault: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  navigateToNextProduct: PropTypes.func.isRequired,
  sessionCompletedProducts: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

Vault.defaultProps = {
  history: {},
  showProceedBtn: false,
  showVaultClicked: () => {},
  proceedWithApplication: () => {},
  deleteApplication: () => {},
  doGetProductFromVault: () => {},
};

const VaultWrapper = withApplicantHOC(Vault);

export default withVaultHOC(VaultWrapper);
