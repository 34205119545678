import React, { Component } from "react";
import { connect } from "react-redux";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import doConfirmApplication from "Components/ConfirmAccountCreate/Actions/ConfirmAccountCreateAction";
import AppConstants from "Constants/AppConstants";

import SpousalInfo from "./SpousalInfo";

class SpousalInfoContainer extends Component {
  submitData = (spousalInfo) => {
    const { continuehandler } = this.props;
    continuehandler(spousalInfo, AppConstants.APPLICATIONSTEP.SPOUSAL_INFO);
  };

  render() {
    const { steps, activeStepID, getDataFromServer, handleBack } = this.props;
    return (
      <SpousalInfo
        submitData={this.submitData}
        steps={steps}
        activeStepID={activeStepID}
        getDataFromServer={getDataFromServer}
        handleBack={handleBack}
      />
    );
  }
}

SpousalInfoContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  handleBack: PropTypes.func.isRequired,
};

SpousalInfoContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doConfirmApp: (applicationId, callback) =>
        dispatch(doConfirmApplication(applicationId, callback)),
    },
    dispatch
  );
};

const SpousalInfoWrapper = withApplicantHOC(SpousalInfoContainer);

export default connect(mapDispatchToProps)(SpousalInfoWrapper);
