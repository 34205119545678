import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import SigninRequest from "Communication/ApiClasses/SigninRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import { TOKEN_KEY, IS_LOGOUT } from "Communication/Constants";
import {
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  TIMEOUT,
} from "../../../Communication/Constants";

//  worker
function* handleSignin(action) {
  yield put(doLoadingStart(STRINGS.SIGNIN.API_KEYS.SIGNIN));
  const signinApi = new SigninRequest(action.data);
  try {
    const result = yield call(signinApi.getData);
    sessionStorage.setItem(TOKEN_KEY, result.data.token);
    sessionStorage.setItem(IS_LOGOUT, false);
    sessionStorage.setItem(EXPIRES_AT, result.data.expiresAt);
    sessionStorage.setItem(REFRESH_EXPIRES_AT, result.data.refreshExpiresAt);
    yield put({ type: actionTypes.SIGNIN_RESPONSE, success: result });
    // yield put({
    //   type: actionTypes.GET_ACCESS_TOKEN,
    //   timeout: result.data.expiresAt,
    // });
    yield call(action.callback, result);
  } catch (e) {
    yield put({ type: actionTypes.SIGNIN_ERROR, error: e.message });
  } finally {
    yield put(doLoadingFinish(STRINGS.SIGNIN.API_KEYS.SIGNIN));
  }
}

//  watcher
export default function* signinWatcherSaga() {
  yield takeLatest(actionTypes.SIGNIN_REQUEST, handleSignin);
}
