import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import getAccount from "Redux/Actions/GetAccountAction";
import AppConstants from "Constants/AppConstants";

import {
  getProductFromVault,
  updateVaultProductList,
  updateProductToVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import GettingStarted from "./GettingStarted";
import {
  getLendingProduct,
  updateLendingProduct,
  getLoanProduct,
  updateLoanProductForm,
  getFinancialList,
  getFinancialAnalysisFinancialList,
} from "./Actions/LoanDetailsAction";

class LoanPurposeContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loanPurposeCode: null,
      loanPurposes: [],
      currentLendingProductInfo: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("componentDidUpdate");
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      doGetLoanProduct,
      products,
      getActiveProduct,
      doGetLendingProduct,
      doGetFinancialList,
      doUpdateProductToVault,
      loanProducts,
      continuehandler,
      actionBack,
      handleBack,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    const currentProduct = loanProducts.find(
      (product) => product.id === activeProduct.productId
    );
    console.log(currentProduct);
    this.setState({
      loanPurposes: currentProduct.purpose,
    });

    this.setState({
      loanPurposeCode: activeProduct.loanPurposeCode,
    });
    // TODO correct code below
    // doGetLendingProduct(activeProduct.applicationId, activeProduct.doximProductId, (res) => {
    //   console.log(res);
    //   this.setState({
    //     loanPurposeCode: res.lpBasicDetails.PurposeCode,
    //     currentLendingProductInfo: res,
    //   })
    // })

    if (!activeProduct.finAnalysisId) {
      doGetFinancialList({ applicantId: activeProduct.applicantId }, (res) => {
        if (res) {
          res.forEach((raw, idx) => {
            if (raw.applicationId == activeProduct.applicationId) {
              products[0]["finAnalysisId"] = raw.finAnalysisId;
              doUpdateProductToVault(products, () => {});
            }
          });
        }
      });
    }
  }

  handleChange = () => {};

  handleContinue = (choice) => {
    const {
      continuehandler,
      doUpdateLendingProduct,
      products,
      getActiveProduct,
      doUpdateProductToVault,
    } = this.props;
    const {
      currentLendingProductInfo,
      loanPurposeCode,
      loanPurposes,
    } = this.state;
    const activeProduct = getActiveProduct(products);

    products[0].loanPurposeCode = loanPurposeCode;
    doUpdateProductToVault(products, () => {
      continuehandler({ choice }, AppConstants.APPLICATIONSTEP.LOAN_PURPOSE);
    });

    // TODO correct flow below
    // const payLoad = {
    //   LPBasicDetails: {
    //     PurposeCode : loanPurposeCode
    //   }
    // };
    // console.log(payLoad);
    // doUpdateLendingProduct(activeProduct.applicationId, activeProduct.doximProductId, payLoad, (res) => {
    //   continuehandler({ choice }, AppConstants.APPLICATIONSTEP.LOAN_PURPOSE);
    // });
  };

  handleSelectPurpose = (purposeId) => {
    console.log("handleSelectPurpose", purposeId);
    this.setState({
      loanPurposeCode: purposeId,
    });
  };

  render() {
    const { choice, loanPurposes, loanPurposeCode } = this.state;
    console.log(loanPurposeCode);
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      loanProducts,
      products,
      loanProductForm,
    } = this.props;

    return (
      <>
        <GettingStarted
          handleContinue={this.handleContinue}
          handleSelectPurpose={this.handleSelectPurpose}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          loanProducts={loanProducts}
          products={products}
          loanProductForm={loanProductForm}
          loanPurposes={loanPurposes}
          loanPurposeCode={loanPurposeCode}
        />
      </>
    );
  }
}

LoanPurposeContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

LoanPurposeContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  loanProductForm: state.LoanDetailsReducer.loanProductForm,
  actionBack: state.ApplicationReducer.actionBack,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetLendingProduct: (applicationId, doximProductId, callback) =>
      dispatch(getLendingProduct(applicationId, doximProductId, callback)),
    doUpdateLendingProduct: (
      applicationId,
      doximProductId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLendingProduct(applicationId, doximProductId, payLoad, callback)
      ),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetLoanProduct: (request, callback) =>
      dispatch(getLoanProduct(request, callback)),
    updateLoanProductForm: (loanProductForm, callback) =>
      dispatch(updateLoanProductForm(loanProductForm, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doGetFinancialList: (reqData, callback) =>
      dispatch(getFinancialList(reqData, callback)),
    doGetFinancialAnalysisFinancialList: (reqData, callback) =>
      dispatch(getFinancialAnalysisFinancialList(reqData, callback)),
  };
};

const LoanDetailsWrapper = withApplicantHOC(LoanPurposeContainer);
export default connect(mapStateToProps, mapDispatchToProps)(LoanDetailsWrapper);
