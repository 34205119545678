import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import MarketPlaceLayout from "Components/MarketPlaceLayout/MarketPlaceLayout";
import ProductCard from "Components/Common/ProductCardContainer/ProductCard";
import PageBanner from "Components/Common/PageBanner/PageBanner";
import STRINGS from "Constants/Strings";

import AppConstants from "Constants/AppConstants";
import { getVaultCardProductList } from "Utils/CommonUtilities";

import "./VaultDashboard.scss";

class VaultDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [...STRINGS.VAULT_DASHBOARD.TABS],
    };
  }

  renderApplicationCompleteList = (applicationCompleteList) => {
    const { productList } = this.props;
    let list = [];

    productList.forEach((element) => {
      const { products } = { ...element };
      if (list.length !== 0) {
        list = list.concat(products);
      } else {
        list = products;
      }
    });
    const cardArray = [];
    applicationCompleteList.forEach((application) => {
      if (application.doximProducts && application.doximProducts.length !== 0) {
        const { bundleProductId } = application.doximProducts.find(
          (product) => product.type !== "debitCard"
        );
        const index = list.findIndex(
          (product) => product.productId === bundleProductId
        );
        if (index !== -1) {
          const product = { ...list[index] };
          product.id = application.id;
          product.status = "";
          if (
            application.inReview ||
            (application.submittedToDecisionAt && !application.uploadedAt)
          ) {
            product.status = AppConstants.DASHBOARD.PRODUCT_STATUS.IN_REVIEW;
          } else if (application.uploadedAt) {
            product.status = AppConstants.DASHBOARD.PRODUCT_STATUS.IS_COMPLETED;
          } else {
            product.status = AppConstants.DASHBOARD.PRODUCT_STATUS.IS_REJECTED;
          }
          cardArray.push(product);
        }
      }
    });
    if (cardArray.length !== 0) return this.renderCards(cardArray, false);
    return (
      <div className="[ col-12 ] [ product-row-sub-title ]">
        <span className="[ empty-cart ]">
          {STRINGS.VAULT_DASHBOARD.NO_APPLICATION_COMPLETE}
        </span>
      </div>
    );
  };

  renderShoppingCartList = () => {
    const { productList, vaultProductList } = this.props;
    const vaultCardProductList = getVaultCardProductList(
      productList,
      vaultProductList
    );
    if (vaultCardProductList.length !== 0)
      return this.renderCards(vaultCardProductList, true);
    return (
      <div className="[ col-12 ] [ product-row-sub-title ]">
        <span className="[ empty-cart ]">
          {STRINGS.VAULT_DASHBOARD.EMPTY_VAULT_TEXT}
        </span>
      </div>
    );
  };

  handleProceed = () => {
    const {
      proceedWithApplication,
      navigateToNextProduct,
      sessionCompletedProducts,
    } = this.props;
    proceedWithApplication(() => {
      if (sessionCompletedProducts && sessionCompletedProducts.length > 0) {
        navigateToNextProduct();
      }
    });
  };

  renderCards = (cardArray, isShoppingCartList = true) => {
    const { doUpdateProductToVault } = this.props;
    return (
      <div className="[ col-12 ] [ overflow-auto ] [ text-nowrap ] [ product-list ]">
        {cardArray.map((product) => (
          <ProductCard
            key={product.productIndex ? product.productIndex : product.id}
            product={product}
            handleAddToVault={doUpdateProductToVault}
            addToVault={false}
            isShoppingCartList={isShoppingCartList}
          />
        ))}
      </div>
    );
  };

  render() {
    const { tabs } = this.state;
    const { activeTab } = this.props;
    const {
      doUpdateVaultProductList,
      vaultProductList,
      categoryList,
      productList,
      doGetProductFromVault,
      doUpdateProductToVault,
      doUpdateActiveTab,
      doGetApplicationList,
      doGetProductList,
      userName,
      applicationCompleteList,
      applicationList,
    } = this.props;

    let tempCompleteList = [];
    // tempCompleteList.push(applicationCompleteList);

    console.log("applicationCompleteList", applicationCompleteList);

    // Remove current vault products in complete list
    tempCompleteList = applicationCompleteList.filter((item) => {
      const vault = vaultProductList.find(
        (vaultItem) => vaultItem.applicationId === item.id
      );
      if (!vault) return true;
    });

    console.log("tempCompleteList", tempCompleteList);

    if (tempCompleteList.length > 0) {
      sessionStorage.setItem("TEMP_HIDE", "true");
    } else {
      sessionStorage.setItem("TEMP_HIDE", "false");
    }

    return (
      <>
        <div className="[ dashboard-container ]">
          <PageBanner loggedInUser userName={userName} />
          <main className="[ dashboard__body-container ] [ container-fluid ]">
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <ul className="[ nav nav-tabs ]">
                  {tabs.map((tab, index) => (
                    <li className="[ nav-item ]" key={tab.TAB_NAME}>
                      <button
                        type="button"
                        className={
                          index === activeTab
                            ? "[ form-subtitle nav-link active ]"
                            : "[ form-subtitle  nav-link ]"
                        }
                        data-toggle="tab"
                        role="tab"
                        aria-controls={tab.TAB_NAME}
                        onClick={() => {
                          doGetProductList(() => {});
                          doGetProductFromVault(() => {});
                          if (index === 0) doGetApplicationList(() => {});
                          doUpdateActiveTab(index);
                        }}
                      >
                        {tab.NAME}
                      </button>
                    </li>
                  ))}
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className={
                      activeTab === 0
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id={STRINGS.VAULT_DASHBOARD.TABS[0].TAB_NAME}
                    role="tabpanel"
                    aria-labelledby={`${STRINGS.VAULT_DASHBOARD.TABS[0].TAB_NAME}-tab`}
                  >
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ product-card-container ]">
                          <div className="[ row ]">
                            <div className="[ col-12 ] [ pb-3 ]">
                              <span className="[ form-subtitle ]">
                                <span class="fas fa-shopping-cart "></span>
                                {STRINGS.VAULT_DASHBOARD.SHOPPING_CART}
                                {vaultProductList.length !== 0
                                  ? ` ( ${vaultProductList.length} )`
                                  : " "}
                              </span>
                            </div>
                          </div>

                          <div className="[ row ]">
                            {this.renderShoppingCartList()}
                            {vaultProductList.length !== 0 && (
                              <div className="[ col-12 col-xl-2 ] [ d-flex align-items-end justify-content-center ] [ mb-2 ] [ mt-3 ]">
                                <button
                                  type="button"
                                  className="[ btn ] [ submit-btn ]"
                                  onClick={this.handleProceed}
                                  disabled={vaultProductList.length === 0}
                                >
                                  {STRINGS.VAULT_DASHBOARD.PROCEED}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="[ vault-dashboard-container__line-separator ]" />

                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ product-card-container ]">
                          <div className="[ row ]">
                            <div className="[ col-12 ] [ pb-3 ]">
                              <span className="[ form-subtitle ]">
                                {STRINGS.VAULT_DASHBOARD.APPLICATION_COMPLETE}
                                {tempCompleteList.length !== 0
                                  ? ` ( ${tempCompleteList.length} )`
                                  : " "}
                              </span>
                            </div>
                          </div>

                          <div className="[ row ]">
                            {this.renderApplicationCompleteList(
                              tempCompleteList
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      activeTab === 1
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id={STRINGS.VAULT_DASHBOARD.TABS[1].TAB_NAME}
                    role="tabpanel"
                    aria-labelledby={`${STRINGS.VAULT_DASHBOARD.TABS[1].TAB_NAME}-tab`}
                  >
                    <MarketPlaceLayout
                      categoryList={categoryList}
                      productList={productList}
                      doUpdateVaultProductList={doUpdateVaultProductList}
                      doGetProductFromVault={doGetProductFromVault}
                      doUpdateProductToVault={doUpdateProductToVault}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

VaultDashboard.propTypes = {
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doUpdateVaultProductList: PropTypes.func,
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  applicationCompleteList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doUpdateProductToVault: PropTypes.func,
  proceedWithApplication: PropTypes.func,
  categoryList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doGetProductFromVault: PropTypes.func,
  activeTab: PropTypes.number.isRequired,
  doUpdateActiveTab: PropTypes.func,
  doGetApplicationList: PropTypes.func,
  doGetProductList: PropTypes.func,
  userName: PropTypes.string,
  navigateToNextProduct: PropTypes.func.isRequired,
  sessionCompletedProducts: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

VaultDashboard.defaultProps = {
  vaultProductList: [],
  applicationCompleteList: [],
  productList: [],
  proceedWithApplication: () => {},
  categoryList: [],
  doUpdateVaultProductList: () => {},
  doGetProductFromVault: () => {},
  doUpdateProductToVault: () => {},
  doUpdateActiveTab: () => {},
  doGetApplicationList: () => {},
  doGetProductList: () => {},
  userName: "",
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
});

const VaultDashboardWrapper = withApplicantHOC(VaultDashboard);

export default connect(mapStateToProps)(VaultDashboardWrapper);
