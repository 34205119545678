import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import GetFinInfoApiRequest from "Communication/ApiClasses/GetFinInfoApiRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleProduct(action) {
  yield put(doLoadingStart(STRINGS.MARKETPLACE.API_KEYS.GET_FI_INFO));
  const finInfoApiRequest = new GetFinInfoApiRequest();
  try {
    const result = yield call(finInfoApiRequest.getData);
    yield call(action.callback, result);
    yield put({
      type: actionTypes.STORE_FI_INFO,
      data: result.data,
    });
  } catch (error) {
    yield call(action.callback, error.response);
  } finally {
    yield put(doLoadingFinish(STRINGS.MARKETPLACE.API_KEYS.GET_FI_INFO));
  }
}

// watcher
export default function* productWatcherSaga() {
  yield takeLatest(actionTypes.GET_FI_INFO, handleProduct);
}
