import React, { Component } from "react";
import PropTypes from "prop-types";
import { conformToMask } from "react-text-mask";

import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";
import PopupModal from "Components/Common/PopupModal/PopupModal";

import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";

import { IDVERIFICATION_FAILED_CODE, ATTEMPTS } from "Constants/APIConstants";
import { ERROR_CODE, HTTP_STATUS, APPLICANTID } from "Communication/Constants";

import {
  isScanningAllowed,
  convertBase64ImageToString,
} from "Utils/CommonUtilities";
import getUserCheckList from "Utils/AboutYouUtilities";

import IDSelection from "./IdSelection";
import ScanIdFront from "./ScanIdFront";
import ScanIdBack from "./ScanIdBack";
import Selfie from "./Selfie";
import Review from "./Review";

import "./AboutYou.scss";

const AboutYouParts = {
  IDSELECTION: "idSelection",
  SCANIDFRONT: "scanIdFront",
  SCANIDBACK: "scanIdBack",
  SELFIE: "selfie",
  REVIEW: "review",
};

class AboutYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePart: AboutYouParts.REVIEW,
      idName: null,
      idType: null,
      idFront: null,
      idBack: null,
      selfie: props.formData?.selfieImage,
      retriveCount: ATTEMPTS,
      checkInList: IDVERIFICATION_FAILED_CODE.INITIAL,
      formData: props.formData,
      showModal: false,
      showWelcome: false,
      error: false,
      description: "",
      isDesktop: true,
    };
  }

  componentDidMount() {
    const { scanDocument, newMember } = this.props;
    console.log("componentDidMount", newMember);
    // is scanning allowed function is used to check if the device is mobile or tablet

    if (
      isScanningAllowed() &&
      scanDocument &&
      !sessionStorage.getItem(APPLICANTID)
    ) {
      // if the logged in device is not desktop then this flag is used show the scanning confirmation question.
      this.setState({ isDesktop: false });

      const { checkInList } = this.state;
      const nextActivePart = this.getNextActivePart(checkInList);
      this.setState({ activePart: nextActivePart });
    }
    const { isInEditFlow } = this.props;
    // if the flow is edit then user by default lands to review part.
    if (isInEditFlow || sessionStorage.getItem(APPLICANTID)) {
      this.setState({ activePart: AboutYouParts.REVIEW });
      if (newMember) {
        this.setState({
          showWelcome: true,
          description: STRINGS.WELCOME_BACK1,
          description2: STRINGS.WELCOME_BACK2,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { vaultProductList, getActiveProduct } = this.props;
    if (prevProps.vaultProductList !== vaultProductList) {
      const activeProduct = getActiveProduct(vaultProductList);
      if (activeProduct.applicantId) {
        this.setReviewStep();
      }
    }

    const { scanDocument } = this.props;
    if (prevProps.scanDocument !== scanDocument) {
      if (
        isScanningAllowed() &&
        scanDocument &&
        !sessionStorage.getItem(APPLICANTID)
      ) {
        // is scanning allowed function is used to check if the device is mobile or tablet
        // if the logged in device is not desktop then this flag is used show the scanning confirmation question.
        this.setStateAfterUpdate({ isDesktop: false });

        const { checkInList } = this.state;
        const nextActivePart = this.getNextActivePart(checkInList);
        this.setStateAfterUpdate({ activePart: nextActivePart });
      }
    }
  }

  setStateAfterUpdate = (state) => {
    this.setState(state);
  };

  setReviewStep = () => {
    this.setState({ activePart: AboutYouParts.REVIEW });
  };

  setStateFromResponseData = (resState) => {
    this.setState(resState);
  };

  setFormData = (formData) => {
    const { postalCode } = formData;
    const postalCodeFormatted = postalCode
      ? conformToMask(postalCode, AppConstants.ABOUT_YOU.CA_POSTALMASK, {
          guide: false,
        }).conformedValue
      : postalCode;
    this.setState({
      formData: { ...formData, postalCode: postalCodeFormatted },
    });
  };

  checkAllDataSet = () => {
    const { idFront, idBack, idType, selfie, checkInList } = this.state;
    const {
      selectId,
      captureFrontId,
      captureBackId,
      captureSelfie,
    } = checkInList;

    if (
      !selectId &&
      !captureFrontId &&
      !captureBackId &&
      !captureSelfie &&
      idFront &&
      idBack &&
      selfie &&
      idType
    ) {
      this.verifyDocuments(idFront, idBack, selfie, idType);
    } else {
      const nextActivePart = this.getNextActivePart(checkInList);
      this.setState({ activePart: nextActivePart });
    }
  };

  /**
   * @description returns which part should be active in about you from 4 parts.
   *              1. IDSelection, 2. Scan Front, 3. Scan Back, 4. Review.
   * @param {*} checkList
   * @value {
    profile: true,
    selectId: true,
    captureFrontId: true,
    captureBackId: true,
    captureSelfie: true,
    }
   */
  getNextActivePart = (checkList) => {
    const {
      selectId,
      captureFrontId,
      captureBackId,
      captureSelfie,
      profile,
    } = checkList;
    if (selectId) {
      const checkInList = {
        selectId: false,
        captureFrontId,
        captureBackId,
        captureSelfie,
        profile,
      };
      this.setState({ checkInList });
      return AboutYouParts.IDSELECTION;
    }
    if (captureFrontId) {
      const checkInList = {
        selectId,
        captureFrontId: false,
        captureBackId,
        captureSelfie,
        profile,
      };
      this.setState({ checkInList });
      return AboutYouParts.SCANIDFRONT;
    }
    if (captureBackId) {
      const checkInList = {
        selectId,
        captureFrontId,
        captureBackId: false,
        captureSelfie,
        profile,
      };
      this.setState({ checkInList });
      return AboutYouParts.SCANIDBACK;
    }
    if (captureSelfie) {
      const checkInList = {
        selectId,
        captureFrontId,
        captureBackId,
        captureSelfie: false,
        profile,
      };
      this.setState({ checkInList });
      return AboutYouParts.SELFIE;
    }
    if (profile) {
      return AboutYouParts.REVIEW;
    }
    return null;
  };

  clearData = (checkList) => {
    const {
      selectId,
      captureFrontId,
      captureBackId,
      captureSelfie,
    } = checkList;

    if (selectId) {
      this.setState({ idType: null });
    }
    if (captureFrontId) {
      this.setState({ idFront: null });
    }
    if (captureBackId) {
      this.setState({ idBack: null });
    }
    if (captureSelfie) {
      this.setState({ selfie: null });
    }
  };

  idSelectionSubmitHandler = (id, type) => {
    this.setState(
      {
        idName: id,
        idType: type,
      },
      () => {
        this.checkAllDataSet();
      }
    );
  };

  scanIdFrontSubmitHandler = (scannedIdFront) => {
    const { idType, checkInList } = this.state;
    if (idType === AppConstants.IDTypes.PASSPORT.desc) {
      this.getNextActivePart(checkInList);
    }
    this.setState(
      {
        idFront: scannedIdFront,
      },
      () => {
        this.checkAllDataSet();
      }
    );
  };

  scanIdBackSubmitHandler = (scannedIdBack) => {
    this.setState(
      {
        idBack: scannedIdBack,
      },
      () => {
        this.checkAllDataSet();
      }
    );
  };

  selfieSubmitHandler = (scannedSelfie) => {
    this.setState(
      {
        selfie: scannedSelfie,
      },
      () => {
        this.checkAllDataSet();
      }
    );
  };

  verifyDocuments = (idFront, idBack, scannedSelfie, documentType) => {
    const { verifyDoc } = this.props;
    const request = {
      frontImage: convertBase64ImageToString(idFront),
      backImage: convertBase64ImageToString(idBack),
      selfieImage: convertBase64ImageToString(scannedSelfie),
      docType: documentType,
    };
    verifyDoc(request, (response) => {
      const { doSubmitDocVerify } = this.props;
      doSubmitDocVerify({ token: response?.data }, (submitIDRes) => {
        this.handleSubmitIDVerify(submitIDRes, response.data);
      });
    });
  };

  handleSubmitIDVerify = (response, token) => {
    if (response.status === HTTP_STATUS.OK) {
      this.setFormData(response.data);
      const { retriveCount, checkInList } = this.state;
      const { authentication } = response.data;
      let checkList = checkInList;
      if (retriveCount - 1 > 0) {
        checkList = getUserCheckList(response.data);
        const count = retriveCount - 1;
        this.clearData(checkList);
        this.setStateFromResponseData({
          checkInList: checkList,
          retriveCount: count,
        });
      } else {
        this.setStateFromResponseData({
          checkInList: IDVERIFICATION_FAILED_CODE.FINAL,
        });
        checkList = IDVERIFICATION_FAILED_CODE.FINAL;
      }
      const nextActivePart = this.getNextActivePart(checkList);
      this.setStateFromResponseData({
        activePart: nextActivePart,
      });
      if (nextActivePart !== AboutYouParts.REVIEW) {
        if (
          nextActivePart == AboutYouParts.IDSELECTION ||
          nextActivePart == AboutYouParts.SCANIDFRONT ||
          nextActivePart == AboutYouParts.SCANIDBACK ||
          nextActivePart == AboutYouParts.SELFIE
        ) {
          this.setStateFromResponseData({
            showModal: true,
            error: true,
            description: authentication,
          });
        } else {
          //continue with review even if it is in error
          this.setStateFromResponseData({
            activePart: AboutYouParts.REVIEW,
          });
        }
      }
    } else if (response.status === HTTP_STATUS.INTERNAL_SERVER_ERROR) {
      if (response.data?.code === ERROR_CODE.DATA_NOT_AVAILABLE) {
        const { doSubmitDocVerify } = this.props;
        setTimeout(
          doSubmitDocVerify(
            {
              token,
            },
            (submitIDRes) => {
              this.handleSubmitIDVerify(submitIDRes, token);
            }
          ),
          10000
        );
      }
    }
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.ABOUTYOU);
  };

  render() {
    const {
      activePart,
      idName,
      idType,
      idFront,
      idBack,
      selfie,
      formData,
      showModal,
      error,
      description,
      description2,
      isDesktop,
      showWelcome,
    } = this.state;
    const {
      getDataFromServer,
      continuehandler,
      getActiveProduct,
      products,
      newMember,
    } = this.props;
    let pageHeader = STRINGS.ABOUTYOU.SCREENTITLE;
    let aboutYou = null;
    switch (activePart) {
      case AboutYouParts.IDSELECTION:
        aboutYou = (
          <IDSelection idSelectionHandler={this.idSelectionSubmitHandler} />
        );
        break;
      case AboutYouParts.SCANIDFRONT:
        aboutYou = (
          <ScanIdFront
            idType={idType}
            idFront={idFront}
            scanIdFrontHandler={this.scanIdFrontSubmitHandler}
          />
        );
        break;
      case AboutYouParts.SCANIDBACK:
        aboutYou = (
          <ScanIdBack
            idBack={idBack}
            scanIdBackHandler={this.scanIdBackSubmitHandler}
          />
        );
        break;
      case AboutYouParts.SELFIE:
        aboutYou = (
          <Selfie selfieHandler={this.selfieSubmitHandler} selfie={selfie} />
        );
        break;
      case AboutYouParts.REVIEW:
        if (formData) {
          aboutYou = (
            <Review
              fName={formData.firstName}
              mName={formData.middleName}
              lName={formData.surname}
              dOB={formData.dateOfBirth}
              address={formData.address}
              addressLine2={formData.addressLine2}
              city={formData.city}
              province={formData.state}
              postalCode={formData.postalCode}
              instntTxnId={formData.instntTxnId}
              selfieImage={selfie}
              idName={idName}
              isDesktop={isDesktop}
              getDataFromServer={getDataFromServer}
              continuehandler={continuehandler}
              getActiveProduct={getActiveProduct}
            />
          );
        } else {
          aboutYou = (
            <Review
              isDesktop={isDesktop}
              idName={idName}
              getDataFromServer={getDataFromServer}
              continuehandler={continuehandler}
              getActiveProduct={getActiveProduct}
            />
          );
        }
        pageHeader = STRINGS.ABOUTYOU.PARTFIVE.TITLE;
        break;
      default:
        break;
    }
    const { steps, activeStepID } = this.props;
    console.log(steps, activeStepID);
    const commonRender = (
      <div className="[ about-you-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{pageHeader}</div>
        {aboutYou}
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={
              error
                ? AppConstants.MODALTYPE.FAILURE
                : AppConstants.MODALTYPE.SUCCESS
            }
            title={
              error ? description : AppConstants.MODALTYPE.SUCCESS.toLower()
            }
            description=""
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        {showWelcome && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            title={null}
            description={description}
            description2={description2}
            showModal={showWelcome}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={commonRender}
          securityText={null}
          infoType={"welcomeBack"}
          memberCreation
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

AboutYou.propTypes = {
  verifyDoc: PropTypes.func,
  formData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  isInEditFlow: PropTypes.bool,
  doSubmitDocVerify: PropTypes.func,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  scanDocument: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  getActiveProduct: PropTypes.func,
};

AboutYou.defaultProps = {
  verifyDoc: () => {},
  formData: null,
  isInEditFlow: false,
  doSubmitDocVerify: () => {},
  steps: [],
  activeStepID: -1,
  vaultProductList: [],
  getActiveProduct: () => {},
};

export default AboutYou;
