import { call, put, takeLatest } from "redux-saga/effects";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";
import CreateInveriteRequest from "Communication/ApiClasses/CreateInveriteRequest";

//  worker
function* handleCreateInverite(action) {
  yield put(doLoadingStart(STRINGS.INVERITE_IFRAME_MODAL.API_KEYS.CREATE));
  const createApi = new CreateInveriteRequest();
  try {
    const result = yield call(createApi.getData);
    yield call(action.callback, result);
  } finally {
    yield put(doLoadingFinish(STRINGS.INVERITE_IFRAME_MODAL.API_KEYS.CREATE));
  }
}

//  watcher
export default function* createApiWatcherSaga() {
  yield takeLatest(actionTypes.CREATE_INVERITE_REQUEST, handleCreateInverite);
}
