import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";
import { HTTP_STATUS } from "Communication/Constants";
import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import {
  addBundleProduct,
  deleteDebitCard,
  storeDebitCard,
} from "Components/DebitCard/Actions/DebitCardAction";
import { setOnlineBanking } from "Components/OnlineBanking/Actions/OnlineBankingAction";
import {
  getApplicationList,
  toggleBackFlag,
} from "Redux/Actions/ApplicationAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import { getApplicationProperty } from "Components/JointApplicant/Actions/JointApplicantAction";
import getBundleProductRelation from "Redux/Actions/GetBundleProductRelationAction";
import DebitCard from "./DebitCard";
import STRINGS from "../../Constants/Strings";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";

class DebitCardContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      debitProductId: 0,
      enableCreditCard: false,
      rendered: false,
    };
  }

  componentDidMount() {
    const {
      products,
      doGetApplicationProperty,
      doximProducts,
      applicationAppliedList,
      isDebitCardEnabled,
      getActiveProduct,
      doStoreDebitCard,
      bundleProductRelation,
      actionBack,
      doGetBundleProductRelation,
      doGetApplicationList,
      applicantData,
    } = this.props;
    console.log(
      "DebitCardContainer",
      isDebitCardEnabled,
      bundleProductRelation
    );
    if (applicantData?.acquireType === "search") {
      if (actionBack) {
        this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
      } else {
        this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
      }
      return;
    }
    doGetApplicationList((res) => {
      const activeProduct = getActiveProduct(products);
      res.data.forEach((item) => {
        const debitCardProduct = item.doximProducts?.find(
          (product) => product.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
        );
        if (debitCardProduct && item.id !== activeProduct.applicationId) {
          doStoreDebitCard(true, activeProduct.applicationId);
          if (actionBack) {
            this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
          } else {
            this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
          }
        }
      });
      if (res.data.length > 1) {
        if (actionBack) {
          this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
        } else {
          this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
        }
        return;
      }
      doGetBundleProductRelation(
        {
          bundleProductId: activeProduct.productId,
          demandProductId: activeProduct.productId,
          applicationId: activeProduct.applicationId,
        },
        (getBundleProductRelationRes) => {
          if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
            if (getBundleProductRelationRes.data.length <= 0) {
              if (actionBack) {
                this.doHandleBack(AppConstants.APPLICATIONSTEP.DEBIT_CARD);
              } else {
                this.doContinueHandle(
                  "",
                  AppConstants.APPLICATIONSTEP.DEBIT_CARD
                );
              }
            }
          }
        }
      );
    });

    if (doximProducts && doximProducts.length === 0) {
      const { applicationId } = products[0];
      const reqData = {
        applicationId,
      };
      doGetApplicationProperty(reqData);
    }
  }

  doHandleBack = (step) => {
    const { handleBack, doToggleBackFlag } = this.props;
    doToggleBackFlag(true);
    handleBack(step);
  };

  doContinueHandle = (data, step) => {
    const { continuehandler, doToggleBackFlag } = this.props;
    doToggleBackFlag(false);
    continuehandler(data, step);
  };

  componentDidUpdate() {
    const { rendered } = this.state;
    const { doximProducts } = this.props;
    const debitCardAlreadyLoaded = sessionStorage.getItem(
      AppConstants.SESSION.DEBIT_CARD_ALREADY_LOADED
    );
    if (debitCardAlreadyLoaded) {
      if (rendered === false) {
        doximProducts.map((item) => {
          if (item.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM) {
            this.setState({
              enableCreditCard: true,
              debitProductId: item.id,
              rendered: true,
            });
          }
          return false;
        });
      }
    } else {
      this.setState({
        enableCreditCard: true,
      });
      console.log("setting debit card flag");
      sessionStorage.setItem(
        AppConstants.SESSION.DEBIT_CARD_ALREADY_LOADED,
        "true"
      );
    }
  }

  handleChange = (choice) => {
    this.setState({ enableCreditCard: choice });
  };

  handleContinue = () => {
    const {
      products,
      bundleProductRelation,
      doAddBundleProduct,
      doDeleteDebitCard,
      doGetApplicationProperty,
      getActiveProduct,
      doSetOnlineBanking,
      doUpdateProductToVault,
    } = this.props;
    const { debitProductId, enableCreditCard } = this.state;
    let activeProduct = getActiveProduct(products);
    let newProducts = products;
    activeProduct.enableDebitCard = enableCreditCard;
    newProducts[0] = activeProduct;
    doUpdateProductToVault(newProducts, () => {});

    if (enableCreditCard === true) {
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (res) => {
          const debitCardProduct = res.doximProducts?.find(
            (product) => product.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
          );
          if (debitCardProduct) {
            doSetOnlineBanking(
              activeProduct.applicationId,
              debitCardProduct.productId,
              activeProduct.templateId
            );
            this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
          } else {
            doAddBundleProduct(
              bundleProductRelation.applicationId,
              bundleProductRelation[0].bundleProduct.id,
              activeProduct.doximProductId,
              (res2) => {
                if (res2 && res2.status === HTTP_STATUS.OK) {
                  doSetOnlineBanking(
                    bundleProductRelation.applicationId,
                    bundleProductRelation[0].bundleProduct.id,
                    activeProduct.templateId
                  );
                  const { applicationId } = products[0];
                  doGetApplicationProperty({ applicationId });
                  this.doContinueHandle(
                    "",
                    AppConstants.APPLICATIONSTEP.DEBIT_CARD
                  );
                }
              }
            );
          }
        }
      );
    } else if (debitProductId === 0) {
      this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
    } else {
      doDeleteDebitCard(
        bundleProductRelation.applicationId,
        debitProductId,
        (res) => {
          if (res && res.status === HTTP_STATUS.OK) {
            doSetOnlineBanking(null, null, null);
            const { applicationId } = products[0];
            this.setState({
              debitProductId: 0,
            });
            doGetApplicationProperty({ applicationId });
            this.doContinueHandle("", AppConstants.APPLICATIONSTEP.DEBIT_CARD);
          }
        }
      );
    }
  };

  togglePopupModal = () => {
    this.setState({});
  };

  render() {
    const { enableCreditCard } = this.state;
    const { getDataFromServer, steps, activeStepID } = this.props;

    return (
      <>
        <DebitCard
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          isDebitCardEnabled={enableCreditCard}
          handleBack={this.doHandleBack}
          steps={steps}
          activeStepID={activeStepID}
        />
      </>
    );
  }
}

DebitCardContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doAddBundleProduct: PropTypes.func.isRequired,
  doDeleteDebitCard: PropTypes.func.isRequired,
  bundleProductRelation: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doximProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  applicationAppliedList: PropTypes.arrayOf(PropTypes.any).isRequired,
  isDebitCardEnabled: PropTypes.bool,
  getActiveProduct: PropTypes.func.isRequired,
  doSetOnlineBanking: PropTypes.func.isRequired,
  actionBack: PropTypes.bool,
  doToggleBackFlag: PropTypes.func.isRequired,
  doGetApplicationList: PropTypes.func,
};

DebitCardContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  isDebitCardEnabled: false,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  bundleProductRelation: state.GetBundleProductRelationReducer.data,
  doximProducts: state.ApplicationReducer.doximProducts,
  applicationAppliedList: state.ApplicationReducer.applicationAppliedList,
  isDebitCardEnabled: state.DebitCardReducer.isDebitCardEnabled,
  actionBack: state.ApplicationReducer.actionBack,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doAddBundleProduct: (
      applicationId,
      bundleProductId,
      demandProductId,
      callback
    ) =>
      dispatch(
        addBundleProduct(
          applicationId,
          bundleProductId,
          demandProductId,
          callback
        )
      ),
    doDeleteDebitCard: (applicationId, debitProductId, callback) =>
      dispatch(deleteDebitCard(applicationId, debitProductId, callback)),
    doStoreDebitCard: (enableDebitCard, applicationId, callback) =>
      dispatch(storeDebitCard(enableDebitCard, applicationId, callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doSetOnlineBanking: (
      applicationId,
      debitCardProductId,
      templateId,
      callback
    ) =>
      dispatch(
        setOnlineBanking(
          applicationId,
          debitCardProductId,
          templateId,
          callback
        )
      ),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    doGetBundleProductRelation: (activeProduct, callback) =>
      dispatch(getBundleProductRelation(activeProduct, callback)),
    doGetApplicationList: (callback) => dispatch(getApplicationList(callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

const DebitCardWrapper = withApplicantHOC(DebitCardContainer);

export default connect(mapStateToProps, mapDispatchToProps)(DebitCardWrapper);
