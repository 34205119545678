import * as actionTypes from "Redux/Actions/actionTypes";

export const callCreateInverite = (callback) => ({
  type: actionTypes.CREATE_INVERITE_REQUEST,
  callback,
});

export const callFetchInverite = (callback) => ({
  type: actionTypes.FETCH_INVERITE_REQUEST,
  callback,
});

export const callCreateInveriteAccountList = (callback) => ({
  type: actionTypes.CREATE_INVERITE_ACCOUNT_LIST_REQUEST,
  callback,
});

export const callFetchInveriteAccountList = (
  requestGuid,
  callback = () => {}
) => ({
  type: actionTypes.FETCH_INVERITE_ACCOUNT_LIST_REQUEST,
  data: {
    requestGuid,
  },
  callback,
});
