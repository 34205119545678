import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Collapse from "react-bootstrap/Collapse";

import STRINGS from "Constants/Strings";

import { storeFilterProduct } from "Components/MarketPlace/Action/MarketPlaceAction";
import { isScanningAllowed } from "Utils/CommonUtilities";

class FilterBar extends Component {
  constructor(props) {
    super(props);
    const isMobile = isScanningAllowed();
    this.state = {
      bankDropdown: STRINGS.MARKETPLACE.BODY.PERSONAL_BANKING,
      bankDropdownArray: [
        {
          code: 1,
          description: STRINGS.MARKETPLACE.BODY.PERSONAL_BANKING,
          className: "[ default ] [ dropdown__option ]",
        },
        {
          code: 2,
          description: STRINGS.MARKETPLACE.BODY.BUSINESS_BANKING,
          className: "[ default ] [ dropdown__option ] [ disabled ]",
        },
      ],
      filterByOpen: true,
      categoryOpen: !isMobile,
    };
  }

  checkBoxHandler = (event) => {
    const { allCheck } = this.props;
    const {
      categoryList,
      productList,
      doStoreFilterProduct,
      setAllCheck,
    } = this.props;

    let resultAllCheck = true;
    if (event.target.value === STRINGS.MARKETPLACE.BODY.ALL) {
      setAllCheck(!allCheck);
    } else if (event.target.checked === false && allCheck) {
      setAllCheck(false);
    }
    const resultProductList = productList.map((item) => {
      const productListItem = { ...item };
      if (
        productListItem.id === event.target.value ||
        event.target.value === STRINGS.MARKETPLACE.BODY.ALL
      ) {
        productListItem.show = event.target.checked;
        productListItem.products = productListItem.products.map((elem) => {
          const product = { ...elem };
          product.show = true;
          return product;
        });
      }
      return productListItem;
    });

    const resultcategoryList = categoryList.map((item) => {
      const temp = { ...item };
      if (
        temp.description === event.target.value ||
        event.target.value === STRINGS.MARKETPLACE.BODY.ALL
      ) {
        temp.checked = event.target.checked;
      }
      if (!temp.checked) resultAllCheck = false;
      return temp;
    });
    doStoreFilterProduct(resultProductList, resultcategoryList);
    setAllCheck(resultAllCheck);
  };

  onChangeHandler = (event) => {
    this.setState({ bankDropdown: event.target?.value });
  };

  showCollapse = () => {
    const { categoryOpen } = this.state;
    this.setState({ categoryOpen: !categoryOpen });
  };

  showFilterByCollapse = () => {
    const { filterByOpen } = this.state;
    this.setState({ filterByOpen: !filterByOpen });
  };

  render() {
    const { categoryList } = this.props;
    const { allCheck } = this.props;
    const {
      bankDropdown,
      bankDropdownArray,
      filterByOpen,
      categoryOpen,
    } = this.state;

    const selectList = bankDropdownArray.map((elem) => (
      <option
        value={elem.description}
        className={elem.className}
        key={elem.code}
      >
        {elem.description}
      </option>
    ));
    const checkboxList = categoryList.map((elem) => (
      <div
        className="[ col-12 ] [ d-flex align-items-center ] [ checkbox ]"
        key={elem.code}
      >
        <input
          type="checkbox"
          value={elem.description}
          onChange={(event) => this.checkBoxHandler(event)}
          checked={elem.checked}
          id={elem.description}
        />
        <label className="[ checkbox-text ]" htmlFor={elem.description}>
          {elem.description}
        </label>
      </div>
    ));
    const selectElement = (
      <select
        className="[ form-control ] [ select__dropdown ]"
        id="bankingTypeDropdownList"
        name="banking"
        value={bankDropdown}
        onChange={this.onChangeHandler}
      >
        {selectList}
      </select>
    );
    const categoryComponent = (
      <div className="[ category-component ]">
        <div
          className="[ row ] [ mx-0 ] [ justify-content-between ]"
          tabIndex={0}
          role="button"
          onKeyDown={this.showCollapse}
          onClick={this.showCollapse}
          aria-controls="category-collapse-text"
          aria-expanded={categoryOpen}
        >
          <div className="[ form-subtitle ]">
            {STRINGS.MARKETPLACE.BODY.CATEGORY}
          </div>
          <div className="">
            <span
              className={
                categoryOpen
                  ? "[ icon ] [ collapse-icon-minus ]"
                  : "[ icon ] [ collapse-icon-plus ]"
              }
            />
          </div>
        </div>
        <Collapse in={categoryOpen} ref={this.collapseWrapper}>
          <div className="[ row ]" id="[ category-collapse-text ]">
            <div className="[ col-12 ] [ d-flex align-items-center ] [ checkbox ] ">
              <input
                type="checkbox"
                value={STRINGS.MARKETPLACE.BODY.ALL}
                onChange={(event) => this.checkBoxHandler(event)}
                checked={allCheck}
                id={STRINGS.MARKETPLACE.BODY.ALL}
              />
              <label
                className="[ checkbox-text ]"
                htmlFor={STRINGS.MARKETPLACE.BODY.ALL}
              >
                {STRINGS.MARKETPLACE.BODY.ALL}
              </label>
            </div>
            {checkboxList}
          </div>
        </Collapse>
      </div>
    );

    return (
      <aside className="[ col-12 col-xl-2 ]">
        <div className="[ product-filters ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.MARKETPLACE.BODY.SEE_PRODUCTS}
                </div>
                {selectElement}
              </div>
            </div>
          </div>

          <div className="[ row ]">
            <div className="[ col-12 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.MARKETPLACE.BODY.FILTER_BY}
                </div>
                {categoryComponent}
              </div>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

FilterBar.propTypes = {
  categoryList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doStoreFilterProduct: PropTypes.func.isRequired,
  allCheck: PropTypes.bool.isRequired,
  setAllCheck: PropTypes.func.isRequired,
};

FilterBar.defaultProps = {
  categoryList: [],
  productList: [],
};

const mapStateToProps = (state) => ({
  categoryList: state.MarketplaceReducer.categoryList,
  productList: state.MarketplaceReducer.productList,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doStoreFilterProduct: storeFilterProduct,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
