import * as actionTypes from "Redux/Actions/actionTypes";

export const doGetIdType = () => ({
  type: actionTypes.GET_ID_TYPE,
});

export const doVerifyInstntDocument = (documentData, callback) => ({
  type: actionTypes.INSTNT_DOC_VERIFY,
  data: documentData,
  callback,
});

export const doSubmitVerify = (docData, callback) => ({
  type: actionTypes.SUBMIT_VERIFY,
  data: docData,
  callback,
});

export const doCreateApplication = (requestData, callback = () => {}) => ({
  type: actionTypes.CREATE_APPLICATION,
  data: requestData,
  requestData,
  callback,
});

export const doGetProvinceList = () => ({
  type: actionTypes.GET_PROVINCE_LIST,
});
