import { call, put, takeLatest } from "redux-saga/effects";
import LogoutRequest from "Communication/ApiClasses/LogoutRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import { logout } from "../../Utils";

//  worker
function* handleLogout(action) {
  yield put(doLoadingStart(STRINGS.COMMON.API_KEYS.LOGOUT));
  const logoutApi = new LogoutRequest();
  try {
    const result = yield call(logoutApi.getData);
    logout();
    yield put({ type: actionTypes.LOGOUT });
    yield put({ type: actionTypes.RESET_STORE_REQUEST });
    yield call(action.callback, result);
  } catch (e) {
    yield put({ type: actionTypes.LOGOUT_ERROR });
  } finally {
    yield put(doLoadingFinish(STRINGS.COMMON.API_KEYS.LOGOUT));
  }
}
//  watcher
export default function* logoutWatcherSaga() {
  yield takeLatest(actionTypes.LOGOUT_REQUEST, handleLogout);
}
