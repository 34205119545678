import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as ROUTES from "Constants/Routes";

// import common actions
import { toggleEditFlag } from "Redux/Actions/ApplicationAction";
import getAccount from "Redux/Actions/GetAccountAction";

import { HTTP_STATUS } from "Communication/Constants";
import MODALMESSAGES from "Constants/Messages";
import AppConstants from "Constants/AppConstants";

import { doGetProvinceList } from "Components/AboutYou/Actions/AboutYouActions";

import {
  updateProductToVault,
  getProductFromVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import LoanSummary from "./LoanSummary";
import {
  getFinancialAnalysisFinancialList,
  getLoanProduct,
  updateLoanProductForm,
  getLendingProduct,
  updateLendingProduct,
} from "Components/LoanDetails/Actions/LoanDetailsAction";
import {
  fetchGettingStartedBranch,
  fetchGettingStartedResidentStatus,
} from "Components/GettingStarted/Actions/GettingStartedActions";
import doConfirmApplication from "Components/ConfirmAccountCreate/Actions/ConfirmAccountCreateAction";
import { getApplicationProperty } from "../JointApplicant/Actions/JointApplicantAction";
import Disbursement from "../Disbursement/Disbursement";
import { format, parse } from "date-fns";
import { getLoanRate } from "../../Utils/LosUtilities";

class LoanSummaryContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      choice: "",
      assetList: [],
      incomeList: [],
      expenseList: [],
      liabilitiesList: [],
      aboutData: {},
      loanDetails: {
        hasAdditionalSourceIncome: false,
        loanAmount: 0,
        loanRate: 0,
        loanScheduleDate: "",
        loanTerm: 0,
        monthlyPayment: "",
        paymentType: "",
        purposeId: "",
      },
      gettingStarted: {},
    };
  }

  componentDidMount() {
    console.log("container");
    const {
      products,
      getActiveProduct,
      doGetFinancialAnalysisFinancialList,
      doGetLoanProduct,
      provinceList,
      doFetchGettingStartedResidentStatus,
      doGetBranchData,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    // console.log(activeProduct);
    doGetLoanProduct();
    this.initializeLoanDetail();

    doFetchGettingStartedResidentStatus();
    doGetBranchData();
    // province list is needed on about you page details
    if (provinceList && provinceList.length <= 0) {
      const { doGetProvinceData } = this.props;
      doGetProvinceData();
    }

    doGetFinancialAnalysisFinancialList(
      {
        applicantId: activeProduct.applicantId,
        finAnalysisId: activeProduct.finAnalysisId,
      },
      (res) => {
        this.getAssetList(res);
        this.getIncomeList(res);
        this.getLiabilitiesList(res);
        this.getExpenseList(res);
        console.log(res);
        console.log(this.state.liabilitiesList);
      }
    );
  }

  initializeLoanDetail() {
    const { loanProducts, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (!activeProduct) {
      return;
    }
    const productId = activeProduct.productId;
    let purposes = [];
    let paymentTypes = [];
    let loanConfig = {};
    console.log(loanProducts);
    if (loanProducts) {
      loanProducts.forEach((raw, idx) => {
        if (raw.id === productId) {
          purposes = raw.purpose;
          paymentTypes = raw.paymentTypes;
          loanConfig = {
            loanAmount: raw.loanAmount,
            loanTerm: raw.loanTerm,
            loanRate: raw.loanRate,
          };
        }
      });
    }

    let loanDetails = activeProduct.loanDetails;
    if (loanDetails) {
      this.getLoanDetailsData(activeProduct, loanDetails);
    }
  }

  getLoanDetailsData = (activeProduct, loanProductForm) => {
    console.log("getLoanDetailsData");
    const { doGetLendingProduct } = this.props;
    doGetLendingProduct(
      activeProduct.applicationId,
      activeProduct.doximProductId,
      (res) => {
        console.log(res);
        let loanDetails = {
          ...activeProduct.loanDetails,
        };
        loanDetails.totalInterestPaid = res.lpBasicDetails.totalInterestPaid.toFixed(
          2
        );
        loanDetails.totalFinancedFees = res.lpBasicDetails.totalFinancedFees.toFixed(
          2
        );
        loanDetails.totalCob = res.lpBasicDetails.totalCob.toFixed(2);
        loanDetails.paymentType = res.lpTermsDetails.paymentFrequency;
        if (activeProduct.category !== AppConstants.LOAN_CATEGORY.HELOC) {
          loanDetails.loanRate = getLoanRate(res);
        }
        console.log("loanDetails", loanDetails);
        this.setState({
          loanDetails,
        });
      }
    );
  };

  filterFinancialAnalysis(data, type) {
    return data.filter((financial) => financial.type === type);
  }

  updatePaymentInfo = (request) => {
    console.log("updatePaymentInfo", request);
    const {
      doUpdateLendingProduct,
      products,
      getActiveProduct,
      doGetLendingProduct,
      doUpdateProductToVault,
    } = this.props;
    const { loanDetails } = this.state;
    const activeProduct = getActiveProduct(products);
    const payLoad = {
      LPBasicDetails: {
        AdvanceTypeCode: "1",
      },
      LPTermsDetails: {
        PaymentFrequency: request.paymentType,
        PrePayTypeCode: "1",
        FirstPaymentDate: format(request.paymentDate, "yyyy-MM-dd"),
        RateCommittedDate: format(new Date(), "yyyy-MM-dd"),
      },
    };
    doUpdateLendingProduct(
      activeProduct.applicationId,
      activeProduct.doximProductId,
      payLoad,
      (x) => {
        console.log("doUpdateLendingProduct response", x, loanDetails);
        let newLoanDetails = { ...loanDetails };
        newLoanDetails.loanScheduleDate =
          payLoad.LPTermsDetails.FirstPaymentDate;
        newLoanDetails.paymentType = payLoad.LPTermsDetails.PaymentFrequency;
        products[0]["loanDetails"] = newLoanDetails;
        doUpdateProductToVault(products, () => {
          this.initializeLoanDetail();
        });
      }
    );
  };

  getAssetList = (data) => {
    const filteredAssets = this.filterFinancialAnalysis(
      data,
      AppConstants.ASSET.TYPE
    );
    this.setState({
      assetList: filteredAssets,
    });
  };

  getIncomeList = (data) => {
    const filteredIncomeList = this.filterFinancialAnalysis(
      data,
      AppConstants.INCOME.TYPE
    );
    filteredIncomeList.shift();
    this.setState({
      incomeList: filteredIncomeList,
    });
  };

  getExpenseList = (data) => {
    const filteredExpenseList = this.filterFinancialAnalysis(
      data,
      AppConstants.EXPENSE.TYPE
    );
    console.log("filteredExpenseList", filteredExpenseList);
    this.setState({
      expenseList: filteredExpenseList,
    });
  };

  getLiabilitiesList = (data) => {
    const filteredLiabilitiesList = this.filterFinancialAnalysis(
      data,
      AppConstants.LIABILITIES.TYPE
    );
    this.setState({
      liabilitiesList: filteredLiabilitiesList,
    });
  };

  /**
   *
   * @param {string} redirectToPage
   * handleEditPage makes URL navigation
   * using key as value
   */

  handleEditPage = (redirectToPage) => {
    const { history, doToggleEdit } = this.props;
    doToggleEdit(true);
    history.push(redirectToPage);
  };

  handleChange = () => {};

  handleContinue = (request) => {
    const {
      continuehandler,
      getActiveProduct,
      products,
      doGetApplicationProperty,
      applicantData,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log("handleContinue", activeProduct, applicantData);
    if (activeProduct.templateId === 7) {
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (currentApplication) => {
          if (
            currentApplication.inReview ||
            currentApplication.uploadedAt ||
            currentApplication.appliedAt
          ) {
            if (activeProduct.category == AppConstants.LOAN_CATEGORY.LOC) {
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.LOAN_SUMMARY.LOC.CONGRATS_TITLE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ),
                  description: MODALMESSAGES.LOAN_SUMMARY.LOC.CONGRATS_MESSAGE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ).replaceAll(
                    AppConstants.TEMPLATE.PRODUCT,
                    activeProduct.productName
                  ),
                },
              });
            } else if (
              activeProduct.category == AppConstants.LOAN_CATEGORY.HELOC
            ) {
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.LOAN_SUMMARY.HELOC.CONGRATS_TITLE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ),
                  description: MODALMESSAGES.LOAN_SUMMARY.HELOC.CONGRATS_MESSAGE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ).replaceAll(
                    AppConstants.TEMPLATE.PRODUCT,
                    activeProduct.productName
                  ),
                },
              });
            } else {
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.LOAN_SUMMARY.PRE_QUALIFY.CONGRATS_TITLE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ),
                  description: MODALMESSAGES.LOAN_SUMMARY.PRE_QUALIFY.CONGRATS_MESSAGE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ).replaceAll(
                    AppConstants.TEMPLATE.PRODUCT,
                    activeProduct.productName
                  ),
                },
              });
            }
          } else {
            continuehandler(request, AppConstants.APPLICATIONSTEP.LOAN_SUMMARY);
          }
        }
      );
    } else {
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (currentApplication) => {
          if (currentApplication.inReview) {
            this.setState({
              showModal: true,
              modalType: AppConstants.MODALTYPE.SUCCESS,
              modal: {
                title: MODALMESSAGES.LOAN_SUMMARY.REVIEW.CONGRATS_TITLE.replaceAll(
                  AppConstants.TEMPLATE.NAME,
                  applicantData.member.name
                ),
                description: MODALMESSAGES.LOAN_SUMMARY.REVIEW.CONGRATS_MESSAGE.replaceAll(
                  AppConstants.TEMPLATE.NAME,
                  applicantData.member.name
                ).replaceAll(
                  AppConstants.TEMPLATE.PRODUCT,
                  activeProduct.productName
                ),
              },
            });
          } else {
            continuehandler(request, AppConstants.APPLICATIONSTEP.LOAN_SUMMARY);
          }
        }
      );
    }
  };

  handlePopupModalBtnClick = () => {
    const { continuehandler } = this.props;
    continuehandler({}, AppConstants.APPLICATIONSTEP.LOAN_SUMMARY);
  };

  toggleModal = () => {};

  render() {
    const {
      choice,
      modal,
      modalType,
      showModal,
      assetList,
      liabilitiesList,
      expenseList,
      incomeList,
      loanDetails,
      aboutData,
      gettingStarted,
    } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      provinceList,
      gettingStartedResidentState,
      gettingStartedBranchState,
      products,
      getActiveProduct,
      loanProducts,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    return (
      <>
        <LoanSummary
          type={modalType}
          modal={modal}
          showModal={showModal}
          toggleModal={this.toggleModal}
          handlePopupModalBtnClick={this.handlePopupModalBtnClick}
          activeProduct={activeProduct}
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          loanDetails={loanDetails}
          assetList={assetList}
          liabilitiesList={liabilitiesList}
          expenseList={expenseList}
          incomeList={incomeList}
          aboutData={aboutData}
          gettingStarted={gettingStarted}
          provinceList={provinceList}
          handleEditPage={this.handleEditPage}
          gettingStartedBranchState={gettingStartedBranchState}
          gettingStartedResidentState={gettingStartedResidentState}
          loanProducts={loanProducts}
          updatePaymentInfo={this.updatePaymentInfo}
        />
      </>
    );
  }
}

LoanSummaryContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doToggleEdit: PropTypes.func.isRequired,
  provinceList: PropTypes.arrayOf(PropTypes.any),
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doFetchGettingStartedResidentStatus: PropTypes.func,
  gettingStartedResidentState: PropTypes.objectOf(PropTypes.any),
};

LoanSummaryContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  provinceList: [],
  doFetchGettingStartedResidentStatus: () => {},
  gettingStartedResidentState: {},
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
  provinceList: state.AboutYouReducer.provinceList,
  gettingStartedBranchState: state.GettingStartedBranchReducer,
  gettingStartedResidentState: state.GettingStartedResidentReducer,
  applicantData: state.ApplicationReducer.response,
  loanProductForm: state.LoanDetailsReducer.loanProductForm,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doConfirmApp: (applicationId, callback) =>
      dispatch(doConfirmApplication(applicationId, callback)),
    doGetBranchData: () => dispatch(fetchGettingStartedBranch()),
    doFetchGettingStartedResidentStatus: () =>
      dispatch(fetchGettingStartedResidentStatus()),
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
    doGetProvinceData: () => dispatch(doGetProvinceList()),
    doGetLoanProduct: (callback) => dispatch(getLoanProduct(callback)),
    doGetFinancialAnalysisFinancialList: (request, callback) =>
      dispatch(getFinancialAnalysisFinancialList(request, callback)),
    updateLoanProductForm: (loanProductForm, callback) =>
      dispatch(updateLoanProductForm(loanProductForm, callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doGetLendingProduct: (applicationId, doximProductId, callback) =>
      dispatch(getLendingProduct(applicationId, doximProductId, callback)),
    doUpdateLendingProduct: (
      applicationId,
      doximProductId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLendingProduct(applicationId, doximProductId, payLoad, callback)
      ),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

const LoanSummaryContainerWrapper = withApplicantHOC(LoanSummaryContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanSummaryContainerWrapper);
