import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  jsonData: [],
  success: "",
  error: "",
  formData: "",
  loanProducts: {
    success: "",
    error: "",
    data: "",
  },
  conditionGroups: {
    success: "",
    error: "",
    data: "",
  },
  loanProductForm: {
    purposeId: "",
  },
  financialAnalysisList: {
    success: "",
    error: "",
    data: "",
  },
  financialAnalysisFinancialList: {
    success: "",
    error: "",
    data: "",
  },
  incomeTypes: [],
  liabilityTypes: [],
  expenseTypes: [],
  assetTypes: [],
  config: {},
};

const LoanDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOAN_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.response,
      };
    case actionTypes.GET_LOAN_PRODUCT_ERROR:
      return {
        ...state,
        loanProducts: {
          success: "",
          error: action.error,
          data: "",
        },
      };
    case actionTypes.UPDATE_LOAN_PRODUCT_FORM:
      return {
        ...state,
        loanProductForm: action.data,
      };
    case actionTypes.GET_FINANCIALANALYSYS_LIST_SUCCESS:
      return {
        ...state,
        financialAnalysisList: {
          success: true,
          error: action.error,
          data: action.data,
        },
      };
    case actionTypes.GET_FINANCIALANALYSYS_LIST_ERROR:
      return {
        ...state,
        financialAnalysisList: {
          success: "",
          error: action.error,
          data: action.data,
        },
      };
    case actionTypes.GET_FINANCIALANALYSYS_FINANCIAL_LIST_SUCCESS:
      return {
        ...state,
        financialAnalysisFinancialList: {
          success: true,
          error: action.error,
          data: action.data,
        },
      };
    case actionTypes.GET_FINANCIALANALYSYS_FINANCIAL_LIST_ERROR:
      return {
        ...state,
        financialAnalysisFinancialList: {
          success: "",
          error: action.error,
          data: action.data,
        },
      };
    default:
      return state;
  }
};
export default LoanDetailsReducer;
