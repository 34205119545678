import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import NumberFormat from "react-number-format";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";
import VALIDATIONS from "Constants/ValidationList";
import checkErrors from "Utils/InputValidator";
import "./EmploymentAndIncome.scss";
import options, { setOptions } from "Utils/EmploymentAndIncomeUtilities";
import formatStringByPattern from "format-string-by-pattern";
import { LOANPRODUCT } from "../../Communication/Constants";

class EmploymentAndIncome extends React.Component {
  constructor(props) {
    super(props);
    // inputfield error object name  should be inputobject with suffix 'Error'
    //  For example input object: username, input error object: usernameError
    this.state = {
      employmentStatus: "",
      jobTitle: "",
      employerName: "",
      employerContactNumber: "",
      monthlyGrossIncome: "",
      sourceOfIncome: "",
      areYouTaxResidentOfCanada: "",
      areYouTaxResidentOfOtherCountry: "",
      errors: {
        areYouTaxResidentOfCanadaErrorList: "",
        areYouTaxResidentOfOtherCountryErrorList: "",
        countryNameErrorList: "",
        countryListErrorList: "",
        sinSsnNoneErrorList: "",
        sinErrorList: "",
        ssnErrorList: "",
        employmentStatusError: "",
        jobTitleError: "",
        employerNameError: "",
        employerContactNumberError: "",
        monthlyGrossIncomeError: "",
        sourceOfIncomeError: "",
      },
      sin: "",
      ssn: "",
      countryName: "",
      countryList: "",
      formIsValid: true,
      countryOption: [],
      showOtherTaxResidencyField: false,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.EMPLOYMENT, (response) => {
      this.setFormData(response);
    });
  }

  setFormData = (response) => {
    const { employment, regulatory, member } = response.data;
    this.setState({
      monthlyGrossIncome: employment.employerSalary,
      employerContactNumber: formatStringByPattern(
        AppConstants.EMPLOYMENTANDINCOME.PHONEFORMAT,
        employment.employerPhoneArea + employment.employerPhone
      ),
      employerName: employment.currentEmployer,
      employmentStatus: employment.employmentStatus,
      jobTitle: employment.jobTitle,
      sin: member.sin,
      sourceOfIncome: employment.incomeType,
      ssn: regulatory.ssn,
    });

    const resOptions = setOptions(regulatory.taxResidency);

    if (resOptions) {
      this.setState({
        areYouTaxResidentOfCanada: resOptions.taxResidentHome,
        areYouTaxResidentOfOtherCountry: resOptions.taxResidentOther,
        countryOption: resOptions.options,
      });

      const selectedOption = resOptions.options.find(
        (obj) => obj.taxResidencyResult.taxResidency === regulatory.taxResidency
      );

      const { showOtherTaxResidencyField } = selectedOption.taxResidencyResult;
      this.setState({
        showOtherTaxResidencyField,
        countryList: regulatory.taxResidencyOther,
        countryName: selectedOption.taxResidentOtherName,
      });
    }
  };

  getOptions = (taxResidentHome, taxResidentOther) => {
    const option = options(taxResidentHome || false, taxResidentOther || false);
    this.setState({ countryOption: option });
  };

  showCountryListField = (countryName) => {
    const { countryOption } = this.state;
    const selectedOption = countryOption.find(
      (obj) => obj.taxResidentOtherName === countryName
    );

    const { showOtherTaxResidencyField } = selectedOption.taxResidencyResult;
    this.setState({ showOtherTaxResidencyField });
  };

  handleChange = (event) => {
    const { errors } = this.state;
    const errorObject = `${event?.target.name}${STRINGS.COMMON.ERROR_LIST}`;
    this.setState(
      {
        [event?.target.name]: event?.target.value,
        errors: {
          ...errors,
          [errorObject]: "",
        },
      },
      () => {
        const { formIsValid } = this.state;
        if (!formIsValid) this.verifyData();
      }
    );
    if (
      event.target.name === STRINGS.EMPLOYMENT_AND_INCOME.FORM.COUNTRYFIELDNAME
    ) {
      this.showCountryListField(event.target.value);
    }
  };

  handleChoice = (name, choice) => {
    this.setState({ [name]: choice });

    const {
      areYouTaxResidentOfCanada,
      areYouTaxResidentOfOtherCountry,
      errors,
    } = this.state;
    this.getOptions(
      name ===
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.FIELDNAME.AREYOUTAXRESIDENTOFCANADA
        ? choice
        : areYouTaxResidentOfCanada,
      name ===
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.FIELDNAME.AREYOUTAXRESIDENTOFOTHER
        ? choice
        : areYouTaxResidentOfOtherCountry
    );
    if (
      name ===
      STRINGS.EMPLOYMENT_AND_INCOME.FORM.FIELDNAME.AREYOUTAXRESIDENTOFCANADA
    ) {
      if (choice) {
        this.setState(
          {
            errors: {
              ...errors,
              areYouTaxResidentOfCanadaErrorList: "",
              areYouTaxResidentOfOtherCountryErrorList: "",
              sinSsnNoneErrorList: "",
            },
          },
          () => {
            const { formIsValid } = this.state;
            if (!formIsValid) this.verifyData();
          }
        );
      } else {
        this.setState(
          {
            sin: "",
            errors: {
              ...errors,
              sinErrorList: "",
              areYouTaxResidentOfCanadaErrorList: "",
            },
          },
          () => {
            const { formIsValid } = this.state;
            if (!formIsValid) this.verifyData();
          }
        );
      }
    } else if (choice) {
      this.setState(
        {
          errors: {
            ...errors,
            areYouTaxResidentOfOtherCountryErrorList: "",
            sinSsnNoneErrorList: "",
          },
        },
        () => {
          const { formIsValid } = this.state;
          if (!formIsValid) this.verifyData();
        }
      );
    } else {
      this.setState(
        {
          countryName: "",
          ssn: "",
          errors: {
            ...errors,
            countryNameErrorList: "",
            ssnErrorList: "",
            areYouTaxResidentOfOtherCountryErrorList: "",
          },
        },
        () => {
          const { formIsValid } = this.state;
          if (!formIsValid) this.verifyData();
        }
      );
    }
  };

  handleSubmit = () => {
    const { onSubmitHandler } = this.props;
    if (this.verifyData(true)) onSubmitHandler(this.state);
  };

  changeSin = (event) => {
    const { errors } = this.state;
    this.setState({
      sin: event.value,
      errors: {
        ...errors,
        sinErrorList: "",
      },
    });
  };

  changeSsn = (event) => {
    const { errors } = this.state;
    this.setState({
      ssn: event.value,
      errors: {
        ...errors,
        ssnErrorList: "",
      },
    });
  };

  handleCountryList = (showOtherTaxResidencyField) => {
    this.setState({ showOtherTaxResidencyField });
  };

  verifyData = (isContinueClicked = false) => {
    const {
      areYouTaxResidentOfCanada,
      areYouTaxResidentOfOtherCountry,
      ssn,
      sin,
      countryName,
      countryList,
      errors,
      showOtherTaxResidencyField,
      employmentStatus,
      jobTitle,
      employerName,
      sourceOfIncome,
      employerContactNumber,
      monthlyGrossIncome,
    } = this.state;
    const { activeProduct } = this.props;
    let errorLength = 0;
    let formIsValid = true;

    let employmentStatusError = [];
    let jobTitleError = [];
    let employerNameError = [];
    let employerContactNumberError = [];
    let monthlyGrossIncomeError = [];
    let sourceOfIncomeError = [];

    if (activeProduct.type === LOANPRODUCT) {
      employmentStatusError = checkErrors(
        employmentStatus,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.employmentStatus
      );
      errorLength += employmentStatusError.length;

      jobTitleError = checkErrors(
        jobTitle,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.jobTitle
      );
      errorLength += jobTitleError.length;

      employerNameError = checkErrors(
        employerName,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.employerName
      );
      errorLength += employerNameError.length;

      employerContactNumberError = checkErrors(
        employerContactNumber,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.employerContactNumber
      );
      errorLength += employerContactNumberError.length;

      monthlyGrossIncomeError = checkErrors(
        monthlyGrossIncome,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.monthlyGrossIncome
      );
      errorLength += monthlyGrossIncomeError.length;

      sourceOfIncomeError = checkErrors(
        sourceOfIncome,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.sourceOfIncome
      );
      errorLength += sourceOfIncomeError.length;
    }

    const areYouTaxResidentOfCanadaError = checkErrors(
      areYouTaxResidentOfCanada,
      VALIDATIONS.EMPLOYMENT_AND_INCOME.taxrecidentconfirmation
    );
    errorLength += areYouTaxResidentOfCanadaError.length;

    const areYouTaxResidentOfOtherCountryError = checkErrors(
      areYouTaxResidentOfOtherCountry,
      VALIDATIONS.EMPLOYMENT_AND_INCOME.taxrecidentofothercountryconfirmation
    );
    errorLength += areYouTaxResidentOfOtherCountryError.length;

    let sinError = "";
    if (areYouTaxResidentOfCanada && isContinueClicked) {
      sinError = checkErrors(sin, VALIDATIONS.EMPLOYMENT_AND_INCOME.sin);
      errorLength += sinError.length;
    }

    let countryNameError = "";
    let ssnError = "";
    let countryListError = "";
    if (areYouTaxResidentOfOtherCountry) {
      if (!isContinueClicked) {
        if (countryName) countryNameError = "";
        if (
          countryName === STRINGS.EMPLOYMENT_AND_INCOME.FORM.COUNTRYNAME &&
          ssn
        ) {
          ssnError = "";
        } else if (showOtherTaxResidencyField) {
          countryListError = "";
        }
      } else {
        countryNameError = checkErrors(
          countryName,
          VALIDATIONS.EMPLOYMENT_AND_INCOME.country
        );
        errorLength += countryNameError.length;
        if (countryName === STRINGS.EMPLOYMENT_AND_INCOME.FORM.COUNTRYNAME) {
          ssnError = checkErrors(ssn, VALIDATIONS.EMPLOYMENT_AND_INCOME.ssn);
          errorLength += ssnError.length;
        } else if (showOtherTaxResidencyField) {
          countryListError = checkErrors(
            countryList,
            VALIDATIONS.EMPLOYMENT_AND_INCOME.listOfCountries
          );
          errorLength += countryListError.length;
        }
      }
    }
    let sinSsnNoneError = "";
    if (!areYouTaxResidentOfCanada && !areYouTaxResidentOfOtherCountry) {
      sinSsnNoneError =
        STRINGS.EMPLOYMENT_AND_INCOME.FORM.ERROR.BOTHSELECTEDNONEERROR;
      errorLength += 1;
    }

    if (errorLength > 0) {
      formIsValid = false;
    }
    this.setState({
      formIsValid,
      errors: {
        ...errors,
        areYouTaxResidentOfCanadaErrorList: areYouTaxResidentOfCanadaError,
        areYouTaxResidentOfOtherCountryErrorList: areYouTaxResidentOfOtherCountryError,
        countryNameErrorList: countryNameError,
        countryListErrorList: countryListError,
        sinErrorList: sinError,
        ssnErrorList: ssnError,
        sinSsnNoneErrorList: sinSsnNoneError,
        employmentStatusError,
        jobTitleError,
        employerNameError,
        employerContactNumberError,
        monthlyGrossIncomeError,
        sourceOfIncomeError,
      },
    });
    return formIsValid;
  };

  countryTax = () => {
    const {
      countryName,
      countryList,
      ssn,
      errors,
      countryOption,
      showOtherTaxResidencyField,
    } = this.state;

    return (
      <div className="[ drawer-content-container ]">
        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.EMPLOYMENT_AND_INCOME.FORM.TAX_RESIDENCY}
          </div>

          <select
            className={
              errors?.countryNameErrorList.length > 0
                ? "[ form-control ] [ select__dropdown ] [ invalid-input ]"
                : "[ form-control ] [ select__dropdown ]"
            }
            id={STRINGS.EMPLOYMENT_AND_INCOME.FORM.COUNTRY}
            name="countryName"
            value={countryName}
            onChange={(event) => this.handleChange(event)}
          >
            <option value="" className="[ default ] [ dropdown__option ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.COUNTRY}
            </option>
            {countryOption
              ? countryOption.map((country) => (
                  <option
                    key={country?.taxResidencyResult?.taxResidency}
                    value={country?.taxResidentOtherName}
                    className="[ dropdown__option ]"
                  >
                    {country.taxResidentOtherName}
                  </option>
                ))
              : ""}
          </select>

          <div className="[ error-msg ] ">
            {errors?.countryNameErrorList && (
              <>{errors?.countryNameErrorList}</>
            )}
          </div>
        </div>

        {countryName === STRINGS.EMPLOYMENT_AND_INCOME.FORM.COUNTRYNAME ? (
          <>
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.SSN}
              </div>
              <NumberFormat
                format={AppConstants.EMPLOYMENTANDINCOME.TIN_FORMATE}
                className={
                  errors?.ssnErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                type="text"
                name="ssn"
                value={ssn}
                placeholder={`${STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.SSN}`}
                onValueChange={(value) => {
                  this.changeSsn(value);
                }}
              />
              <div className="[ error-msg ]">
                {errors?.ssnErrorList && <>{errors?.ssnErrorList[0]}</>}
              </div>
            </div>
          </>
        ) : null}

        {showOtherTaxResidencyField ? (
          <>
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.LIST_THE_COUNTRIES}
              </div>

              <input
                type="text"
                className={
                  errors?.countryListErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                name="countryList"
                value={countryList}
                placeholder={
                  STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                    .LIST_THE_COUNTRIES
                }
                onChange={this.handleChange}
                maxLength={30}
              />
              <div className="[ error-msg ]">
                {errors?.countryListErrorList}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  sinRender = (editMode) => {
    const { sin, errors } = this.state;
    return (
      <div className="[ drawer-content-container ]">
        <div className="[ info-title ]">
          {STRINGS.EMPLOYMENT_AND_INCOME.FORM.TAX_RESIDENCY}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ form-label ]">
            {STRINGS.EMPLOYMENT_AND_INCOME.FORM.SIN}
          </div>
          <NumberFormat
            disabled={!editMode}
            format={AppConstants.EMPLOYMENTANDINCOME.TIN_FORMATE}
            className={
              errors?.sinErrorList.length > 0
                ? "[ form-control ] [ invalid-input ]"
                : "[ form-control ]"
            }
            type="text"
            name="sin"
            value={sin}
            placeholder={`${STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.SIN}`}
            onValueChange={(value) => {
              this.changeSin(value);
            }}
          />

          <div className="[ error-msg ]">
            {errors?.sinErrorList && <>{errors?.sinErrorList[0]}</>}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      employmentStatus,
      jobTitle,
      employerName,
      sourceOfIncome,
      employerContactNumber,
      monthlyGrossIncome,
      areYouTaxResidentOfCanada,
      areYouTaxResidentOfOtherCountry,
      errors,
    } = this.state;

    const {
      statusList,
      incomeSourcesList,
      handleBack,
      steps,
      activeStepID,
      editMode,
      newMember,
      config,
    } = this.props;

    const actionComponent = (
      <div className="[ employment-and-income ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.EMPLOYMENT_AND_INCOME.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-subtitle ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.SUBTITLE}
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.EMPLOYMENT_STATUS}
            </div>
            <select
              className="[ form-control select__dropdown]"
              id="exampleFormControlSelect1"
              name="employmentStatus"
              value={employmentStatus}
              placeholder={
                STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.EMPLOYMENT_STATUS
              }
              onChange={this.handleChange}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {
                  STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                    .EMPLOYMENT_STATUS
                }
              </option>
              {statusList
                ? statusList.map((status) => (
                    <option
                      key={status.code}
                      value={status.code}
                      className="[ dropdown__option ]"
                    >
                      {status.description}
                    </option>
                  ))
                : ""}
            </select>
            <div className="[ error-msg ]">
              {errors?.employmentStatusError && (
                <>{errors?.employmentStatusError}</>
              )}
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.OCCUPATION}
            </div>
            <select
              className="[ form-control select__dropdown]"
              id="jobTitle"
              name="jobTitle"
              value={jobTitle}
              placeholder={
                STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.OCCUPATION
              }
              onChange={this.handleChange}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.OCCUPATION}
              </option>
              {config?.occupations
                ? config.occupations.map((occupation) => (
                    <option
                      key={occupation.code}
                      value={occupation.code}
                      className="[ dropdown__option ]"
                    >
                      {occupation.description}
                    </option>
                  ))
                : ""}
            </select>
            <div className="[ error-msg ]">
              {errors?.jobTitleError && <>{errors?.jobTitleError}</>}
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.EMPLOYER_NAME}
            </div>
            <input
              type="text"
              name="employerName"
              className="[ form-control ]"
              placeholder={
                STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.EMPLOYER_NAME
              }
              value={employerName}
              onChange={this.handleChange}
            />
            <div className="[ error-msg ]">
              {errors?.employerNameError && <>{errors?.employerNameError}</>}
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.EMPLOYER_CONTACT_NO}
            </div>
            <NumberFormat
              format={AppConstants.EMPLOYMENTANDINCOME.NUMBERFORMAT}
              className="[ form-control ]"
              type="text"
              name="employerContactNumber"
              value={employerContactNumber}
              placeholder={`${STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.EMPLOYER_CONTACT_NO}`}
              onChange={this.handleChange}
            />
            <div className="[ error-msg ]">
              {errors?.employerContactNumberError && (
                <>{errors?.employerContactNumberError}</>
              )}
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.MONTHLY_GROSS_INCOME}
            </div>
            <NumberFormat
              maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
              type="text"
              thousandSeparator
              allowNegative={false}
              allowLeadingZeros={false}
              prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
              name="monthlyGrossIncome"
              className="[ form-control ]"
              placeholder={
                STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                  .MONTHLY_GROSS_INCOME
              }
              onChange={this.handleChange}
              value={monthlyGrossIncome}
            />

            <div className="[ error-msg ]">
              {errors?.monthlyGrossIncomeError && (
                <>{errors?.monthlyGrossIncomeError}</>
              )}
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.EMPLOYMENT_AND_INCOME.FORM.SOURCE_OF_INCOME}
            </div>
            <select
              className="[ form-control select__dropdown]"
              id="sourceOfIncome"
              name="sourceOfIncome"
              value={sourceOfIncome}
              onChange={this.handleChange}
              placeholder={
                STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.EMPLOYMENT_STATUS
              }
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {
                  STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER
                    .SOURCE_OF_INCOME
                }
              </option>
              {incomeSourcesList
                ? incomeSourcesList.map((incomeSource) => (
                    <option
                      key={incomeSource.code}
                      value={incomeSource.code}
                      className="[ dropdown__option ]"
                    >
                      {incomeSource.description}
                    </option>
                  ))
                : ""}
            </select>
            <div className="[ error-msg ]">
              {errors?.sourceOfIncomeError && (
                <>{errors?.sourceOfIncomeError}</>
              )}
            </div>
          </div>

          <div className="[ mb-3 ]">
            {editMode && (
              <div className="[ form-label ]">
                {STRINGS.EMPLOYMENT_AND_INCOME.FORM.TAX_RESIDENT_CONFIRMATION}
              </div>
            )}
            {editMode && (
              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    disabled={!editMode}
                    type="button"
                    className={
                      areYouTaxResidentOfCanada !== "" &&
                      areYouTaxResidentOfCanada
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    onClick={(event) =>
                      this.handleChoice(
                        STRINGS.EMPLOYMENT_AND_INCOME.FORM.FIELDNAME
                          .AREYOUTAXRESIDENTOFCANADA,
                        true,
                        event
                      )
                    }
                  >
                    {STRINGS.COMMON.CHOICE.YES}
                  </button>
                </div>

                <div className="[ col-6 ]">
                  <button
                    disabled={!editMode}
                    type="button"
                    className={
                      areYouTaxResidentOfCanada !== "" &&
                      !areYouTaxResidentOfCanada
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    onClick={(event) =>
                      this.handleChoice(
                        STRINGS.EMPLOYMENT_AND_INCOME.FORM.FIELDNAME
                          .AREYOUTAXRESIDENTOFCANADA,
                        false,
                        event
                      )
                    }
                  >
                    {STRINGS.COMMON.CHOICE.NO}
                  </button>
                </div>
              </div>
            )}

            {editMode && (
              <div className="[ error-msg ]">
                {errors?.areYouTaxResidentOfCanadaErrorList && (
                  <>{errors?.areYouTaxResidentOfCanadaErrorList}</>
                )}
              </div>
            )}

            {areYouTaxResidentOfCanada ? this.sinRender(editMode) : null}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {
                STRINGS.EMPLOYMENT_AND_INCOME.FORM
                  .RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION
              }
            </div>
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    areYouTaxResidentOfOtherCountry !== "" &&
                    areYouTaxResidentOfOtherCountry
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="areYouTaxResidentOfOtherCountry"
                  onClick={(event) =>
                    this.handleChoice(
                      STRINGS.EMPLOYMENT_AND_INCOME.FORM.FIELDNAME
                        .AREYOUTAXRESIDENTOFOTHER,
                      true,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    areYouTaxResidentOfOtherCountry !== "" &&
                    !areYouTaxResidentOfOtherCountry
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="areYouTaxResidentOfOtherCountry"
                  onClick={(event) =>
                    this.handleChoice(
                      STRINGS.EMPLOYMENT_AND_INCOME.FORM.FIELDNAME
                        .AREYOUTAXRESIDENTOFOTHER,
                      false,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>

            <div className="[ error-msg ]">
              {errors?.areYouTaxResidentOfOtherCountryErrorList}
              {areYouTaxResidentOfOtherCountry === false &&
              areYouTaxResidentOfCanada === false
                ? errors?.sinSsnNoneErrorList
                : ""}
            </div>
            {areYouTaxResidentOfOtherCountry ? this.countryTax() : ""}
          </div>

          <div className="[ error-msg ] [ row ]">
            <div className="[ col-12 ]" />
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <AuthBody
        actionComponent={actionComponent}
        securityText={null}
        infoType={"welcomeBack"}
        memberCreation
        handleBack={handleBack}
      />
    );
  }
}

EmploymentAndIncome.propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.object).isRequired,
  incomeSourcesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};
EmploymentAndIncome.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
};
export default EmploymentAndIncome;
