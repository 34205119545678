import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import {
  HTTP_STATUS,
  EXISTING_OR_SAME_PRODUCT_MESSAGE,
  APPLICANTID,
} from "Communication/Constants";
import {
  getProductList,
  updateVaultProductList,
  getProductFromVault,
  updateProductToVault,
  updateActiveTab,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import {
  getApplication,
  getApplicationList,
} from "Redux/Actions/ApplicationAction";
import {
  storeFundProduct,
  addFundAmount,
  addFundMethod,
} from "Components/FundingAmount/Action/FundAction";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import VaultDashboard from "./VaultDashboard";
import { setInSessionJointApplicant } from "../JointApplicant/Actions/JointApplicantAction";

class VaultDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      showModal: false,
      modal: {
        title: "",
        description: "",
      },
    };
  }

  componentDidMount() {
    const {
      doGetProductFromVault,
      doGetProductList,
      doUpdateActiveTab,
      doGetApplicationList,
      doGetApplicantListAction,
      doGetApplicant,
      doUpdateProductToVault,
      doStoreFundProduct,
      prepareVaultProductList,
      doAddFundMethod,
      doAddFundAmount,
      doSetInSessionJointApplicant,
    } = this.props;
    console.log("VaultDashboardContainer");
    doStoreFundProduct({});
    doAddFundMethod("");
    doAddFundAmount("");
    doSetInSessionJointApplicant(null);
    const title = sessionStorage.getItem(EXISTING_OR_SAME_PRODUCT_MESSAGE);
    if (title) {
      sessionStorage.removeItem(EXISTING_OR_SAME_PRODUCT_MESSAGE);
      const modal = {
        title,
        description: "",
      };
      this.setState({ showModal: true, modal });
    }

    const applicantId = sessionStorage.getItem(APPLICANTID);
    if (applicantId === null) {
      doGetApplicantListAction((res) => {
        if (res.status === HTTP_STATUS.OK) {
          if (res.data.length === 0) {
            this.setState({
              userName: STRINGS.MARKETPLACE.PAGE_BANNER.WELCOME_TEXT,
            });
          } else {
            // get first applicantID from the list
            const selfApplication = res.data.find(
              (app) => app.relationship === AppConstants.RELATIONSHIP.SELF
            );
            const getFirstApplicantID = selfApplication.id;
            sessionStorage.setItem(APPLICANTID, getFirstApplicantID);
            doGetApplicant(getFirstApplicantID, (responseResult) => {
              if (responseResult.status === HTTP_STATUS.OK) {
                const userName =
                  STRINGS.MARKETPLACE.PAGE_BANNER.WELCOME_TEXT +
                  responseResult.data.member.name;
                this.setState({ userName });
              }
            });
          }
        }
      });
    } else {
      doGetApplicant(applicantId, (responseResult) => {
        if (responseResult.status === HTTP_STATUS.OK) {
          const userName =
            STRINGS.MARKETPLACE.PAGE_BANNER.WELCOME_TEXT +
            responseResult.data.member.name;
          this.setState({ userName });
        }
      });
    }

    doGetProductList((response) => {
      if (response && response.status === HTTP_STATUS.OK) {
        doGetProductFromVault((res) => {
          if (res && res.status === HTTP_STATUS.OK) {
            if (res.data && res.data.length !== 0) {
              const vaultProductList = res.data;
              vaultProductList[0].active = false;
              doUpdateProductToVault(vaultProductList, () => {});
            }
            doGetApplicationList((result) => {
              if (result && result.status === HTTP_STATUS.OK) {
                if (
                  result.data.length === 0 &&
                  (res.data === "" || res.data.length === 0)
                ) {
                  doUpdateActiveTab(1);
                } else {
                  doUpdateActiveTab(0);
                }
              }
            });
          }
        });
      }
    });

    const jointSlotId = sessionStorage.getItem(
      STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
    );
    if (jointSlotId && jointSlotId !== "") {
      prepareVaultProductList(true);
    }
  }

  handleMarketplaceClicked = () => {
    const { doGetProductList } = this.props;
    doGetProductList(() => {});
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  render() {
    const {
      doUpdateVaultProductList,
      doGetProductFromVault,
      proceedWithApplication,
      deleteApplication,
      applicationCompleteList,
      productList,
      categoryList,
      doUpdateProductToVault,
      activeTab,
      doUpdateActiveTab,
      doGetApplicationList,
      doGetProductList,
      doSetInSessionJointApplicant,
      applicationList,
    } = this.props;

    const { showModal, modal, userName } = this.state;
    return (
      <>
        {showModal && (
          <PopupModal
            type={AppConstants.MODALTYPE.INFORMATION}
            title={modal.title}
            toggleModal={this.toggleModal}
            description={modal.description}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <VaultDashboard
          applicationCompleteList={applicationCompleteList}
          productList={productList}
          categoryList={categoryList}
          handleMarketplaceClicked={this.handleMarketplaceClicked}
          doUpdateVaultProductList={doUpdateVaultProductList}
          deleteApplication={deleteApplication}
          doGetProductFromVault={doGetProductFromVault}
          proceedWithApplication={(callback) =>
            proceedWithApplication(callback)
          }
          doUpdateProductToVault={doUpdateProductToVault}
          activeTab={activeTab}
          doUpdateActiveTab={doUpdateActiveTab}
          doGetApplicationList={doGetApplicationList}
          doGetProductList={doGetProductList}
          userName={userName}
          doSetInSessionJointApplicant={doSetInSessionJointApplicant}
          applicationList={applicationList}
        />
      </>
    );
  }
}

VaultDashboardContainer.propTypes = {
  doGetProductList: PropTypes.func,
  doUpdateVaultProductList: PropTypes.func,
  doGetProductFromVault: PropTypes.func,
  deleteApplication: PropTypes.func,
  doGetApplicationList: PropTypes.func,
  applicationCompleteList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  categoryList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  proceedWithApplication: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
  activeTab: PropTypes.number.isRequired,
  doUpdateActiveTab: PropTypes.func,
  doGetApplicantListAction: PropTypes.func,
  doGetApplicant: PropTypes.func,
  doStoreFundProduct: PropTypes.func,
  prepareVaultProductList: PropTypes.func.isRequired,
  doAddFundMethod: PropTypes.func.isRequired,
  doAddFundAmount: PropTypes.func.isRequired,
  doSetInSessionJointApplicant: PropTypes.func.isRequired,
};

VaultDashboardContainer.defaultProps = {
  doGetProductList: () => {},
  doUpdateVaultProductList: () => {},
  doGetProductFromVault: () => {},
  deleteApplication: () => {},
  doGetApplicationList: () => {},
  applicationCompleteList: [],
  productList: [],
  categoryList: [],
  proceedWithApplication: () => {},
  doUpdateProductToVault: () => {},
  doUpdateActiveTab: () => {},
  doGetApplicantListAction: () => {},
  doGetApplicant: () => {},
  doStoreFundProduct: () => {},
};

const mapStateToProps = (state) => ({
  productList: state.MarketplaceReducer.productList,
  categoryList: state.MarketplaceReducer.categoryList,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  applicationList: state.ApplicationReducer.applicationList,
  activeTab: state.MarketplaceReducer.activeTab,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doGetProductList: getProductList,
      doUpdateVaultProductList: updateVaultProductList,
      doGetProductFromVault: getProductFromVault,
      doGetApplicationList: getApplicationList,
      doUpdateProductToVault: updateProductToVault,
      doUpdateActiveTab: updateActiveTab,
      doGetApplicant: getApplication,
      doStoreFundProduct: storeFundProduct,
      doAddFundMethod: addFundMethod,
      doAddFundAmount: addFundAmount,
      doSetInSessionJointApplicant: setInSessionJointApplicant,
    },
    dispatch
  );
};

const VaultDashboardWrapper = withVaultHOC(VaultDashboardContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VaultDashboardWrapper);
