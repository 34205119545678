import {
  IDVERIFICATION_FAILED_CODE,
  IDEVERIFICATION_AUTH,
} from "Constants/APIConstants";
import STRINGS from "Constants/Strings";

const getFailedResponse = (reasonCodes) => {
  return IDVERIFICATION_FAILED_CODE[reasonCodes[0]];
};

const getUserCheckList = (response) => {
  let authCheckList = getAuthCheckList(response);
  if (!response.matchSelfie || response.matchSelfie !== "Verified") {
    authCheckList.captureSelfie = true;
  }
  return authCheckList;
};

const getAuthCheckList = (response) => {
  switch (response.authentication) {
    case IDEVERIFICATION_AUTH.VERIFIED:
    case IDEVERIFICATION_AUTH.FRAUD_SHIELD:
      return {
        profile: true,
        selectId: false,
        captureFrontId: false,
        captureBackId: false,
        captureSelfie: false,
      };
    case IDEVERIFICATION_AUTH.RECAPTURE_SELFIE:
      return {
        profile: true,
        selectId: false,
        captureFrontId: false,
        captureBackId: false,
        captureSelfie: true,
      };
    case IDEVERIFICATION_AUTH.RECAPTURE_ID:
      return {
        profile: true,
        selectId: false,
        captureFrontId: true,
        captureBackId: true,
        captureSelfie: false,
      };
    case IDEVERIFICATION_AUTH.FAILED:
      return getFailedResponse(response.reasonCodes);
    case IDEVERIFICATION_AUTH.RECAPTURE_ALL:
    case IDEVERIFICATION_AUTH.ID_EXPIRATION:
    case IDEVERIFICATION_AUTH.UNSUPPORTED_ID:
    case IDEVERIFICATION_AUTH.POTENTIAL:
    case IDEVERIFICATION_AUTH.SUBMISSION_ERROR:
    default:
      return {
        profile: true,
        selectId: true,
        captureFrontId: true,
        captureBackId: true,
        captureSelfie: true,
      };
  }
};

/**
 * Returns the code of the country selected
 * from STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRIES object.
 * Designed to support more countries.
 * @param {*} country selected country name
 */
export const getSelectedCountry = (country) => {
  let countryCode = STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRIES.CA.CODE;

  const countriesKeys = Object.keys(
    STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRIES
  );

  if (countriesKeys && countriesKeys.length > 0) {
    const selectedCountry = countriesKeys.find((countryObj) => {
      return (
        STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRIES[countryObj].NAME ===
        country
      );
    });

    if (selectedCountry) {
      countryCode = selectedCountry;
    }
  }
  return countryCode;
};

export default getUserCheckList;
