import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";

import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import "./Beneficiaries.scss";

class BeneficiaryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeHandler = (event) => {
    const { beneficiary, onInputChangeHandler, isEditBeneficiary } = this.props;
    const { showEditAndDeletBtn } = beneficiary;
    if (!isEditBeneficiary && showEditAndDeletBtn) {
      return;
    }
    const { errors } = beneficiary;
    const inputField = event?.target.name;
    const errorList = {
      ...errors,
      [`${inputField}ErrorList`]: [],
    };
    let updatedBeneficiary = {
      ...beneficiary,
    };
    updatedBeneficiary = {
      ...updatedBeneficiary,
      [inputField]: event?.target.value,
      errors: errorList,
    };
    onInputChangeHandler(updatedBeneficiary);
  };

  renderDropDownList = (dropdownList) => {
    const { beneficiary } = this.props;
    const { editMode } = beneficiary;
    return (
      <>
        {dropdownList?.map((item) => (
          <option
            value={item.description}
            key={item.code}
            className="[ dropdown__option ]"
            disabled={!editMode}
          >
            {item.description}
          </option>
        ))}
      </>
    );
  };

  render() {
    const {
      beneficiary,
      provinceList,
      countryList,
      beneficiaryRelationList,
      beneficiaryTypeList,
      index,
      handleDeleteBeneficiary,
      handleEditClick,
      showSaveAndCancelBtn,
      renderDetails,
      isEditBeneficiary,
    } = this.props;
    const {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      beneficiaryRelation,
      beneficiaryType,
      address1,
      address2,
      city,
      province,
      postalCode,
      mobileNo,
      sin,
      country,
      showEditAndDeletBtn,
      editMode,
      errors,
    } = beneficiary;

    const userInformation = (
      <>
        <div className="[ row ] [ mr-0 ] [ pr-1 ]">
          <div className="[ col-6 ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.BENEFICIARY}
              {index + 1}
            </div>
          </div>
          {showEditAndDeletBtn && (
            <div className="[ col-6 ] [ text-right ] [ pr-0 ]">
              <button
                type="button"
                className={`[ btn edit-btn ] ${
                  !isEditBeneficiary && " [ common-edit-btn_color ]"
                }`}
                disabled={isEditBeneficiary}
                onClick={() => {
                  handleEditClick(beneficiary);
                }}
                aria-label="edit"
              >
                <span className="[ edit-icon ]" />
                <span className="[ edit-btn__label ]">
                  {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                </span>
              </button>
              <span
                className="beneficiary-remove-icon delete_icon pl-3"
                role="presentation"
                onClick={() => {
                  handleDeleteBeneficiary(beneficiary);
                }}
              />
            </div>
          )}
        </div>

        <div className="[ row ]">
          <div className="[ col-md-3 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.FIRSTNAMELABEL}
            </div>
            <>
              <div className="[ info-group__input ]">
                <input
                  type="text"
                  name="firstName"
                  className={
                    errors?.firstNameErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  placeholder={STRINGS.BENEFICIARIES.FORM.FIRSTNAMELABEL}
                  value={firstName}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                />
              </div>
              <div className="[ error-msg ] [ d-inline ]">
                {errors?.firstNameErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-md-3 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.MIDDLENAMELABEL}
            </div>
            <>
              <div className="[ info-group__input ]">
                <input
                  type="text"
                  name="middleName"
                  className="[ form-control form-control-sm ]"
                  placeholder={STRINGS.BENEFICIARIES.FORM.MIDDLENAMELABEL}
                  value={middleName}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                />
                <div className="[ error-msg ]" />
              </div>
            </>
          </div>
          <div className="[ col-md-3 col-12 ] [ info-group ] [ pr-3 ] ">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.LASTNAMELABEL}
            </div>
            <>
              <div className="[ info-group__input ]">
                <input
                  type="text"
                  name="lastName"
                  className={
                    errors?.lastNameErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  placeholder={STRINGS.BENEFICIARIES.FORM.LASTNAMELABEL}
                  value={lastName}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                />
              </div>
              <div className="[ error-msg ] [ d-inline ]">
                {errors?.lastNameErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-md-3 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.DOBLABEL}
            </div>
            <>
              <div className="[ info-group__input ]">
                <NumberFormat
                  format={AppConstants.DATEOFBIRTH.FORMAT}
                  className={
                    errors?.dateOfBirthErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  type="text"
                  name="dateOfBirth"
                  value={dateOfBirth}
                  mask={AppConstants.DATEOFBIRTH.MASK}
                  placeholder={`${STRINGS.BENEFICIARIES.FORM.DOBPLACEHOLDER}`}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                />
              </div>
              <div className="[ error-msg ]">
                {errors?.dateOfBirthErrorList[0]}
              </div>
            </>
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-sm-6 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.RELATIONSHIP}
            </div>
            <>
              <div className="[ info-group__input ]">
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  name="beneficiaryRelation"
                  value={beneficiaryRelation}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.BENEFICIARIES.FORM.RELATIONSHIP_PLACEHOLDER}
                  </option>
                  {this.renderDropDownList(beneficiaryRelationList)}
                </select>
              </div>
              <div className="[ error-msg ]">
                {errors?.beneficiaryRelationErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-sm-6 col-12 ] [ pr-3 ] [ info-group ]">
            <div className="[ form-label ]">
              {STRINGS.BENEFICIARIES.FORM.BENEFICIARY_TYPE}
            </div>
            <>
              <div className="[ info-group__input ]">
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  name="beneficiaryType"
                  value={beneficiaryType}
                  onChange={(event) => this.onChangeHandler(event)}
                  disabled={!editMode}
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.BENEFICIARIES.FORM.BENEFICIARY_PLACEHOLDER}
                  </option>
                  {this.renderDropDownList(beneficiaryTypeList)}
                </select>
              </div>
              <div className="[ error-msg ]">
                {errors?.beneficiaryTypeErrorList[0]}
              </div>
            </>
          </div>
        </div>
      </>
    );

    const countrySelectElem = (
      <select
        name="country"
        value={country}
        className={
          errors?.countryErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={(event) => this.onChangeHandler(event)}
        disabled={!editMode}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.BENEFICIARIES.FORM.SELECT_COUNTRY}
        </option>
        {this.renderDropDownList(countryList)}
      </select>
    );

    const taxResidency = (
      <div className="[ card-body ] [ py-4 ]">
        <div className="[ form-label ] [ py-2 ] [ m-0 ]">
          {STRINGS.BENEFICIARIES.FORM.TAX_RESIDENCY}
        </div>
        <div className="[ row ]">
          <div className="[ col-xl-9 col-12 ]">{countrySelectElem}</div>
        </div>

        <div className="[ error-msg ] [ row ]">
          {errors?.countryErrorList && (
            <div className="[ col-12 ]">{errors?.countryErrorList}</div>
          )}
        </div>
        <>
          <div className="[ row ] [ py-2 ]">
            <div className="[ form-label ] [ col-12 ]">
              {STRINGS.BENEFICIARIES.FORM.SIN}
            </div>
          </div>
          <div className="[ row ]">
            <div className="[ col-xl-9 col-12 ]">
              <NumberFormat
                format={AppConstants.EMPLOYMENTANDINCOME.TIN_FORMATE}
                className={
                  errors?.sinErrorList.length > 0
                    ? "[ form-control form-control-sm ] [ invalid-input ]"
                    : "[ form-control form-control-sm ]"
                }
                type="text"
                name="sin"
                value={sin}
                placeholder={`${STRINGS.BENEFICIARIES.FORM.SIN_PLACEHOLDER}`}
                onChange={this.onChangeHandler}
                disabled={!editMode}
              />
            </div>
          </div>
          <div className="[ error-msg ] [ row ]">
            {errors?.sinErrorList && (
              <div className="[ col-12 ]">{errors?.sinErrorList[0]}</div>
            )}
          </div>
        </>
      </div>
    );

    const selectList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
        disabled={!editMode}
      >
        {elem.description}
      </option>
    ));

    const selectElement = (
      <select
        name="province"
        value={province}
        className={
          errors?.provinceErrorList.length > 0
            ? "[ form-control form-control-sm ] [ invalid-input ]"
            : "[ form-control form-control-sm ]"
        }
        onChange={(event) => this.onChangeHandler(event)}
        disabled={!editMode}
      >
        <option value="" className="[ dropdown__option ]">
          {" "}
          Select {STRINGS.BENEFICIARIES.FORM.PROVINCE}
        </option>
        {selectList}
      </select>
    );

    const addressInputGroup = (
      <div className="[ address-container__group ]">
        <div className="[ address-container__group__header ]">
          {STRINGS.BENEFICIARIES.FORM.RESIDENCE_ADDRESS}
        </div>
        <div className="[ row ] [ pt-2 ]">
          <div className="[ col-12 ]">
            <input
              type="text"
              name="address1"
              className={
                errors?.address1ErrorList.length > 0
                  ? "[ form-control form-control-sm ] [ invalid-input ]"
                  : "[ form-control form-control-sm ]"
              }
              placeholder={STRINGS.BENEFICIARIES.FORM.ADDRESS1}
              value={address1}
              onChange={(event) => this.onChangeHandler(event)}
              disabled={!editMode}
            />
            <div className="[ error-msg ]">{errors?.address1ErrorList[0]}</div>
          </div>
        </div>
        <div className="[ row ] [ pt-2 ]">
          <div className="[ col-12 ]">
            <input
              type="text"
              name="address2"
              className="[ form-control form-control-sm ]"
              placeholder={STRINGS.BENEFICIARIES.FORM.ADDRESS2}
              value={address2}
              onChange={(event) => this.onChangeHandler(event)}
              disabled={!editMode}
            />
            <div className="[ error-msg ]" />
          </div>
        </div>

        <div className="[ row ] [ pt-2  ]">
          <div className="[ col-sm-6 col-12 ]">
            <input
              type="text"
              name="city"
              className={
                errors?.cityErrorList.length > 0
                  ? "[ form-control form-control-sm ] [ invalid-input ]"
                  : "[ form-control form-control-sm ]"
              }
              placeholder={STRINGS.BENEFICIARIES.FORM.CITY}
              value={city}
              onChange={(event) => this.onChangeHandler(event)}
              disabled={!editMode}
            />
            <div className="[ error-msg ]">{errors?.cityErrorList[0]}</div>
          </div>
          <div className="[ col-sm-6 col-12 ]">
            <div className="[ dropdown ]">
              {selectElement}
              <div className="[ error-msg ]">
                {errors?.provinceErrorList[0]}
              </div>
            </div>
          </div>
        </div>
        <div className="[ row ] [ pt-2 pb-2 ]">
          <div className="[ col-sm-6 col-12 ]">
            <>
              <MaskedInput
                mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                className={
                  errors?.postalCodeErrorList.length > 0
                    ? "[ form-control form-control-sm ] [ invalid-input ]"
                    : "[ form-control form-control-sm ]"
                }
                placeholder={STRINGS.BENEFICIARIES.FORM.POSTALCODE}
                guide={false}
                id="postalCode"
                name="postalCode"
                value={postalCode}
                onChange={(event) => this.onChangeHandler(event)}
                disabled={!editMode}
              />
              <div className="[ error-msg ]">
                {errors?.postalCodeErrorList[0]}
              </div>
            </>
          </div>
          <div className="[ col-sm-6 col-12 ]">
            <>
              <NumberFormat
                format={AppConstants.REGISTRATION.NUMBERFORMAT}
                className={
                  errors?.mobileNoErrorList &&
                  errors?.mobileNoErrorList.length > 0
                    ? "[ form-control form-control-sm ] [ invalid-input ]"
                    : "[ form-control form-control-sm ]"
                }
                type="text"
                name="mobileNo"
                value={mobileNo}
                placeholder={`${STRINGS.BENEFICIARIES.FORM.MOBILENOPLACEHOLDER}`}
                onChange={(values) => this.onChangeHandler(values)}
                disabled={!editMode}
              />
              <div className="[ error-msg ]">
                {errors?.mobileNoErrorList[0]}
              </div>
            </>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {renderDetails && (
          <div>
            {index !== 0 && (
              <div className="[ beneficiary-container__line-separator ] [ my-2 ]" />
            )}
            <div className="[ drawer-content-container ] ">
              <div className="[ pb-2 ] [ justify-content-xl-start justify-content-center ] [ beneficiary-Info ]">
                {userInformation}
              </div>
              <div className="[ adress-container ]">{addressInputGroup}</div>
              <div className="[ tax-residency-container ]">{taxResidency}</div>
              {!editMode && showEditAndDeletBtn
                ? ""
                : showSaveAndCancelBtn && (
                    <div className="[ row ] [ btn-choice ] [ mt-2 ] ">
                      <div className="[ col-6 ]">
                        <button
                          type="button"
                          className="[ btn ] [ w-100 ] [ submit-btn ] [ secondary-submit-btn ] [ text-responsive ]"
                          onClick={() => {
                            const { handleSaveClick } = this.props;
                            handleSaveClick(beneficiary);
                          }}
                        >
                          {STRINGS.BENEFICIARIES.FORM.SAVE}
                        </button>
                      </div>
                      {beneficiary !== "" && beneficiary && (
                        <div className="[ col-6 ]">
                          <button
                            type="button"
                            className="[ btn ] [ w-100 ] [ submit-btn ] [ secondary-submit-btn ] [ text-responsive ]"
                            onClick={() => {
                              const { handleCancelClick } = this.props;
                              handleCancelClick(beneficiary);
                            }}
                          >
                            {STRINGS.BENEFICIARIES.FORM.CANCEL}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
            </div>
          </div>
        )}
      </>
    );
  }
}

BeneficiaryDetails.propTypes = {
  beneficiary: PropTypes.oneOfType(PropTypes.object),
  onInputChangeHandler: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  provinceList: PropTypes.arrayOf(PropTypes.object),
  countryList: PropTypes.arrayOf(PropTypes.object),
  beneficiaryRelationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  beneficiaryTypeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleDeleteBeneficiary: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  showSaveAndCancelBtn: PropTypes.bool,
  renderDetails: PropTypes.bool,
  isEditBeneficiary: PropTypes.bool,
};
BeneficiaryDetails.defaultProps = {
  beneficiary: {},
  countryList: [],
  provinceList: [],
  showSaveAndCancelBtn: false,
  renderDetails: true,
  isEditBeneficiary: false,
};

export default BeneficiaryDetails;
