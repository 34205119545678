import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

class SecurityProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property: {},
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { property } = this.props;
    if (prevProps.property !== property) {
      this.setState({
        property,
      });
    }
  }

  setFormData = () => {};

  handleAddPropertyBtn = (choice) => {
    this.setState({ isAddProperty: choice });
  };

  updateProperty = (prop, value) => {
    let { property } = this.state;
    property[prop] = value;
    this.setState({
      property,
    });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { property } = this.state;
    handleContinue(property);
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS);
  };

  render() {
    const { steps, activeStepID, provinceList, streetTypes } = this.props;
    const { property } = this.state;
    console.log(steps, activeStepID);

    const selectProvinceList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
      >
        {elem.description}
      </option>
    ));

    const streetList = streetTypes.map((streetType, index) => (
      <option value={streetType.code} className="[ dropdown__option ]">
        {streetType.description}
      </option>
    ));

    const actionComponent = (
      <div className="[ property-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.PROPERTY_DETAILS.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-subtitle ]">
              {STRINGS.PROPERTY_DETAILS.FORM.ADDRESS_SUBTITLE}
            </div>
            <div className="[ row ]">
              <div className="[ col-12 col-lg-3 ]">
                {/* UNIT */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.UNIT_LABEL}
                </div>
                <input
                  type="text "
                  id="unitNumber"
                  className="[ form-control ]"
                  placeholder={STRINGS.PROPERTY_DETAILS.FORM.UNIT_LABEL}
                  value={property.unitNumber}
                  onChange={(e) =>
                    this.updateProperty("unitNumber", e.target.value)
                  }
                />
                <div className="[ error-msg ] "></div>
              </div>

              <div className="[ col-12 col-lg-3 ]">
                {/* St. No. */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.STREETNUMBER_LABEL}
                </div>
                <input
                  type="text "
                  id="streetNumber"
                  className="[ form-control ]"
                  placeholder={STRINGS.PROPERTY_DETAILS.FORM.STREETNUMBER_LABEL}
                  value={property.streetNumber}
                  onChange={(e) =>
                    this.updateProperty("streetNumber", e.target.value)
                  }
                />
                <div className="[ error-msg ] "></div>
              </div>

              <div className="[ col-12 col-lg-3 ]">
                {/* St. NAME */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.STREETNAME_LABEL}
                </div>
                <input
                  type="text "
                  id="street"
                  className="[ form-control ]"
                  placeholder={STRINGS.PROPERTY_DETAILS.FORM.STREETNAME_LABEL}
                  value={property.street}
                  onChange={(e) =>
                    this.updateProperty("street", e.target.value)
                  }
                />
                <div className="[ error-msg ] "></div>
              </div>

              <div className="[ col-12 col-lg-3 ]">
                {/* STREET TYPE */}
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.STREETTYPE_LABEL}
                </div>
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  id="streetType"
                  name="streetType"
                  value={property.streetType}
                  onChange={(e) =>
                    this.updateProperty("streetType", e.target.value)
                  }
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.PROPERTY_DETAILS.FORM.STREETTYPE_LABEL}
                  </option>
                  {streetList}
                </select>
                <div className="[ error-msg ] "></div>
              </div>
            </div>

            <div className="[ row ]">
              <div className="[ col-12 col-md-6 ]">
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.CITY_LABEL}
                </div>
                <>
                  <input
                    type="text"
                    id="city"
                    className="[ form-control ]"
                    placeholder={STRINGS.PROPERTY_DETAILS.FORM.CITY_LABEL}
                    value={property.city}
                    onChange={(e) =>
                      this.updateProperty("city", e.target.value)
                    }
                  />
                  <div className="[ error-msg ]"></div>
                </>
              </div>

              <div className="[ col-12 col-md-6 ]">
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.PROVINCE_LABEL}
                </div>
                <select
                  className="[ form-control ] [ select__dropdown ]"
                  id="province"
                  value={property.province}
                  onChange={(e) =>
                    this.updateProperty("province", e.target.value)
                  }
                >
                  <option value="" className="[ default ] [ dropdown__option ]">
                    {STRINGS.PROPERTY_DETAILS.FORM.PROVINCE_LABEL}
                  </option>
                  {selectProvinceList}
                </select>
                <div className="[ error-msg ]"></div>
              </div>

              <div className="[ col-12 col-md-6 ]">
                <div className="[ form-label ]">
                  {STRINGS.PROPERTY_DETAILS.FORM.POST_CODE_LABEL}
                </div>
                <>
                  <MaskedInput
                    mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                    className="[ form-control ] [ postalCode ]"
                    placeholder={STRINGS.PROPERTY_DETAILS.FORM.POST_CODE_LABEL}
                    guide={false}
                    id="postalCode"
                    value={property.postalCode}
                    onChange={(e) =>
                      this.updateProperty("postalCode", e.target.value)
                    }
                  />
                </>
                <div className="[ error-msg ]"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

SecurityProperty.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

SecurityProperty.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default SecurityProperty;
