import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import updateComplianceData from "Redux/Actions/UpdateApplicantAction";
import { toggleBackFlag } from "Redux/Actions/ApplicationAction";
import AppConstants from "Constants/AppConstants";
import * as ROUTES from "Constants/Routes";

import {
  getAccountPurposeList,
  getIndicateWhoList,
} from "./Actions/ComplianceActions";
import Compliance from "./Compliance";

class ComplianceContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
    };
  }

  componentDidMount() {
    const {
      dogetAccountPurposeList,
      dogetIndicateWhoList,
      doToggleBackFlag,
      applicantData,
      actionBack,
      continuehandler,
      handleBack,
      applicationCompleteList,
      vaultProductList,
      getActiveProduct,
    } = this.props;
    doToggleBackFlag(false); //dynamic steps should stop in compliance step
    const activeProduct = getActiveProduct(vaultProductList);
    if (
      applicantData?.acquireType === "search" ||
      (applicationCompleteList && applicationCompleteList.length > 0) ||
      sessionStorage.getItem("TEMP_HIDE") === "true" ||
      activeProduct.isJointProduct
    ) {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.COMPLIANCE);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.COMPLIANCE);
      }
    } else {
      dogetAccountPurposeList();
      dogetIndicateWhoList();
    }
  }

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  handleUpdateComplianceData = (formdata) => {
    console.log("handleUpdateComplianceData", formdata);
    const { continuehandler } = this.props;
    let jurisdictionType = "";
    if (formdata.PEPDomestic) {
      jurisdictionType = AppConstants.COMPLIANCE.JURISDICTION_TYPE.DOMESTIC;
    } else if (formdata.PEPForeign) {
      jurisdictionType = AppConstants.COMPLIANCE.JURISDICTION_TYPE.FOREIGN;
    }

    const thirdPartyData = {
      name: formdata.thirdPartyName,
      relationship: formdata.thirdpPartyRelationship,
    };

    const PEPData = {
      name: formdata.indicateName,
      relationship: formdata.indicateRelationship,
      jurisdictionType,
      hio: formdata.HIO ? formdata.HIO : false,
      officeHeld: formdata.OfficeHeld,
      jurisdiction: formdata.Jurisdiction,
      fundsSource: formdata.SourceOfFunds,
    };
    const dispatchObject = {
      isAnyoneConsideredPEPHIO: formdata.isAnyoneConsideredPEPHIO,
      member: {
        intendedUseOfMembership: formdata.accountPurpose,
        pep: formdata.areYouPEPorHIO,
      },
      address: {
        mailingAddressLineType1: "",
      },
      regulatory: {
        memberHasThirdParty: formdata.isThirdPartyAccount,
        memberIsPedp: formdata.areYouPEPorHIO
          ? formdata.memberPEPDomestic
          : false,
        memberIsPefp: formdata.areYouPEPorHIO
          ? formdata.memberPEPForeign
          : false,
        memberIsHio: formdata.areYouPEPorHIO ? formdata.memberHIO : false,
        memberOfficeHeld: formdata.areYouPEPorHIO
          ? formdata.memberOfficeHeld
          : "",
        memberJurisdiction: formdata.areYouPEPorHIO
          ? formdata.memberJurisdiction
          : "",
        memberFundsSource: formdata.areYouPEPorHIO
          ? formdata.memberSourceOfFunds
          : "",
      },

      thirdParties: formdata.isThirdPartyAccount ? [thirdPartyData] : [],

      peps: formdata.isAnyoneConsideredPEPHIO ? [PEPData] : [],
    };
    // Call update applicant api using HOC
    continuehandler(dispatchObject, AppConstants.APPLICATIONSTEP.COMPLIANCE);
  };

  render() {
    const { modalType, modal, showModal } = this.state;
    const {
      accountPurposeState,
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
    } = this.props;

    return (
      <Compliance
        handleUpdateComplianceData={this.handleUpdateComplianceData}
        accountPurposeArray={accountPurposeState.accountPurposeList}
        indicateWhoArray={accountPurposeState.indicateWhoList}
        bindFormData={accountPurposeState.data ? accountPurposeState.data : ""}
        type={modalType}
        modal={modal}
        showModal={showModal}
        toggleModal={this.toggleModal}
        backClick={this.backClick}
        getDataFromServer={getDataFromServer}
        handleBack={handleBack}
        steps={steps}
        activeStepID={activeStepID}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dogetAccountPurposeList: () => dispatch(getAccountPurposeList()),
    dogetIndicateWhoList: () => dispatch(getIndicateWhoList()),
    doUpdateComplianceData: (formDetails, aplicationId, callback) =>
      dispatch(updateComplianceData(formDetails, aplicationId, callback)),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
  };
};

const mapStateToProps = (state) => ({
  accountPurposeState: state.ComplianceReducer,
  applicantData: state.ApplicationReducer.response,
  actionBack: state.ApplicationReducer.actionBack,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  vaultProductList: state.VaultReducer.vaultProductList,
});

ComplianceContainer.propTypes = {
  dogetIndicateWhoList: PropTypes.func.isRequired,
  dogetAccountPurposeList: PropTypes.func.isRequired,
  accountPurposeState: PropTypes.objectOf(PropTypes.any).isRequired,
  continuehandler: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doToggleBackFlag: PropTypes.func.isRequired,
};
ComplianceContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const ComplianceWrapper = withApplicantHOC(ComplianceContainer);

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceWrapper);
