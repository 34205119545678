import { all, fork } from "redux-saga/effects";
import * as signinSagas from "Components/Signin/Saga/SigninSaga";
import * as registerSagas from "Components/Register/Saga/RegisterSaga";
import * as VerificationCodeSagas from "Components/VerificationCode/Saga/VerificationCodeSaga";
import * as SetPasswordSaga from "Components/SetPassword/Saga/SetPasswordSaga";
import * as GettingStartedSaga from "Components/GettingStarted/Saga/GettingStartedSaga";
import * as GettingStartedBranchSaga from "Components/GettingStarted/Saga/GettingStartedBranchSaga";
import * as GettingStartedResidentSaga from "Components/GettingStarted/Saga/GettingStartedResidentSaga";
import * as existingUserSagas from "Components/Register/Saga/ExistingUserSaga";
import * as countrySaga from "Components/EmploymentAndIncome/Saga/CountrySaga";
import * as statusSaga from "Components/EmploymentAndIncome/Saga/StatusSaga";
import * as incomeSourcesSaga from "Components/EmploymentAndIncome/Saga/IncomeSourceSaga";
import * as TermsSagas from "Components/TermsAndConditions/Saga/TermsSaga";
import * as TermsJsonSaga from "Components/TermsAndConditions/Saga/TermsJsonSaga";
import * as AccountPurposeSaga from "Components/Compliance/Saga/AccountPurposeSaga";
import * as IndicateWhoSaga from "Components/Compliance/Saga/IndicateWhoSaga";
import * as getIdTypeWatcherSaga from "Components/AboutYou/Saga/GetIdTypeSaga";
import * as instntDocWatcherSaga from "Components/AboutYou/Saga/InstntDocSaga";
import * as submitVerifyWatcherSaga from "Components/AboutYou/Saga/SubmitVerifySaga";
import * as createApplicationWatcherSaga from "Components/AboutYou/Saga/CreateApplicationSaga";
import * as getProvinceListWatcherSaga from "Components/AboutYou/Saga/GetProvinceListSaga";
import * as confirmAccountCreateWatcherSaga from "Components/ConfirmAccountCreate/Saga/ConfirmAccountCreateSaga";
import * as ForgotPasswordSagas from "Redux/Sagas/ForgotPasswordSaga";
import * as RecoverPasswordSagas from "Redux/Sagas/RecoverPasswordSaga";
import * as RefreshTokenSaga from "Redux/Sagas/RefreshTokenSaga";
import * as LogoutSaga from "Redux/Sagas/LogoutSaga";
import * as UpdateApplicantSaga from "Redux/Sagas/UpdateApplicantSaga";
import * as FundProcessSaga from "Components/FundingAmount/Saga/FundProcessSaga";
import * as FundPaymentDetailsSaga from "Components/FundingAmount/Saga/FundPaymentDetailsSaga";
import * as ApplicationSaga from "Redux/Sagas/ApplicationSaga";
import * as GetGettingStartedSaga from "Redux/Sagas/GetGettingStartedSaga";
import * as GetApplicantListSaga from "Redux/Sagas/GetApplicantListSaga";
import * as GetAccountSaga from "Redux/Sagas/GetAccountSaga";
import * as ProductListSaga from "Components/MarketPlace/Saga/ProductListSaga";
import * as UpdateProductVaultSaga from "Components/MarketPlace/Saga/UpdateProductVaultSaga";
import * as GetProductVaultSaga from "Components/MarketPlace/Saga/GetProductVaultSaga";
import * as CreateInveriteSaga from "Components/Inverite/Saga/CreateInveriteSaga";
import * as FetchInveriteSaga from "Components/Inverite/Saga/FetchInveriteSaga";
import * as CreateInveriteAccountListSaga from "Components/Inverite/Saga/CreateInveriteAccountListSaga";
import * as FetchInveriteAccountListSaga from "Components/Inverite/Saga/FetchInveriteAccountListSaga";
import * as DeleteApplicationSaga from "Redux/Sagas/DeleteApplicationSaga";
import * as GetApplicationSaga from "Redux/Sagas/GetApplicationSaga";
import * as ProductTemplateSaga from "Redux/Sagas/ProductTemplateSaga";
import * as UpdateProductInformationSaga from "Redux/Sagas/UpdateProductInformationSaga";
import * as IntendedUseSaga from "Components/AccountInformation/Saga/IntendedUseSaga";
import * as GetProductInformationSaga from "Redux/Sagas/GetProductInformationSaga";
import * as GetBeneficiaryTypeSaga from "Components/Beneficiaries/Saga/GetBeneficiaryTypeSaga";
import * as GetBeneficiaryRelationSaga from "Components/Beneficiaries/Saga/GetBeneficiaryRelationSaga";
import * as AddBeneficiariesSaga from "Components/Beneficiaries/Saga/AddBeneficiariesSaga";
import * as DeleteBeneficiarySaga from "Components/Beneficiaries/Saga/DeleteBeneficiarySaga";
import * as GetBeneficiariesSaga from "Components/Beneficiaries/Saga/GetBeneficiariesSaga";
import * as EditBeneficiariSaga from "Components/Beneficiaries/Saga/EditBeneficiariSaga";
import * as SpousalInfoSaga from "Components/SpousalInfo/Saga/SpousalInfoSaga";
import * as GetSpousalInfoSaga from "Components/SpousalInfo/Saga/GetSpousalInfoSaga";
import * as addApplicationSlotWatcherSaga from "Components/JointApplicant/Saga/AddApplicationSlotSaga";
import * as addJointApplicantsSaga from "Components/JointApplicant/Saga/AddJointApplicantsSaga";
import * as inviteApplicationSlotWatcherSaga from "Components/JointApplicant/Saga/InviteApplicationSaga";
import * as GetApplicantTypeSaga from "Components/JointApplicant/Saga/GetApplicantTypeSaga";
import * as GetApplicationPropertySaga from "Components/JointApplicant/Saga/GetApplicationPropertySaga";
import * as DeleteApplicationSlotSaga from "Components/JointApplicant/Saga/DeleteApplicationSlotSaga";
import * as GetBundleProductRelationSaga from "Redux/Sagas/GetBundleProductRelationSaga";
import * as AddBundleProductSaga from "Components/DebitCard/Saga/AddBundleProductSaga";
import * as DeleteDebitCardSaga from "Components/DebitCard/Saga/DeleteDebitCardSaga";
import * as AddGlobalVaultSaga from "Components/JointApplicant/Saga/AddGlobalVaultSaga";
import * as GetGlobalVaultSaga from "Components/JointApplicant/Saga/GetGlobalVaultSaga";
import * as ApplicantCreateRequestSaga from "Components/JointApplicant/Saga/ApplicantCreateRequestSaga";
import * as ApplicantCreateSelfSaga from "Components/JointApplicant/Saga/ApplicantCreateSelfSaga";
import * as OtpVerifyInviteSaga from "Components/JointApplicant/Saga/OtpVerifyInviteSaga";
import * as EnableOnlineBankingSaga from "Components/OnlineBanking/Saga/EnableOnlineBankingSaga";
import * as FillApplicationSlotSaga from "Components/JointApplicant/Saga/FillApplicationSlotSaga";
import * as CreateEsignDocSaga from "Components/Signature/Saga/CreateEsignDocSaga";
import * as GetEsignSaga from "Components/Signature/Saga/GetEsignSaga";
import * as SendEsignPackageSaga from "Components/Signature/Saga/SendEsignPackageSaga";
import * as ConfirmApplicantCreateSaga from "Components/JointApplicant/Saga/ConfirmApplicantCreateSaga";
import * as GetLoanProductSaga from "Components/LoanDetails/Saga/GetLoanProductSaga";
import * as AddAssetSaga from "Components/Asset/Saga/AddAssetSaga";
import * as GetAssetSaga from "Components/Asset/Saga/GetAssetSaga";
import * as UpdateAssetSaga from "Components/Asset/Saga/UpdateAssetSaga";
import * as AddVehicleSaga from "Components/Asset/Saga/AddVehicleSaga";
import * as GetVehicleSaga from "Components/Asset/Saga/GetVehicleSaga";
import * as UpdateVehicleSaga from "Components/Asset/Saga/UpdateVehicleSaga";
import * as AddRealEstateSaga from "Components/Asset/Saga/AddRealEstateSaga";
import * as GetRealEstateSaga from "Components/Asset/Saga/GetRealEstateSaga";
import * as UpdateRealEstateSaga from "Components/Asset/Saga/UpdateRealEstateSaga";
import * as AddIncomeSaga from "Components/Income/Saga/AddIncomeSaga";
import * as GetIncomeSaga from "Components/Income/Saga/GetIncomeSaga";
import * as UpdateIncomeSaga from "Components/Income/Saga/UpdateIncomeSaga";
import * as AddExpenseSaga from "Components/Expense/Saga/AddExpenseSaga";
import * as GetExpenseSaga from "Components/Expense/Saga/GetExpenseSaga";
import * as UpdateExpenseSaga from "Components/Expense/Saga/UpdateExpenseSaga";
import * as AddLiabilitiesSaga from "Components/Liabilities/Saga/AddLiabilitiesSaga";
import * as GetLiabilitiesSaga from "Components/Liabilities/Saga/GetLiabilitiesSaga";
import * as UpdateLiabilitiesSaga from "Components/Liabilities/Saga/UpdateLiabilitiesSaga";
import * as UpdateLendingProductSecuritySaga from "Components/LoanDetails/Saga/UpdateLendingProductSecuritySaga";
import * as GetLoanSecuritySaga from "Components/LoanDetails/Saga/GetLoanSecuritySaga";
import * as DeleteFinancialSaga from "Components/LoanDetails/Saga/DeleteFinancialSaga";
import * as DeleteLoanSecuritySaga from "Components/LoanDetails/Saga/DeleteLoanSecuritySaga";
import * as GetFinancialAnalysisListSaga from "Components/LoanDetails/Saga/GetFinancialAnalysisListSaga";
import * as GetFinancialAnalysisFinancialListSaga from "Components/LoanDetails/Saga/GetFinancialAnalysisFinancialListSaga";
import * as GetLendingProductSaga from "Components/LoanDetails/Saga/GetLendingProductSaga";
import * as UpdateLendingProductSaga from "Components/LoanDetails/Saga/UpdateLendingProductSaga";
import * as GetDocumentListSaga from "Components/Documents/Saga/GetDocumentListSaga";
import * as AddDocumentSaga from "Components/Documents/Saga/AddDocumentSaga";
import * as DeleteDocumentSaga from "Components/Documents/Saga/DeleteDocumentSaga";
import * as FinInfoSaga from "Components/MarketPlace/Saga/FinInfoSaga";
import * as GetBankingProductListSaga from "Components/AccountInformation/Saga/GetBankingProductListSaga";

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(signinSagas),
      ...Object.values(registerSagas),
      ...Object.values(existingUserSagas),
      ...Object.values(VerificationCodeSagas),
      ...Object.values(ForgotPasswordSagas),
      ...Object.values(RecoverPasswordSagas),
      ...Object.values(SetPasswordSaga),
      ...Object.values(countrySaga),
      ...Object.values(statusSaga),
      ...Object.values(incomeSourcesSaga),
      ...Object.values(TermsSagas),
      ...Object.values(TermsJsonSaga),
      ...Object.values(GettingStartedSaga),
      ...Object.values(GettingStartedBranchSaga),
      ...Object.values(GettingStartedResidentSaga),
      ...Object.values(AccountPurposeSaga),
      ...Object.values(IndicateWhoSaga),
      ...Object.values(getIdTypeWatcherSaga),
      ...Object.values(instntDocWatcherSaga),
      ...Object.values(submitVerifyWatcherSaga),
      ...Object.values(createApplicationWatcherSaga),
      ...Object.values(getProvinceListWatcherSaga),
      ...Object.values(confirmAccountCreateWatcherSaga),
      ...Object.values(RefreshTokenSaga),
      ...Object.values(LogoutSaga),
      ...Object.values(UpdateApplicantSaga),
      ...Object.values(FundProcessSaga),
      ...Object.values(FundPaymentDetailsSaga),
      ...Object.values(ApplicationSaga),
      ...Object.values(GetGettingStartedSaga),
      ...Object.values(GetApplicantListSaga),
      ...Object.values(GetAccountSaga),
      ...Object.values(ProductListSaga),
      ...Object.values(UpdateProductVaultSaga),
      ...Object.values(GetProductVaultSaga),
      ...Object.values(CreateInveriteSaga),
      ...Object.values(FetchInveriteSaga),
      ...Object.values(CreateInveriteAccountListSaga),
      ...Object.values(FetchInveriteAccountListSaga),
      ...Object.values(DeleteApplicationSaga),
      ...Object.values(GetApplicationSaga),
      ...Object.values(ProductTemplateSaga),
      ...Object.values(UpdateProductInformationSaga),
      ...Object.values(IntendedUseSaga),
      ...Object.values(GetProductInformationSaga),
      ...Object.values(GetBeneficiaryTypeSaga),
      ...Object.values(GetBeneficiaryRelationSaga),
      ...Object.values(AddBeneficiariesSaga),
      ...Object.values(DeleteBeneficiarySaga),
      ...Object.values(GetBeneficiariesSaga),
      ...Object.values(EditBeneficiariSaga),
      ...Object.values(SpousalInfoSaga),
      ...Object.values(GetSpousalInfoSaga),
      ...Object.values(addApplicationSlotWatcherSaga),
      ...Object.values(addJointApplicantsSaga),
      ...Object.values(inviteApplicationSlotWatcherSaga),
      ...Object.values(GetApplicantTypeSaga),
      ...Object.values(GetApplicationPropertySaga),
      ...Object.values(DeleteApplicationSlotSaga),
      ...Object.values(GetBundleProductRelationSaga),
      ...Object.values(AddBundleProductSaga),
      ...Object.values(DeleteDebitCardSaga),
      ...Object.values(AddGlobalVaultSaga),
      ...Object.values(GetGlobalVaultSaga),
      ...Object.values(ApplicantCreateRequestSaga),
      ...Object.values(ApplicantCreateSelfSaga),
      ...Object.values(OtpVerifyInviteSaga),
      ...Object.values(EnableOnlineBankingSaga),
      ...Object.values(FillApplicationSlotSaga),
      ...Object.values(CreateEsignDocSaga),
      ...Object.values(GetEsignSaga),
      ...Object.values(SendEsignPackageSaga),
      ...Object.values(ConfirmApplicantCreateSaga),
      ...Object.values(GetLoanProductSaga),
      ...Object.values(AddAssetSaga),
      ...Object.values(GetAssetSaga),
      ...Object.values(UpdateAssetSaga),
      ...Object.values(AddVehicleSaga),
      ...Object.values(GetVehicleSaga),
      ...Object.values(UpdateVehicleSaga),
      ...Object.values(AddRealEstateSaga),
      ...Object.values(GetRealEstateSaga),
      ...Object.values(UpdateRealEstateSaga),
      ...Object.values(AddIncomeSaga),
      ...Object.values(GetIncomeSaga),
      ...Object.values(UpdateIncomeSaga),
      ...Object.values(AddExpenseSaga),
      ...Object.values(GetExpenseSaga),
      ...Object.values(UpdateExpenseSaga),
      ...Object.values(AddLiabilitiesSaga),
      ...Object.values(GetLiabilitiesSaga),
      ...Object.values(UpdateLiabilitiesSaga),
      ...Object.values(UpdateLendingProductSaga),
      ...Object.values(GetLoanSecuritySaga),
      ...Object.values(DeleteFinancialSaga),
      ...Object.values(DeleteLoanSecuritySaga),
      ...Object.values(GetFinancialAnalysisListSaga),
      ...Object.values(GetFinancialAnalysisFinancialListSaga),
      ...Object.values(GetLendingProductSaga),
      ...Object.values(UpdateLendingProductSecuritySaga),
      ...Object.values(GetDocumentListSaga),
      ...Object.values(AddDocumentSaga),
      ...Object.values(DeleteDocumentSaga),
      ...Object.values(FinInfoSaga),
      ...Object.values(GetBankingProductListSaga),
    ].map(fork)
  );
}
