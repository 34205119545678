import STRINGS from "Constants/Strings";
import FIInformation from "Constants/FIInformation";

const AppConstants = {
  PREFIX: {
    DOLLAR: "$",
  },
  REGISTRATION: {
    OtpTo: "msisdn",
    NUMBERFORMAT: "(###) ###-####",
    NUMBERPREFIX: "1",
    EMAILPATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    MOBILENOPATTERN: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
    CONTACT_NO_PATTERN: /[- )(]/g,
  },
  MODALTYPE: {
    SUCCESS: "Success",
    FAILURE: "Error",
    INFORMATION: "Info",
    CUSTOM: "Custom",
  },

  SIGNIN: {
    NUMBERPREFIX: "1",
    USERNAME_PATTERN: "/^[0-9]{10}$/",
    EMAILPATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  HEADER: {
    matchRoutePattern: "([w-]+)",
  },
  GETTINGSTARTED: {
    VAULT_API_BODY: {
      residencyStatus: "",
      jointAccountDetails: [],
      branch: "",
      isCardRequired: false,
      isOverdraftProtectionRequired: false,
    },
    EMAIL: "email",
    PAPER: "paper",
  },
  // Application steps key and value pair irrespective of the order.
  APPLICATIONSTEP: {
    TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
    GETTINGSTARTED: "GETTING_STARTED",
    DOC_VERIFICATION: "DOC_VERIFICATION",
    ABOUTYOU: "ABOUT_YOU",
    EMPLOYMENT: "EMPLOYMENT_AND_INCOME",
    COMPLIANCE: "COMPLIANCE",
    ACCOUNT_INFORMATION: "ACCOUNT_INFORMATION",
    CONFIRM: "CONFIRM_ACCOUNT_CREATE",
    FUND_METHOD: "FUND_METHOD",
    BENEFICIARIES: "BENEFICIARIES",
    SPOUSAL_INFO: "SPOUSAL_INFO",
    OVERDRAFT_PROTECTION: "OVERDRAFT_PROTECTION",
    DEBIT_CARD: "DEBIT_CARD",
    LOAN_DETAILS: "LOAN_DETAILS",
    PROPERTY_DETAILS: "PROPERTY_DETAILS",
    SECURITY_VEHICLE: "SECURITY_VEHICLE",
    INCOME: "INCOME",
    EXPENSE: "EXPENSE",
    ASSET: "ASSET",
    LIABILITIES: "LIABILITIES",
    DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
    FEES: "FEES",
    COST_OF_BORROWING: "COST_OF_BORROWING",
    LOAN_SUMMARY: "LOAN_SUMMARY",
    LOAN_ADJUDICATION_RESULTS: "LOAN_ADJUDICATION_RESULTS",
    BEFORE_WE_START: "BEFORE_WE_START",
    DISBURSEMENT: "DISBURSEMENT",
    REPAYMENT: "REPAYMENT",
    JOINT_APPLICANT: "JOINT_APPLICANT",
    JOINT_APPLICANT_STATUS: "JOINT_APPLICANT_STATUS",
    ONLINE_BANKING: "ONLINE_BANKING",
    SIGNATURE: "SIGNATURE",
    VERIFICATION_CODE: "VERIFICATION_CODE",
    LOAN_PURPOSE: "LOAN_PURPOSE",
  },
  JOINT_APPLICANT: {
    NUMBERFORMAT: "(###) ###-####",
    NUMBERPREFIX: "1",
    EMAILPATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    MOBILENOPATTERN: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
    CONTACT_NO_PATTERN: /[- )(]/g,
    INVITE_APPLY_URL: "/inviteapply",
    TYPE: "co",
    FLOW: [
      {
        index: 0,
        componentName: "TERMS_AND_CONDITIONS",
      },
      {
        index: 1,
        componentName: "GETTING_STARTED",
      },
      {
        index: 2,
        componentName: "ABOUT_YOU",
      },
      {
        index: 3,
        componentName: "VERIFICATION_CODE",
      },
      {
        index: 4,
        componentName: "EMPLOYMENT_AND_INCOME",
      },
      {
        index: 5,
        componentName: "COMPLIANCE",
      },
      {
        index: 6,
        componentName: "CONFIRM_ACCOUNT_CREATE",
      },
    ],
  },
  COMPONENTSTEPTYPE: {
    APPLICANT: "Applicant",
    APPLICATION: "Application",
  },
  COMPLIANCE: {
    VAULT_API_BODY: {
      applicantId: "",
      applicationId: "",
      applicationStep: "",
    },
    JURISDICTION_TYPE: {
      DOMESTIC: "Domestic",
      FOREIGN: "Foreign",
    },
  },
  FUND_ACCOUNT: {
    FUND_AMOUNT_CUR: [
      { Id: 1, Name: "$25" },
      { Id: 2, Name: "$40" },
      { Id: 3, Name: "$50" },
      { Id: 4, Name: "$100" },
      { Id: 5, Name: "$200" },
      { Id: 6, Name: "Other" },
    ],
    FUND_STEPS: [
      { Id: 1, Name: STRINGS.FUND_ACCOUNT.METHOD },
      { Id: 2, Name: STRINGS.FUND_ACCOUNT.AMOUNT },
    ],
    FUND_METHODS: [
      { Id: 1, Name: STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_CREDIT_CARD },
      {
        Id: 2,
        Name: STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_DIRECT_DEPOSIT,
      },
    ],
  },
  IDTypes: {
    PASSPORT: {
      id: 1,
      desc: "Passport",
    },
    LICENSE: {
      id: 2,
      desc: "License",
    },
  },
  DATEOFBIRTH: {
    FORMAT: "####-##-##",
    MASK: ["Y", "Y", "Y", "Y", "M", "M", "D", "D"],
  },
  EMPLOYMENTANDINCOME: {
    TIN_FORMATE: "###-###-###",
    NUMBERFORMAT: "(###) ###-####",
    PHONEFORMAT: "(999) 999-9999",
    SSNLENGTH: 9,
    SALARY_LENGTH: 12,
    CONTACT_NO_PATTERN: /[- )(]/g,
    SALARY_PATTERN: /[$,]/g,
    SALARY_PREFIX: "$",
  },
  APPLICATIONDEFAULTNAME: {
    APP_DEFAULT_NAME: "FI APP",
  },
  NODE_NAME: "LINK",

  ABOUT_YOU: {
    CA_POSTALMASK: [/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/],
    US_POSTALMASK: [/\d/, /\d/, /\d/, /\d/, /\d/],
    CA_POSTALPATTERN: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]( )\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    US_POSTALPATTERN: /^\d{5}$/,
    DOBPATTERN: /^(19|20)\d\d[- .](0[1-9]|1[012])[- .](0[1-9]|[12][0-9]|3[01])$/,
    EDIT_MODE_INPUTLIST: ["firstName", "lastName", "dateOfBirth"],
  },
  MARKETPLACE: {
    HEADER: {
      TITLE1: ` Welcome to ${FIInformation.FI_NAME}`,
      TITLE2: ` Online Banking Marketplace`,
      TITLE3: "Your online one-stop-shop for all your banking needs.",
      TITLE4:
        "Simply select the banking products you would like to apply for and we'll add them to your cart.",
      SIGNINREGEBTN: "SIGN IN / REGISTER",
    },
  },
  DASHBOARD: {
    HEADER: {
      TITLE2: ` Your Online Banking Marketplace`,
    },
    PRODUCT_STATUS: {
      IS_COMPLETED: "IsCompleted",
      IN_REVIEW: "InReview",
      IS_REJECTED: "IsRejected",
      IN_PROGRESS: "InProgress",
    },
  },
  BENEFICIARY: {
    beneficiaryId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    beneficiaryRelation: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    province: "",
    mobileNo: "",
    businessArea: "",
    businessPhone: "",
    sin: "",
    country: "",
    beneficiaryType: "",
    editMode: true,
    showEditAndDeletBtn: false,
    showSaveAndCancelBtn: false,
    errors: {
      firstNameErrorList: [],
      lastNameErrorList: [],
      dateOfBirthErrorList: [],
      beneficiaryRelationErrorList: [],
      address1ErrorList: [],
      cityErrorList: [],
      provinceErrorList: [],
      postalCodeErrorList: [],
      mobileNoErrorList: [],
      countryErrorList: [],
      sinErrorList: [],
      beneficiaryTypeErrorList: [],
    },
  },
  RELATIONSHIP: {
    SELF: "self",
    JOINT: "joint",
  },
  FIRSTNAMEPATTERN: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  LASTNAMEPATTERN: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  SIGNATURE: {
    STEPS: [
      { Id: 1, Name: STRINGS.SIGNATURE.CONTRACT },
      { Id: 2, Name: STRINGS.SIGNATURE.SIGN },
    ],
  },
  PRODUCT_TYPE: {
    REGISTERED_CONTRACT: "registeredContract",
    TERM_DEPOSIT: "termDeposit",
  },
  ASSET: {
    TYPE: "Asset",
  },
  LIABILITIES: {
    TYPE: "Liability",
  },
  INCOME: {
    TYPE: "Income",
  },
  EXPENSE: {
    TYPE: "Expense",
  },
  LOANS: {
    PURPOSE: {
      PRE_APPROVE: 50,
      DEBT_CONSOLIDATION: 28,
    },
    SALARY_LENGTH: 12,
    SALARY_PATTERN: /[$,]/g,
    SALARY_PREFIX: "$",
    REVOLVING: "Revolving",
  },
  TEMPLATE: {
    NAME: "_NAME_",
    PRODUCT: "_PRODUCT_",
  },
  LOAN_CATEGORY: {
    PERSONAL: "personal",
    AUTO: "auto",
    LOC: "loc",
    HELOC: "heloc",
    MORTGAGE: "mortgage",
  },
  LOAN_SUB_CATEGORY: {
    MORTGAGE_PRE_APPROVE: "pre-approve",
    MORTGAGE_PRE_QUAL: "pre-qual",
  },
  ACCOUNT_TYPE: {
    CHEQUING: "chequing",
    SAVINGS: "savings",
    OTHER: "other",
  },
  SESSION: {
    OTHER_ACCOUNTS: "S_OTHER_ACCOUNTS",
    INVERITE_ACCOUNTLIST_REQUESTGUID: "S_INVERITE_ACCOUNTLIST_REQUEST_GUID",
    DEBIT_CARD_ALREADY_LOADED: "S_DEBIT_CARD_ALREADY_LOADED",
  },
  PRODUCT_CODE: {
    PC: "PC",
  },
  SECURITY_TYPE: {
    VEHICLE: "Vehicle",
    REAL_ESTATE: "Real Estate",
  },
};

export default AppConstants;
