import BaseApiRequest from "./BaseApiRequest";
import { SUBMIT_VERIFY } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class SubmitVerify extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    idToken: "",
  };

  constructor(data) {
    super();
    this.body.idToken = data.token;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return SUBMIT_VERIFY;
  };

  getBody = () => {
    return this.body;
  };
}
