import { call, put, takeLatest } from "redux-saga/effects";
import GetApplicationProperty from "Communication/ApiClasses/GetApplicationProperty";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";
import * as actionTypes from "Redux/Actions/actionTypes";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// worker
function* handleGetApplicationPropertySaga(action) {
  console.log("handleGetApplicationPropertySaga");
  console.log(action);

  yield put(
    doLoadingStart(STRINGS.JOIN_APPLICANT.API_KEYS.GET_APPLICATION_PROPERTY)
  );
  const getApplicationProperty = new GetApplicationProperty(action.data);
  try {
    if (action.data.delayTime) {
      console.log("delaying get application property", action.data.delayTime);
      yield delay(action.data.delayTime);
    }
    const result = yield call(getApplicationProperty.getData);
    console.log(result);
    yield put({
      type: actionTypes.GET_APPLICATION_PROPERTY_SUCCESS,
      response: result.data,
      formData: { ...action.requestData },
      applicationId: action.requestData.applicationId,
    });

    yield call(action.callback, result.data);
  } catch (error) {
    yield put({
      type: actionTypes.GET_APPLICATION_PROPERTY_ERROR,
      error: error.message,
    });
  } finally {
    yield put(
      doLoadingFinish(STRINGS.JOIN_APPLICANT.API_KEYS.GET_APPLICATION_PROPERTY)
    );
  }
}

// watcher
export default function* getApplicationPropertyWatcherSaga() {
  yield takeLatest(
    actionTypes.GET_APPLICATION_PROPERTY,
    handleGetApplicationPropertySaga
  );
}
