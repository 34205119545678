import InstntApiRequest from "./InstntApiRequest";
import { INSTNT_URLS, DOCUMENT_VERIFY } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class InstntDocumentVerify extends InstntApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    formKey: INSTNT_URLS.INSTNT_ID,
    documentType: "",
    documentFrontImage: "",
    selfieImage: "",
  };

  constructor(documentData) {
    super();
    this.body.documentType = documentData.docType;
    this.body.documentFrontImage = documentData.frontImage;
    this.body.selfieImage = documentData.selfieImage;
    if (documentData.backImage) {
      this.body.documentBackImage = documentData.backImage;
    }
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return DOCUMENT_VERIFY;
  };

  getBody = () => {
    return this.body;
  };
}
