import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import { HTTP_STATUS } from "Communication/Constants";
import MODALMESSAGES from "Constants/Messages";
import { callCreateInverite } from "Components/Inverite/Actions/InveriteAction";
import {
  getProductFromVault,
  updateProductToVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import { getApplicationList } from "Redux/Actions/ApplicationAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import {
  createEsignDoc,
  sendEsignPackage,
  getEsign,
} from "./Actions/SignatureAction";
import Signature from "./Signature";
import { getApplicationProperty } from "../JointApplicant/Actions/JointApplicantAction";

class SignatureContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      showPage1: false,
      showPage2: false,
      showPage3: false,
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      signatureCompleted: false,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      continuehandler,
      history,
      doGetApplicationList,
      doCreateEsignDoc,
      doGetEsign,
      doGetApplicationProperty,
      inReview,
      uploadedAt,
    } = this.props;

    const activeProduct = getActiveProduct(products);

    if (!activeProduct) {
      history.push(ROUTES.VAULT_DASHBOARD);
    } else if (inReview || uploadedAt) {
      continuehandler("", AppConstants.APPLICATIONSTEP.SIGNATURE);
    } else {
      doGetApplicationProperty(
        { applicationId: activeProduct.applicationId },
        (currentApplication) => {
          console.log("current", currentApplication);
          const slotsApplied = currentApplication.slots.filter(
            (slot) => slot.appliedAt
          );
          if (currentApplication.inReview || currentApplication.uploadedAt) {
            continuehandler("", AppConstants.APPLICATIONSTEP.SIGNATURE);
          } else if (
            !currentApplication.appliedAt &&
            currentApplication.slots.length != slotsApplied.length
          ) {
            history.push(ROUTES.CONFIRM_ACCOUNT_CREATE);
          } else if (currentApplication.esignPackageSentAt) {
            this.setState({
              showPage1: false,
              showPage2: true,
              showPage3: false,
            });
            const signer = activeProduct.contract[0].signers.find(
              (signer) => signer.applicantId === activeProduct.applicantId
            );
            if (signer) {
              setTimeout(() => window.open(signer.url, "_blank"), 5000);
            }
          } else if (currentApplication.esignDocStartAt) {
            this.setState({
              showPage1: true,
              showPage2: false,
              showPage3: false,
            });
          } else {
            doCreateEsignDoc(activeProduct.applicationId, (createResponse) => {
              console.log(createResponse);
              this.setState({
                showPage1: true,
                showPage2: false,
                showPage3: false,
              });
            });
          }
        }
      );
    }
  }

  toggleModal = () => {};

  showModal = () => {
    this.setState({});
  };

  handlePopupModalBtnClick = () => {
    const { signatureCompleted } = this.state;
    const { continuehandler } = this.props;
    if (signatureCompleted) {
      continuehandler({}, AppConstants.APPLICATIONSTEP.SIGNATURE);
    }
  };

  handleContinue = (choice) => {
    const {
      continuehandler,
      doCreateEsignDoc,
      products,
      getActiveProduct,
      doSendEsignPackage,
      doGetEsign,
      history,
      doUpdateProductToVault,
      applicantData,
    } = this.props;
    const { showPage1, showPage2, showPage3 } = this.state;
    const dispatchObject = {
      agree: choice,
    };
    if (showPage1) {
      const activeProduct = getActiveProduct(products);
      doSendEsignPackage(activeProduct.applicationId, (res) => {
        products[0].contract = res.data;
        doUpdateProductToVault(products);
        this.setState({
          showPage1: false,
          showPage2: true,
          showPage3: false,
        });
        const signer = res.data[0].signers.find(
          (signer) => signer.applicantId === activeProduct.applicantId
        );
        if (signer) {
          setTimeout(() => window.open(signer.url, "_blank"), 5000);
        }
      });
    } else if (showPage2) {
      // We have sent the package
      const activeProduct = getActiveProduct(products);
      doGetEsign(activeProduct.applicationId, (eSignInfo) => {
        console.log(eSignInfo);
        if (eSignInfo.data.length > 0) {
          const incompleteDoc = eSignInfo.data.find((doc) => !doc.completedAt);
          if (incompleteDoc) {
            this.setState({
              showPage1: false,
              showPage2: false,
              showPage3: true,
            });
          } else {
            this.setState({
              signatureCompleted: true,
            });
            if (activeProduct.templateId === 6) {
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.SIGNATURE.SUCCESS_TITLE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ),
                  description: MODALMESSAGES.SIGNATURE.LOS_CONGRATS_MESSAGE,
                },
              });
            } else {
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.SIGNATURE.SUCCESS_TITLE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ),
                  description: MODALMESSAGES.SIGNATURE.AOS_CONGRATS_MESSAGE,
                },
              });
            }
          }
        } else {
          this.setState({
            showPage1: false,
            showPage2: false,
            showPage3: true,
          });
        }
      });
    } else if (showPage3) {
      const activeProduct = getActiveProduct(products);
      doGetEsign(activeProduct.applicationId, (eSignInfo) => {
        console.log(eSignInfo);
        if (eSignInfo.data.length > 0) {
          const incompleteDoc = eSignInfo.data.find((doc) => !doc.completedAt);
          if (incompleteDoc) {
            this.setState({
              showPage1: false,
              showPage2: false,
              showPage3: true,
            });
          } else {
            this.setState({
              signatureCompleted: true,
            });
            if (activeProduct.templateId === 6) {
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.DISBURSEMENT.CONGRATS_TITLE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ),
                  description: MODALMESSAGES.DISBURSEMENT.CONGRATS_MESSAGE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ),
                },
              });
            } else {
              this.setState({
                showModal: true,
                modalType: AppConstants.MODALTYPE.SUCCESS,
                modal: {
                  title: MODALMESSAGES.SIGNATURE.SUCCESS_TITLE.replaceAll(
                    AppConstants.TEMPLATE.NAME,
                    applicantData.member.name
                  ),
                  description: MODALMESSAGES.SIGNATURE.SUCCESS_MSG,
                },
              });
            }
          }
        } else {
          this.setState({
            showPage1: false,
            showPage2: false,
            showPage3: true,
          });
        }
      });
    }
  };

  render() {
    const {
      modalType,
      modal,
      showModal,
      showPage1,
      showPage2,
      showPage3,
      choice,
    } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <Signature
          type={modalType}
          modal={modal}
          showModal={showModal}
          toggleModal={this.toggleModal}
          handlePopupModalBtnClick={this.handlePopupModalBtnClick}
          handleContinue={this.handleContinue}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          choice={choice}
          steps={steps}
          activeStepID={activeStepID}
          showPage1={showPage1}
          showPage2={showPage2}
          showPage3={showPage3}
        />
      </>
    );
  }
}

SignatureContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]),
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doGetProductFromVault: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  getActiveProduct: PropTypes.func.isRequired,
  applicationList: PropTypes.arrayOf(PropTypes.any).isRequired,
  doGetApplicationList: PropTypes.func.isRequired,
  doCreateEsignDoc: PropTypes.func.isRequired,
  doGetEsign: PropTypes.func.isRequired,
  doUpdateProductToVault: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
};

SignatureContainer.defaultProps = {
  history: null,
  products: [],
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicationList: state.ApplicationReducer.applicationList,
  inReview: state.ApplicationReducer.inReview,
  uploadedAt: state.ApplicationReducer.uploadedAt,
  applicantData: state.ApplicationReducer.response,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doCreateInverite: (callback) => dispatch(callCreateInverite(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicationList: (callback) => dispatch(getApplicationList(callback)),
    doCreateEsignDoc: (applicationId, callback) =>
      dispatch(createEsignDoc(applicationId, callback)),
    doSendEsignPackage: (applicationId, callback) =>
      dispatch(sendEsignPackage(applicationId, callback)),
    doGetEsign: (applicationId, callback) =>
      dispatch(getEsign(applicationId, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
  };
};

const SignatureWrapper = withApplicantHOC(SignatureContainer);

export default connect(mapStateToProps, mapDispatchToProps)(SignatureWrapper);
