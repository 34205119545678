import BaseApiRequest from "./BaseApiRequest";
import { CONFIRM_APPLY_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class ConfirmAccountApplyRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = { applicationId: "" };

  constructor(userDetail) {
    super();
    this.body.applicationId = userDetail.applicationId;
    if (userDetail.applicantId) {
      this.body["applicantId"] = userDetail.applicantId;
    }
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return CONFIRM_APPLY_URL;
  };

  getBody = () => {
    return this.body;
  };
}
