import { combineReducers } from "redux";
import SigninReducer from "Components/Signin/Reducer/SigninReducer";
import VerificationCodeReducer from "Components/VerificationCode/Reducer/VerificationCodeReducer";
import UpdatePasswordReducer from "Components/SetPassword/Reducer/UpdatePasswordReducer";
import GetPasswordCriteriaReducer from "Components/SetPassword/Reducer/GetPasswordCriteriaReducer";
import RegisterReducer from "Components/Register/Reducer/RegisterReducer";
import ExistingUserReducer from "Components/Register/Reducer/ExistingUserReducer";
import ProgressIndicatorReducer from "Components/Common/ProgressIndicator/Reducer/ProgressIndicatorReducer";
import EmploymentAndIncomeReducer from "Components/EmploymentAndIncome/Reducer/EmploymentAndIncomeReducer";
import TermsAndConditionReducer from "Components/TermsAndConditions/Reducer/TermsReducer";
import TermsJsonReducer from "Components/TermsAndConditions/Reducer/TermsJsonReducer";
import GettingStartedBranchReducer from "Components/GettingStarted/Reducer/GettingStartedBranchReducer";
import GettingStartedResidentReducer from "Components/GettingStarted/Reducer/GettingStartedResidentReducer";
import ComplianceReducer from "Components/Compliance/Reducer/ComplianceReducer";
import AboutYouReducer from "Components/AboutYou/Reducer/AboutYouReducer";
import ConfirmApplicationReducer from "Components/ConfirmAccountCreate/Reducer/ConfirmAccountCreateReducer";
import {
  TOKEN_KEY,
  REFRESH_TIMER_ID,
  APPLICANTID,
} from "Communication/Constants";
import STRINGS from "Constants/Strings";
import Loginreducer from "Redux/Reducers/LoginReducer";
import RecoverPasswordReducer from "Redux/Reducers/RecoverPasswordReducer";
import ForgotPasswordReducer from "Redux/Reducers/ForgotPasswordReducer";
import UpdateApplicantReducer from "Redux/Reducers/UpdateApplicantReducer";
import GetApplicationReducer from "Redux/Reducers/GetApplicationReducer";
import FundReducer from "Components/FundingAmount/Reducer/FundReducer";
import ApplicationReducer from "Redux/Reducers/ApplicationReducer";
import MarketplaceReducer from "Components/MarketPlace/Reducer/MarketplaceReducer";
import VaultReducer from "Components/Vault/Reducer/VaultReducer";
import AccountInformationReducer from "Components/AccountInformation/Reducer/AccountInformationReducer";
import BeneficiariesReducer from "Components/Beneficiaries/Reducer/BeneficiariesReducer";
import JoinApplicantReducer from "Components/JointApplicant/Reducer/JoinApplicantReducer";
import GetBundleProductRelationReducer from "Redux/Reducers/GetBundleProductRelationReducer";
import DebitCardReducer from "Components/DebitCard/Reducer/DebitCardReducer";
import OnlineBankingReducer from "Components/OnlineBanking/Reducer/OnlineBankingReducer";
import SignatureReducer from "Components/Signature/Reducer/SignatureReducer";
import LoanDetailsReducer from "Components/LoanDetails/Reducers/LoanDetailsReducer";
import IncomeReducer from "Components/Income/Reducers/IncomeReducer";

import * as actionTypes from "Redux/Actions/actionTypes";

const appReducer = combineReducers({
  /* top-level reducers */
  Loginreducer,
  SigninReducer,
  GetPasswordCriteriaReducer,
  RegisterReducer,
  ExistingUserReducer,
  VerificationCodeReducer,
  UpdatePasswordReducer,
  ForgotPasswordReducer,
  RecoverPasswordReducer,
  ProgressIndicatorReducer,
  EmploymentAndIncomeReducer,
  TermsAndConditionReducer,
  TermsJsonReducer,
  GettingStartedBranchReducer,
  GettingStartedResidentReducer,
  ComplianceReducer,
  AboutYouReducer,
  ConfirmApplicationReducer,
  UpdateApplicantReducer,
  GetApplicationReducer,
  FundReducer,
  ApplicationReducer,
  MarketplaceReducer,
  VaultReducer,
  AccountInformationReducer,
  BeneficiariesReducer,
  JoinApplicantReducer,
  GetBundleProductRelationReducer,
  DebitCardReducer,
  OnlineBankingReducer,
  SignatureReducer,
  LoanDetailsReducer,
  IncomeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.RESET_STORE_REQUEST) {
    sessionStorage.removeItem(TOKEN_KEY); // remove login token from storage
    const timerId = sessionStorage.getItem(REFRESH_TIMER_ID);
    clearInterval(timerId); // clear timer id of refresh token call
    sessionStorage.removeItem(REFRESH_TIMER_ID); // remove timer id from storage
    sessionStorage.removeItem("persist:root");
    sessionStorage.removeItem(APPLICANTID);
    sessionStorage.removeItem(
      STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
    );
    sessionStorage.removeItem(STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID);
    return appReducer(undefined, action); // reset store state
  }

  return appReducer(state, action);
};

export default rootReducer;
