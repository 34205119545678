import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  TOKEN_KEY,
  HTTP_STATUS,
  SORT_ON_PRIORITY,
} from "Communication/Constants";

import FilterBar from "Components/Common/FilterBar/FilterBar";
import Vault from "Components/Vault/Vault";
import ProductCardContainer from "Components/Common/ProductCardContainer/ProductCardContainer";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { sortList } from "Utils/CommonUtilities";

import {
  storeFilterProduct,
  updateVaultProductList,
  updateProductToVault,
  getProductFromVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";

import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import { PRODUCT_IMAGE_URL } from "../../Communication/ApiUrls";
import { LOANPRODUCT } from "../../Communication/Constants";

class MarketPlaceLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      showModal: false,
      modal: {},
      modalType: "",
      allCheck: true,
    };
  }

  setAllCheck = (allCheck) => {
    this.setState({ allCheck });
  };

  handleAddToVault = (cardItem) => {
    const {
      doUpdateVaultProductList,
      doUpdateProductToVault,
      vaultProductList,
      doGetProductFromVault,
    } = this.props;
    console.log("handleAddToVault", cardItem);
    const accessToken = sessionStorage.getItem(TOKEN_KEY);

    if (accessToken) {
      doGetProductFromVault((response) => {
        if (response.status === HTTP_STATUS.OK) {
          const vaultCards = this.isProductExists([...response.data], cardItem);
          doUpdateProductToVault(vaultCards, () => {});
        }
      });
    } else {
      const vaultCards = this.isProductExists([...vaultProductList], cardItem);
      doUpdateVaultProductList(vaultCards);
    }
  };

  isProductExists = (vaultCards, cardItem) => {
    const { selectCard, showModal } = this.state;
    const { loanProducts } = this.props;
    const selectedProduct = cardItem || selectCard;
    console.log("selectedProduct", selectedProduct);
    const product = {
      active: false,
      applicantId: null,
      applicationId: null,
      applicationStep: {
        index: null,
        step: "",
      },
    };
    const rand = Math.floor(Math.random() * 1000);
    product.productIndex = rand;
    product.templateId = selectedProduct.templateId;
    product.priority = selectedProduct.priority;
    const valueIndex = vaultCards.findIndex(
      (elem) => elem.productId === selectedProduct.productId
    );

    product.productName = selectedProduct.productName;
    product.productId = selectedProduct.productId;
    product.type = selectedProduct.type;
    if (product.type === LOANPRODUCT) {
      const loanProduct = loanProducts.find(
        (item) => product.productId === item.id
      );
      console.log("loanProduct", loanProduct);
      product.category = loanProduct.category;
      product.subCategory = loanProduct.subCategory;
      product.productType = loanProduct.productType;
      product.productCode = loanProduct.productCode;
      product.cutoff = loanProduct.cutoff;
    } else {
      product.category = "";
      product.subCategory = "";
    }
    if (valueIndex !== -1) {
      const modal = {};
      modal.title = STRINGS.MARKETPLACE.BODY.PRODUCT_ADDED;
      modal.description = STRINGS.MARKETPLACE.BODY.MULTIPLE_PRODUCT_ADD;

      this.setState({
        showModal: !showModal,
        modal,
        modalType: AppConstants.MODALTYPE.CUSTOM,
      });
    }
    vaultCards.push(product);
    const sortedVaultCards = sortList(vaultCards, SORT_ON_PRIORITY);
    return sortedVaultCards;
  };

  onSearchChangeHandler = (event) => {
    const searchInput = event.target?.value;
    const { categoryList, productList } = this.props;
    const { doStoreFilterProduct } = this.props;
    this.setState({ searchInput });
    const checkValue = !searchInput;
    const resultcategoryList = categoryList.map((elem) => {
      const checkbox = { ...elem };
      checkbox.checked = checkValue;
      return checkbox;
    });
    this.setAllCheck(checkValue);
    const resultProductList = productList.map((item) => {
      const productListItem = { ...item };
      if (searchInput) productListItem.show = false;
      productListItem.products = productListItem.products.map((object) => {
        const product = { ...object };
        if (searchInput) {
          product.show = false;
          if (
            product.productName
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            productListItem.title
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          ) {
            product.show = true;
            productListItem.show = true;
          }
        } else {
          product.show = true;
          productListItem.show = true;
        }
        return product;
      });
      return productListItem;
    });
    doStoreFilterProduct(resultProductList, resultcategoryList);
  };

  toggleModal = () => {
    const { showModal } = this.state;
    const modal = {};
    this.setState({ showModal: !showModal, modal });
  };

  render() {
    const { productList } = this.props;
    const { searchInput, showModal, modal, modalType, allCheck } = this.state;
    return (
      <>
        {showModal && (
          <PopupModal
            modalType={modalType}
            title={modal.title}
            description={modal.description}
            showModal={showModal}
            toggleModal={this.toggleModal}
          />
        )}

        <div className="[ row ]">
          <FilterBar setAllCheck={this.setAllCheck} allCheck={allCheck} />

          <div className="[ col-xl-7 col-12 ]">
            <div className="[ product-gallery ]">
              <div className="[ form-group has-search ]">
                <span className="[ fa fa-search form-control-feedback ]" />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={this.onSearchChangeHandler}
                  value={searchInput}
                  aria-label="Search for..."
                  autoComplete="off"
                  spellCheck="false"
                  aria-autocomplete="list"
                />
              </div>

              <ProductCardContainer
                productList={productList}
                handleAddToVault={this.handleAddToVault}
              />
            </div>
          </div>
          <div className="[ col-xl-3 ] [ d-none d-xl-block ]">
            <div className="[ summary ]">
              <Vault showProceedBtn />
            </div>
          </div>
        </div>
      </>
    );
  }
}

MarketPlaceLayout.propTypes = {
  doUpdateVaultProductList: PropTypes.func,
  doStoreFilterProduct: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
  categoryList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  productList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  doGetProductFromVault: PropTypes.func,
};

MarketPlaceLayout.defaultProps = {
  doUpdateVaultProductList: () => {},
  doStoreFilterProduct: () => {},
  doUpdateProductToVault: () => {},
  doGetProductFromVault: () => {},
  categoryList: [],
  productList: [],
  vaultProductList: [],
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
  productList: state.MarketplaceReducer.productList,
  categoryList: state.MarketplaceReducer.categoryList,
  loanProducts: state.LoanDetailsReducer.loanProducts,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doStoreFilterProduct: storeFilterProduct,
      doUpdateVaultProductList: updateVaultProductList,
      doUpdateProductToVault: updateProductToVault,
      doGetProductFromVault: getProductFromVault,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceLayout);
