import * as actionTypes from "Redux/Actions/actionTypes";

const doConfirmApplication = (applicantId, callback) => {
  return {
    type: actionTypes.CONFIRM_APPLY_REQUEST,
    data: applicantId,
    callback,
  };
};
export default doConfirmApplication;
