import FIInformation from "Constants/FIInformation";

const SERVERERRORS = {
  "100001": "Login failed, please try again later",
  "100002": "Seems the password you entered is incorrect.",
  "100003":
    "Marketplace profile already exists. Either sign in using your existing profile credentials or create a new profile using a different email and mobile number.",
  "100004":
    "Credential conflict. Please try again but with different credentials.",
  "100005":
    "Account does not exist. Check your email address was correctly entered.",
  "100000": "Permission Denied",
  "100006": `There was a problem creating account, please try later or contact ${FIInformation.FI_NAME}`,
  "100007": `There was a problem updating account, please try later or contact ${FIInformation.FI_NAME}`,
  "100008": `There was a problem deleting account, please try later or contact ${FIInformation.FI_NAME}`,
  "100009":
    "Password does not match the criteria, please check the criteria and try again",
  "100010": `Failed to set password, please try later or contact ${FIInformation.FI_NAME}`,
  "100011": "Session expired, login to continue",
  "100012": "Request is too big",
  "100013": "Invalid request, please check the entered data",
  "100014": "", // TODO:  Vault non-empty vault exceed limit
  "100015": `Account is inactive, please contact ${FIInformation.FI_NAME} to activate`,
  "100017": `Account has been locked, please contact ${FIInformation.FI_NAME}`,
  "100018": "User logged in on another device. This session will terminate.",
  "100019": `Server error while refreshing session.Contact ${FIInformation.FI_NAME} if error continues`,
  "100020": "Your session has expired. Please sign in again.",
  "100101": `Server error while generating OTP, please contact ${FIInformation.FI_NAME}`,
  "100102": "OTP expired.Try the Resend OTP option",
  "100103": "Invalid OTP, try again with correct OTP",
  "100104": "OTP wrong purpose",
  "100105": `OTP limit exceeded. Try again later or contact ${FIInformation.FI_NAME}`,
  "103000": "", //
  "103003": "Could not find applicant",
  "500": `Server encountered an unexpected condition, please try later or contact ${FIInformation.FI_NAME}`,
  "404": `There was problem processing your request, please contact ${FIInformation.FI_NAME}`,
  "103018": "Please check on joint applicant info.",
};

const getErrorMessageFromErrorCode = (code, errorObj) => {
  if (SERVERERRORS[code]) {
    return `${SERVERERRORS[code]}`;
  }

  if (errorObj?.info[0]?.Message) {
    return `Error: ${errorObj.info[0].Message}`;
  }
  return `Error: ${errorObj.description}`;
};

export default getErrorMessageFromErrorCode;
