import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";

const NavigationBar = (props) => {
  const { handleBack, isEdit, show } = props;
  return (
    <div className="[ container ] [ navigation-bar ]">
      <div className="[ row ]">
        <div className="[ col-12 ]">
          {show && (
            <button
              type="button"
              className="[ btn ] [ d-flex ] [ align-items-center ] [ p-0 px-2 ] [ navigation-bar__back-btn ]"
              onClick={handleBack}
              disabled={isEdit}
            >
              <span className="[ pr-2 ] [ navigation-bar__back-btn__icon ]" />
              <span className="[ navigation-bar__back-btn__text ]">
                {STRINGS.NAVBAR.NAVBARBUTTONTEXT}
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accountPurposeState: state.ComplianceReducer,
  isEdit: state.ApplicationReducer.isEdit,
});

NavigationBar.propTypes = {
  handleBack: PropTypes.func,
  isEdit: PropTypes.bool.isRequired,
  show: PropTypes.bool,
};

NavigationBar.defaultProps = {
  handleBack: () => {},
  show: true,
};
export default connect(mapStateToProps, null)(NavigationBar);
