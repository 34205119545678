import { formURL } from "Utils/CommonUtilities";
import BaseApiRequest from "./BaseApiRequest";
import { UPDATE_PRODUCT_INFORMATION_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class UpdateProductInformationRequest extends BaseApiRequest {
  method = METHOD.HTTP.PUT;

  body = {
    productDescription: "chq2",
  };

  url = UPDATE_PRODUCT_INFORMATION_URL;

  constructor(requestBody, paramList) {
    super();
    this.body = { ...this.body, ...requestBody };
    this.url = formURL(this.url, paramList);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
