/* eslint-disable react/no-access-state-in-setstate */
import React from "react";
import VALIDATIONS from "Constants/ValidationList";
import checkErrors from "Utils/InputValidator";
import PropTypes from "prop-types";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import AppConstants from "Constants/AppConstants";
import Stepper from "Components/Common/Stepper/Stepper";
import STRINGS from "Constants/Strings";

class AccountInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intendedUse: "",
      intendedUseError: [],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    getDataFromServer(
      AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  setFormData = (response) => {
    const { intendedUse } = response;
    this.setState({ intendedUse });
  };

  /**
   * Function to handle change event of 'Intended Use' dropdown. Set the error to empty.
   */
  handleChange = (event) => {
    this.setState({
      intendedUse: event.target.value,
      intendedUseError: [],
    });
  };

  /**
   * On click of Continue, validate the form and call the API to update the product information on server
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const { handleAccountInformation } = this.props;
    const formIsValid = this.verifyData();
    if (formIsValid) {
      const formData = this.state;
      handleAccountInformation(formData);
    }
  };

  /**
   * Validate the form data
   */
  verifyData = () => {
    let valid = true;
    const { intendedUse } = this.state;
    const error = checkErrors(
      intendedUse,
      VALIDATIONS.ACCOUNT_INFORMATION.INTENDEDUSE
    );
    if (error.length) {
      valid = false;
      this.setState({ intendedUseError: error });
    }
    return valid;
  };

  render() {
    const { intendedUseError, intendedUse } = this.state;
    const { steps, activeStepID, handleBack, intendedUseList } = this.props;
    const actionComponent = (
      <div className="[ account-information-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.ACCOUNT_INFORMATION.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ACCOUNT_INFORMATION.FORM.ACCOUNT_PURPOSE_LABEL}
            </div>

            <select
              className={
                intendedUseError && intendedUseError.length > 0
                  ? "[ form-control ] [ select__dropdown ] [ invalid-input ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              id="intendedUse"
              name="intendedUse"
              placeholder={
                STRINGS.ACCOUNT_INFORMATION.FORM.ACCOUNT_PURPOSE_PLACEHOLDER
              }
              value={intendedUse}
              onChange={this.handleChange}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.ACCOUNT_INFORMATION.FORM.ACCOUNT_PURPOSE_PLACEHOLDER}
              </option>
              {intendedUseList?.map((obj) => {
                return (
                  <option
                    key={obj.code}
                    value={obj.description}
                    className="[ dropdown__option ]"
                  >
                    {obj.description}
                  </option>
                );
              })}
            </select>
            <div className="[ error-msg ]">
              {intendedUseError ? intendedUseError[0] : ""}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <AuthBody
        handleBack={handleBack}
        actionComponent={actionComponent}
        memberCreation
      />
    );
  }
}

AccountInformation.propTypes = {
  getDataFromServer: PropTypes.func,
  intendedUseList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleAccountInformation: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};
AccountInformation.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
};
export default AccountInformation;
