import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";
import MODALMESSAGES from "Constants/Messages";
import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import Repayment from "./Repayment";
import { updateLendingProduct } from "Components/LoanDetails/Actions/LoanDetailsAction";
import { getApplicationProperty } from "../JointApplicant/Actions/JointApplicantAction";
import Disbursement from "../Disbursement/Disbursement";
import InveriteAccountList from "../Inverite/InveriteAccountList";
import { callCreateInveriteAccountList } from "Components/Inverite/Actions/InveriteAction";
import { HTTP_STATUS } from "Communication/Constants";
import { getApplicantBankingProductList } from "../AccountInformation/Actions/AccountInformationAction";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";
import STRINGS from "../../Constants/Strings";

class RepaymentContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      showModal: false,
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
      repayment: {},
      accountList: [],
      productList: [],
      accountError: [],
    };
  }

  componentDidMount() {
    const {
      products,
      getActiveProduct,
      bankingProductList,
      doGetApplicantBankingProductList,
    } = this.props;

    const activeProduct = getActiveProduct(products);
    if (activeProduct) {
      if (activeProduct.accountList) {
        this.setState({
          accountList: activeProduct.accountList,
        });
      }
      if (activeProduct.repayment) {
        this.setState({
          repayment: activeProduct.repayment,
        });
      }
      if (bankingProductList) {
        const productList = bankingProductList.products?.filter(
          (product) => product.productCode === AppConstants.PRODUCT_CODE.PC
        );
        console.log("productList", productList);
        this.setState({
          productList,
        });
      } else {
        doGetApplicantBankingProductList(
          activeProduct.applicantId,
          (response) => {
            const productList = response.products?.filter(
              (product) => product.productCode === AppConstants.PRODUCT_CODE.PC
            );
            this.setState({
              productList,
            });
          }
        );
      }
    }
    console.log(activeProduct);
  }

  updateRepayment = (repayment) => {
    console.log("updateRepayment", repayment);
    if (repayment) {
      this.setState({
        repayment,
      });
    }
  };

  handleChange = () => {};

  handleContinue = () => {
    const { repayment, accountList } = this.state;
    const {
      continuehandler,
      doUpdateLendingProduct,
      products,
      getActiveProduct,
      doGetApplicationProperty,
      applicantData,
      doUpdateProductToVault,
    } = this.props;
    console.log("handleContinue", repayment);
    const activeProduct = getActiveProduct(products);
    let payLoad = {};
    if (repayment.accountType) {
      if (repayment.accountType === AppConstants.ACCOUNT_TYPE.OTHER) {
        const selectedAccount = accountList.find(
          (acct) => acct.account === repayment.selectedOtherAccount
        );
        payLoad = {
          LPPaymentSourceDetails: {
            PaymentSourceType: "external account",
            ExternalPaymentInst: selectedAccount.institution,
            ExternalPaymentTransit: selectedAccount.transit,
            ExternalPaymentAccountNumber: repayment.selectedOtherAccount,
            ExternalPaymentAccountName: selectedAccount.account_description,
            ExternalPaymentInstName: selectedAccount.bank,
          },
        };
      } else {
        payLoad = {
          LPPaymentSourceDetails: {
            PaymentSourceType: "cu account",
            // CUPaymentBranchShortName: repayment.basicDetails.branch,
            CUPaymentAccountNumber:
              repayment.basicDetails.acctNo.length > 10
                ? repayment.basicDetails.acctNo.substring(0, 9)
                : repayment.basicDetails.acctNo,
            // CUPaymentAccountType: repayment.accountType,
            // CUPaymentSub: repayment.basicDetails.sub,
            CUPaymentBranchShortName: "Admin",
            CUPaymentAccountType: "hisa",
          },
        };
      }
      doUpdateLendingProduct(
        activeProduct.applicationId,
        activeProduct.doximProductId,
        payLoad,
        (res) => {
          console.log(res);
          products[0].repayment = repayment;
          doUpdateProductToVault(products, () => {
            doGetApplicationProperty(
              { applicationId: activeProduct.applicationId },
              (currentApplication) => {
                if (
                  currentApplication.inReview ||
                  currentApplication.uploadedAt
                ) {
                  this.setState({
                    showModal: true,
                    modalType: AppConstants.MODALTYPE.SUCCESS,
                    modal: {
                      title: MODALMESSAGES.REPAYMENT.CONGRATS_TITLE.replaceAll(
                        AppConstants.TEMPLATE.NAME,
                        applicantData.member.name
                      ),
                      description: MODALMESSAGES.REPAYMENT.CONGRATS_MESSAGE.replaceAll(
                        AppConstants.TEMPLATE.NAME,
                        applicantData.member.name
                      ),
                    },
                  });
                } else {
                  continuehandler("", AppConstants.APPLICATIONSTEP.REPAYMENT);
                }
              }
            );
          });
        }
      );
    } else {
      this.setState({
        accountError: [STRINGS.REPAYMENT.ERROR.REQUIRED],
      });
    }
  };

  handlePopupModalBtnClick = () => {
    console.log("handlePopupModalBtnClick");
    const { continuehandler } = this.props;
    continuehandler({}, AppConstants.APPLICATIONSTEP.REPAYMENT);
  };

  openInverite = () => {
    console.log("openInverite");
    const { doCreateInveriteAccountList } = this.props;
    doCreateInveriteAccountList((response) => {
      console.log(response);
      if (response.status === HTTP_STATUS.OK) {
        sessionStorage.setItem(
          AppConstants.SESSION.INVERITE_ACCOUNTLIST_REQUEST_GUID,
          response.data.requestGuid
        );
        this.setState({
          iframeModal: true,
          iframeUrl: response.data.iframeUrl,
        });
      }
    });
  };

  processInverite = (data) => {
    const { doUpdateProductToVault, products } = this.props;
    const { repayment } = this.state;
    console.log("processInverite", data);
    const updateAccountList = data.accountList.map((acct) => {
      return {
        account: acct.account,
        account_description: acct.account_description,
        bank: acct.bank,
        institution: acct.institution,
        membership_number: acct.membership_number,
        routing_code: acct.routing_code,
        transit: acct.transit,
        type: acct.type,
      };
    });
    products[0].accountList = updateAccountList;
    doUpdateProductToVault(products, () => {
      this.setState({
        iframeModal: false,
        accountList: updateAccountList,
        repayment: {
          ...repayment,
          accountType: AppConstants.ACCOUNT_TYPE.OTHER,
        },
      });
    });
  };

  closeInveriteWindow = () => {
    this.setState({
      iframeModal: false,
    });
  };

  render() {
    const {
      choice,
      modal,
      modalType,
      showModal,
      iframeModal,
      iframeUrl,
      accountList,
      repayment,
      productList,
      accountError,
    } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <Repayment
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          type={modalType}
          modal={modal}
          showModal={showModal}
          toggleModal={this.toggleModal}
          handlePopupModalBtnClick={this.handlePopupModalBtnClick}
          openInverite={this.openInverite}
          accountList={accountList}
          repayment={repayment}
          updateRepayment={this.updateRepayment}
          productList={productList}
          accountError={accountError}
        />
        {iframeModal && (
          <InveriteAccountList
            iframeUrl={iframeUrl}
            iframeModal={iframeModal}
            toggleModal={this.toggleModal}
            showModal={iframeModal}
            popupBtnClick={this.toggleModal}
            processInverite={this.processInverite}
            closeBtnClick={this.closeInveriteWindow}
          />
        )}
      </>
    );
  }
}

RepaymentContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

RepaymentContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantData: state.ApplicationReducer.response,
  bankingProductList: state.AccountInformationReducer.bankingProductList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doUpdateLendingProduct: (
      applicationId,
      doximProductId,
      payLoad,
      callback
    ) =>
      dispatch(
        updateLendingProduct(applicationId, doximProductId, payLoad, callback)
      ),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doCreateInveriteAccountList: (callback) =>
      dispatch(callCreateInveriteAccountList(callback)),
    doGetApplicantBankingProductList: (applicantId, callback) =>
      dispatch(getApplicantBankingProductList(applicantId, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

const RepaymentWrapper = withApplicantHOC(RepaymentContainer);

export default connect(mapStateToProps, mapDispatchToProps)(RepaymentWrapper);
