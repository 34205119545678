/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import { getProduct, getGettingStarted } from "Utils/CommonUtilities";

import {
  toggleEditFlag,
  getApplication,
  getGettingStartedData,
  toggleScanFlag,
  updateProductTemplate,
  getApplicationList,
  setNotFirstFlag,
  storeCompletedProductInASession,
  toggleBackFlag,
} from "Redux/Actions/ApplicationAction";
import {
  updateProductInformation,
  getProductInformation,
} from "Redux/Actions/ProductInformationAction";
import getAccount from "Redux/Actions/GetAccountAction";

import updateApplicantData from "Redux/Actions/UpdateApplicantAction";
import { getProductTemplate } from "Redux/Actions/ProductTemplateActions";

import { updateGettingStarted } from "Components/GettingStarted/Actions/GettingStartedActions";
import { doCreateApplication } from "Components/AboutYou/Actions/AboutYouActions";
import {
  updateProductToVault,
  getProductFromVault,
  getProductList,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import { storeFundProduct } from "Components/FundingAmount/Action/FundAction";
import {
  addBeneficiaries,
  getBeneficiaries,
} from "Components/Beneficiaries/Action/BeneficiariesAction";

import AppConstants from "Constants/AppConstants";
import * as ROUTES from "Constants/Routes";
import {
  HTTP_STATUS,
  APPLICANTID,
  REGISTERCONTRACTPRODUCT,
  TERMDEPOSITEPRODUCT,
} from "Communication/Constants";
import STRINGS from "Constants/Strings";
import MODALMESSAGES from "Constants/Messages";
import {
  setSpousalInfo,
  getSpousalInfo,
} from "Components/SpousalInfo/Actions/SpousalInfoAction";

import {
  addApplicationSlot,
  inviteApplicationSlot,
  getGlobalVault,
  getApplicationProperty,
  applicantCreateSelf,
  fillApplicationSlot,
  updateGlobalVault,
  applicantCreateRequest,
  setInSessionJointApplicant,
} from "Components/JointApplicant/Actions/JointApplicantAction";
import getBundleProductRelation from "Redux/Actions/GetBundleProductRelationAction";
import {
  addBundleProduct,
  deleteDebitCard,
  storeDebitCard,
  debitCardShown,
} from "Components/DebitCard/Actions/DebitCardAction";
import { showDebitCard, showOnlineBanking } from "../../Utils/LogicUtilities";
import App from "../../App";

export default function withApplicantHOC(WrappedComponent) {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        steps: [],
        activeStepID: -1,
        showModal: false,
        modal: {
          title: "",
          description: "",
          type: "",
        },
      };
    }

    /**
     *
     * @param {*} step default value is blank, need only for first step
     * @return callback
     * There are following steps followed before calling the get APIs respectively.
     * 1. Get the product details from the product vault.
     * 2. Get the templateId and template details of the active product
     * 3. Prepare the stepper details for each steps
     * 4. Check if step has been completed previously or not then call the Get API i.e. step 5
     *
     * So the following method is Step 1
     */
    getDataFromServer = (step = "", callback) => {
      const { doGetProductFromVault, products, productsList } = this.props;
      console.log("getDataFromServer", step);
      if (step === AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
        this.prepareOnlineBankingStep();
      } else {
        if (products.length <= 0) {
          doGetProductFromVault((productsRes) => {
            if (productsRes && productsRes.length > 0) {
              if (productsRes?.status === HTTP_STATUS.OK) {
                this.getProcessFlowAndCallApplicantDetails(
                  productsRes?.data ? productsRes.data : [],
                  step,
                  callback
                );
              }
            } else {
              this.redirectToDashboard();
            }
          });
        } else {
          this.getProcessFlowAndCallApplicantDetails(products, step, callback);
        }

        if (productsList && productsList.length <= 0) {
          const { doGetProductList } = this.props;
          doGetProductList();
        }
      }
    };

    /**
     * Step 2
     * @param {*} products
     * @param {*} step
     * @param {*} callback
     */
    getProcessFlowAndCallApplicantDetails = (products, step = "", callback) => {
      const {
        processTemplate,
        doGetProductTemplateFlow,
        doGetBundleProductRelation,
        doGetAcoountDetails,
        doGetGlobalVault,
      } = this.props;
      console.log("getProcessFlowAndCallApplicantDetails", products, step);
      const activeProduct = this.getActiveProduct(products);
      if (!processTemplate || processTemplate?.flow?.length <= 0) {
        const jointApplicant = sessionStorage.getItem(
          STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
        );
        if (jointApplicant) {
          doGetAcoountDetails((getAccountActionRes) => {
            if (getAccountActionRes.status === HTTP_STATUS.OK) {
              const mobileNo = getAccountActionRes.data.msisdn;
              const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${mobileNo}`;
              doGetGlobalVault(
                {
                  vaultKey: vKey,
                  product: activeProduct,
                  productTemplates: STRINGS.JOIN_APPLICANT.PRODUCT_TEMPLATE,
                },
                (gobalVaultRes) => {
                  if (
                    gobalVaultRes &&
                    gobalVaultRes.jointApplicants.status ===
                      STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING
                  ) {
                    doGetProductTemplateFlow((response) => {
                      this.getActiveTemplateAndCallNextMethod(
                        response,
                        activeProduct,
                        step,
                        callback
                      );
                    }, activeProduct);
                  }
                }
              );
            }
          });
        } else {
          doGetProductTemplateFlow((response) => {
            this.getActiveTemplateAndCallNextMethod(
              response,
              activeProduct,
              step,
              callback
            );
          }, activeProduct);
        }
      } else if (processTemplate.templateId !== activeProduct?.templateId) {
        const { doUpdateProductTemplate, templates } = this.props;

        // if the product is deleted in between steps then need to change the
        // template ID
        doUpdateProductTemplate(activeProduct);

        this.getActiveTemplateAndCallNextMethod(
          templates,
          activeProduct,
          step,
          callback
        );
      } else {
        doGetBundleProductRelation(
          {
            bundleProductId: activeProduct.productId,
            demandProductId: activeProduct.productId,
            applicationId: activeProduct.applicationId,
          },
          (getBundleProductRelationRes) => {
            if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
              this.getCompletedApplicationsAndGetApplicantDetails(
                processTemplate,
                step,
                getBundleProductRelationRes.data,
                callback
              );
            }
          }
        );
      }
    };

    /**
     * Helper method
     */
    getActiveTemplateAndCallNextMethod = (
      templates,
      activeProduct,
      step,
      callback
    ) => {
      console.log(
        "getActiveTemplateAndCallNextMethod",
        templates,
        activeProduct,
        step
      );
      const { doGetBundleProductRelation } = this.props;
      const processTemp = this.getTemplateByTemplateID(
        templates,
        activeProduct?.templateId
      );

      doGetBundleProductRelation(
        {
          bundleProductId: activeProduct.productId,
          demandProductId: activeProduct.productId,
          applicationId: activeProduct.applicationId,
        },
        (getBundleProductRelationRes) => {
          if (getBundleProductRelationRes.status === HTTP_STATUS.OK) {
            this.getCompletedApplicationsAndGetApplicantDetails(
              processTemp,
              step,
              getBundleProductRelationRes.data,
              callback
            );
          }
        }
      );
    };

    /**
     * Step 3
     * @param {*} applicationTemplate
     * @param {*} activeProduct
     * @param {*} step
     * @param {*} bundleProductRelation
     */
    prepareStepsAndActiveStep = (
      applicationTemplate,
      activeProduct,
      step,
      bundleProductRelation
    ) => {
      const {
        isDebitCardEnabled,
        doStoreDebitCard,
        completedApplications,
        doGetApplicationList,
        applicantData,
        doDebitCardShown,
        inSessionJointApplicant,
      } = this.props;
      console.log(
        "prepareStepsAndActiveStep",
        applicationTemplate,
        activeProduct,
        step,
        bundleProductRelation
      );
      let debitCardAlreadyEnabled = false;
      if (!completedApplications) {
        doGetApplicationList(() => {
          completedApplications.forEach((item) => {
            const debitCardProduct = item.doximProducts?.find(
              (product) =>
                product.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
            );
            if (debitCardProduct && item.id !== activeProduct.applicationId) {
              debitCardAlreadyEnabled = true;
            }
          });
        });
      } else {
        completedApplications.forEach((item) => {
          const debitCardProduct = item.doximProducts?.find(
            (product) => product.type === STRINGS.DEBIT_CARD.PRODUCT_TYPE_DOXIM
          );
          if (debitCardProduct && item.id !== activeProduct.applicationId) {
            debitCardAlreadyEnabled = true;
          }
        });
      }
      console.log(
        "prepareStepsAndActiveStep",
        isDebitCardEnabled,
        debitCardAlreadyEnabled,
        activeProduct,
        completedApplications,
        applicantData,
        bundleProductRelation,
        inSessionJointApplicant
      );

      const steps = [];
      let activeStepID = -1;

      let index = 1;
      if (
        inSessionJointApplicant &&
        step != AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS
      ) {
        AppConstants.JOINT_APPLICANT.FLOW.forEach((flow) => {
          steps.push({
            Id: index,
            Name: STRINGS.STEPS[flow.componentName],
          });
          if (flow.componentName === step) {
            activeStepID = index;
          }
          index += 1;
        });
      } else {
        applicationTemplate.flow.forEach((flow) => {
          if (
            flow.componentName !==
              AppConstants.APPLICATIONSTEP.DOC_VERIFICATION &&
            flow.componentName !== AppConstants.APPLICATIONSTEP.FUND_METHOD
          ) {
            if (
              (flow.componentName === AppConstants.APPLICATIONSTEP.DEBIT_CARD &&
                debitCardAlreadyEnabled === false &&
                applicantData?.acquireType === "create" &&
                bundleProductRelation.length > 0) ||
              flow.componentName !== AppConstants.APPLICATIONSTEP.DEBIT_CARD
            ) {
              if (
                (flow.componentName ===
                  AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
                  debitCardAlreadyEnabled === false &&
                  applicantData?.acquireType === "create") ||
                flow.componentName !==
                  AppConstants.APPLICATIONSTEP.ONLINE_BANKING
              ) {
                if (activeProduct.isJointProduct) {
                  if (
                    flow.componentType === "Applicant" ||
                    flow.componentName === AppConstants.APPLICATIONSTEP.CONFIRM
                  ) {
                    steps.push({
                      Id: index,
                      Name: STRINGS.STEPS[flow.componentName],
                    });
                    if (flow.componentName === step) {
                      activeStepID = index;
                    }
                    index += 1;
                  }
                } else {
                  // if (
                  //   flow.componentName ===
                  //   AppConstants.APPLICATIONSTEP.DEBIT_CARD
                  // ) {
                  //   console.log("setting debit card as shown");
                  //   doDebitCardShown(true);
                  // }

                  steps.push({
                    Id: index,
                    Name: STRINGS.STEPS[flow.componentName],
                  });
                  if (flow.componentName === step) {
                    activeStepID = index;
                  }
                  index += 1;
                }
              }
            }
          }
        });
      }
      this.setState({ steps, activeStepID });
    };

    /**
     * step 4
     * @param {*} applicationTemplate
     * @param {*} step
     * @param {*} callback
     */
    getCompletedApplicationsAndGetApplicantDetails = (
      applicationTemplate,
      step,
      bundleProductRelation,
      callback
    ) => {
      console.log(
        "getCompletedApplicationsAndGetApplicantDetails",
        applicationTemplate,
        step,
        bundleProductRelation
      );
      const { products, inSessionJointApplicant } = this.props;
      const activeProduct = this.getActiveProduct(products);
      // prepares stepper
      this.prepareStepsAndActiveStep(
        applicationTemplate,
        activeProduct,
        step,
        bundleProductRelation
      );

      if (inSessionJointApplicant) {
        this.getApplicantDetails(callback);
      } else {
        // if the wrong step then its redirecting.
        // if (step !== "") {
        //   this.checkStepVisited(step, applicationTemplate);
        // }

        if (step === AppConstants.APPLICATIONSTEP.GETTINGSTARTED) {
          // change condition for getting started specifically.
          // Retrieving Getting Started Data from Applicant API if applicantID exist
          const applicantID = sessionStorage.getItem(APPLICANTID);
          if (applicantID) {
            this.getGettingStartedDataFromApplicantData(applicantID, callback);
          } else {
            this.getGettingStartedDataAndReturn(callback);
          }
        } else if (
          step === AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION ||
          step === AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION
        ) {
          this.getAccountInFormationData(activeProduct, callback);
        } else if (step === AppConstants.APPLICATIONSTEP.BENEFICIARIES) {
          this.getBeneficiariesData(activeProduct, callback);
        } else if (step === AppConstants.APPLICATIONSTEP.SPOUSAL_INFO) {
          this.getSpousalInFormationData(activeProduct, callback);
        } else if (step !== AppConstants.APPLICATIONSTEP.TERMS_AND_CONDITIONS) {
          this.getApplicantDetails(callback);
        }
      }
    };

    /**
     * Helper Method
     * calls the callback with getting started data
     */
    getGettingStartedDataAndReturn = (callback) => {
      const { doGetGettingStartedData } = this.props;
      doGetGettingStartedData((gettingStartedRes) => {
        callback(gettingStartedRes);
      });
    };

    /**
     * Helper Method
     * calls the callback with product information data
     * @param {*} callback
     */
    getAccountInFormationData = (activeProduct, callback) => {
      const { doGetProductInformation } = this.props;
      doGetProductInformation(
        activeProduct.applicationId,
        activeProduct.type,
        activeProduct.doximProductId,
        (getProductInformationRes) => {
          if (getProductInformationRes.status === HTTP_STATUS.OK) {
            callback(getProductInformationRes.data);
          }
        }
      );
    };

    /**
     * @param {*} activeProduct
     * calls the callback with beneficiary information data
     * @param {*} callback
     */
    getBeneficiariesData = (activeProduct, callback) => {
      const { doGetBeneficiaries } = this.props;
      doGetBeneficiaries(
        activeProduct.applicationId,
        activeProduct.contractProductId,
        (getBeneficiariesDataRes) => {
          if (getBeneficiariesDataRes.status === HTTP_STATUS.OK) {
            callback(getBeneficiariesDataRes.data);
          }
        }
      );
    };

    getSpousalInFormationData = (activeProduct, callback) => {
      const { doGetSpousalInformation } = this.props;
      doGetSpousalInformation(
        activeProduct.applicationId,
        REGISTERCONTRACTPRODUCT,
        activeProduct.contractProductId,
        (getSpousalInformationRes) => {
          if (getSpousalInformationRes.status === HTTP_STATUS.OK) {
            callback(getSpousalInformationRes.data);
          }
        }
      );
    };

    /**
     * step 5
     * @param {*} step
     * @param {*} completedApplications
     * @param {*} callback
     */
    getApplicantDetails = (callback) => {
      console.log("getApplicantDetails");
      const { doGetApplicant, products, inSessionJointApplicant } = this.props;
      const activeProduct = this.getActiveProduct(products);
      let applicantId = activeProduct.applicantId
        ? activeProduct.applicantId
        : sessionStorage.getItem(APPLICANTID);
      if (inSessionJointApplicant) {
        applicantId = inSessionJointApplicant.applicantId;
      }
      if (applicantId) {
        doGetApplicant(applicantId, (applicantDetails) => {
          callback(applicantDetails);
        });
      }
    };

    /**
     * Returns active product if not found then returns first object from the array
     * @param {*} products
     */
    getActiveProduct = (products) => {
      let activeProduct = products?.find((product) => product?.active);
      if (!activeProduct) {
        activeProduct = { ...products[0] };
        const jointSlotId = sessionStorage.getItem(
          STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
        );
        if (jointSlotId) {
          activeProduct.applicantId = sessionStorage.getItem(APPLICANTID);
        }
      }
      return activeProduct;
    };

    /**
     * Returns template from the list of templates which matches the template ID
     * @param {*} templates
     * @param {*} templateId
     */
    getTemplateByTemplateID = (templates, templateId) => {
      return templates.find((template) => template.templateId === templateId);
    };

    /**
     * returns the step object from the template flow which matches the param step
     * @param {*} template
     * @param {*} step
     */
    getCurrentStepFromTemplateFlowByStep = (template, step) => {
      return template.flow.find(
        (applicantStep) => applicantStep.componentName === step
      );
    };

    /**
     * returns the step object's index from the template flow which matches the param step
     * @param {*} template
     * @param {*} step
     */
    getCurrentStepIndexFromTemplateFlowByStep = (template, step) => {
      return template?.flow?.findIndex(
        (applicantStep) => applicantStep.componentName === step
      );
    };

    /**
     * Check if the previous step from the current template flow is completed or not..
     * Also check for in between step visit is valid or not
     * @param {*} step
     * @param {*} applicationTemplate
     */
    checkStepVisited = (step, applicationTemplate) => {
      console.log("checkStepVisited", step, applicationTemplate);
      const { products, applicantData, completedApplications } = this.props;
      const activeProduct = this.getActiveProduct(products);
      if (products && products.length > 0) {
        if (
          (step === AppConstants.APPLICATIONSTEP.GETTINGSTARTED ||
            step === AppConstants.APPLICATIONSTEP.TERMS_AND_CONDITIONS) &&
          applicantData?.acquireType !== "search"
        ) {
          this.checkForConsentAndRedirect(step);
          // } else if (step === AppConstants.APPLICATIONSTEP.ABOUTYOU) {
          //   // If user tries to access the about you before completing the getting started step
          //   if (
          //     !activeProduct ||
          //     (activeProduct.applicationStep?.index === null &&
          //       applicantData?.acquireType !== "search" &&
          //       (!completedApplications ||
          //         sessionStorage.getItem("TEMP_HIDE") === "true"))
          //   ) {
          //     this.redirectToDashboard();
          //   }
        } else {
          const currentStepObj = this.getCurrentStepFromTemplateFlowByStep(
            applicationTemplate,
            step
          );
          //
          if (activeProduct?.isJointProduct) {
            if (
              !activeProduct ||
              !currentStepObj ||
              activeProduct?.applicationStep?.index === null
            ) {
              this.redirectToDashboard();
            }
          } else if (
            !activeProduct ||
            !currentStepObj ||
            activeProduct?.applicationStep?.index === null
          ) {
            this.redirectToDashboard();
          }
        }
      } else {
        const { history } = this.props;
        history.push(ROUTES.VAULT_DASHBOARD);
      }
    };

    /**
     * Helper method
     * Checking if the user has checked the consent and redirect based on the step.
     * @param {*} step
     */
    checkForConsentAndRedirect = (step) => {
      console.log("checkForConsentAndRedirect", step);
      // if not consent then it should go to terms and condition
      const { doGetAcoountDetails, applicantData } = this.props;
      doGetAcoountDetails((response) => {
        console.log(response);
        if (
          response.data.consent === null &&
          applicantData?.acquireType !== "search" &&
          step === AppConstants.APPLICATIONSTEP.GETTINGSTARTED
        ) {
          this.redirectToDashboard();
        }
        if (
          response.data.consent &&
          step === AppConstants.APPLICATIONSTEP.TERMS_AND_CONDITIONS
        ) {
          this.redirectToDashboard();
        }
      });
    };

    /**
     * Helper method
     * Redirect to dashboard
     */
    redirectToDashboard = () => {
      const { history } = this.props;
      history.push(ROUTES.VAULT_DASHBOARD);
    };

    /**
     *
     * @param {*} request
     * @param {*} applicationDetails this will made here
     * @param {*} nextStep route remove this. and this also.
     * @param {*} step default value is blank, need only for first two steps
     */
    onContinueHandler = (request, step) => {
      const {
        products,
        doGetAcoountDetails,
        doGetGlobalVault,
        inSessionJointApplicant,
        doUpdateApplicant,
        doSetInSessionJointApplicant,
        history,
        doToggleBackFlag,
        applicantData,
      } = this.props;
      console.log("onContinueHandler", request, step);
      const activeProduct = this.getActiveProduct(products);
      doToggleBackFlag(false);
      switch (step) {
        case AppConstants.APPLICATIONSTEP.GETTINGSTARTED: {
          const { doUpdateGettingStarted } = this.props;
          if (inSessionJointApplicant) {
            let inSessionData = {
              ...inSessionJointApplicant,
              ...request,
            };
            if (!inSessionData.branch) {
              inSessionData.branch = applicantData.member.branch;
            }
            doSetInSessionJointApplicant(inSessionData);
            history.push(ROUTES.ABOUT_YOU);
          } else {
            if (request) {
              doUpdateGettingStarted(request, (response) => {
                if (response.status === HTTP_STATUS.OK) {
                  const applicantID = sessionStorage.getItem(APPLICANTID);
                  if (applicantID) {
                    this.createGettingStartedData(
                      request,
                      (updateApplicantDataRequest) => {
                        doUpdateApplicant(
                          updateApplicantDataRequest,
                          applicantID,
                          (doUpdateApplicantResponse) => {
                            if (
                              doUpdateApplicantResponse.status ===
                              HTTP_STATUS.OK
                            ) {
                              this.addApplicationProgressOrRedirect(step);
                            }
                          }
                        );
                      }
                    );
                  } else {
                    this.addApplicationProgressOrRedirect(step);
                  }
                }
              });
            } else {
              doGetAcoountDetails((getAccountActionRes) => {
                this.addApplicationProgressOrRedirect(step);
              });
            }
          }
          break;
        }
        case AppConstants.APPLICATIONSTEP.ABOUTYOU: {
          if (!activeProduct.applicationId) {
            const jointApplicant = sessionStorage.getItem(
              STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
            );
            if (jointApplicant) {
              doGetAcoountDetails((getAccountActionRes) => {
                if (getAccountActionRes.status === HTTP_STATUS.OK) {
                  const mobileNo = getAccountActionRes.data.msisdn;
                  const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${mobileNo}`;
                  doGetGlobalVault({ vaultKey: vKey }, (gobalVaultRes) => {
                    if (
                      gobalVaultRes?.jointApplicants?.status &&
                      gobalVaultRes.jointApplicants.status ===
                        STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING
                    ) {
                      this.createNewJointApplicantApplication(
                        request,
                        products,
                        step,
                        vKey
                      );
                    }
                  });
                }
              });
            } else {
              this.createNewApplication(request, products, step);
            }
          } else if (inSessionJointApplicant) {
            if (inSessionJointApplicant.applicantId) {
              this.updateApplicantData(request, step);
            } else {
              this.createInSessionJointApplicant(request);
            }
          } else {
            this.updateApplicantData(request, step);
          }
          break;
        }
        case AppConstants.APPLICATIONSTEP.CONFIRM:
          this.addApplicationProgressOrRedirect(step);
          break;

        case AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION:
          this.updateProductInformation(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.SPOUSAL_INFO:
          this.setSpousalInfo(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION:
          this.updateProductInformation(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.BENEFICIARIES:
          this.addApplicationProgressOrRedirect(step);
          // this.addBeneficiaries(request, step);
          break;

        case AppConstants.APPLICATIONSTEP.DEBIT_CARD:
          this.setDebitCard(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.ONLINE_BANKING:
          this.setOnlineBanking(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.SIGNATURE:
          this.setSignature(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.JOINT_APPLICANT:
          this.setJointApplicant(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS:
          this.setJointApplicantStatus(request, step);
          break;
        case AppConstants.APPLICATIONSTEP.INCOME:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.EXPENSE:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.ASSET:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.LIABILITIES:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.DISBURSEMENT:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.REPAYMENT:
          this.addApplicationProgressOrRedirect(step);
          break;
        case AppConstants.APPLICATIONSTEP.LOAN_SUMMARY:
          this.addApplicationProgressOrRedirect(step);
          break;
        default: {
          this.updateApplicantData(request, step);
        }
      }
    };

    setOnlineBanking = (request, step) => {
      // const { products } = this.props;
      // const activeProduct = this.getActiveProduct(products);
      // TODO what needs to be done for online banking
      this.addApplicationProgressOrRedirect(step);
    };

    setSignature = (request, step) => {
      // const { products } = this.props;
      // const activeProduct = this.getActiveProduct(products);
      // TODO what needs to be done for e-signature
      this.addApplicationProgressOrRedirect(step);
    };

    setDebitCard = (request, step) => {
      // const { products } = this.props;
      // const activeProduct = this.getActiveProduct(products);
      // TODO what needs to be done for debit
      this.addApplicationProgressOrRedirect(step);
    };

    setJointApplicant = (request, step) => {
      this.addApplicationProgressOrRedirect(step);
    };

    setJointApplicantStatus = (request, step) => {
      this.addApplicationProgressOrRedirect(step);
    };

    setSpousalInfo = (request, step) => {
      const { doSetSpousalInformation, products } = this.props;
      const activeProduct = this.getActiveProduct(products);
      doSetSpousalInformation(
        request,
        activeProduct.applicationId,
        REGISTERCONTRACTPRODUCT,
        activeProduct.contractProductId,
        (response) => {
          if (response.status === HTTP_STATUS.OK) {
            this.addApplicationProgressOrRedirect(step);
          }
        }
      );
    };

    /**
     * Create new application-> creates new applicant for the first time
     * then update application progress in product vault
     * @param {*} request
     * @param {*} products
     * @param {*} step
     */
    createNewApplication = (
      request,
      products,
      step,
      isNotFirstFlag = false,
      processTemplate
    ) => {
      const {
        doCreateNewApplicant,
        doUpdateProductToVault,
        doUpdateApplicant,
        doDebitCardShown,
      } = this.props;

      const applicationStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );

      doCreateNewApplicant(request, (response) => {
        if (response.status === HTTP_STATUS.OK) {
          doDebitCardShown(false);
          sessionStorage.removeItem(
            AppConstants.SESSION.DEBIT_CARD_ALREADY_LOADED
          );
          this.getGettingStartedDataFromApplicantData(
            response.data.applicantId,
            (gettingStarted) => {
              this.createGettingStartedData(
                gettingStarted,
                (updateApplicantDataRequest) => {
                  doUpdateApplicant(
                    updateApplicantDataRequest,
                    response.data.applicantId,
                    () => {}
                  );
                }
              );
            }
          );
          const updatedVaultProductList = products;
          if (updatedVaultProductList && updatedVaultProductList.length !== 0) {
            updatedVaultProductList[0].applicationId =
              response.data.applicationId;
            updatedVaultProductList[0].applicantId = response.data.applicantId;
            if (
              response.data.doximProducts &&
              response.data.doximProducts.length !== 0
            ) {
              if (response.data.doximProducts.length === 1) {
                updatedVaultProductList[0].doximProductId =
                  response.data.doximProducts[0].id;
              } else {
                const termDepositProduct = response.data.doximProducts.find(
                  (item) => item.type === AppConstants.PRODUCT_TYPE.TERM_DEPOSIT
                );
                if (termDepositProduct) {
                  updatedVaultProductList[0].doximProductId =
                    termDepositProduct.id;
                  const registeredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type ===
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  updatedVaultProductList[0].contractProductId = registeredContract
                    ? registeredContract.id
                    : null;
                } else {
                  updatedVaultProductList[0].doximProductId =
                    response.data.doximProducts[0].id;
                  console.error("UNHANDLED MULTI PRODUCTS", response.data);
                }
              }
            }

            if (
              isNotFirstFlag &&
              !(applicationStepIndex <= -1 || applicationStepIndex - 1 <= -1)
            ) {
              updatedVaultProductList[0].active = true;
              updatedVaultProductList[0].applicationStep = {
                index: processTemplate.flow[applicationStepIndex - 1].index,
                componentName:
                  processTemplate.flow[applicationStepIndex - 1].componentName,
              };
            }

            doUpdateProductToVault(updatedVaultProductList, () => {
              if (isNotFirstFlag) {
                const { history } = this.props;
                history.push(ROUTES[step]);
              } else {
                this.addApplicationProgressOrRedirect(
                  step,
                  response.data,
                  !isNotFirstFlag
                );
              }
            });
          }
        }
      });
    };

    createInSessionJointApplicant = (request) => {
      console.log("createInSessionJointApplicant");
      const {
        doApplicantCreateRequest,
        doSetInSessionJointApplicant,
        inSessionJointApplicant,
        history,
      } = this.props;

      request.relationship = "joint";
      request.email = inSessionJointApplicant.extra.email;
      request.msisdn = inSessionJointApplicant.extra.msisdn;
      request.consent = inSessionJointApplicant.consent;
      request.branch = inSessionJointApplicant.branch;
      request.accountStatementType =
        inSessionJointApplicant.accountStatementType;

      doApplicantCreateRequest(request, (response) => {
        if (response.status === HTTP_STATUS.OK) {
          const inSessionData = {
            ...inSessionJointApplicant,
            otpId: response.data.otpId,
          };
          doSetInSessionJointApplicant(inSessionData);
          history.push(ROUTES.VERIFICATION_CODE);
        }
      });
    };

    /**
     * Create new application-> creates new joint applicant
     * @param {*} request
     * @param {*} products
     * @param {*} step
     */
    createNewJointApplicantApplication = (
      request,
      products,
      step,
      vKey,
      isNotFirstFlag = false,
      processTemplate
    ) => {
      const {
        doApplicantCreateSelf,
        doUpdateProductToVault,
        doFillApplicationSlot,
        doUpdateGlobalVault,
      } = this.props;

      const applicationStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );

      request.relationship = "self";

      doApplicantCreateSelf(request, (response) => {
        if (response.status === HTTP_STATUS.OK) {
          const updatedVaultProductList = products;
          this.fillApplicationSlot(response.data.applicantId, vKey);

          if (updatedVaultProductList && updatedVaultProductList.length !== 0) {
            updatedVaultProductList[0].applicationId = sessionStorage.getItem(
              STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
            );
            updatedVaultProductList[0].applicantId = response.data.applicantId;
            if (
              response.data.doximProducts &&
              response.data.doximProducts.length !== 0
            ) {
              if (response.data.doximProducts.length === 1) {
                updatedVaultProductList[0].doximProductId =
                  response.data.doximProducts[0].id;
              } else {
                const termDepositProduct = response.data.doximProducts.find(
                  (item) => item.type === AppConstants.PRODUCT_TYPE.TERM_DEPOSIT
                );
                if (termDepositProduct) {
                  updatedVaultProductList[0].doximProductId =
                    termDepositProduct.id;
                  const registeredContract = response.data.doximProducts.find(
                    (item) =>
                      item.type ===
                      AppConstants.PRODUCT_TYPE.REGISTERED_CONTRACT
                  );
                  updatedVaultProductList[0].contractProductId = registeredContract
                    ? registeredContract.id
                    : null;
                } else {
                  updatedVaultProductList[0].doximProductId =
                    response.data.doximProducts[0].id;
                  console.error("UNHANDLED MULTI PRODUCTS", response.data);
                }
              }
            }

            if (
              isNotFirstFlag &&
              !(applicationStepIndex <= -1 || applicationStepIndex - 1 <= -1)
            ) {
              updatedVaultProductList[0].active = true;
              updatedVaultProductList[0].applicationStep = {
                index: processTemplate.flow[applicationStepIndex - 1].index,
                componentName:
                  processTemplate.flow[applicationStepIndex - 1].componentName,
              };
            }

            doUpdateProductToVault(updatedVaultProductList, () => {
              if (isNotFirstFlag) {
                const { history } = this.props;
                history.push(ROUTES[step]);
              } else {
                this.addApplicationProgressOrRedirect(step);
              }
            });
          }
        }
      });
    };

    /**
     * Call applicant-fill api for joint applicant registration
     * @param {*} applicantId
     * @param {*} vKey
     */
    fillApplicationSlot = (applicantId, vKey) => {
      console.log("fillApplicationSlot");
      const {
        doGetAcoountDetails,
        doFillApplicationSlot,
        doUpdateGlobalVault,
      } = this.props;

      const jointApplicant = sessionStorage.getItem(
        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
      );
      const jointApplicationId = sessionStorage.getItem(
        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
      );

      const jointApp = jointApplicant.split("-");
      const slotId = jointApp[0];
      const type = jointApp[1];

      if (vKey) {
        doFillApplicationSlot(
          {
            applicantId,
            slotId,
            applicationId: jointApplicationId,
          },
          (fillAppRes) => {
            if (fillAppRes.status === HTTP_STATUS.OK) {
              sessionStorage.removeItem(
                STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
              );
              sessionStorage.removeItem(
                STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
              );

              doUpdateGlobalVault({
                vaultKey: vKey,
                body: {},
              });
            }
          }
        );
      } else {
        doGetAcoountDetails((getAccountActionRes) => {
          if (getAccountActionRes.status === HTTP_STATUS.OK) {
            const mobileNo = getAccountActionRes.data.msisdn;
            vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${mobileNo}`;
            doFillApplicationSlot(
              {
                applicantId,
                slotId,
                applicationId: jointApplicationId,
              },
              (fillAppRes) => {
                if (fillAppRes.status === HTTP_STATUS.OK) {
                  sessionStorage.removeItem(
                    STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
                  );
                  sessionStorage.removeItem(
                    STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
                  );

                  doUpdateGlobalVault({
                    vaultKey: vKey,
                    body: {},
                  });
                }
              }
            );
          }
        });
      }
    };

    /**
     * Updating product information API
     * then update application progress in product vault
     * @param {*} request
     * @param {*} step
     */
    updateProductInformation = (request, step) => {
      const { doUpdateProductInformation, products } = this.props;
      const activeProduct = this.getActiveProduct(products);
      doUpdateProductInformation(
        request,
        activeProduct.applicationId,
        activeProduct.type,
        activeProduct.doximProductId,
        (response) => {
          if (response.status === HTTP_STATUS.OK) {
            this.addApplicationProgressOrRedirect(step);
          }
        }
      );
    };

    addBeneficiaries = (request, step) => {
      const { doAddBeneficiaries, products } = this.props;
      const activeProduct = this.getActiveProduct(products);
      doAddBeneficiaries(
        request,
        activeProduct.applicationId,
        activeProduct.contractProductId,
        (response) => {
          if (response.status === HTTP_STATUS.OK) {
            this.addApplicationProgressOrRedirect(step);
          }
        }
      );
    };

    /**
     * updating application details
     * then update application progress in product vault
     * @param {*} request
     * @param {*} step
     */
    updateApplicantData = (request, step) => {
      console.log("updateApplicantData", request, step);
      const { doUpdateApplicant, inSessionJointApplicant } = this.props;
      const { products } = this.props;
      const activeProduct = this.getActiveProduct(products);
      if (inSessionJointApplicant) {
        if (request) {
          request.branch = inSessionJointApplicant.branch;
          request.accountStatementType =
            inSessionJointApplicant.accountStatementType;
          doUpdateApplicant(
            request,
            inSessionJointApplicant.applicantId,
            (response) => {
              if (response.status === HTTP_STATUS.OK) {
                this.redirectToNextStep(step);
              }
            }
          );
        } else {
          this.redirectToNextStep(step);
        }
      } else {
        if (request === null) {
          this.addApplicationProgressOrRedirect(step);
        } else {
          doUpdateApplicant(request, activeProduct.applicantId, (response) => {
            if (response.status === HTTP_STATUS.OK) {
              this.addApplicationProgressOrRedirect(step);

              const jointApplicant = sessionStorage.getItem(
                STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
              );
              if (jointApplicant) {
                activeProduct.applicantId = sessionStorage.getItem(APPLICANTID);
                if (activeProduct.applicantId) {
                  this.fillApplicationSlot(activeProduct.applicantId, null);
                }
              }
            }
          });
        }
      }
    };

    /**
     *
     * @param {*} step
     * @param {*} responseApplicantDetails
     * @param {*} shouldNotUpdate used from create application method as create is already updating the app progress
     */
    addApplicationProgressOrRedirect = (
      step,
      responseApplicantDetails,
      shouldNotUpdate = true
    ) => {
      // Previous application progress.
      const {
        products,
        doUpdateProductToVault,
        doStoreFundProduct,
        doGetApplicationProperty,
        isNotFirst,
        processTemplate,
        inSessionJointApplicant,
        inReview,
        uploadedAt,
      } = this.props;

      const activeProduct = this.getActiveProduct(products);
      console.log(
        "addApplicationProgressOrRedirect",
        step,
        shouldNotUpdate,
        responseApplicantDetails
      );
      if (
        shouldNotUpdate &&
        (!this.isCurrentStepCompleted(step) ||
          (activeProduct && activeProduct?.applicationStep?.index === null))
      ) {
        let updatedProductList = [...products];

        if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.SIGNATURE
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: inReview || uploadedAt ? 0 : 5000,
            },
            (res) => {
              doStoreFundProduct(this.getActiveProduct(products));

              const { doStoreCompletedProductInASession } = this.props;
              doStoreCompletedProductInASession(
                this.getActiveProduct(products)
              );

              if (res.inReview || res.uploadedAt || res.esignCompletedAt) {
                updatedProductList = products.slice(1, products.length);
              }

              if (updatedProductList && updatedProductList.length !== 0) {
                updatedProductList[0].active = true;
                updatedProductList[0].applicantId = sessionStorage.getItem(
                  APPLICANTID
                );
              }

              doUpdateProductToVault(updatedProductList, () => {
                this.redirectToNextStep(step);
              });
            }
          );
        } else if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.REPAYMENT
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: inReview || uploadedAt ? 0 : 5000,
            },
            (res) => {
              if (res.inReview || res.uploadedAt) {
                updatedProductList = products.slice(1, products.length);
                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    APPLICANTID
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.redirectToDashboard();
                });
              } else {
                this.redirectToNextStep(step);
              }
            }
          );
        } else if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.LOAN_SUMMARY &&
          activeProduct.templateId === 7
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (res) => {
              console.log(res);
              if (res.inReview || res.uploadedAt || res.appliedAt) {
                updatedProductList = products.slice(1, products.length);
                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    APPLICANTID
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.showNextProductPopUporRedirect();
                });
              } else {
                this.redirectToNextStep(step);
              }
            }
          );
        } else if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.LOAN_SUMMARY &&
          activeProduct.templateId === 6
        ) {
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: 0,
            },
            (res) => {
              console.log(res);
              if (res.inReview) {
                updatedProductList = products.slice(1, products.length);
                if (updatedProductList && updatedProductList.length !== 0) {
                  updatedProductList[0].active = true;
                  updatedProductList[0].applicantId = sessionStorage.getItem(
                    APPLICANTID
                  );
                }

                doUpdateProductToVault(updatedProductList, () => {
                  this.showNextProductPopUporRedirect();
                });
              } else {
                this.redirectToNextStep(step);
              }
            }
          );
        } else if (step === AppConstants.APPLICATIONSTEP.CONFIRM) {
          const { flow } = processTemplate;
          const currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
            processTemplate,
            step
          );

          if (
            currentStepIndex + 1 === flow.length ||
            flow[currentStepIndex + 1].componentName ===
              AppConstants.APPLICATIONSTEP.FUND_METHOD ||
            (flow[currentStepIndex + 1].componentName ===
              AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
              flow[currentStepIndex + 1].componentName ===
                AppConstants.APPLICATIONSTEP.FUND_METHOD)
          ) {
            doStoreFundProduct(this.getActiveProduct(products));
            const { doStoreCompletedProductInASession } = this.props;
            doStoreCompletedProductInASession(this.getActiveProduct(products));
            updatedProductList = products.slice(1, products.length);
            if (updatedProductList && updatedProductList.length !== 0) {
              updatedProductList[0].active = true;
              updatedProductList[0].applicantId = sessionStorage.getItem(
                APPLICANTID
              );
            }
            doUpdateProductToVault(updatedProductList, () => {
              this.redirectToNextStep(step);
            });
          } else {
            const request = this.prepareApplicationProgress(
              step,
              responseApplicantDetails
            );
            updatedProductList[0] = request;
            doUpdateProductToVault(updatedProductList, () => {
              this.redirectToNextStep(step);
            });
          }
        } else if (step === AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
          this.redirectToNextStep(step);
        } else if (
          !isNotFirst ||
          (isNotFirst &&
            this.getNextStep(step) ===
              this.getNextApplicationStep(step, processTemplate))
        ) {
          // when its not the second product configuration
          // or if the seond product configuration then if the normal next step
          // and skipping next step is similar then continue as it is
          if (inSessionJointApplicant) {
            this.redirectToNextStep(step);
          } else {
            const request = this.prepareApplicationProgress(
              step,
              responseApplicantDetails
            );
            updatedProductList[0] = request;
            doUpdateProductToVault(updatedProductList, () => {
              this.redirectToNextStep(step);
            });
          }
        } else {
          // when configuring second product and next step is not similar as next skipping step
          if (inSessionJointApplicant) {
            this.redirectToNextStep(step);
          } else {
            const nextStep = this.getNextApplicationStep(step, processTemplate);
            const nextStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
              processTemplate,
              nextStep
            );
            const request = this.prepareApplicationProgress(
              processTemplate.flow[nextStepIndex - 1].componentName,
              responseApplicantDetails
            );
            updatedProductList[0] = request;
            doUpdateProductToVault(updatedProductList, () => {
              this.redirectToNextStep(step);
            });
          }
        }
      } else {
        console.log("skip and redirectToNextStep");
        if (
          activeProduct.applicationId &&
          step === AppConstants.APPLICATIONSTEP.SIGNATURE
        ) {
          // this happens if after signature the user exits where the product stayed in the vault
          let updatedProductList = [...products];
          doGetApplicationProperty(
            {
              applicationId: activeProduct.applicationId,
              delayTime: inReview || uploadedAt ? 0 : 5000,
            },
            (res) => {
              doStoreFundProduct(this.getActiveProduct(products));

              const { doStoreCompletedProductInASession } = this.props;
              doStoreCompletedProductInASession(
                this.getActiveProduct(products)
              );

              if (res.inReview || res.uploadedAt || res.esignCompletedAt) {
                updatedProductList = products.slice(1, products.length);
              }

              if (updatedProductList && updatedProductList.length !== 0) {
                updatedProductList[0].active = true;
                updatedProductList[0].applicantId = sessionStorage.getItem(
                  APPLICANTID
                );
              }

              doUpdateProductToVault(updatedProductList, () => {
                this.redirectToNextStep(step);
              });
            }
          );
        } else {
          this.redirectToNextStep(step, isNotFirst, processTemplate);
        }
      }
    };

    /**
     * Specific redirect for Online banking since product vault is already removed/updated
     * Prepare stepper
     */
    prepareOnlineBankingStep = () => {
      const {
        processTemplate,
        onlineBankingInfo,
        applicantData,
        bundleProductRelation,
        history,
      } = this.props;
      console.log("prepareOnlineBankingStep", processTemplate);
      const steps = [];
      let activeStepID = -1;
      let index = 1;
      processTemplate.flow.forEach((flow) => {
        if (
          flow.componentName !==
            AppConstants.APPLICATIONSTEP.DOC_VERIFICATION &&
          flow.componentName !== AppConstants.APPLICATIONSTEP.FUND_METHOD
        ) {
          steps.push({
            Id: index,
            Name: STRINGS.STEPS[flow.componentName],
          });
          if (
            flow.componentName === AppConstants.APPLICATIONSTEP.ONLINE_BANKING
          ) {
            activeStepID = index;
          }
          index += 1;
        }
      });
      this.setState({ steps, activeStepID });
    };

    /**
     * Prepare the application progress object
     * @param {*} step
     * @param {*} responseApplicantDetails
     */
    prepareApplicationProgress = (step, responseApplicantDetails = null) => {
      const { products, processTemplate, inSessionJointApplicant } = this.props;
      const activeProduct = this.getActiveProduct(products);

      const applicationStep = this.getCurrentStepFromTemplateFlowByStep(
        processTemplate,
        step
      );

      if (responseApplicantDetails) {
        sessionStorage.setItem(
          APPLICANTID,
          responseApplicantDetails.applicantId
        );
        activeProduct.applicantId = responseApplicantDetails.applicantId;
        activeProduct.applicationId = responseApplicantDetails.applicationId;
      }

      if (activeProduct) {
        activeProduct.applicationStep = {
          index: applicationStep.index,
          componentName: applicationStep.componentName,
        };
      }

      return activeProduct;
    };

    /**
     * Redirect to the next step from current template flow
     * redirects to confirm if its in edit mode
     * @param {*} step
     */
    redirectToNextStep = (step, isNotFirstFlag = false, processTemp) => {
      const {
        history,
        isEdit,
        isNotFirst,
        inSessionJointApplicant,
      } = this.props;
      console.log(
        "redirectToNextStep",
        step,
        isNotFirstFlag,
        processTemp,
        isEdit,
        isNotFirst
      );
      console.log("redirectToNextStep", step, isNotFirstFlag, processTemp);
      const notFirstFlag = isNotFirstFlag || isNotFirst;

      /**
       * Edit flow from confirm
       * Use reducers isEdit flag.
       * if isEdit true then make it false.
       */
      if (isEdit) {
        const { doToggleEdit } = this.props;
        doToggleEdit(false);
        history.push(ROUTES.CONFIRM_ACCOUNT_CREATE);
      } else if (notFirstFlag && !inSessionJointApplicant) {
        const { processTemplate } = this.props;
        let finalProcessTemplate = { ...processTemplate };
        if (processTemp && processTemp?.flow.length > 0) {
          finalProcessTemplate = { ...processTemp };
        }
        this.navigateToNextApplicationStep(step, finalProcessTemplate, history);
      } else {
        const nextStep = this.getNextStep(step);
        console.log("nextStep", nextStep);
        if (nextStep === step) {
          // if next step is the same as current step
          this.showNextProductPopUporRedirect();
        } else {
          history.push(ROUTES[nextStep]);
        }
      }
    };

    /**
     * When configuring second product navigating to next application step
     * or non visited applicant step
     * @param {*} step
     * @param {*} processTemplate
     * @param {*} history
     */
    navigateToNextApplicationStep = (step, processTemplate, history) => {
      console.log("navigateToNextApplicationStep", step);
      const nextStep = this.getNextApplicationStep(step, processTemplate);
      console.log("nextStep", nextStep);
      if (nextStep === step) {
        this.showNextProductPopUporRedirect();
      } else {
        history.push(ROUTES[nextStep]);
      }
    };

    /**
     * To get the next application step
     * or non visited applicant step
     * @param {*} step
     * @param {*} processTemplate
     */
    getNextApplicationStep = (step, processTemplate) => {
      console.log("getNextApplicationStep", step);
      const { products, applicationCompleteList, applicantData } = this.props;
      let nextStep = step;
      let currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
        processTemplate,
        step
      );
      currentStepIndex += 1;
      const activeProduct = this.getActiveProduct(products);
      while (currentStepIndex < processTemplate?.flow?.length) {
        if (
          processTemplate?.flow[currentStepIndex]?.componentType ===
            AppConstants.COMPONENTSTEPTYPE.APPLICATION ||
          !this.stepVisisted(
            processTemplate?.flow[currentStepIndex]?.componentName
          )
        ) {
          const targetNextStep =
            processTemplate?.flow[currentStepIndex]?.componentName;
          if (targetNextStep === AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
            if (
              showOnlineBanking(
                activeProduct,
                applicationCompleteList,
                applicantData
              )
            ) {
              nextStep = targetNextStep;
              break;
            }
          } else {
            nextStep = processTemplate?.flow[currentStepIndex]?.componentName;
            break;
          }
        }
        currentStepIndex += 1;
      }

      return nextStep;
    };

    /**
     * When configuring second product then check if the step visited in previously
     * completed products in the session
     * @param {*} step
     */
    stepVisisted = (step) => {
      const { templates, products, sessionCompletedProducts } = this.props;
      let stepCompleted = false;
      // this part added to check if the step is completed in current template or not
      const activeProduct = this.getActiveProduct(products);
      let completedTemplate = null;
      for (let i = 0; i < sessionCompletedProducts.length; i += 1) {
        const completedProduct = sessionCompletedProducts[i];

        if (completedProduct) {
          completedTemplate = this.getTemplateByTemplateID(
            templates,
            completedProduct.templateId
          );
        }

        if (completedTemplate) {
          const stepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
            completedTemplate,
            step
          );

          if (stepIndex >= 0) {
            stepCompleted = true;
            break;
          }
          stepCompleted = false;
        } else {
          // this part added to check if the step is completed in current template or not
          completedTemplate = this.getTemplateByTemplateID(
            templates,
            activeProduct.templateId
          );

          if (completedTemplate) {
            const currentStepObj = this.getCurrentStepFromTemplateFlowByStep(
              completedTemplate,
              step
            );

            if (
              currentStepObj &&
              activeProduct.applicationStep?.index <= currentStepObj.index
            ) {
              stepCompleted = true;
              break;
            }
          }
        }
      }
      return stepCompleted;
    };

    /**
     * Returns next step to the step(from param)
     * @param {*} step
     */
    getNextStep = (step) => {
      console.log("getNextStep", step);
      let nextStep = step;
      const {
        processTemplate,
        isDebitCardEnabled,
        inSessionJointApplicant,
        products,
        applicationCompleteList,
        applicantData,
        doGetApplicationProperty,
      } = this.props;

      const activeProduct = this.getActiveProduct(products);

      if (activeProduct.isJointProduct) {
        const currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
          processTemplate,
          step
        );
        if (
          currentStepIndex !== -1 &&
          currentStepIndex !== processTemplate.flow.length - 1
        ) {
          if (
            processTemplate.flow[currentStepIndex + 1].componentType ===
            "Application"
          ) {
            nextStep = AppConstants.APPLICATIONSTEP.CONFIRM;
          } else {
            nextStep = processTemplate.flow[currentStepIndex + 1].componentName;
            if (nextStep === AppConstants.APPLICATIONSTEP.DOC_VERIFICATION) {
              const { doToggleScanFlag } = this.props;
              doToggleScanFlag(true);
              nextStep =
                processTemplate.flow[currentStepIndex + 2].componentName;
            }
            if (!nextStep) {
              nextStep =
                processTemplate.flow[currentStepIndex + 2].componentName;
            }
          }
        }
      } else if (inSessionJointApplicant) {
        const currentStepIndex = AppConstants.JOINT_APPLICANT.FLOW.findIndex(
          (applicantStep) => applicantStep.componentName === step
        );

        if (
          currentStepIndex !== -1 &&
          currentStepIndex !== AppConstants.JOINT_APPLICANT.FLOW.length - 1
        ) {
          nextStep =
            AppConstants.JOINT_APPLICANT.FLOW[currentStepIndex + 1]
              .componentName;
        }
      } else {
        const currentStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
          processTemplate,
          step
        );

        if (
          currentStepIndex !== -1 &&
          currentStepIndex !== processTemplate.flow.length - 1
        ) {
          // if current step is not the last step

          nextStep = processTemplate.flow[currentStepIndex + 1].componentName;

          if (nextStep === AppConstants.APPLICATIONSTEP.DOC_VERIFICATION) {
            const { doToggleScanFlag } = this.props;
            doToggleScanFlag(true);
            if (processTemplate.flow[currentStepIndex + 2]) {
              nextStep =
                processTemplate.flow[currentStepIndex + 2].componentName;
            } else {
              nextStep = step;
            }
          }

          if (nextStep == AppConstants.APPLICATIONSTEP.DEBIT_CARD) {
            const newStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
              processTemplate,
              nextStep
            );
            if (
              !showDebitCard(
                activeProduct,
                applicationCompleteList,
                applicantData
              )
            ) {
              if (processTemplate.flow[newStepIndex + 1]) {
                nextStep = processTemplate.flow[newStepIndex + 1].componentName;
              } else {
                nextStep = step;
              }
            }
          }

          if (nextStep == AppConstants.APPLICATIONSTEP.ONLINE_BANKING) {
            const newStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
              processTemplate,
              nextStep
            );
            if (
              !showOnlineBanking(
                activeProduct,
                applicationCompleteList,
                applicantData
              )
            ) {
              if (processTemplate.flow[newStepIndex + 1]) {
                nextStep = processTemplate.flow[newStepIndex + 1].componentName;
              } else {
                nextStep = step;
              }
            }
          }

          if (nextStep == AppConstants.APPLICATIONSTEP.FUND_METHOD) {
            doGetApplicationProperty(
              { applicationId: activeProduct.applicationId },
              (res) => {
                if (!res.uploadedAt) {
                  nextStep = step;
                }
              }
            );
          }
        }
      }

      return nextStep;
    };

    /**
     * Check if current step is already visited or not
     * so the application progress should be updated or not
     * @param {*} step
     */
    isCurrentStepCompleted = (step) => {
      console.log("isCurrentStepCompleted", step);
      const { processTemplate } = this.props;

      const currentStepObj = this.getCurrentStepFromTemplateFlowByStep(
        processTemplate,
        step
      );

      console.log("currentStepObj", currentStepObj);

      // Previous application progress.
      const { products } = this.props;
      const activeProduct = this.getActiveProduct(products);

      console.log(
        "currentStepObj result",
        !currentStepObj ||
          !activeProduct ||
          activeProduct?.applicationStep?.index === null ||
          currentStepObj.index < activeProduct?.applicationStep?.index
      );

      return (
        !currentStepObj ||
        !activeProduct ||
        activeProduct?.applicationStep?.index === null ||
        currentStepObj.index < activeProduct?.applicationStep?.index
      );
    };

    /**
     * From the current template flow goes to the previous step
     * @param {*} currentStep
     */
    onBackHandleClick = (currentStep) => {
      const previousStep = this.getPreviousStep(currentStep);
      const { isNotFirst, doToggleBackFlag } = this.props;
      if (isNotFirst) {
        const { doSetNotFirstFlag } = this.props;
        doSetNotFirstFlag(false);
      }
      if (previousStep) {
        const { history, doToggleEdit } = this.props;
        doToggleEdit(false);
        doToggleBackFlag(true);
        history.push(ROUTES[previousStep]);
      }
    };

    /**
     * Returns the previous step from current template flow
     * @param {*} step
     */
    getPreviousStep = (step) => {
      const {
        processTemplate,
        appProperties,
        isDebitCardEnabled,
        enableDebitCard,
        inSessionJointApplicant,
      } = this.props;
      console.log("getPreviousStep", step);
      let previousStep = "";

      if (inSessionJointApplicant) {
        const applicantStepIndex = AppConstants.JOINT_APPLICANT.FLOW.findIndex(
          (applicantStep) => applicantStep.componentName === step
        );
        if (applicantStepIndex) {
          previousStep =
            processTemplate.flow[applicantStepIndex - 1].componentName;
        }
      } else {
        const applicantStepIndex = this.getCurrentStepIndexFromTemplateFlowByStep(
          processTemplate,
          step
        );

        if (applicantStepIndex) {
          previousStep = processTemplate.flow[applicantStepIndex - 1]
            .componentName
            ? processTemplate.flow[applicantStepIndex - 1].componentName
            : processTemplate.flow[applicantStepIndex - 2].componentName;
        }

        if (
          previousStep === AppConstants.APPLICATIONSTEP.DOC_VERIFICATION ||
          previousStep === "" ||
          (previousStep === AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
            isDebitCardEnabled) ||
          (previousStep === AppConstants.APPLICATIONSTEP.ONLINE_BANKING &&
            enableDebitCard === false) ||
          (previousStep ===
            AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS &&
            appProperties &&
            appProperties.length < 2)
        ) {
          previousStep =
            processTemplate.flow[applicantStepIndex - 2].componentName;
        }
      }

      return previousStep;
    };

    /**
     * Display Pop up if there is any more product left to configure
     * or redirect to dashboard
     */
    showNextProductPopUporRedirect = () => {
      console.log("showNextProductPopUporRedirect");
      const { products } = this.props;
      if (products && products.length !== 0) {
        this.showContinueWithNextProductPopup();
      } else {
        this.redirectToDashboard();
      }
    };

    /**
     * Show Pop up for next product
     */
    showContinueWithNextProductPopup = () => {
      const { productsList, products } = this.props;
      const product = getProduct(productsList, products);
      const modal = {};
      const description =
        MODALMESSAGES.FUNDING_AMOUNT.FUND_PRODUCT_POPUP_MESSAGE;
      modal.title = MODALMESSAGES.FUNDING_AMOUNT.FUND_PRODUCT;
      modal.description = description.replace("$", product.productName);
      modal.type = AppConstants.MODALTYPE.SUCCESS;
      this.setState({
        modal,
        showModal: true,
      });
    };

    /**
     * To start configuring the second product
     * Assuming the about you step will be skipping in the next product configutaion
     */
    navigateToNextProduct = () => {
      console.log("navigateToNextProduct");
      // Set the not first flag to true
      // this flag gets invalidated when user clicks on back when configuraing second product
      const { doSetNotFirstFlag, products } = this.props;
      doSetNotFirstFlag(true);

      // checking if the second products process template is different or same
      const { processTemplate } = this.props;
      let processTemp = { ...processTemplate };
      const activeProduct = this.getActiveProduct(products);
      if (activeProduct.templateId !== processTemplate?.templateId) {
        // if process template different then updating the reducer
        const { doUpdateProductTemplate, templates } = this.props;
        doUpdateProductTemplate(activeProduct);

        processTemp = this.getTemplateByTemplateID(
          templates,
          activeProduct.templateId
        );
      }

      const nextComponent = processTemp.flow.find(
        (flow) =>
          ((flow.componentType === AppConstants.COMPONENTSTEPTYPE.APPLICANT &&
            !this.stepVisisted(flow.componentName)) ||
            flow.componentType ===
              AppConstants.COMPONENTSTEPTYPE.APPLICATION) &&
          flow.componentName !== AppConstants.APPLICATIONSTEP.DOC_VERIFICATION
      );

      const nextStep = nextComponent?.componentName;

      // check if the second product configuration is dropeed in between then
      // do not call create application for same product twice
      if (!activeProduct.applicationId) {
        this.callCreateNewApplication(nextStep, processTemp);
      } else {
        this.startProcessingApplication(activeProduct, nextStep);
      }
    };

    startProcessingApplication = (activeProduct, nextStep) => {
      const { history } = this.props;
      console.log("startProcessingApplication", activeProduct, nextStep);
      history.push(ROUTES[nextStep]);
    };

    /**
     * Call create application method
     * @param {*} addProgressForStep this step passed will be the landing step
     * @param {*} processTemp updated process template
     */
    callCreateNewApplication = (addProgressForStep, processTemp) => {
      const { applicantData, products } = this.props;
      const activeProduct = this.getActiveProduct(products);
      const { member, address } = applicantData;
      const request = {
        firstName: member.name,
        middleName: member.middleName,
        lastName: member.surname,
        dateOfBirth: member.dob,
        address: address.currentAddressLine1,
        addressLine2: address.currentAddressLine2,
        city: address.currentAddressCity,
        province: address.currentAddressProvince,
        postalCode: address.currentAddressPostalCode,
        mailingAddressLine1: address.mailingAddressLine1,
        mailingAddressLine2: address.mailingAddressLine2,
        mailingCity: address.mailingCity,
        mailingProvince: address.mailingProvince,
        mailingPostalCode: address.mailingPostalCode,
        mailingCountry: address.mailingCountry,
        mailingSameAsCivic: address.mailingSameAsCivic,
        bundleProductId: activeProduct ? activeProduct.productId : "",
      };

      this.createNewApplication(
        request,
        products,
        addProgressForStep,
        true,
        processTemp
      );
    };

    handlePopupModalCloseBtnClick = () => {
      const { history } = this.props;
      history.push(ROUTES.VAULT_DASHBOARD);
    };

    toggleModal = () => {
      const { showModal } = this.state;
      const modal = { title: "", description: "", type: "" };
      this.setState({ showModal: !showModal, modal });
    };

    getGettingStartedDataFromApplicantData = (applicantID, callback) => {
      const { doGetApplicant } = this.props;
      doGetApplicant(applicantID, (applicantDetails) => {
        getGettingStarted(applicantDetails.data, callback);
      });
    };

    createGettingStartedData = (gettingStartedData, callback) => {
      const gettingStartedDataRequest = {
        member: {
          branch: gettingStartedData.branch,
        },
        regulatory: {
          mailStmt:
            gettingStartedData.accountStatementType ===
            AppConstants.GETTINGSTARTED.PAPER,
          eStmt:
            gettingStartedData.accountStatementType ===
            AppConstants.GETTINGSTARTED.EMAIL,
        },
      };
      callback(gettingStartedDataRequest);
    };

    render() {
      const { steps, activeStepID, showModal, modal } = this.state;
      return (
        <>
          {showModal && (
            <PopupModal
              type={modal.type}
              title={modal.title}
              description={modal.description}
              toggleModal={this.toggleModal}
              showModal={showModal}
              popupBtnClick={this.navigateToNextProduct}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
              closeBtnClick={this.handlePopupModalCloseBtnClick}
            />
          )}
          <WrappedComponent
            getDataFromServer={(step, callback) =>
              this.getDataFromServer(step, callback)
            }
            continuehandler={(request, applicationDetails, nextStep, step) =>
              this.onContinueHandler(
                request,
                applicationDetails,
                nextStep,
                step
              )
            }
            handleBack={(step) => this.onBackHandleClick(step)}
            getNextStep={(step) => this.getNextStep(step)}
            steps={steps}
            activeStepID={activeStepID}
            showNextProductPopUporRedirect={this.showNextProductPopUporRedirect}
            getActiveProduct={this.getActiveProduct}
            navigateToNextProduct={this.navigateToNextProduct}
            {...this.props}
          />
        </>
      );
    }
  }

  HOC.propTypes = {
    doGetApplicant: PropTypes.func.isRequired,
    doGetGettingStartedData: PropTypes.func.isRequired,
    doUpdateGettingStarted: PropTypes.func.isRequired,
    doCreateNewApplicant: PropTypes.func.isRequired,
    doUpdateApplicant: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    isEdit: PropTypes.bool.isRequired,
    doToggleEdit: PropTypes.func.isRequired,
    doGetAcoountDetails: PropTypes.func.isRequired,
    productsList: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.string,
    ]).isRequired,
    doUpdateProductToVault: PropTypes.func.isRequired,
    processTemplate: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
    doGetProductTemplateFlow: PropTypes.func.isRequired,
    doGetProductFromVault: PropTypes.func.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    doToggleScanFlag: PropTypes.func.isRequired,
    doUpdateProductTemplate: PropTypes.func.isRequired,
    templates: PropTypes.arrayOf(PropTypes.object).isRequired,
    doGetApplicationList: PropTypes.func.isRequired,
    completedApplications: PropTypes.arrayOf(PropTypes.object),
    applicantList: PropTypes.arrayOf(PropTypes.object),
    doGetProductList: PropTypes.func.isRequired,
    doStoreFundProduct: PropTypes.func.isRequired,
    doUpdateProductInformation: PropTypes.func.isRequired,
    doSetSpousalInformation: PropTypes.func.isRequired,
    doGetProductInformation: PropTypes.func.isRequired,
    doAddBeneficiaries: PropTypes.func.isRequired,
    doGetBeneficiaries: PropTypes.func.isRequired,
    doGetSpousalInformation: PropTypes.func.isRequired,
    doAddApplicationSlot: PropTypes.func.isRequired,
    doinviteApplicationSlot: PropTypes.func.isRequired,
    doGetBundleProductRelation: PropTypes.func.isRequired,
    doAddBundleProduct: PropTypes.func.isRequired,
    doDeleteDebitCard: PropTypes.func.isRequired,
    doGetGlobalVault: PropTypes.func.isRequired,
    doGetApplicationProperty: PropTypes.func.isRequired,
    appProperties: PropTypes.arrayOf(PropTypes.object),
    doSetNotFirstFlag: PropTypes.func.isRequired,
    isNotFirst: PropTypes.bool.isRequired,
    doStoreCompletedProductInASession: PropTypes.func.isRequired,
    sessionCompletedProducts: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
    applicantData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    doApplicantCreateSelf: PropTypes.func.isRequired,
    doFillApplicationSlot: PropTypes.func.isRequired,
    isDebitCardEnabled: PropTypes.bool,
    enableDebitCard: PropTypes.bool,
    applicationAppliedList: PropTypes.arrayOf(PropTypes.any).isRequired,
    doStoreDebitCard: PropTypes.func.isRequired,
    doUpdateGlobalVault: PropTypes.func.isRequired,
    doDebitCardShown: PropTypes.func.isRequired,
    inSessionJointApplicant: PropTypes.object,
    doApplicantCreateRequest: PropTypes.func.isRequired,
    doSetInSessionJointApplicant: PropTypes.func.isRequired,
    onlineBankingInfo: PropTypes.object,
    gettingStarted: PropTypes.objectOf(PropTypes.any),
  };

  HOC.defaultProps = {
    completedApplications: [],
    applicantList: [],
    appProperties: [],
    applicantData: "",
    isDebitCardEnabled: false,
    enableDebitCard: false,
    inSessionJointApplicant: null,
    gettingStarted: null,
  };

  const mapStateToProps = (state) => ({
    inReview: state.ApplicationReducer.inReview,
    uploadedAt: state.ApplicationReducer.uploadedAt,
    isEdit: state.ApplicationReducer.isEdit,
    processTemplate: state.ApplicationReducer.processTemplate,
    products: state.VaultReducer.vaultProductList,
    templates: state.ApplicationReducer.templates,
    productsList: state.MarketplaceReducer.productList,
    completedApplications: state.ApplicationReducer.applicationCompleteList,
    applicantList: state.ApplicationReducer.applicantList,
    appProperties: state.JoinApplicantReducer.appProperties,
    isNotFirst: state.ApplicationReducer.isNotFirst,
    sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
    applicantData: state.ApplicationReducer.response,
    isDebitCardEnabled: state.DebitCardReducer.isDebitCardEnabled,
    enableDebitCard: state.DebitCardReducer.enableDebitCard,
    applicationAppliedList: state.ApplicationReducer.applicationAppliedList,
    inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
    onlineBankingInfo: state.OnlineBankingReducer,
    gettingStarted: state.ApplicationReducer.gettingStarted,
    applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      doGetApplicant: (id, callback) => dispatch(getApplication(id, callback)),
      doGetGettingStartedData: (callback) =>
        dispatch(getGettingStartedData(callback)),
      doUpdateGettingStarted: (request, callback) =>
        dispatch(updateGettingStarted(request, callback)),
      doCreateNewApplicant: (request, callback) =>
        dispatch(doCreateApplication(request, callback)),
      doUpdateApplicant: (request, applicantId, callback) =>
        dispatch(updateApplicantData(request, applicantId, callback)),
      doToggleEdit: (flag) => dispatch(toggleEditFlag(flag)),
      doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
      doUpdateProductToVault: (vaultProductList, callback) =>
        dispatch(updateProductToVault(vaultProductList, callback)),
      doGetProductTemplateFlow: (callback, product) =>
        dispatch(getProductTemplate(callback, product)),
      doGetProductFromVault: (callback) =>
        dispatch(getProductFromVault(callback)),
      doToggleScanFlag: (flag) => dispatch(toggleScanFlag(flag)),
      doUpdateProductTemplate: (activeProduct) =>
        dispatch(updateProductTemplate(activeProduct)),
      doGetApplicationList: (callback) =>
        dispatch(getApplicationList(callback)),
      doGetProductList: () => dispatch(getProductList()),
      doStoreFundProduct: (product) => dispatch(storeFundProduct(product)),
      doUpdateProductInformation: (
        requestBody,
        applicationId,
        productType,
        doximProductId,
        callback
      ) =>
        dispatch(
          updateProductInformation(
            requestBody,
            applicationId,
            productType,
            doximProductId,
            callback
          )
        ),
      doSetSpousalInformation: (
        requestBody,
        applicationId,
        regidteredcontractproduct,
        reporductNo,
        callback
      ) =>
        dispatch(
          setSpousalInfo(
            requestBody,
            applicationId,
            regidteredcontractproduct,
            reporductNo,
            callback
          )
        ),
      doGetSpousalInformation: (
        applicationId,
        regidteredcontractproduct,
        reporductNo,
        callback
      ) =>
        dispatch(
          getSpousalInfo(
            applicationId,
            regidteredcontractproduct,
            reporductNo,
            callback
          )
        ),
      doGetProductInformation: (
        applicationId,
        productType,
        doximProductId,
        callback
      ) =>
        dispatch(
          getProductInformation(
            applicationId,
            productType,
            doximProductId,
            callback
          )
        ),
      doAddBeneficiaries: (
        requestBody,
        applicationId,
        contractProductId,
        callback
      ) =>
        dispatch(
          addBeneficiaries(
            requestBody,
            applicationId,
            contractProductId,
            callback
          )
        ),
      doGetBeneficiaries: (applicationId, contractProductId, callback) =>
        dispatch(getBeneficiaries(applicationId, contractProductId, callback)),
      doAddApplicationSlot: (request, callback) =>
        dispatch(addApplicationSlot(request, callback)),
      doinviteApplicationSlot: (request, callback) =>
        dispatch(inviteApplicationSlot(request, callback)),
      doGetBundleProductRelation: (activeProduct, callback) =>
        dispatch(getBundleProductRelation(activeProduct, callback)),
      doAddBundleProduct: (
        applicationId,
        bundleProductId,
        demandProductId,
        callback
      ) =>
        dispatch(
          addBundleProduct(
            applicationId,
            bundleProductId,
            demandProductId,
            callback
          )
        ),
      doDeleteDebitCard: (applicationId, debitProductId, callback) =>
        dispatch(deleteDebitCard(applicationId, debitProductId, callback)),
      doGetGlobalVault: (request, callback) =>
        dispatch(getGlobalVault(request, callback)),
      doGetApplicationProperty: (request, callback) =>
        dispatch(getApplicationProperty(request, callback)),
      doSetNotFirstFlag: (flag) => dispatch(setNotFirstFlag(flag)),
      doStoreCompletedProductInASession: (product) =>
        dispatch(storeCompletedProductInASession(product)),
      doApplicantCreateSelf: (request, callback) =>
        dispatch(applicantCreateSelf(request, callback)),
      doFillApplicationSlot: (request, callback) =>
        dispatch(fillApplicationSlot(request, callback)),
      doStoreDebitCard: (enableDebitCard, applicationId, callback) =>
        dispatch(storeDebitCard(enableDebitCard, applicationId, callback)),
      doUpdateGlobalVault: (request, callback) =>
        dispatch(updateGlobalVault(request, callback)),
      doDebitCardShown: (flagDebitCardShown, callback) =>
        dispatch(debitCardShown(flagDebitCardShown, callback)),
      doApplicantCreateRequest: (request, callback) =>
        dispatch(applicantCreateRequest(request, callback)),
      doSetInSessionJointApplicant: (request, callback) =>
        dispatch(setInSessionJointApplicant(request, callback)),
      doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    };
  };

  return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HOC);
}
