import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import checkErrors from "Utils/InputValidator";
import { doGetIdType } from "./Actions/AboutYouActions";

class IdSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      govID: "",
      idError: [],
      idList: [],
    };
  }

  componentDidMount() {
    const { doGetIdList } = this.props;
    doGetIdList();
  }

  componentDidUpdate(prevProps) {
    const { idListResponse } = this.props;
    if (prevProps.idListResponse !== idListResponse) {
      if (!idListResponse.error) {
        this.setListFromS3(idListResponse.idTypes);
      }
    }
  }

  setListFromS3 = (list) => {
    this.setState({ idList: list });
  };

  changeHandler = (event) => {
    this.setState({ govID: event.target.value, idError: [] });
  };

  verifyData = () => {
    const { govID } = this.state;
    const idErrorsList = checkErrors(govID, VALIDATIONS.ABOUTYOU.ID);
    if (idErrorsList && idErrorsList.length > 0) {
      this.setState({ idError: idErrorsList });
      return false;
    }

    return true;
  };

  submitData = () => {
    if (this.verifyData()) {
      const { govID } = this.state;
      const { idSelectionHandler } = this.props;
      const idType = this.getIdType(govID);
      idSelectionHandler(govID, idType);
    }
  };

  getIdType = (idName) => {
    const { idList } = this.state;
    let selectedID = null;
    if (idList && idList.length > 0) {
      selectedID = idList.find((elem) => {
        return elem.description === idName;
      });
    }
    return selectedID ? selectedID.type : null;
  };

  render() {
    const { govID, idError, idList } = this.state;
    const className = ["form-control", "select__dropdown"];
    if (govID) {
      className.push("active");
    } else {
      className.push("default");
    }

    let selectIdListElements = [];
    if (idList && idList.length > 0) {
      selectIdListElements = idList.map((elem) => {
        return (
          <option
            value={elem.description}
            key={elem.description + elem.code}
            className="[ dropdown__option ]"
          >
            {elem.description}
          </option>
        );
      });
    }
    const defaultOpt = (
      <option
        value=""
        key="default"
        className="[ default ] [ dropdown__option ]"
      >
        {STRINGS.ABOUTYOU.PARTONE.DEFAULTIDPLACEHOLDER}
      </option>
    );
    selectIdListElements.splice(0, 0, defaultOpt);

    if (idError && idError.length > 0) {
      className.push("invalid-input");
    }

    const selectElement = (
      <select
        value={govID}
        className={className.join(" ")}
        onChange={this.changeHandler}
      >
        {selectIdListElements}
      </select>
    );

    return (
      <>
        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTONE.SUBTITILE}
          </div>
          <div className="[ form-description ]">
            {STRINGS.ABOUTYOU.PARTONE.ABOUTYOUINFO}
          </div>
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTONE.LABEL}
            </div>
            {selectElement}
            <div className="[ error-msg ]">{idError[0]}</div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.submitData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

IdSelection.propTypes = {
  idSelectionHandler: PropTypes.func,
  doGetIdList: PropTypes.func,
  idListResponse: PropTypes.oneOfType([
    PropTypes.arrayOf(Object),
    PropTypes.object,
    PropTypes.string,
  ]),
};

IdSelection.defaultProps = {
  idSelectionHandler: () => {},
  doGetIdList: () => {},
  idListResponse: null,
};

const mapStateToProps = (state) => ({
  idListResponse: state.AboutYouReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    doGetIdList: () => dispatch(doGetIdType()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdSelection);
