import React from "react";

const InfoBanner = (props) => {
  const { infoType } = props;
  let infoBannerText = null;
  if (props.infoText && props.infoText.trim() !== "") {
    if (infoType === "welcomeBack") {
      infoBannerText = (
        <div className="[ container-fluid ] [ info-banner ] [ welcome-back ]">
          <div className="[ d-flex ] [ justify-content-center align-items-center ]">
            <span className="[ info-banner__text ]">{props.infoText}</span>
          </div>
        </div>
      );
    } else {
      infoBannerText = (
        <div className="[ container-fluid ] [ info-banner ]">
          <div className="[ d-flex ] [ justify-content-center align-items-center ]">
            <span className="[ pr-2 ] [ info-banner__lock-icon ]" />
            <span className="[ info-banner__text ]">{props.infoText}</span>
          </div>
        </div>
      );
    }
  }
  return infoBannerText;
};

export default InfoBanner;
