import * as actionTypes from "Redux/Actions/actionTypes";
import { getProductCategoryList } from "../../../Utils/CommonUtilities";

const initialState = {
  productList: [],
  categoryList: [],
  activeTab: 0,
};

const MarketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_PRODUCT_LIST:
      return {
        ...state,
        productList: action.data,
        categoryList: getProductCategoryList(action.data),
      };
    case actionTypes.FILTER_PRODUCT:
      return {
        ...state,
        productList: action.productList,
        categoryList: action.categoryList,
      };

    case actionTypes.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case actionTypes.STORE_FI_INFO:
      return {
        ...state,
        finInfo: action.data,
      };
    case actionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default MarketplaceReducer;
