import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import STRINGS from "../../Constants/Strings";
import { HTTP_STATUS } from "../../Communication/Constants";
import AppConstants from "../../Constants/AppConstants";
import withApplicantHOC from "../../Hoc/ApplicantHOC/ApplicantHOC";
import JointApplicant from "./JointApplicant";
import { toggleBackFlag } from "Redux/Actions/ApplicationAction";
import getAccount from "../../Redux/Actions/GetAccountAction";
import { getProductFromVault } from "../MarketPlace/Action/MarketPlaceAction";
import {
  getApplicantType,
  addGlobalVault,
  getApplicationProperty,
  deleteApplicationSlot,
  setInSessionJointApplicant,
  addJointApplicants,
} from "./Actions/JointApplicantAction";

class JointApplicantContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jointAccounts: [],
      jointInSession: false,
      inSessionGlobalVault: [],
    };
  }

  componentDidMount() {
    const {
      doGetApplicantType,
      doGetApplicationProperty,
      getActiveProduct,
      products,
    } = this.props;
    doGetApplicantType();
    this.getJointApplicants();
  }

  getJointApplicants = () => {
    const { doGetApplicationProperty, getActiveProduct, products } = this.props;
    const activeProduct = getActiveProduct(products);
    doGetApplicationProperty(
      { applicationId: activeProduct.applicationId },
      (res) => {
        const jointAccounts = res.slots.filter(
          (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
        );
        if (res.slots.length > 1) {
          this.setState({
            jointAccounts,
            jointInSession: res.slots[1].extra.inSession, // all joints has the same value
          });
        }
        return jointAccounts;
      }
    );
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  handleContinue = (state) => {
    const {
      continuehandler,
      products,
      doDeleteApplicationSlot,
      getActiveProduct,
    } = this.props;
    const {
      applicants,
      isApplicantPresent,
      isAddApplicant,
      applicationId,
      applicantId,
      jointAppVaultProducts,
    } = state;
    const {
      jointAccounts, // old list of joint
      jointInSession, // old settings
      inSessionGlobalVault,
    } = this.state;

    console.log("applicants", applicants);
    console.log("isApplicantPresent", isApplicantPresent);
    console.log(products);
    console.log("jointAccounts", jointAccounts);
    console.log("isAddApplicant", isAddApplicant);

    const activeProduct = getActiveProduct(products);

    if (isAddApplicant) {
      this.addApplicants(
        applicantId,
        applicationId,
        applicants,
        isApplicantPresent,
        jointAppVaultProducts,
        jointInSession
      );
    } else if (jointAccounts.length > 0) {
      // delete all joint since joint is set to NO
      jointAccounts.forEach((joint) => {
        doDeleteApplicationSlot({
          applicationId: activeProduct.applicationId,
          slotId: joint.id,
        });
      });
      continuehandler(
        inSessionGlobalVault,
        AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
      );
    } else {
      continuehandler(
        inSessionGlobalVault,
        AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
      );
    }
  };

  handleBack = () => {
    const { handleBack, doToggleBackFlag } = this.props;
    doToggleBackFlag(true);
    handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
  };

  addToGlobalVault(
    raw,
    jointAppVaultProducts,
    jointType,
    applicantId,
    applicationId
  ) {
    const { doAddGlobalVault } = this.props;
    const validMobileNo =
      AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
      raw.mobilePhone.replace(
        AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
        ""
      );
    const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${validMobileNo}`;
    if (jointType === STRINGS.JOIN_APPLICANT.IN_SESSION) {
      const inSessionVault = {
        type: jointType,
        vaultProducts: jointAppVaultProducts,
        mainApplicantId: applicantId,
        jointApplicants: {
          email: raw.emailAddress,
          msisdn: validMobileNo,
          status: STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING,
        },
      };
      this.state.inSessionGlobalVault.push(inSessionVault);
      return;
    }

    console.log(vKey);
    jointAppVaultProducts[0].slotId = raw.id;
    doAddGlobalVault(
      {
        vaultKey: vKey,
        body: {
          type: jointType,
          vaultProducts: jointAppVaultProducts,
          // eslint-disable-next-line object-shorthand
          applicantId: applicantId,
          // eslint-disable-next-line object-shorthand
          jointApplicants: {
            email: raw.emailAddress,
            msisdn: validMobileNo,
            status: STRINGS.JOIN_APPLICANT.JOINT_STATUS_PENDING,
          },
        },
      },
      (globalVaultRes) => {
        console.log(globalVaultRes);
      }
    );
  }

  addApplicants(
    applicantId,
    applicationId,
    applicants,
    isApplicantPresent,
    jointAppVaultProducts,
    oldJointInSession
  ) {
    console.log("addApplicants");
    const {
      doDeleteApplicationSlot,
      doAddJointApplicants,
      continuehandler,
      doGetApplicationProperty,
      products,
      getActiveProduct,
    } = this.props;
    const { jointAccounts, inSessionGlobalVault } = this.state;
    const activeProduct = getActiveProduct(products);
    let jointType = STRINGS.JOIN_APPLICANT.IN_SESSION;

    if (isApplicantPresent !== "" && !isApplicantPresent) {
      jointType = STRINGS.JOIN_APPLICANT.OUT_OF_SESSION;
    }

    const jointApplicantRequests = [];
    for (const joint of applicants) {
      console.log("joint", joint);
      const request = {
        applicationId,
        jointApplicants: [],
      };
      const jointApplicants = [];
      const joinInfo = {
        joinInfo: {
          joinType: joint.applicantType,
          signer: true,
        },
        extra: {
          inSession: isApplicantPresent,
          email: joint.emailAddress,
          msisdn:
            AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
            joint.mobilePhone.replace(
              AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
              ""
            ),
        },
      };
      console.log("joinInfo", joinInfo);
      jointApplicants.push(joinInfo);
      request.jointApplicants = jointApplicants;
      if (joint.id) {
        // joint would already have an id if already added
        const currentJointInfo = jointAccounts.find(
          (item) => item.id === joint.id
        );
        console.log("currentJointInfo", currentJointInfo);
        if (
          joinInfo.extra.email !== currentJointInfo.extra.email ||
          joinInfo.extra.msisdn !== currentJointInfo.extra.msisdn ||
          joinInfo.joinInfo.joinType !== currentJointInfo.joinInfo.joinType
        ) {
          doDeleteApplicationSlot({ applicationId, slotId: joint.id });
          jointApplicantRequests.push(request);
        } else if (isApplicantPresent !== oldJointInSession) {
          // we can't update the in session flag so we need to delete and add
          doDeleteApplicationSlot({ applicationId, slotId: joint.id });
          jointApplicantRequests.push(request);
        }
      } else {
        jointApplicantRequests.push(request);
      }
      this.addToGlobalVault(
        joint,
        jointAppVaultProducts,
        jointType,
        applicantId,
        applicationId
      );
    }

    //delete joints removed
    for (const joint of jointAccounts) {
      const jointExist = applicants.find((item) => item.id === joint.id);
      if (!jointExist) {
        doDeleteApplicationSlot({ applicationId, slotId: joint.id });
      }
    }

    if (jointApplicantRequests.length > 0) {
      doAddJointApplicants(jointApplicantRequests, (res) => {
        console.log(res);
        doGetApplicationProperty(
          { applicationId: activeProduct.applicationId },
          (res) => {
            const jointAccounts = res.slots.filter(
              (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
            );
            if (res.slots.length > 1) {
              this.setState({
                jointAccounts,
                jointInSession: res.slots[1].extra.inSession, // all joints has the same value
              });
            }
            const notInvited = jointAccounts.find((item) => !item.inviteDetail);
            if (notInvited) {
              // user has to fix data issue
            } else {
              continuehandler(
                inSessionGlobalVault,
                AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
              );
            }
          }
        );
      });
    } else {
      const notInvited = applicants.find((item) => !item.inviteDetail);
      if (notInvited) {
        // user has to fix data issue
      } else {
        continuehandler(
          inSessionGlobalVault,
          AppConstants.APPLICATIONSTEP.JOINT_APPLICANT
        );
      }
    }
  }

  addJointAccount(applicationId, isApplicantPresent, request, joint) {
    console.log("addJointAccount", isApplicantPresent, request, joint);
    const {
      doAddApplicationSlot,
      doinviteApplicationSlot,
      doGetApplicationProperty,
    } = this.props;
    doAddApplicationSlot(request, (response) => {
      if (response.status === HTTP_STATUS.OK) {
        const body = {
          email: joint.emailAddress,
          msisdn:
            AppConstants.JOINT_APPLICANT.NUMBERPREFIX +
            joint.mobilePhone.replace(
              AppConstants.JOINT_APPLICANT.CONTACT_NO_PATTERN,
              ""
            ),
        };
        const inviteReq = {
          // eslint-disable-next-line object-shorthand
          body: body,
          // eslint-disable-next-line object-shorthand
          applicationId: applicationId,
          slotId: response.data.slots[0].id,
        };
        if (!isApplicantPresent) {
          console.log("inviting joint", inviteReq);
          doinviteApplicationSlot(inviteReq, (inviteRes) => {
            if (inviteRes.status !== HTTP_STATUS.OK) {
              doGetApplicationProperty({ applicationId }, (res) => {
                // wait to get reducer updated
              });
            }
          });
        } else {
          doGetApplicationProperty({ applicationId }, (res) => {
            // wait to get reducer updated
          });
        }
      } else {
        // eslint-disable-next-line spaced-comment
        //hasError = false;
      }
    });
  }

  render() {
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      continuehandler,
      doGetProductFromVault,
      products,
      doAddApplicationSlot,
      doinviteApplicationSlot,
      doGetApplicantType,
      applicantTypes,
      doAddGlobalVault,
      doGetApplicationProperty,
      getActiveProduct,
    } = this.props;
    const { jointAccounts, jointInSession } = this.state;
    return (
      <div>
        <JointApplicant
          continuehandler={continuehandler}
          handleBack={this.handleBack}
          handleContinue={this.handleContinue}
          getDataFromServer={getDataFromServer}
          steps={steps}
          activeStepID={activeStepID}
          doGetProductFromVault={doGetProductFromVault}
          doAddApplicationSlot={doAddApplicationSlot}
          doinviteApplicationSlot={doinviteApplicationSlot}
          doGetApplicantType={doGetApplicantType}
          doGetApplicationProperty={doGetApplicationProperty}
          doAddGlobalVault={doAddGlobalVault}
          products={products}
          applicantTypes={applicantTypes}
          getActiveProduct={getActiveProduct}
          jointAccounts={jointAccounts}
          jointInSession={jointInSession}
        />
      </div>
    );
  }
}

JointApplicantContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doAddApplicationSlot: PropTypes.func.isRequired,
  doinviteApplicationSlot: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  doGetProductFromVault: PropTypes.func.isRequired,
  doAddGlobalVault: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doDeleteApplicationSlot: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  globalVault: PropTypes.object,
  doToggleBackFlag: PropTypes.func.isRequired,
};
JointApplicantContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  applicantTypes: [],
  products: [],
  globalVault: {},
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicantType: (callback) => dispatch(getApplicantType(callback)),
    doAddGlobalVault: (callback) => dispatch(addGlobalVault(callback)),
    doGetApplicationProperty: (request, callback) =>
      dispatch(getApplicationProperty(request, callback)),
    doDeleteApplicationSlot: (request, callback) =>
      dispatch(deleteApplicationSlot(request, callback)),
    dosetInSessionJointApplicant: (request, callback) =>
      dispatch(setInSessionJointApplicant(request, callback)),
    doToggleBackFlag: (flag) => dispatch(toggleBackFlag(flag)),
    doAddJointApplicants: (request, callback) =>
      dispatch(addJointApplicants(request, callback)),
  };
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  applicantTypes: state.JoinApplicantReducer.applicantTypes,
  globalVault: state.JoinApplicantReducer.globalVault,
});

const JointApplicantWrapper = withApplicantHOC(JointApplicantContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JointApplicantWrapper);
