/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";

import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";

import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";

import "./FundMethod.scss";

class FundMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fundMethod: "",
      fundMethodError: "",
    };
  }

  handleChangeMethod = (event, fundMethod) => {
    const { doAddFundMethod } = this.props;
    event.preventDefault();
    doAddFundMethod(fundMethod);
    this.setState({
      fundMethod,
      fundMethodError: "",
    });
  };

  handleContinue = (e) => {
    e.preventDefault();
    const { handleContinue, fundMethod } = this.props;
    if (fundMethod) {
      handleContinue();
    } else {
      this.setState({
        fundMethodError: STRINGS.FUND_METHOD.FUND_ERROR_MSG,
      });
    }
  };

  render() {
    const { fundMethodError } = this.state;
    const { productName, handleBack, fundMethod, handleSkip } = this.props;
    const fundAccount = (
      <div className="[ fund-method-container ]">
        <Stepper steps={AppConstants.FUND_ACCOUNT.FUND_STEPS} active={1} />

        <div className="[ form-title ]">{STRINGS.FUND_METHOD.FUND_TITLE}</div>
        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.FUND_METHOD.FUND_SUB_TITLE}
            </div>

            <div className="[ row ] [ custom-radios ]">
              {AppConstants.FUND_ACCOUNT.FUND_METHODS.map((button) => {
                return (
                  <div className="[ col-6 ]" key={button.Id}>
                    <button
                      type="button"
                      className={
                        fundMethod === button.Name
                          ? "[ btn custom-radio-selected ]"
                          : "[ btn custom-radio-unselected ]"
                      }
                      onClick={(event) =>
                        this.handleChangeMethod(event, button.Name)
                      }
                    >
                      {button.Name}
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="[ error-msg ]">{fundMethodError}</div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
          <div className="[ row ] [ custom-radios ] [ pt-3 ]">
            <div className="[ col-lg-4 ] [ offset-lg-4 ]">
              <button
                type="button"
                className="[ btn ] [ w-100 ] [ skip-funding ]"
                onClick={handleSkip}
              >
                {STRINGS.FUND_METHOD.SKIP}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <AuthBody
        actionComponent={fundAccount}
        memberCreation
        showFundAccount
        productName={productName}
        handleBack={handleBack}
        fundMethod={fundMethod}
      />
    );
  }
}

FundMethod.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  productName: PropTypes.string,
  handleBack: PropTypes.func.isRequired,
  fundMethod: PropTypes.string,
  doAddFundMethod: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
};
FundMethod.defaultProps = {
  productName: "",
  fundMethod: "",
};

export default FundMethod;
