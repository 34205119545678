import React, { Component } from "react";
import PropTypes from "prop-types";

import PageBanner from "Components/Common/PageBanner/PageBanner";
import MarketPlaceLayout from "Components/MarketPlaceLayout/MarketPlaceLayout";

class MarketPlace extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      doUpdateVaultProductList,
      navigateToSignIn,
      navigateToRegister,
      doGetProductFromVault,
      doUpdateProductToVault,
    } = this.props;

    return (
      <div className="[ marketplace-container ]">
        <PageBanner
          handleSignIn={navigateToSignIn}
          handleRegister={navigateToRegister}
        />
        <main className="[ marketplace__body-container ] [ container-fluid ]">
          <MarketPlaceLayout
            doUpdateVaultProductList={doUpdateVaultProductList}
            doGetProductFromVault={doGetProductFromVault}
            doUpdateProductToVault={doUpdateProductToVault}
          />
        </main>
      </div>
    );
  }
}

MarketPlace.propTypes = {
  doUpdateVaultProductList: PropTypes.func,
  navigateToSignIn: PropTypes.func,
  navigateToRegister: PropTypes.func,
  doGetProductFromVault: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
};

MarketPlace.defaultProps = {
  navigateToSignIn: () => {},
  navigateToRegister: () => {},
  doUpdateVaultProductList: () => {},
  doGetProductFromVault: () => {},
  doUpdateProductToVault: () => {},
};

export default MarketPlace;
