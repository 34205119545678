import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import "./GettingStarted.scss";

class GettingStarted extends Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();

    this.state = {
      showModal: false,
      branchArray: [],

      branchDropdown: "",
      rendered: true,
      accountStatementType: "",

      accountStatementTypeError: "",
      branchDropdownError: "",
    };

    this.id = 0;
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.GETTINGSTARTED,
      (response) => {
        console.log(response);
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate() {
    const {
      gettingStartedBranchState,
      gettingStartedResidentState,
    } = this.props;
    const { rendered } = this.state;

    if (gettingStartedBranchState.jsonData.length > 0 && rendered) {
      this.updateArray(gettingStartedBranchState, gettingStartedResidentState);
    }
  }

  setFormData = (formData) => {
    this.setState({
      branchDropdown: formData?.branch === undefined ? "" : formData.branch,
      accountStatementType:
        formData.accountStatementType === undefined
          ? ""
          : formData.accountStatementType,
    });
  };

  verifyData = () => {
    let valid = true;
    const { branchDropdown, accountStatementType } = this.state;

    let error = checkErrors(
      branchDropdown,
      VALIDATIONS.GETTING_STARTED.selectBranch
    );
    if (error.length) {
      valid = false;
      this.setState({ branchDropdownError: error });
    }

    error = checkErrors(
      accountStatementType,
      VALIDATIONS.GETTING_STARTED.selectAccountStatementRequired
    );
    if (error.length) {
      valid = false;
      this.setState({ accountStatementTypeError: error });
    }

    if (valid) {
      const { submitRequest } = this.props;
      const body = {
        branch: branchDropdown,
        accountStatementType,
      };
      submitRequest(body);
    }
  };

  // Update array from reducer result
  updateArray = (branchObject, residentObject) => {
    this.setState({
      branchArray: branchObject.jsonData,
      rendered: false,
    });
    if (branchObject.jsonData.length > 0 && residentObject.length > 0) {
      this.setState({ rendered: false });
    }
  };

  changeBranchHandler = (event) => {
    this.setState({
      branchDropdown: event.target.value,
      branchDropdownError: "",
    });
  };

  handleChoice = (value) => {
    this.setState({
      accountStatementType: value,
      accountStatementTypeError: "",
    });
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  render() {
    const {
      accountStatementType,
      branchDropdownError,
      accountStatementTypeError,
      branchArray,
      branchDropdown,
      showModal,
      modalType,
      modal,
    } = this.state;

    const optionList = branchArray.map((data) => (
      <option
        className="[ dropdown__option ]"
        value={data.code}
        key={data.code}
      >
        {data.description}
      </option>
    ));

    const { steps, activeStepID } = this.props;

    const gettingStartedComponent = (
      <div className="[ getting-started-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.GETTING_STARTED.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.GETTING_STARTED.SELECT_BRANCH}
            </div>
            <select
              value={branchDropdown}
              className={
                branchDropdownError && branchDropdownError.length > 0
                  ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
                  : "[ form-control ] [ select__dropdown ]"
              }
              onChange={this.changeBranchHandler}
              id={STRINGS.GETTING_STARTED.BRANCH_DROPDOWN_ID}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.DROPDOWN_INITIAL_TEXT}
              </option>
              {optionList}
            </select>
            <div
              className={
                branchDropdownError.length > 0
                  ? "[ error-msg ]"
                  : "[ error-msg ]"
              }
            >
              {branchDropdownError ? branchDropdownError[0] : ""}
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {
                STRINGS.GETTING_STARTED
                  .SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT
              }
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    accountStatementType === "paper"
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="accountStatementType"
                  onClick={(event) => this.handleChoice("paper", event)}
                >
                  {STRINGS.GETTING_STARTED.PAPER}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="submit"
                  className={
                    accountStatementType === "email"
                      ? "[ btn custom-radio-selected ] "
                      : "[ btn custom-radio-unselected ] "
                  }
                  name="accountStatementType"
                  onClick={(event) => this.handleChoice("email", event)}
                >
                  {STRINGS.GETTING_STARTED.EMAIL}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]">{accountStatementTypeError}</div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-xl-6 ] [ offset-xl-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.verifyData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={gettingStartedComponent}
          memberCreation
          showNavigationBar={false}
        />
      </>
    );
  }
}
GettingStarted.propTypes = {
  submitRequest: PropTypes.func,
  gettingStartedBranchState: PropTypes.objectOf(PropTypes.any),
  gettingStartedResidentState: PropTypes.objectOf(PropTypes.any),
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};
GettingStarted.defaultProps = {
  submitRequest: () => {},
  gettingStartedBranchState: {},
  gettingStartedResidentState: {},
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  gettingStartedBranchState: state.GettingStartedBranchReducer,
  gettingStartedResidentState: state.GettingStartedResidentReducer,
});

export default connect(mapStateToProps)(GettingStarted);
