import BaseApiRequest from "./BaseApiRequest";
import { APPLICATION_SLOT_FILL_URL } from "../ApiUrls";
import { METHOD } from "../Constants";
import { formURL } from "Utils/CommonUtilities";

export default class FillApplicationSlot extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    applicantId: "",
  };

  url = APPLICATION_SLOT_FILL_URL;

  constructor(reqData) {
    super();
    console.log(reqData);

    const { slotId, applicantId, applicationId } = reqData;
    this.body.applicantId = applicantId;

    let paramList = [applicationId, slotId];
    this.url = formURL(this.url, paramList);
    console.log(this.url);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
