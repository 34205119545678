import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import SecurityVehicle from "./SecurityVehicle";
import { getLoanLoanSecurity } from "../LoanDetails/Actions/LoanDetailsAction";
import {
  addLoanVehicle,
  updateLoanVehicle,
} from "../Asset/Actions/AssetActions";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";

class SecurityVehicleContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      vehicle: {},
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      continuehandler,
      actionBack,
      handleBack,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (activeProduct.category !== AppConstants.LOAN_CATEGORY.AUTO) {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE);
      }
    } else {
      this.initialize(activeProduct);
    }
  }

  initialize(activeProduct) {
    const { doGetLoanLoanSecurity } = this.props;
    doGetLoanLoanSecurity(activeProduct.applicationId, (res) => {
      console.log(res);
      const vehicle = res.data.find(
        (item) => item.securityType === AppConstants.SECURITY_TYPE.VEHICLE
      );
      console.log("vehicle", vehicle);
      if (vehicle) {
        if (vehicle) {
          const arr = vehicle.securityDescription.split(" ");
          vehicle.make = arr[0];
          vehicle.model = arr[1];
          vehicle.year = arr[2];
          vehicle.serial = activeProduct.vehicle?.serial;
        }
        this.setState({
          vehicle,
        });
      }
    });
  }

  showModal = () => {
    this.setState({});
  };

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      products,
      getActiveProduct,
      continuehandler,
      doAddLoanVehicle,
      doUpdateLoanVehicle,
      doUpdateProductToVault,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    //TODO move this vault update inside doxim call
    products[0]["vehicle"] = data;
    doUpdateProductToVault(products, () => {});
    if (data.securityId) {
      doUpdateLoanVehicle(
        activeProduct.applicationId,
        data.securityId,
        data,
        (res) => {
          console.log(res);
          continuehandler(null, AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE);
        }
      );
    } else {
      doAddLoanVehicle(activeProduct.applicationId, data, (res) => {
        console.log(res);
        continuehandler(null, AppConstants.APPLICATIONSTEP.SECURITY_VEHICLE);
      });
    }
  };

  togglePopupModal = () => {
    this.setState({});
  };

  render() {
    const { choice, vehicle } = this.state;
    const { getDataFromServer, handleBack, steps, activeStepID } = this.props;

    return (
      <>
        <SecurityVehicle
          handleContinue={this.handleContinue}
          getDataFromServer={getDataFromServer}
          choice={choice}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          vehicle={vehicle}
        />
      </>
    );
  }
}

SecurityVehicleContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

SecurityVehicleContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  actionBack: state.ApplicationReducer.actionBack,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetLoanLoanSecurity: (applicationId, callback) =>
      dispatch(getLoanLoanSecurity(applicationId, callback)),
    doAddLoanVehicle: (applicationId, payLoad, callback) =>
      dispatch(addLoanVehicle(applicationId, payLoad, callback)),
    doUpdateLoanVehicle: (applicationId, securityId, payLoad, callback) =>
      dispatch(updateLoanVehicle(applicationId, securityId, payLoad, callback)),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

const SecurityVehicleWrapper = withApplicantHOC(SecurityVehicleContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityVehicleWrapper);
