import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  success: "",
  error: "",
};

const ExistingUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXISTINGUSER_RESPONSE:
      return {
        ...state,
        success: action.success,
        error: "",
      };
    case actionTypes.EXISTINGUSER_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
      };
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default ExistingUserReducer;
