import React, { Component } from "react";
import PropTypes from "prop-types";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import AppConstants from "Constants/AppConstants";
import OverdraftProtection from "./OverdraftProtection";

class OverdraftProtectionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  submitRequest = (body) => {
    const { continuehandler } = this.props;
    continuehandler(body, AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION);
  };

  render() {
    const {
      history,
      showVault,
      getDataFromServer,
      steps,
      activeStepID,
      handleBack,
    } = this.props;

    return (
      <OverdraftProtection
        showVault={showVault}
        history={history}
        submitRequest={this.submitRequest}
        getDataFromServer={getDataFromServer}
        steps={steps}
        activeStepID={activeStepID}
        handleBack={() =>
          handleBack(AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION)
        }
      />
    );
  }
}

OverdraftProtectionContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  showVault: PropTypes.bool,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  handleBack: PropTypes.func.isRequired,
};

OverdraftProtectionContainer.defaultProps = {
  history: {},
  showVault: false,
  steps: [],
  activeStepID: -1,
};

const OverdraftProtectionWrapper = withApplicantHOC(
  OverdraftProtectionContainer
);

export default OverdraftProtectionWrapper;
