import React, { Component } from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import AppConstants from "Constants/AppConstants";
import NumberFormat from "react-number-format";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import Stepper from "Components/Common/Stepper/Stepper";
import { connect } from "react-redux";

import "./SpousalInfo.scss";

class SpousalInfo extends Component {
  constructor(props) {
    super(props);
    // const { fName, lName, dOB, sin } = this.props;
    this.state = {
      choice: true,
      errors: {
        firstNameErrorList: [],
        lastNameErrorList: [],
        dateOfBirthErrorList: [],
        sinErrorList: "",
      },
      editMode: true,
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      sin: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(AppConstants.APPLICATIONSTEP.SPOUSAL_INFO, (response) => {
      this.setFormData(response);
    });
  }

  setFormData = (response) => {
    this.setState({
      firstName: !response.spouseFirstName ? "" : response.spouseFirstName,
      lastName: !response.spouseLastName ? "" : response.spouseLastName,
      dateOfBirth: !response.spouseDob ? "" : response.spouseDob,
      sin: !response.spouseSin ? "" : response.spouseSin,
      choice: !response.isSpousal ? false : response.isSpousal,
    });
  };

  handleChange = (choice) => {
    this.setState({ choice });
    this.hideErrorORToggle();
  };

  hideErrorORToggle = () => {
    const { choice } = this.state;
    if (!choice) {
      this.setState({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        sin: "",
      });
    }
    this.setState({
      errors: {
        firstNameErrorList: [],
        lastNameErrorList: [],
        dateOfBirthErrorList: [],
        sinErrorList: "",
      },
    });
  };

  changeSin = (event) => {
    const { errors } = this.state;
    this.setState({
      sin: event.value,
      errors: {
        ...errors,
        sinErrorList: "",
      },
    });
  };

  onChangeHandler = (event) => {
    const { errors } = this.state;
    const inputField = event?.target.name;
    this.setState(
      {
        [inputField]: event?.target.value,
        errors: {
          ...errors,
          [`${event?.target.name}ErrorList`]: [],
        },
      },
      () => {
        if (inputField === STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMEREF) {
          this.textInput.focus();
        }
      }
    );
  };

  handleSubmit = () => {
    const { submitData } = this.props;
    const { firstName, lastName, dateOfBirth, sin, choice } = this.state;
    const spousalInfo = {
      spouseFirstName: choice ? firstName : "",
      spouseLastName: choice ? lastName : "",
      spouseDob: choice ? dateOfBirth : "",
      spouseSin: choice ? sin : "",
      isSpousal: choice,
    };

    if (this.verifySpouseData()) submitData(spousalInfo);
  };

  verifySpouseData = () => {
    const {
      choice,
      firstName,
      lastName,
      dateOfBirth,
      sin,
      errors,
    } = this.state;

    if (!choice) {
      return true;
    }
    {
      const errorLength = 0;
      let errorList = { ...errors };
      let valid = true;

      const firstNameErrorList = checkErrors(
        firstName,
        VALIDATIONS.ABOUTYOU.FIRSTNAME
      );

      errorList = { ...errorList, firstNameErrorList };
      if (firstNameErrorList && firstNameErrorList.length > 0) {
        valid = false;
      }

      const lastNameErrorList = checkErrors(
        lastName,
        VALIDATIONS.ABOUTYOU.LASTNAME
      );
      errorList = { ...errorList, lastNameErrorList };
      if (lastNameErrorList && lastNameErrorList.length > 0) {
        valid = false;
      }

      const dateOfBirthErrorList = checkErrors(
        dateOfBirth,
        VALIDATIONS.ABOUTYOU.DOB
      );
      errorList = { ...errorList, dateOfBirthErrorList };
      if (dateOfBirthErrorList && dateOfBirthErrorList.length > 0) {
        valid = false;
      }
      const sinErrorList = checkErrors(
        sin,
        VALIDATIONS.EMPLOYMENT_AND_INCOME.sin
      );
      errorList = { ...errorList, sinErrorList };
      if (sinErrorList && sinErrorList.length > 0) {
        valid = false;
      }

      if (errorLength > 0) {
        valid = false;
      }
      this.setState({
        errors: errorList,
      });
      return valid;
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.SPOUSAL_INFO);
  };

  render() {
    const {
      choice,
      errors,
      editMode,
      firstName,
      lastName,
      dateOfBirth,
      sin,
    } = this.state;

    const { steps, activeStepID } = this.props;

    const spousalInfo = (
      <div className="[ spousal_info_container spousal-info-field-container] ">
        <Stepper steps={steps} active={activeStepID} />
        <div className="[ form-title ]">{STRINGS.SPOUSAL_INFO.SCREENTITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.SPOUSAL_INFO.SPOUSE_QUI}
            </div>
          </div>
          <div className="[ row ] [ custom-radios ]">
            <div className="[ col-6 ]">
              <button
                type="button"
                className={
                  choice !== "" && choice
                    ? "[ btn custom-radio-selected ]"
                    : "[ btn custom-radio-unselected ]"
                }
                onClick={() => this.handleChange(true)}
              >
                {STRINGS.COMMON.CHOICE.YES}
              </button>
            </div>

            <div className="[ col-6 ]">
              <button
                type="button"
                className={
                  choice !== "" && choice === false
                    ? "[ btn custom-radio-selected ]"
                    : "[ btn custom-radio-unselected ]"
                }
                onClick={() => this.handleChange(false)}
              >
                {STRINGS.COMMON.CHOICE.NO}
              </button>
            </div>
          </div>

          {choice ? (
            <div className="[ drawer-content-container ] ">
              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL}
                </div>
                <input
                  type="text"
                  name="firstName"
                  className={
                    errors?.firstNameErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  placeholder={
                    STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL
                  }
                  value={firstName}
                  onChange={this.onChangeHandler}
                  readOnly={!editMode}
                  ref={(input) => {
                    this.textInput = input;
                  }}
                />
                <div className="[ error-msg ] ">
                  {errors.firstNameErrorList.length > 0
                    ? errors?.firstNameErrorList[0]
                    : ""}
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
                </div>
                <input
                  type="text"
                  name="lastName"
                  className={
                    errors?.lastNameErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  placeholder={
                    STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL
                  }
                  value={lastName}
                  onChange={this.onChangeHandler}
                  readOnly={!editMode}
                />
                <div className="[ error-msg ] ">
                  {errors.lastNameErrorList.length > 0
                    ? errors?.lastNameErrorList[0]
                    : ""}
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL}
                </div>
                <NumberFormat
                  format={AppConstants.DATEOFBIRTH.FORMAT}
                  className={
                    errors?.dateOfBirthErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  type="text"
                  name="dateOfBirth"
                  value={dateOfBirth}
                  mask={AppConstants.DATEOFBIRTH.MASK}
                  placeholder={`${STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBPLACEHOLDER}`}
                  onChange={(event) => this.onChangeHandler(event)}
                  readOnly={!editMode}
                />
                <div className="[ error-msg ] ">
                  {errors.dateOfBirthErrorList.length > 0
                    ? errors?.dateOfBirthErrorList[0]
                    : ""}
                </div>
              </div>

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.EMPLOYMENT_AND_INCOME.FORM.SIN}
                </div>
                <NumberFormat
                  format={AppConstants.EMPLOYMENTANDINCOME.TIN_FORMATE}
                  className={
                    errors?.sinErrorList.length > 0
                      ? "[ form-control form-control-sm ] [ invalid-input ]"
                      : "[ form-control form-control-sm ]"
                  }
                  type="text"
                  name="sin"
                  value={sin}
                  placeholder={`${STRINGS.EMPLOYMENT_AND_INCOME.FORM.PLACEHOLDER.SINPLAACEHOLDER}`}
                  onValueChange={(value) => {
                    this.changeSin(value);
                  }}
                />
                <div className="[ error-msg ] ">
                  {errors.sinErrorList.length > 0
                    ? errors?.sinErrorList[0]
                    : ""}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleSubmit}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={spousalInfo}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

SpousalInfo.propTypes = {
  submitData: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

SpousalInfo.defaultProps = {
  steps: [],
  activeStepID: -1,
};

export default connect()(SpousalInfo);
