import axios from "axios";
import getErrorMessageFromErrorCode from "Constants/ServerErrors";
import { ERROR_CODE, METHOD, HTTP_STATUS } from "Communication/Constants";

// constants
import {
  URLS,
  LOGOUT_URL,
  PROCESS_URL,
  GENERATE_ACCESS_TOKEN,
} from "./ApiUrls";

// error lookup
import errorLookup from "./ErrorLookup";

import BaseApi from "./BaseApi";
import { showError } from "../Utils/LogicUtilities";
import {
  EXPIRES_AT,
  REFRESH_EXPIRES_AT,
  REFRESH_TRIGGER_SECONDS,
  TOKEN_KEY,
} from "./Constants";
import RefreshTokenRequest from "./ApiClasses/RefreshTokenRequest";
import { refreshToken } from "../Components/Signin/Actions/SigninAction";

export default class Axios extends BaseApi {
  constructor(showErrorModal, generateAccessToken, goToLoginPage) {
    super();
    this.showErrorModal = showErrorModal;
    this.generateAccessToken = generateAccessToken;
    this.goToLoginPage = goToLoginPage;
    this.axios = axios.create({
      baseURL: URLS.BASE_URL,
    });

    const getNewToken = async (currentAuth) => {
      const response = await fetch(GENERATE_ACCESS_TOKEN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: currentAuth,
        },
        body: JSON.stringify({
          token: sessionStorage.getItem(TOKEN_KEY),
        }),
      });
      const json = await response.json();
      return json;
    };

    this.requestInterceptor = this.axios.interceptors.request.use(
      async (request) => {
        console.log("request interceptor", request);
        const currentAuth = "Bearer " + sessionStorage.getItem(TOKEN_KEY);

        if (!request.headers.Authorization) {
          return request;
        } else if (request.headers.Authorization !== currentAuth) {
          request.headers.Authorization = currentAuth;
          return request;
        } else {
          const expiresAt = sessionStorage.getItem(EXPIRES_AT);
          const refreshExpiresAt = sessionStorage.getItem(REFRESH_EXPIRES_AT);
          const expireTime = Number(expiresAt);
          const refreshExpireTime = Number(refreshExpiresAt);
          console.log("expireTime", expireTime);
          console.log("refreshExpireTime", refreshExpireTime);
          const d = new Date().getTime();
          const currentTime = Math.floor(d / 1000);
          console.log("currentTime", currentTime);

          const timeToRefresh = expireTime - currentTime;
          console.log("timeToRefresh", timeToRefresh);

          if (
            !window.refreshingToken &&
            REFRESH_TRIGGER_SECONDS > timeToRefresh
          ) {
            if (currentTime < refreshExpireTime) {
              console.log("refreshing token");
              window.refreshingToken = true;
              try {
                let res = await fetch(GENERATE_ACCESS_TOKEN, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: currentAuth,
                  },
                  body: JSON.stringify({
                    token: sessionStorage.getItem(TOKEN_KEY),
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    console.log(res);
                    window.refreshingToken = false;
                    if (res.token) {
                      sessionStorage.setItem(TOKEN_KEY, res.token);
                      sessionStorage.setItem(EXPIRES_AT, res.expiresAt);
                      sessionStorage.setItem(
                        REFRESH_EXPIRES_AT,
                        res.refreshExpiresAt
                      );
                      request.headers.Authorization = "Bearer " + res.token;
                      console.log(
                        "updated header",
                        request.headers.Authorization
                      );
                      return request;
                    } else if (
                      res.code === ERROR_CODE.NO_ACTIVITY ||
                      res.code === ERROR_CODE.USER_DUPLICATE_LOGIN
                    ) {
                      this.showErrorModal(
                        res.code,
                        getErrorMessageFromErrorCode(res.code, res.data)
                      );
                      return Promise.reject(res);
                    }
                  });
              } catch (error) {
                window.refreshingToken = false;
              }
              return request;
            } else {
              console.log(
                "no activity",
                getErrorMessageFromErrorCode(ERROR_CODE.NO_ACTIVITY, {})
              );
              this.showErrorModal(
                ERROR_CODE.NO_ACTIVITY,
                getErrorMessageFromErrorCode(ERROR_CODE.NO_ACTIVITY, {})
              );
              return request;
              //return Promise.reject({});
            }
          } else {
            return request;
          }
        }
        console.log("exiting interceptor");
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.responseInterceptor = this.axios.interceptors.response.use(
      (res) => {
        console.log("response interceptor", res);
        if (res.data.error) {
          const errorObj = errorLookup(res.data.error);
          this.showErrorModal(errorObj.code, errorObj.message);
          return errorObj;
        }
        res.isError = false;
        return res;
      },

      (error) => {
        if (error.response?.config.url === PROCESS_URL) {
          return Promise.reject(error);
        }
        if (error.response) {
          const originalReq = error.config;

          if (
            error.response.status === HTTP_STATUS.BAD_REQUEST ||
            error.response.status === HTTP_STATUS.UNPROCESSABLE_ENTITY
          ) {
            console.log(error);
            if (error.response.config.url.includes("refresh")) {
              // do nothing
              // TODO need to handle this
            } else {
              if (showError(error)) {
                this.showErrorModal(
                  error.response.data.code,
                  getErrorMessageFromErrorCode(
                    error.response.data.code,
                    error.response.data
                  )
                );
              }
            }
          } else if (
            error.response.status === HTTP_STATUS.INTERNAL_SERVER_ERROR
          ) {
            /**
             * DATA_NOT_AVAILABLE: When user scan document it takes time to get data back from server
             * DOCS_STILL_CREATING: When sending docs but are still being created
             * So we make a call one after another until we receive some data from backend, we do not show error modal for each call.
             */
            if (
              ERROR_CODE.DATA_NOT_AVAILABLE !== error.response.data.code &&
              ERROR_CODE.DOCS_STILL_CREATING !== error.response.data.code
            ) {
              if (showError(error)) {
                this.showErrorModal(
                  error.response.data.code,
                  getErrorMessageFromErrorCode(
                    HTTP_STATUS.INTERNAL_SERVER_ERROR,
                    error.response.data
                  )
                );
              }
            }
          } else if (error.response.status === HTTP_STATUS.NOT_FOUND) {
            /**
             * Developer information print in log
             * User pop up should have some meaningfull message. add in server errors
             */
            console.log(`Current URL: ${error.response.config.url}.`);
            this.showErrorModal(
              HTTP_STATUS.NOT_FOUND,
              getErrorMessageFromErrorCode(HTTP_STATUS.NOT_FOUND.toString(), {})
            );
          } else if (
            error.response.status === HTTP_STATUS.FORBIDDEN ||
            error.response.status === HTTP_STATUS.UNAUTHORISED
          ) {
            if (error.response.config.url === LOGOUT_URL) {
              this.goToLoginPage(true);
            } else {
              //TODO show session expired
              this.goToLoginPage(true);
            }
          } else {
            if (showError(error)) {
              this.showErrorModal(
                error.response.data.code,
                getErrorMessageFromErrorCode(
                  error.response.data.code,
                  error.response.data
                )
              );
            }
          }
        } else {
          this.showErrorModal(0, error.toString());
        }
        return Promise.reject(error);
      }
    );
  }

  callApi(method, url, requestBody, accessToken) {
    const config = {
      headers: {
        "content-type": "application/json",
      },
      method,
      url,
      // timeout: TIMEOUT_DURATION,
    };
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config[method !== METHOD.HTTP.GET ? "data" : "params"] = requestBody;
    return this.axios(config);
  }

  destructor = () => {
    this.axios.interceptors.response.eject(this.responseInterceptor);
  };
}
