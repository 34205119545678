import FIInformation from "Constants/FIInformation";

const MODALMESSAGES = {
  REGISTRATION: {
    TERMSERROR: "Please accept the terms and conditions.",
    USEREXIST: "Profile already exists. Please sign in to continue.",
  },
  VERIFICATIONCODE: {
    SUCCESSMSG: "Verification code sent successfully to your mobile device",
    ERRORMSG: "Something went wrong..",
    RESENDCODEERROR: "Email and mobile number not found..",
  },
  SIGNIN: {
    SUCCESSMSG: "Success! User exists",
    FAILURE:
      "There was an issue while adding your selection to your cart. <br/><br/> Please try adding it again from the marketplace.",
    SAMEPRODUCTS:
      "We notice that you have more than one of the same product application. <br/><br/> Please review your cart if this was unintended.",
    SOMEPRODUCTS:
      "We notice that you have an application in progress. <br/><br/> Please continue or remove this before beginning any new applications.",
  },
  FORGOT_PASSWORD: {
    ERRORMSG:
      "Something didn't work and the vrification code failed to send. Please try again later.",
    SUCCESSMSG:
      "We've sent a verification code to your registered mobile device.",
    SUCCESS_MAIL: "We've sent you an email to your registered email address.",
  },
  SETPASSWORD: {
    SUCCESSMSG: "Your marketplace profile has been created successfully.",
    ERRORMSG: "Please try again.",
  },
  RECOVERPASSWORD: {
    SUCCESSMSG: "Your password has been updated successfully.",
    ERRORMSG: "Please try again.",
  },
  TERMS_AND_CONDITIONS: {
    INFO1: `Welcome... before you can begin your _PRODUCT_ application, you first need to be a member of ${FIInformation.FI_NAME}.`,
    INFO2: `These first few screens are just about you in general, in order to qualify your membership. It only takes a minute.`,
  },
  GETTINGSTARTED: {
    SUCCESSMSG: "SUCCESS",
  },
  COMPLIANCE: {
    SUCCESSMSG: "Application updated successfully.",
    ERRORMSG: "Failed to update application.",
  },
  CONFIRM_PAGE: {
    SUCCESS_TITLE: "Account created!",
    SUCCESS_JOINT_TITLE: "Joint Application",
    SUCCESS_MSG:
      "We are thrilled to set up your new account. An email confirmation has been sent to you for your records.",
    SUCCESS_JOINT_MSG: "Your joint account registration is complete. ",
  },
  ON_REVIEW: {
    TITLE: "Your application is in review.",
    MSG:
      "We are thrilled to set up your new account. An email confirmation has been sent to you for your records.",
  },
  EXISTINGMEMBER: {
    SUCCESS_TITLE: "Existing Customer",
    SUCCESS_MSG:
      "It appears you are already an existing customer. Go back and try clicking 'Yes'.",
  },
  FUNDING_AMOUNT: {
    FUND_PRODUCT: "Congratulations on your successful application! ",
    FUND_PRODUCT_POPUP_MESSAGE:
      "Let's continue with setting up your $ product.",
  },
  SIGNATURE: {
    DOC_NOT_CREATED_TITLE: "e-Sign Documents",
    DOC_NOT_CREATED_MESSAGE:
      "Your documents are still being created. Please try again later.",
    SUCCESS_TITLE: "Congratulations _NAME_!",
    SUCCESS_MSG:
      "Congratulations on your new account! An email confirmation has been sent to you for your records. ",
    AOS_CONGRATS_MESSAGE:
      "Your application is complete. An email confirmation has been sent to you for your records.",
    LOS_CONGRATS_MESSAGE:
      "We are thrilled to give you this new loan. You will be able to access your funds within 24 hrs. An email confirmation has been sent to you for your records.",
  },
  JOINT_APPLICANT_STATUS: {
    PENDING_OS_TITLE: "Applicants: Pending",
    PENDING_OS_MESSAGE:
      "It appears your joint applicant(s) haven't completed their portion of the application yet.",
    JOINT_START_IN_SESSION:
      "Now it's time for your joint applicant with the email address _EMAIL_ to complete their portion of the application.",
  },
  DISBURSEMENT: {
    CONGRATS_TITLE: "Congratulations _NAME_!",
    CONGRATS_MESSAGE:
      "We are thrilled to give you this new loan. You will be able to access your funds within 24 hrs. An email confirmation has been sent to you for your records.",
  },
  REPAYMENT: {
    CONGRATS_TITLE: "Congratulations _NAME_!",
    CONGRATS_MESSAGE:
      "We are thrilled to give you this new loan. You will be able to access your funds within 24 hrs. An email confirmation has been sent to you for your records.",
  },
  LOAN_SUMMARY: {
    LOAN: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE:
        "We are thrilled to give you this new loan. You will be able to access your funds within 24 hrs. An email confirmation has been sent to you for your records.",
    },
    REVIEW: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE: `Your loan is currently being reviewed. An email has been sent to you for your records. Someone from ${FIInformation.FI_NAME} will reach out to you soon.`,
    },
    PRE_QUALIFY: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE: `We are thrilled to pre-qualify you for a mortgage. An email has been sent to you for your records. Someone from ${FIInformation.FI_NAME} will reach out to you soon.`,
    },
    LOC: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE: `We are thrilled to approve you for your line of credit. An email has been sent to you for your records. Someone from ${FIInformation.FI_NAME} will reach out to you soon.`,
    },
    HELOC: {
      CONGRATS_TITLE: "Congratulations _NAME_!",
      CONGRATS_MESSAGE: `We are thrilled to approve you for your home equity line of credit. An email has been sent to you for your records. Someone from ${FIInformation.FI_NAME} will reach out to you soon.`,
    },
  },
  HELOC: {
    CANCEL_TITLE: "You do not qualify for HELOC.",
    CANCEL_DESCRIPTION:
      "Please apply for a line of credit or a personal loan instead.",
  },
};
export default MODALMESSAGES;
