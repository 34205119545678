/* eslint-disable react/prefer-stateless-function */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import AppConstants from "Constants/AppConstants";

import { getCountryList } from "../EmploymentAndIncome/Actions/EmploymentAndIncomeAction";
import { doGetProvinceList } from "../AboutYou/Actions/AboutYouActions";
import {
  getBeneficiaryTypeList,
  getBeneficiaryRelationList,
  addBeneficiaries,
  getBeneficiaries,
  deleteBeneficiary,
  editBeneficiary,
} from "./Action/BeneficiariesAction";

import Beneficiaries from "./Beneficiaries";

class BeneficiariesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      steps,
      activeStepID,
      getDataFromServer,
      handleBack,
      provinceList,
      countryList,
      doGetCountryList,
      doGetProvinceData,
      doGetBeneficiaryTypeList,
      beneficiaryTypeList,
      doGetBeneficiaryRelationList,
      beneficiaryRelationList,
      continuehandler,
      doAddBeneficiaries,
      vaultProductList,
      getActiveProduct,
      doGetBeneficiaries,
      doDeleteBeneficiary,
      doEditBeneficiary,
    } = this.props;

    return (
      <Beneficiaries
        steps={steps}
        handleBack={() =>
          handleBack(AppConstants.APPLICATIONSTEP.BENEFICIARIES)
        }
        activeStepID={activeStepID}
        getDataFromServer={getDataFromServer}
        provinceList={provinceList}
        countryList={countryList}
        doGetCountryList={doGetCountryList}
        doGetProvinceData={doGetProvinceData}
        doGetBeneficiaryTypeList={doGetBeneficiaryTypeList}
        beneficiaryTypeList={beneficiaryTypeList}
        doGetBeneficiaryRelationList={doGetBeneficiaryRelationList}
        beneficiaryRelationList={beneficiaryRelationList}
        continuehandler={continuehandler}
        doAddBeneficiaries={doAddBeneficiaries}
        vaultProductList={vaultProductList}
        getActiveProduct={getActiveProduct}
        doGetBeneficiaries={doGetBeneficiaries}
        doDeleteBeneficiary={doDeleteBeneficiary}
        doEditBeneficiary={doEditBeneficiary}
      />
    );
  }
}

BeneficiariesContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  continuehandler: PropTypes.func.isRequired,
  provinceList: PropTypes.arrayOf(PropTypes.object),
  countryList: PropTypes.arrayOf(PropTypes.object),
  doGetCountryList: PropTypes.func.isRequired,
  doGetProvinceData: PropTypes.func.isRequired,
  doGetBeneficiaryTypeList: PropTypes.func.isRequired,
  beneficiaryTypeList: PropTypes.arrayOf(PropTypes.object),
  doGetBeneficiaryRelationList: PropTypes.func.isRequired,
  beneficiaryRelationList: PropTypes.arrayOf(PropTypes.object),
  doAddBeneficiaries: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  vaultProductList: PropTypes.arrayOf(PropTypes.any),
  doGetBeneficiaries: PropTypes.func.isRequired,
  doDeleteBeneficiary: PropTypes.func.isRequired,
  doEditBeneficiary: PropTypes.func.isRequired,
};

BeneficiariesContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  countryList: [],
  provinceList: [],
  beneficiaryTypeList: [],
  beneficiaryRelationList: [],
  vaultProductList: [],
};

const mapStateToProps = (state) => ({
  provinceList: state.AboutYouReducer.provinceList,
  countryList: state.EmploymentAndIncomeReducer.countryList,
  vaultProductList: state.VaultReducer.vaultProductList,
  beneficiaryTypeList: state.BeneficiariesReducer.beneficiaryTypeList,
  beneficiaryRelationList: state.BeneficiariesReducer.beneficiaryRelationList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetCountryList: () => dispatch(getCountryList()),
    doGetProvinceData: () => dispatch(doGetProvinceList()),
    doGetBeneficiaryTypeList: () => dispatch(getBeneficiaryTypeList()),
    doGetBeneficiaryRelationList: () => dispatch(getBeneficiaryRelationList()),
    doAddBeneficiaries: (
      requestBody,
      applicationId,
      contractProductId,
      callback
    ) =>
      dispatch(
        addBeneficiaries(
          requestBody,
          applicationId,
          contractProductId,
          callback
        )
      ),
    doGetBeneficiaries: (applicationId, contractProductId, callback) =>
      dispatch(getBeneficiaries(applicationId, contractProductId, callback)),
    doDeleteBeneficiary: (
      applicationId,
      contractProductId,
      beneficiaryId,
      callback
    ) =>
      dispatch(
        deleteBeneficiary(
          applicationId,
          contractProductId,
          beneficiaryId,
          callback
        )
      ),
    doEditBeneficiary: (
      request,
      applicationId,
      contractProductId,
      beneficiaryId,
      callback
    ) =>
      dispatch(
        editBeneficiary(
          request,
          applicationId,
          contractProductId,
          beneficiaryId,
          callback
        )
      ),
  };
};

const BeneficiariesWrapper = withApplicantHOC(BeneficiariesContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BeneficiariesWrapper);
