import AppConstants from "Constants/AppConstants";

export const togglePassword = (toggleFlag) => {
  return toggleFlag ? "text" : "password";
};

export const usernameCredentials = (username) => {
  if (!Number.isNaN(username)) {
    const pattern = AppConstants.SIGNIN.USERNAME_PATTERN;

    const checkpattern = new RegExp(pattern).test(username);
    let credentialNumber;

    if (checkpattern) {
      credentialNumber = AppConstants.SIGNIN.NUMBERPREFIX + username;
    } else {
      credentialNumber = username;
    }
    return credentialNumber;
  }
  return username;
};

export const removeLinkTag = () => {
  if (window.IDMetricsCaptureFramework === undefined) {
    setTimeout(removeLinkTag, 50); // wait 50 millisecnds then recheck
    return;
  }
  const instntDiv = document.getElementById("instnt-form-generator");
  for (let i = 0; i < instntDiv.children.length; i += 1) {
    const linkNode =
      instntDiv.children[i].nodeName === AppConstants.NODE_NAME
        ? instntDiv.children[i]
        : null;
    if (linkNode) {
      instntDiv.removeChild(linkNode);
    }
  }
};
/**
 *
 * @param {array} searchInArray represents array
 * to be passed for searching
 * @param {string} searchForCode represents code
 * using which we can search for it's respective
 * description value
 */
export const getDescriptionFromArray = (searchInArray, searchForCode) => {
  const result = searchInArray.find((search) => search.code === searchForCode);
  return result?.description;
};

/**
 * Name: isDeviceMobile
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["Android","webOS","iPhone","iPad","iPod","BlackBerry","Windows Phone"]
 */
export const isDeviceMobile = () => {
  if (
    window.navigator.userAgent.match(/Android/i) ||
    window.navigator.userAgent.match(/webOS/i) ||
    window.navigator.userAgent.match(/iPhone/i) ||
    window.navigator.userAgent.match(/iPod/i) ||
    window.navigator.userAgent.match(/BlackBerry/i) ||
    window.navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};

/**
 * Name: isDeviceTablet
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["iPad"]
 */
export const isDeviceTablet = () => {
  if (window.navigator.userAgent.match(/iPad/i)) {
    return true;
  }
  return false;
};

/**
 * Name: isDeviceMobile
 * Description: Checking for device type using JS window object
 *              Returns true only if the device is mobile or tablet.
 * Device List: ["Android","webOS","iPhone","iPad","iPod","BlackBerry","Windows Phone"]
 */
export const isScanningAllowed = () => {
  if (isDeviceMobile() || isDeviceTablet()) {
    return true;
  }
  return false;
};

/**
 * Name: convertBase64ImageToString
 * @param {*} dataUri
 * Description: Removes prefix from the base 64 scanned images.
 */
export const convertBase64ImageToString = (dataUri) => {
  let byteString;
  if (dataUri.split(",")[0].indexOf("base64") <= 0)
    byteString = atob(dataUri.split(",")[1]);
  else byteString = unescape(dataUri.split(",")[1]);

  return byteString;
};

export const getProductCategoryList = (products) => {
  return products.map((item, index) => {
    return {
      code: index + 1,
      description: item.id,
      checked: true,
    };
  });
};

export const getVaultCardProductList = (productList, vaultProductList) => {
  const vaultCardProductList = [];
  let list = [];

  productList.forEach((element) => {
    const { products } = { ...element };
    if (list.length !== 0) {
      list = list.concat(products);
    } else {
      list = products;
    }
  });

  vaultProductList.forEach((product) => {
    let card = {};
    const index = list.findIndex(
      (item) => item.productId === product.productId
    );
    if (index !== -1) {
      card = { ...list[index] };
      card.productIndex = product.productIndex;
      vaultCardProductList.push(card);
    }
  });
  return vaultCardProductList;
};

export const getProduct = (productList, vaultProductList) => {
  let list = [];
  let product = { productName: "" };
  if (vaultProductList.length === 0) return product;
  const productobject = vaultProductList[0];
  productList.forEach((element) => {
    const { products } = { ...element };
    if (list.length !== 0) {
      list = list.concat(products);
    } else {
      list = products;
    }
  });
  const index = list.findIndex(
    (item) => item.productId === productobject.productId
  );
  if (index !== -1) {
    product = { ...list[index] };
    return product;
  }
  return product;
};

/**
 * Function to dynamically replace placeholders in an API URL with actual values.
 * @param {String} url with palceholders prefixed with '%'. Eg. %1
 * @param {Array} paramList
 * returns String URL with dynamic params
 */
export const formURL = (url, paramList) => {
  return url.replace(/%(\d+)/g, (_, n) => paramList[+n - 1]);
};
/**
 * Sort vaultProductList in ascending order on field property.
 * @param {*} listToSort
 * @param {*} field
 */
export const sortList = (listToSort, field) => {
  return listToSort.sort(
    (productOne, productTwo) => productOne[field] - productTwo[field]
  );
};

export const getGettingStarted = (data, callback) => {
  const gettingStartedData = {
    branch: "",
    accountStatementType: "",
  };
  gettingStartedData.branch = data.member.branch;

  if (data.regulatory.eStmt) {
    gettingStartedData.accountStatementType = AppConstants.GETTINGSTARTED.EMAIL;
  } else {
    gettingStartedData.accountStatementType = AppConstants.GETTINGSTARTED.PAPER;
  }
  callback(gettingStartedData);
};

export const commaFormatted = (amount) => {
  if (amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
};

export const capitalize = (s) => {
  if (typeof s !== "string") return s;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const findCode = (code, lookup) => {
  let result = "";
  if (code && lookup) {
    const x = lookup.find((item) => item.code === code);
    if (x) {
      result = x.description;
    }
  }
  return result;
};
