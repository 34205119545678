/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import * as ROUTES from "Constants/Routes";
import {
  TOKEN_KEY,
  HTTP_STATUS,
  APPLICANTID,
  EXISTING_OR_SAME_PRODUCT_MESSAGE,
  SORT_ON_PRIORITY,
} from "Communication/Constants";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";
import { sortList } from "Utils/CommonUtilities";

import {
  updateVaultProductList,
  updateProductToVault,
  getProductFromVault,
} from "Components/MarketPlace/Action/MarketPlaceAction";
import doLogout from "Redux/Actions/LogoutAction";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { deleteApplication } from "Redux/Actions/ApplicationAction";
import { doGetApplicantList } from "Redux/Actions/GetApplicantListAction";
import getAccount from "Redux/Actions/GetAccountAction";
import { getGlobalVault } from "Components/JointApplicant/Actions/JointApplicantAction";
import { getProductTemplate } from "../../Redux/Actions/ProductTemplateActions";

export default function withVaultHOC(WrappedComponent) {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false,
        modalType: "",
        nextComponent: "",
        modal: {
          title: "",
          description: "",
        },
      };
    }

    setModalState = (modal) => {
      this.setState(modal);
    };

    /**
     *
     * @param  selectedProduct product to delete from vault
     * If we have access token then
     *  1)Get vaultProductList from productVault from server.
     *  2)Remove selectedProduct from  vaultProductList
     *  3)Update productVault at server side with new updated vaultProductList
     *  4)If selectedProduct has applicationId then delete that application
     *  5)If selectProduct has active flag true means that it is first product in list the redirect to Dashboard
     * else if we dont have access token
     *   1)Remove selectedProduct from  vaultProductList
     *   2)Update vaultProductList into VaultReducer
     */

    deleteApplication = (selectedProduct) => {
      const accessToken = sessionStorage.getItem(TOKEN_KEY);
      const { vaultProductList, history } = this.props;
      const {
        doUpdateVaultProductList,
        doUpdateProductToVault,
        doDeleteApplication,
        doGetProductFromVault,
      } = this.props;

      if (accessToken) {
        let applicationProduct = null;
        doGetProductFromVault((getProductFromVaultResponse) => {
          if (
            getProductFromVaultResponse &&
            getProductFromVaultResponse.status === HTTP_STATUS.OK
          ) {
            const resultIndex = this.getArrayIndexOfProduct(
              getProductFromVaultResponse.data,
              selectedProduct
            );
            applicationProduct = {
              ...getProductFromVaultResponse.data[resultIndex],
            };
            const updatedVaultProductList = this.removeSelectedProductFromList(
              getProductFromVaultResponse.data,
              selectedProduct
            );
            doUpdateProductToVault(
              updatedVaultProductList,
              (udateProductToVaultResponse) => {
                if (
                  udateProductToVaultResponse &&
                  udateProductToVaultResponse.status === HTTP_STATUS.OK
                ) {
                  if (applicationProduct.applicationId) {
                    doDeleteApplication(
                      applicationProduct.applicationId,
                      () => {
                        if (applicationProduct.active) {
                          history.push(ROUTES.VAULT_DASHBOARD);
                        }
                      }
                    );
                  } else if (applicationProduct.active) {
                    history.push(ROUTES.VAULT_DASHBOARD);
                  }
                }
              }
            );
          }
        });
      } else {
        const updatedVaultProductList = this.removeSelectedProductFromList(
          vaultProductList,
          selectedProduct
        );
        doUpdateVaultProductList(updatedVaultProductList);
      }
    };

    /**
     * Helper method
     */
    getArrayIndexOfProduct = (products, selectedProduct) => {
      const index = products.findIndex(
        (product) => product.productIndex === selectedProduct.productIndex
      );
      return index;
    };

    /**
     * Helper method
     */
    removeSelectedProductFromList = (products, selectedProduct) => {
      const updatedVaultProductList = products.filter((product) => {
        return product.productIndex !== selectedProduct.productIndex;
      });
      return updatedVaultProductList;
    };

    toggleModal = () => {
      const { showModal } = this.state;
      this.setState({
        showModal: !showModal,
      });
    };

    /**
     * @param  isLogin Is true if call from login page and false if call from register page
     * Merged previous session and ongoing session products.
     * Update new vaultProductList to productVault at server side.
     * If user dont have products in vault then redirect to Dashboard
     * If user have only products in ongoing session then get applicant List
     * If user have products in both session or only in previous session then generate appropriate popup message
     */
    prepareVaultProductList = (isLogin) => {
      const {
        doGetProductFromVault,
        doUpdateProductToVault,
        history,
        vaultProductList,
        doGetAccountAction,
        doGetGlobalVault,
      } = this.props;
      const currentSessionVaultProductList = [...vaultProductList];
      let lastSessionVaultProductList = [];
      let updatedVaultProductList = [];
      doGetProductFromVault((getProductFromVaultResponse) => {
        if (
          getProductFromVaultResponse &&
          getProductFromVaultResponse.status === HTTP_STATUS.OK
        ) {
          if (isLogin) {
            lastSessionVaultProductList = getProductFromVaultResponse.data
              ? getProductFromVaultResponse.data
              : [];
            updatedVaultProductList = [...lastSessionVaultProductList];
          }
          updatedVaultProductList.push(...vaultProductList);
          updatedVaultProductList = sortList(
            updatedVaultProductList,
            SORT_ON_PRIORITY
          );
          if (updatedVaultProductList.length !== 0) {
            doUpdateProductToVault(
              updatedVaultProductList,
              (updateProductToVaultResponse) => {
                if (updateProductToVaultResponse.status !== HTTP_STATUS.OK) {
                  this.setRedirectpage(
                    null,
                    AppConstants.MODALTYPE.FAILURE,
                    MODALMESSAGES.SIGNIN.FAILURE
                  );
                }
              }
            );
          }
        }
        if (
          lastSessionVaultProductList.length === 0 &&
          currentSessionVaultProductList.length !== 0
        ) {
          this.callGetApplicantList();
        } else {
          if (lastSessionVaultProductList.length !== 0) {
            this.checkExistingProducts(
              currentSessionVaultProductList,
              lastSessionVaultProductList
            );
          }
          const jointApplicant = sessionStorage.getItem(
            STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
          );

          if (jointApplicant && jointApplicant !== "") {
            const jointApp = jointApplicant.split("-");
            const slotId = jointApp[0];
            const type = jointApp[1];

            doGetAccountAction((getAccountActionRes) => {
              if (getAccountActionRes.status === HTTP_STATUS.OK) {
                const mobileNo = getAccountActionRes.data.msisdn;
                const vKey = `${STRINGS.JOIN_APPLICANT.VAULT_KEY}-${mobileNo}`;
                doGetGlobalVault({ vaultKey: vKey }, (gobalVaultRes) => {
                  if (
                    gobalVaultRes.vaultProducts &&
                    gobalVaultRes.vaultProducts.length > 0
                  ) {
                    const { vaultProducts } = gobalVaultRes;
                    if (type !== "register") {
                      vaultProducts[0].applicationId = sessionStorage.getItem(
                        STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
                      );
                    }
                    vaultProducts[0].active = true;
                    vaultProducts[0].slotid = slotId;
                    doUpdateProductToVault(vaultProducts, (prodVaultRes) => {
                      if (prodVaultRes.status === HTTP_STATUS.OK) {
                        history.push(ROUTES.EXISTING_MEMBER);
                      }
                    });
                  }
                });
              }
            });
          }
          history.push(ROUTES.VAULT_DASHBOARD);
        }
      });
    };

    /**
     * @param  currentSessionVaultProductList list contains on going session products
     * @param  lastSessionVaultProductList list contains previous session products
     * Generate popup message by comparing products from both vault productList
     *
     */
    checkExistingProducts = (
      currentSessionVaultProductList,
      lastSessionVaultProductList
    ) => {
      let message = MODALMESSAGES.SIGNIN.SOMEPRODUCTS;
      lastSessionVaultProductList.forEach((oldProduct) =>
        currentSessionVaultProductList.forEach((newProduct) => {
          if (newProduct.productId === oldProduct.productId) {
            message = MODALMESSAGES.SIGNIN.SAMEPRODUCTS;
          }
        })
      );
      sessionStorage.setItem(EXISTING_OR_SAME_PRODUCT_MESSAGE, message);
    };

    /**
     * Fetch applicantId from server side.
     * If get applicantId redirect to EXISTING_MEMBER
     * else get account details.
     */
    callGetApplicantList = () => {
      console.log("callGetApplicantList");
      const { doGetApplicantListAction } = this.props;
      doGetApplicantListAction((GetApplicantListActionResponse) => {
        if (GetApplicantListActionResponse.status === HTTP_STATUS.OK) {
          if (GetApplicantListActionResponse.data.length === 0) {
            this.setRedirectpage(ROUTES.EXISTING_MEMBER, null, null);
          } else {
            // get first applicantID from the list
            const selfApplication = GetApplicantListActionResponse.data.find(
              (app) => app.relationship === AppConstants.RELATIONSHIP.SELF
            );
            const getFirstApplicantID = selfApplication.id;
            sessionStorage.setItem(APPLICANTID, getFirstApplicantID);
            this.getAccountDetails(
              GetApplicantListActionResponse,
              selfApplication.applicationIds
            );
          }
        }
      });
    };

    getAccountDetails = (callback, applicationIds = () => {}) => {
      console.log("getAccountDetails");
      // call getaccount api to check terms and condition accepted or not
      const {
        doGetAccountAction,
        sessionCompletedProducts,
        applicantData,
        applicationCompleteList,
        doGetApplicantList,
      } = this.props;
      if (sessionCompletedProducts && sessionCompletedProducts.length <= 0) {
        if (
          applicantData?.acquireType === "search" ||
          applicationCompleteList?.length > 0 ||
          (applicationIds && applicationIds.length > 0)
        ) {
          this.setRedirectpage(ROUTES.ABOUT_YOU, null, null);
        } else {
          doGetAccountAction((getAccountActionResponse) => {
            if (getAccountActionResponse.data.consent === null) {
              this.setRedirectpage(ROUTES.MEMBER_CREATION, null, null);
            } else {
              this.setRedirectpage(ROUTES.GETTING_STARTED, null, null);
            }
          });
        }
      } else {
        callback();
      }
    };

    /**
     * Returns active product if not found then returns first object from the array
     * @param {*} products
     */
    getActiveProduct = (products) => {
      let activeProduct = products?.find((product) => product?.active);
      if (!activeProduct) {
        activeProduct = { ...products[0] };
        const jointSlotId = sessionStorage.getItem(
          STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID
        );
        if (jointSlotId) {
          activeProduct.applicantId = sessionStorage.getItem(APPLICANTID);
        }
      }
      return activeProduct;
    };

    goToNextStep = (vaultProductList, callback) => {
      console.log("goToNextStep", vaultProductList);
      const { doGetProductTemplate, history } = this.props;
      const activeProduct = this.getActiveProduct(vaultProductList);
      console.log("activeProduct", activeProduct);
      if (activeProduct.applicationStep.index) {
        doGetProductTemplate((response) => {
          console.log(response);
          const currentTemplate = response.find(
            (template) => template.templateId === activeProduct.templateId
          );
          console.log("currentTemplate", currentTemplate);
          const currentStep = currentTemplate.flow.find(
            (step) => step.index === activeProduct.applicationStep.index
          );
          console.log("currentStep", currentStep);
          if (
            AppConstants.COMPONENTSTEPTYPE.APPLICATION ===
            currentStep.componentType
          ) {
            const nextStep = currentTemplate.flow.find(
              (step) => step.index === activeProduct.applicationStep.index + 1
            );
            if (nextStep) {
              history.push(ROUTES[nextStep.componentName]);
            } else {
              history.push(ROUTES[currentStep.componentName]);
            }
          } else {
            const nextStep = currentTemplate.flow.find(
              (step) => step.index === activeProduct.applicationStep.index + 1
            );
            history.push(ROUTES[nextStep.componentName]);
          }
        }, activeProduct);
      } else {
        if (sessionStorage.getItem(APPLICANTID)) {
          this.getAccountDetails(callback);
        } else {
          this.callGetApplicantList();
        }
      }
    };

    proceedWithApplication = (callback = () => {}) => {
      console.log("proceedWithApplication");
      const { history } = this.props;

      const accessToken = sessionStorage.getItem(TOKEN_KEY);
      if (accessToken) {
        const { doUpdateProductToVault, doGetProductFromVault } = this.props;
        doGetProductFromVault((getProductFromVaultResponse) => {
          if (
            getProductFromVaultResponse &&
            getProductFromVaultResponse.status === HTTP_STATUS.OK
          ) {
            if (
              getProductFromVaultResponse.data &&
              getProductFromVaultResponse.data.length !== 0
            ) {
              const vaultProductList = getProductFromVaultResponse.data;
              vaultProductList[0].active = true;
              for (let i = 0; i < vaultProductList.length; i += 1) {
                vaultProductList[i].applicantId = sessionStorage.getItem(
                  APPLICANTID
                );
              }
              doUpdateProductToVault(vaultProductList, () => {
                this.goToNextStep(vaultProductList, callback);
              });
            }
          }
        });
      } else {
        history.push(ROUTES.SIGN_IN);
      }
    };

    setRedirectpage = (redirectUrl, type, title) => {
      const { forgotPasswordState, history } = this.props;
      if (type === null) {
        if (forgotPasswordState && forgotPasswordState.success) {
          this.setModalState({
            showModal: true,
            modalType: AppConstants.MODALTYPE.SUCCESS,
            modal: {
              title:
                forgotPasswordState.success.status === HTTP_STATUS.OK
                  ? MODALMESSAGES.RECOVERPASSWORD.SUCCESSMSG
                  : MODALMESSAGES.SETPASSWORD.SUCCESSMSG,
              description: "",
            },
            nextComponent:
              forgotPasswordState.success.status === HTTP_STATUS.OK
                ? ROUTES.SIGN_IN
                : redirectUrl,
          });
        } else {
          history.push(redirectUrl);
        }
      } else {
        this.setModalState({
          showModal: true,
          modalType: type,
          modal: {
            title,
            description: "",
          },
          nextComponent: ROUTES.VAULT_DASHBOARD,
        });
      }
    };

    handlePopupModalBtnClick = () => {
      const { history, doLogOutAction } = this.props;
      const { nextComponent } = this.state;
      if (nextComponent === ROUTES.SIGN_IN) {
        doLogOutAction((res) => {
          if (res.status === HTTP_STATUS.OK) {
            history.push(ROUTES.SIGN_IN);
          }
        });
      } else {
        history.push(nextComponent);
      }
    };

    render() {
      const { showModal, modalType, modal } = this.state;

      return (
        <>
          {showModal && (
            <PopupModal
              type={modalType}
              title={modal.title}
              description={modal.description}
              toggleModal={this.toggleModal}
              showModal={showModal}
              popupBtnClick={this.handlePopupModalBtnClick}
              btnText={STRINGS.POPUPMODAL.OKBUTTON}
            />
          )}
          <WrappedComponent
            deleteApplication={(selectedProduct) =>
              this.deleteApplication(selectedProduct)
            }
            prepareVaultProductList={this.prepareVaultProductList}
            proceedWithApplication={(callback) =>
              this.proceedWithApplication(callback)
            }
            {...this.props}
          />
        </>
      );
    }
  }

  HOC.propTypes = {
    vaultProductList: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.string,
    ]),
    doUpdateVaultProductList: PropTypes.func.isRequired,
    doUpdateProductToVault: PropTypes.func.isRequired,
    doDeleteApplication: PropTypes.func.isRequired,
    history: PropTypes.oneOfType([PropTypes.object]),
    doGetProductFromVault: PropTypes.func,
    productList: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.string,
    ]),
    doGetAccountAction: PropTypes.func,
    doGetApplicantListAction: PropTypes.func,
    forgotPasswordState: PropTypes.objectOf(PropTypes.any),
    doLogOutAction: PropTypes.func.isRequired,
    doGetGlobalVault: PropTypes.func.isRequired,
    sessionCompletedProducts: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
  };

  HOC.defaultProps = {
    productList: [],
    vaultProductList: [],
    doGetProductFromVault: () => {},
    doGetAccountAction: () => {},
    doGetApplicantListAction: () => {},
    history: {},
    forgotPasswordState: {},
  };

  const mapStateToProps = (state) => ({
    productList: state.MarketplaceReducer.productList,
    vaultProductList: state.VaultReducer.vaultProductList,
    jointApplicant: state.JoinApplicantReducer.jointApplicant,
    sessionCompletedProducts: state.ApplicationReducer.sessionCompletedProducts,
    applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
    applicantData: state.ApplicationReducer.response,
  });

  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        doUpdateVaultProductList: updateVaultProductList,
        doUpdateProductToVault: updateProductToVault,
        doDeleteApplication: deleteApplication,
        doGetProductTemplate: getProductTemplate,
        doGetProductFromVault: getProductFromVault,
        doGetApplicantListAction: doGetApplicantList,
        doGetAccountAction: getAccount,
        doLogOutAction: doLogout,
        doGetGlobalVault: getGlobalVault,
      },
      dispatch
    );
  };
  return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HOC);
}
