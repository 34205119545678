import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import GettingStarted from "./GettingStarted";
import {
  fetchGettingStartedBranch,
  fetchGettingStartedResidentStatus,
} from "./Actions/GettingStartedActions";

class GettingStartedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      doGetBranchData,
      fetchGettingStartedResidentStatuss,
      applicantData,
      history,
      applicationCompleteList,
      continuehandler,
    } = this.props;
    console.log("componentDidMount", applicationCompleteList);
    if (
      applicantData?.acquireType === "search" ||
      (applicationCompleteList && applicationCompleteList.length > 0) ||
      sessionStorage.getItem("TEMP_HIDE") === "true"
    ) {
      continuehandler(null, AppConstants.APPLICATIONSTEP.GETTINGSTARTED);
    } else {
      fetchGettingStartedResidentStatuss();
      doGetBranchData();
    }
  }

  submitRequest = (body) => {
    const { continuehandler } = this.props;
    continuehandler(body, AppConstants.APPLICATIONSTEP.GETTINGSTARTED);
  };

  render() {
    const {
      history,
      showVault,
      gettingStartedResidentState,
      gettingStartedBranchState,
      getDataFromServer,
      steps,
      activeStepID,
    } = this.props;

    return (
      <GettingStarted
        active={1}
        showVault={showVault}
        history={history}
        submitRequest={this.submitRequest}
        gettingStartedBranchState={gettingStartedBranchState}
        gettingStartedResidentState={gettingStartedResidentState}
        getDataFromServer={getDataFromServer}
        steps={steps}
        activeStepID={activeStepID}
      />
    );
  }
}

GettingStartedContainer.propTypes = {
  doGetBranchData: PropTypes.func.isRequired,
  fetchGettingStartedResidentStatuss: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  showVault: PropTypes.bool,
  gettingStartedResidentState: PropTypes.objectOf(PropTypes.any),
  gettingStartedBranchState: PropTypes.objectOf(PropTypes.any),
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

GettingStartedContainer.defaultProps = {
  history: {},
  fetchGettingStartedResidentStatuss: () => {},
  showVault: false,
  gettingStartedResidentState: {},
  gettingStartedBranchState: {},
  steps: [],
  activeStepID: -1,
};
const mapDispatchToProps = (dispatch) => {
  return {
    doGetBranchData: () => dispatch(fetchGettingStartedBranch()),
    fetchGettingStartedResidentStatuss: () =>
      dispatch(fetchGettingStartedResidentStatus()),
  };
};

const mapStateToProps = (state) => ({
  gettingStartedBranchState: state.GettingStartedBranchReducer,
  gettingStartedResidentState: state.GettingStartedResidentReducer,
  termsState: state.TermsAndConditionReducer,
  applicantData: state.ApplicationReducer.response,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
});

const GettingStartedWrapper = withApplicantHOC(GettingStartedContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GettingStartedWrapper);
