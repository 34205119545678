import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";

class Asset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TYPE_DESC: "",
      choice: false,
      formIsValid: false,
      subTypeError: [],
      descriptionError: [],
      valueError: [],
      institutionError: [],
      assets: [
        {
          subType: "",
          description: "",
          value: "",
          institution: "",
          primaryResidence: false,
          notes: "",
        },
      ],
    };
  }

  componentDidMount() {
    console.log("child container");
    const { getDataFromServer } = this.props;
    getDataFromServer(AppConstants.APPLICATIONSTEP.ASSET, (response) => {
      this.setFormData(response);
    });
  }

  componentDidUpdate(prevProps) {
    console.log("component did update");
    console.log(prevProps);
    const { assets, choice } = this.props;

    if (prevProps.assets !== assets) {
      this.updateFormData(assets, choice);
      // this.getAssetList(prevProps.assets);
    }
  }

  updateFormData(assets, choice) {
    console.log("updateFormData", assets, choice);

    if (assets.length > 0) {
      this.setState({
        choice: true,
      });
    }
    const newAssetList = [];
    assets.forEach((asset) => {
      newAssetList.push({
        subType: asset.subType,
        description: asset.description,
        value: asset.value,
        institution: asset.institution,
        primaryResidence: asset.primaryResidence,
        financialId: asset.financialId,
      });
    });
    this.setState({
      assets: newAssetList,
    });
  }

  handleAssetChange = (raw, prop) => (event) => {
    console.log("handleAssetChange", raw, prop);
    const { assets } = this.state;
    const asset = raw;
    asset[prop] = event.currentTarget.value;
    this.setState({ assets });
  };

  validateForm = () => {
    let valid = true;
    const { assets, choice } = this.state;
    this.setState({
      subTypeError: [],
      descriptionError: [],
      valueError: [],
      institutionError: [],
      formIsValid: true,
    });

    if (assets.length > 0 && choice) {
      // Check error for asset Type
      let error = checkErrors(
        assets[assets.length - 1].subType,
        VALIDATIONS.ASSET.ASSET_TYPE
      );
      if (error.length) {
        valid = false;
        this.setState({ subTypeError: error });
      }

      // Check error for Description
      error = checkErrors(
        assets[assets.length - 1].description,
        VALIDATIONS.ASSET.DESCRIPTION
      );
      if (error.length) {
        valid = false;
        this.setState({ descriptionError: error });
      }

      // Check error for Value
      error = checkErrors(
        assets[assets.length - 1].value,
        VALIDATIONS.ASSET.VALUE
      );
      if (error.length) {
        valid = false;
        this.setState({ valueError: error });
      }

      // Check error for institution
      // error = checkErrors(
      //   assets[assets.length - 1].institution,
      //   VALIDATIONS.ASSET.INSTITUTION
      // );
      // if (error.length) {
      //   valid = false;
      //   this.setState({ institutionError: error });
      // }

      this.setState({
        formIsValid: valid,
      });
    }

    return valid;
  };

  handleAddAsset = () => {
    console.log("handleAddAsset");
    if (this.validateForm()) {
      const asset = {
        subType: "",
        titleDescription: "Description",
        description: "",
        value: "",
        institution: "",
        primaryResidence: "",
        notes: "",
      };
      const action = {
        type: "add",
        asset: asset,
      };
      this.updateAssets(action);
    }
  };

  handleDeleteAsset = (idx) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateAssets(action);
    const { assets } = this.state;
    if (assets.length === 0) {
      this.setState({
        choice: false,
      });
    }
    this.validateForm();
  };

  updateAssets(action) {
    console.log("updateAssets", action);
    const { assets } = this.state;
    if (action.type === "add") {
      assets.push(action.asset);
    } else if (action.type === "remove") {
      if (action.index === assets.length - 1) {
        this.setState({
          subTypeError: [],
          descriptionError: [],
          valueError: [],
          institutionError: [],
        });
      }
      if (action.index > -1) {
        assets.splice(action.index, 1);
      }
    }
    this.setState({ assets });
  }
  setFormData = () => {};

  handleAssetButtonChange = (value) => {
    const { handleAssetButtonChange } = this.props;
    if (!value) {
      this.setState({ assets: [] });
    } else {
      this.handleAddAsset();
    }
    this.setState({ choice: value });
    handleAssetButtonChange(value);
  };

  handleContinue = () => {
    const { handleContinue } = this.props;

    if (this.validateForm()) {
      console.log(this.state.assets);
      handleContinue({
        assets: this.state.assets,
      });
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.ASSET);
  };

  getAssesValueDescription = (code) => {
    const { assetTypes } = this.props;
    let result = STRINGS.ASSET.FORM.VALUE;
    const asset = assetTypes.find((item) => item.code === code);
    if (asset) {
      if (asset.valueDescription) {
        result = asset.valueDescription;
      }
    }
    return result;
  };

  render() {
    const {
      choice,
      assets,
      formIsValid,
      subTypeError,
      descriptionError,
      valueError,
      institutionError,
      TYPE_DESC,
    } = this.state;
    const { steps, activeStepID, assetTypes } = this.props;
    console.log("assetTypes", assetTypes);
    console.log("assets", assets);
    const adjustmentTypeList = ["Con", "Exc", "New"];
    const realEstateType = ["Vacation home", "Rental home"];
    const institutionList = ["TD"];

    const subTypeComponent = assetTypes.map((expense, index) => (
      <option value={expense.code} className="[ dropdown__option ]">
        {expense.description}
      </option>
    ));
    const adjustmentTypeComponent = adjustmentTypeList.map(
      (adjustmentType, index) => (
        <option value={adjustmentType} className="[ dropdown__option ]">
          {adjustmentType}
        </option>
      )
    );
    const institutionComponent = institutionList.map((institution, index) => (
      <option value={institution} className="[ dropdown__option ]">
        {institution}
      </option>
    ));

    const addAssetsComponent = assets.map((asset, index) => (
      <div className="[ drawer-content-item ]">
        <div className="[ info-title ]">
          {STRINGS.ASSET.FORM.ASSET_TYPE_NO} {index + 1}
          <button
            type="button"
            className="[ fas fa-trash ]"
            onClick={() => this.handleDeleteAsset(index)}
            aria-label="remove"
          >
            <span className="[ sr-only ]">
              {STRINGS.EXPENSE.FORM.REMOVE_EXPENSE}
            </span>
          </button>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            {/* ASSET TYPE */}
            <div className="[ col-12 col-lg-4 ]">
              <div className="[ form-label ]">
                {STRINGS.ASSET.FORM.ASSET_TYPE}
              </div>
              <select
                value={asset.subType ? asset.subType : ""}
                className="[ form-control ] [ select__dropdown ]"
                onChange={this.handleAssetChange(asset, "subType")}
              >
                <option value="" className="[ default ] [ dropdown__option ]">
                  {STRINGS.ASSET.FORM.ASSET_TYPE_DEFAULT_SELECT}
                </option>
                {subTypeComponent}
              </select>
              <div className="[ error-msg ]">
                {subTypeError
                  ? index + 1 === assets.length
                    ? subTypeError[0]
                    : ""
                  : ""}
              </div>
            </div>

            {/* DESCRIPTION */}
            <div className="[ col-12 col-lg-4 ]">
              <div className="[ form-label ] ">
                {asset.titleDescription
                  ? asset.titleDescription
                  : STRINGS.ASSET.FORM.DESCRIPTION}
              </div>
              <input
                type="text"
                name="description"
                className="[ form-control ]"
                placeholder={STRINGS.ASSET.FORM.DESCRIPTION}
                onChange={this.handleAssetChange(asset, "description")}
                value={asset.description ? asset.description : ""}
              />
              <div className="[ error-msg ]">
                {descriptionError
                  ? index + 1 === assets.length
                    ? descriptionError[0]
                    : ""
                  : ""}
              </div>
            </div>

            {/* VALUE */}
            <div className="[ col-12 col-lg-4 ]">
              <div className="[ form-label ] ">
                {this.getAssesValueDescription(asset.subType)}
              </div>
              <NumberFormat
                maxLength={AppConstants.EMPLOYMENTANDINCOME.SALARY_LENGTH}
                type="text"
                thousandSeparator
                allowNegative={false}
                prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                name="monthlyAmount"
                className="[ form-control ]"
                placeholder={STRINGS.ASSET.FORM.VALUE_AMOUNT_PLACEHOLDER}
                onChange={this.handleAssetChange(asset, "value")}
                value={asset.value ? asset.value : ""}
              />
              <div className="[ error-msg ]">
                {valueError
                  ? index + 1 === assets.length
                    ? valueError[0]
                    : ""
                  : ""}
              </div>
            </div>
          </div>
          {asset.notes && (
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <span className="[ notes ]">{asset.notes}</span>
              </div>
            </div>
          )}
          {/* CHRIS NOTE: Not sure how applicable this is here for this screen. Commenting out for now and will review with team. */}
          {/* <div className="[ row ]"> */}
          {/* PRIMARY RESIDENCE? */}
          {/* <div className="[ col-12 ]">
              <div className="[ form-label ]">
                {STRINGS.ASSET.FORM.PRIMARY_RESIDENCE}
              </div>
              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      choice !== "" && choice
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="choice"
                    onClick={(event) => this.handleAssetButtonChange(true, event)}
                  >
                    {STRINGS.COMMON.CHOICE.YES}
                  </button>
                </div>
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      choice !== "" && choice
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="choice"
                    onClick={(event) => this.handleAssetButtonChange(false, event)}
                  >
                    {STRINGS.COMMON.CHOICE.NO}
                  </button>
                </div>
                <div className="[ error-msg ]"></div>
              </div>
            </div>
          </div> */}

          {/*<div className="[ row ]">*/}
          {/*  <div className="[ col-6 ]">*/}
          {/*    <div className="[ form-label ]">*/}
          {/*      {STRINGS.ASSET.FORM.ORGANIZATION}*/}
          {/*    </div>*/}
          {/*    <select*/}
          {/*      value={asset.institution ? asset.institution : ""}*/}
          {/*      className=" [ form-control ] [ select__dropdown ] "*/}
          {/*      onChange={this.handleAssetChange(asset, "institution")}*/}
          {/*    >*/}
          {/*      <option value="" className="[ default ] [ dropdown__option ]">*/}
          {/*        {STRINGS.ASSET.FORM.ORGANIZATION_DEFAULT_SELECT}*/}
          {/*      </option>*/}
          {/*      {institutionComponent}*/}
          {/*    </select>*/}
          {/*    <div className="[ error-msg ]">*/}
          {/*      {institutionError*/}
          {/*        ? index + 1 === assets.length*/}
          {/*          ? institutionError[0]*/}
          {/*          : ""*/}
          {/*        : ""}*/}
          {/*    </div>*/}
          {/*  </div>*/}

          {/*  /!* CHRIS NOTE: Original 'checkbox' code here. One page had radio, one page had checkbox, one page had a dropdown selector. So all pages should use the radio *!/*/}
          {/*  <div className="[ col-6 col-lg-6 col-md-6 ] [ d-flex align-items-center ] [ checkbox ]">*/}
          {/*    <input*/}
          {/*      key={index}*/}
          {/*      type="checkbox"*/}
          {/*      defaultChecked={false}*/}
          {/*      value={asset.primaryResidence}*/}
          {/*      checked={asset.primaryResidence}*/}
          {/*      onChange={this.handleAssetChange(asset, "primaryResidence")}*/}
          {/*      id={"primaryResidence" + index}*/}
          {/*    />*/}
          {/*    <label*/}
          {/*      htmlFor={"primaryResidence" + index}*/}
          {/*      className="[ checkbox-text ]"*/}
          {/*    >*/}
          {/*      {STRINGS.ASSET.FORM.PRIMARY_RESIDENCE}*/}
          {/*    </label>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

        <div className="[ divider ] [ mb-3 ]" />
      </div>
    ));
    console.log(steps, activeStepID);
    const actionComponent = (
      <div className="[ asset-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.ASSET.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.ASSET.PAGE_DESCRIPTION1}{" "}
            <span className="[ form-description ]">
              {STRINGS.ASSET.PAGE_DESCRIPTION2}
            </span>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ASSET.FORM.ASSET_RADIO_LABEL}
            </div>
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) => this.handleAssetButtonChange(true, event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    choice !== "" && !choice
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="choice"
                  onClick={(event) =>
                    this.handleAssetButtonChange(false, event)
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
              <div className="[ error-msg ]"></div>
            </div>
          </div>

          {choice ? (
            <div className="[ drawer-content-container mb-3 ]">
              {addAssetsComponent}

              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-3 ] [ offset-lg-9 ]">
                    <button
                      type="button"
                      className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                      onClick={(event) => this.handleAddAsset(event)}
                    >
                      <span className="fas fa-plus" />
                      {STRINGS.ASSET.FORM.ADD_BTN}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={actionComponent}
        memberCreation
        handleBack={this.handleBack}
      />
    );
  }
}

Asset.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleAssetButtonChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

Asset.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
};

export default Asset;
