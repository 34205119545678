import FIInformation from "Constants/FIInformation";

const STRINGS = {
  VERSION: "AM-JAN14-2021",
  COMMON: {
    CHOICE: {
      YES: "Yes",
      NO: "No",
    },
    CONTINUEBTN: "Continue",
    FI_NAME: `${FIInformation.FI_NAME}`,
    USER_SESSION_EXPIRED_ERROR: "User session is expired.",
    ERROR_LIST: "ErrorList",
    API_KEYS: {
      LOGOUT: "logout",
      RESET_STORE: "resetStore",
      UPDATE_APPLICANT: "updateApplicant",
      GET_ACCOUNT: "getAccount",
      GET_APPLICANT_LIST: "getApplicantList",
      DELETE_APPLICATION: "deleteApplication",
      GET_APPLICATION_LIST: "getApplicationlist",
      GET_PRODUCT_TEMPLATE: "getProductTemplate",
      GET_PRODUCT_VAULT: "GET_PRODUCT_VAULT",
      UPDATE_PRODUCT_INFORMATION: "updateProductInformation",
      GET_PRODUCT_INFORMATION: "getProductInformation",
    },
  },
  NETWORK: {
    NETWORK_CONNECTIVITY_ISSUE:
      "It appears you are not connected to the Internet.",
    OFFLINE: "offline",
    ONLINE: "online",
    ERROR: "Network Error",
  },
  POPUPMODAL: {
    BACKBUTTON: "BACK",
    OKBUTTON: "OK",
  },
  DROPDOWN_INITIAL_TEXT: "Select dropdown item",
  SHOW: "SHOW",
  HIDE: "HIDE",
  HEADER: {
    SIGNINTITLE: "OPEN YOUR ACCOUNTS",
    WELCOME: "Welcome",
    SIGNOUT: "Sign Out",
    GLOBALNAV: {
      TITLE: "Main Navigation",
      SIGNIN: "Sign In",
      REGISTER: "Register",
      SIGNOUT: "Sign Out",
      SETTINGS: "Settings",
      CHANGEPASSWORD: "Change Password",
      LEGALESE: "Terms & Policies",
      PRIVACY: "Privacy Policy",
      CONDITIONS: "Terms & Conditions",
      CONTACTUS: "Contact Us",
      CUSTOMERSERVICE: "Email Customer Service",
      COMPANYSITE: `${FIInformation.FI_NAME} Website`,
    },
    MEMBER_INFO: "Member Info",
    REGISTRATION: "Register",
    SIGNIN: "Sign In",
    FORGOTPASSWORD: "Forgot Password",
  },
  INFOBANNER: {
    SECURESESSIONTEXT: "Session secured with SSL and 256-bit encryption.",
  },
  NAVBAR: {
    NAVBARBUTTONTEXT: "BACK",
  },
  HOME_BREADCRUMB: "Home",
  FORGOT_PASSWORD_BREADCRUMB: "Forgot Password",
  EXISTING_MEMBER_BREADCRUMB: "Existing Member",
  RECOVER_PASSWORD_BREADCRUMB: "Recover Password",
  CONFIRM_ACCOUNT_CREATE_BREADCRUMB: "Confirm Account",
  MEMBER_CREATION_BREADCRUMB: "Member Info",
  EMPLOYMENT_AND_INCOME_BREDCRUM: "New Member Account",
  GETTING_STARTED_BREADCRUMB: "New Member Account",
  COMPLIANCE_BREADCRUMB: "Compliance",
  ACCOUNT_INFORMATION_BREADCRUMB: "Account Information",
  FUND_ACCOUNT_BREADCRUMB: "Fund Account",
  BENEFICIARIES_BREADCRUMB: "Beneficiaries",
  OVERDRAFT_PROTECTION_BREADCRUMB: "Overdraft Protection",
  SIGNATURE_BREADCRUMB: "e-Signature",
  SIGNIN: {
    SIGN_IN_BREADCRUMB: "Sign In",
    VAULTSIGNINTITLE: "SIGN IN",
    FORM: {
      USERNAMELABEL: "Email",
      EMAILPLACEHOLDER: "Enter email",
      MOBILENOPLACEHOLDER: "Mobile no.",
      PASSWORDLABEL: "Password",
      PASSWORDPLACEHOLDER: "Enter password",
      FORGOTPASSWORDLINK: "Forgot password",
      REMEBERUSERCHECK: "Remember me",
      SUBMITBUTTON: "Sign In",
    },
    INFOCONTAINER: {
      REGISTERTITLE: "First time here?",
      REGISTERNOWINFO1:
        "You will need to register your marketplace profile before continuing with your application(s).",
      REGISTERNAVIGATEBTN: "Register Now",
    },
    API_KEYS: {
      SIGNIN: "signIn",
    },
  },
  SETPASSWORD: {
    TITLE: "PROFILE PASSWORD",
    FORM: {
      NEWPASSWORDLABEL: "New password",
      RETYPEPASSWORDLABEL: "Retype new password",
      PASSWORDCRITERIA: "Passwords must be:",
    },
    INFOBLOCK: {
      TITLE: "Your profile password is not your online banking password.",
      DESCRIPTION: `Your online applications' profile allows you to begin, save, leave, continue and complete financial applications or apply online for new financial products offered by ${FIInformation.FI_NAME}.`,
    },
    ERROR: {
      CONFIRM_PASSWORD_ERROR: "Passwords do not match",
    },
    API_KEYS: {
      GET_PASSWORD: "getPassword",
      SET_PASSWORD: "setPassword",
    },
  },
  VAULT: {
    VAULTTITLE: "YOUR CART",
    PRODUCTDETAILSLINK: "See Details",
    VAULT_PASSWORD_BREADCRUMB: "Profile Password",
    PROCEED: "Proceed",
    EMPTY_VAULT_TEXT: " “0” item(s) in your cart",
    PRODUCT_COUNT_TITLE: " $ item(s) in your cart",
    DELETE_TITLE: "Remove Product",
    DELETE_DESCRIPTION: "Are you sure you want to remove this product?",
    DELETE_CONFIRM: "Remove",
    DELETE_CANCEL: "Cancel",
    API_KEYS: {
      GET_VAULT: "getVault",
      VAULT: "vault",
    },
  },

  FOOTER: {
    COPYRIGHT: "Current Version",
    HELP: "Chat with us for help!",
  },

  REGISTRATION: {
    REGISTER_BREADCRUMB: "Register",
    TITLE: "REGISTER",
    FORM: {
      EMAILLABEL: "Email",
      EMAILPLACEHOLDER: "Enter email",
      MOBILENOLABEL: "Mobile no.",
      MOBILENOPLACEHOLDER: "Enter mobile no.",
      TERMSCHECK: "Remember me",
      REGISTRATIONBTN: "Register",
      TERMSLABEL1:
        "By continuing, you confirm that you have read and agree to the",
      TERMSLABEL2: " terms and conditions ",
      TERMSLABEL3: "and your relationship with Mobetize.",
    },
    INFOBLOCK: {
      SIGNINTOCONTINUE: "Been here before?",
      SIGNINTOCONTINUEBTN: "SIGN IN",
      SIGNINTOCONTINUEINFO1:
        "Sign in to continue with your applications or shop around for new financial products.",
    },
    SECURESESSIONTEXT: "Session secured with SSL and 256-bit encryption.",
    API_KEYS: {
      EXISTING_USER: "existingUser",
      REGISTER: "Register",
    },
  },

  VERIFICATIONCODE: {
    VERIFICATION_CODE_BREADCRUMB: "Verification Code",
    TITLE: "VERIFICATION CODE",
    FORM: {
      VERIFICATIONCODELABEL: "Verification Code",
      VERIFICATIONCODEPLACEHOLDER: "Enter verification code",
    },
    INFOBLOCK: {
      TITLE: "Verification Code",
      SENTCODE: "We’ve sent a verification code to your mobile device. ",
      ENTERCODE: "Enter the code to continue.",
      RECEIVEDCODE: "Didn't receive a code?",
      RESENDCODEBTN: "Resend Code",
    },
    API_KEYS: {
      VERIFICATION_CODE: "verificationCode",
    },
  },

  FORGOTPASSWORD: {
    TITLE: "Forgot password",
    VERIFICATIONCODEMESSAGE: "How would you like to reset your password?",
    EMAILLABEL: "Email",
    TEXTMESSAGE: "Text Message",
    SECURESESSIONTEXT: "Session secured with SSL and 256-bit encryption.",
    OTP_TO: {
      EMAIL: "email",
      MSISDN: "registered mobile device.",
    },
    MODAL: {
      SUCCESS_MAIL:
        "Use the link within the email to continue to reset your password.",
      SUCCESS_MESSAGE:
        "Use this code in the next step to continue to reset your password.",
    },
    API_KEYS: {
      FORGOT_PASSWORD: "forgotPassowrd",
      RECOVER_PASSWORD: "recoverPassword",
    },
  },

  EMPLOYMENT_AND_INCOME: {
    TITLE: "EMPLOYMENT & INCOME",
    SUBTITLE: "Please provide the following:",
    FORM: {
      EMPLOYMENT_STATUS: "Employment status",
      JOB_TITLE: "Job title",
      OCCUPATION: "Occupation",
      EMPLOYER_NAME: "Employer name",
      EMPLOYER_CONTACT_NO: "Employer contact no.",
      ANNUAL_GROSS_INCOME: "Annual gross income",
      MONTHLY_GROSS_INCOME: "Monthly gross income",
      SOURCE_OF_INCOME: "Source of income",
      TAX_RESIDENT_CONFIRMATION: "Are you a tax resident of Canada? ",
      COUNTRY: "COUNTRY",
      COUNTRYFIELDNAME: "countryName",
      COUNTRYNAME: "US",
      RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION:
        "Are you a tax resident of any other country? ",
      RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION: "Are you a citizen of ",
      ADD_ANOTHER_COUNTRY: "Add Another Country ",
      TAX_IDENTITY_NO: "Tax Identification Number (TIN)",
      SSN: "Social Security Number (SSN)",
      SIN: "Social Insurance Number (SIN)",
      TAX_RESIDENCY: "TAX RESIDENCY",
      SELECT_BEST_OPTION_THAT_DESCRIBES_YOU:
        "Select the option that best describes you",
      LIST_THE_COUNTRIES: "List the countries you are a tax resident of",
      PLACEHOLDER: {
        EMPLOYMENT_STATUS: "Select the status that best describes you",
        OCCUPATION: "Select your occupation",
        JOB_TITLE: "Enter job title",
        EMPLOYER_NAME: "Enter employer name",
        EMPLOYER_CONTACT_NO: "Enter employer contact no.",
        ANNUAL_GROSS_INCOME: "Enter your yearly amount before taxes",
        SOURCE_OF_INCOME:
          "Select the option that best describes your main source of income",
        COUNTRY: "Select country",
        ADD_ANOTHER_COUNTRY: "Add Another Country ",
        SSN: "Enter social security number (SSN)",
        SIN: "Enter social insurance number (SIN)",
        LIST_THE_COUNTRIES: "List the countries you are a tax resident of",
        SINPLAACEHOLDER: "Social insurance number (SIN)",
      },
      ERROR: {
        TAX_RESIDENT_CONFIRMATION: "whether you are tax resident of canada",
        COUNTRY: "country name",
        TAX_RESIDENT_OF_OTHER_COUNTRY_CONFIRMATION:
          "whether you are tax resident of another country or not",
        RECIDENT_OF_COUNTRY_IN_SELECTION_CONFIRMATION:
          "whether you are a resident of another country or not",
        TAX_IDENTITY_NO: "tax identity number",
        SSN: "social security number (SSN)",
        SIN: "social insurance number (SIN)",
        LIST_OF_COUNTRIES: "countries you are a tax resident of",
        BOTHSELECTEDNONEERROR:
          "you must be the tax resident of at least one country",
        COUNTRY_LIST: "country list",
        INCOME_LIST: "incomesource list",
        EMPLOYMENT_STATUS: "employment status",
        JOB_TITLE: "job title",
        EMPLOYER_NAME: "employer name",
        EMPLOYER_CONTACT_NO: "employer contact number",
        MONTHLY_GROSS_INCOME: "monthly gross income",
        SOURCE_OF_INCOME: "source of income",
      },
      ERRORMESSAGE: {
        EMPLOYMENT_STATUS: "Unable to fetch employment status from server",
        COUNTRY_LIST: "Unable to fetch country list from server",
        INCOME_LIST: "Unable to fetch incomesource list from server",
      },
      FIELDNAME: {
        AREYOUTAXRESIDENTOFCANADA: "areYouTaxResidentOfCanada",
        AREYOUTAXRESIDENTOFOTHER: "areYouTaxResidentOfOtherCountry",
      },
    },
    CONTINUE: "Continue",
    API_KEYS: {
      COUNTRY_LIST: "countryList",
      INCOME_SOURCE_LIST: "IncomeSourceList",
      STATUS_LIST: "StatusList",
    },
  },

  EXISTING_MEMBER: {
    TITLE: "EXISTING CLIENTS",
    CHOICE_MESSAGE: `Do you have an account or are you already a client of ${FIInformation.FI_NAME}?`,
    INFOBLOCK: {
      TITLE: "Existing client verification",
      DESCRIPTION: `If you are already an existing or returning client of ${FIInformation.FI_NAME}, and use online banking, we can verify you with a one-time online log in.`,
    },
  },
  // DEBIT CARD
  DEBIT_CARD: {
    TITLE: "DEBIT CARD",
    CHOICE_MESSAGE: `Would you like a debit card for this account?`,
    API_KEYS: {
      GET_BUNDLE_PRODUCT_RELATION: "getBundleProductRelation",
      ADD_BUNDLE_PRODUCT: "addBundleProduct",
      DELTE_DEBIT_CARD: "deleteDebitCard",
    },
    PRODUCT_TYPE: "DEBITCARD",
    PRODUCT_TYPE_DOXIM: "debitCard",
  },

  SIGNATURE: {
    TITLE: "e-SIGNATURE",
    CHOICE_MESSAGE: `Please enter initials to accept contract.`,
    INSTRUCTION_PAGE1_TITLE: "Almost finished!",
    INSTRUCTION_PAGE1_SUBTITLE: "Last steps...",
    INSTRUCTION_PAGE1_DESCRIPTION:
      "In order to finalize your PRODUCT_NAME__HERE, we need your electronic signature.",
    INSTRUCTION_PAGE1_DESCRIPTION1:
      "Tap or click the button below and your electronic agreement will open in a new window.",
    INSTRUCTION_PAGE1_DESCRIPTION2:
      'Simply mark the document as instructed. When done, return to this window and hit the "Continue" button.',
    INSTRUCTION_PAGE1_DESCRIPTION3:
      "For your convenience, we will also send you an email containing a link to the same document.",
    INSTRUCTION_PAGE2_TITLE: "Electronic agreement requested.",
    INSTRUCTION_PAGE2_SUBTITLE: "Last steps...",
    INSTRUCTION_PAGE2_DESCRIPTION:
      "Your electronic agreement document should have opened in another browser window.",
    INSTRUCTION_PAGE2_DESCRIPTION1: `If you haven't done so already, mark the document as instructed. When done, return to this window and hit the "Continue" button.`,
    INSTRUCTION_PAGE2_DESCRIPTION2:
      "If your document did not open in another window, you may have pop-ups blocked in your browser settings.",
    INSTRUCTION_PAGE2_DESCRIPTION3: `For your convenience, you can also access the same document from the link in the email we sent you. Once completed, simply sign back into your marketplace profile and hit "Proceed" to complete your application with ${FIInformation.FI_NAME}.`,
    INSTRUCTION_PAGE3_TITLE: "Electronic agreement requested.",
    INSTRUCTION_PAGE3_SUBTITLE: "Wait a second...",
    INSTRUCTION_PAGE3_DESCRIPTION:
      "It looks like your electronic agreement has not yet been signed by all applicants.",
    INSTRUCTION_PAGE3_DESCRIPTION1:
      "Please check your email and complete your electronic agreement.",
    INSTRUCTION_PAGE3_DESCRIPTION2: `If there is still an issue, please contact a ${FIInformation.FI_NAME} customer service rep.`,
    CLICKTOBEGIN: "Begin e-Signature Docs",
    API_KEYS: {
      ESIGN_CREATE: "createEsignDoc",
      ESIGN_SEND: "sendEsignPackage",
      ESIGN_GET: "getEsign",
    },
    STATUS: {
      ACTIVATED: "Activated",
      COMPLETED: "Completed",
    },
    CONTRACT: "e-SIGNATURE",
    SIGN: "Signature",
  },

  // MEMBER CREATION COMPONENT STRINGS
  MEMBERCREATION: {
    TITLE: "BEFORE WE START...",
    FIRST_CARD_TEXT:
      "Be sure you and any co-applicants have reached the age of majority.",
    SECOND_CARD_TEXT:
      "You and any co-applicants have a valid Social Insurance Number (SIN).",
    THIRD_CARD_TEXT: "You and any co-applicants have a valid Govt. issued ID.",
    TERM_TEXT:
      "By continuing, you confirm that you have read and agree to the terms and conditions.",
    F1_TEXT: `Keep me informed about ${FIInformation.FI_NAME} news updates and special offers.`,
    F1_ALERT: `You also understand that some financial applications may require ${FIInformation.FI_NAME} to request a hard credit check. This may or may not impact your credit score. If a hard credit check will be required for an application, you will be alerted to it and asked to "Agree" before being asked to submit your application.`,
    TERM_TEXT_DETAIL: `You also understand that some financial applications may require ${FIInformation.FI_NAME} to request a hard credit check. This may or may not impact your credit score. If a hard credit check will be required for an application, you will be alerted to it and asked to "Agree" before being asked to submit your application.`,
    TERMS_ERROR_TEXT: "accept terms and conditions.",
    CONTACT_DETAILS: "",
    SIN_TEXT: "SIN",
    AGREE_TERMS: " Please read and agree to the following:",
    AGE_TEXT: "18+",
    API_KEYS: {
      TERMS_JSON: "termsJson",
      TERMS_LIST: "termsList",
    },
  },
  // ABOUT YOU COMPONENT STRINGS
  ABOUTYOU: {
    SCREENTITLE: "ABOUT YOU",
    UPLOADANDCONTINUE: "Upload & Continue",
    RETAKE: "Retake",
    CLICKTOBEGIN: "Tap To Begin",
    COMMONPART: {
      CAPTUREMODE: "Auto",
    },
    PARTONE: {
      SUBTITILE: "PART I: Identity Verification",
      ABOUTYOUINFO:
        "To verify your identity, you will need to upload pictures of your govt. issued photo ID.",
      LABEL: "Please select your ID type",
      IDLABEL: "ID",
      IDERROR: "your ID type",
      DEFAULTIDPLACEHOLDER: "Select your govt. issued ID",
    },
    PARTTWO: {
      SUBTITILE: "PART II: ID Scan",
      ABOUTYOUINFO: "Please take a picture of the front of your ID.",
      IDFRONTLABEL: "Front of ID card",
      DOCUMENTSETTING: {
        DOCUMENTSIDE: "Front",
        GLARETHRESHOLD: 15,
      },
    },
    PARTTHREE: {
      SUBTITILE: "PART II: ID Scan",
      ABOUTYOUINFO: "Now take a picture of the back of your ID.",
      IDBACKLABEL: "Back of ID card",
      DOCUMENTSETTING: {
        DOCUMENTSIDE: "Back",
        DOCUMENTTYPE: "License",
        CAPTUREMODE: "Manual",
        GLARETHRESHOLD: 2.5,
        COMPRESSIONTYPE: "JPEG",
        COMPRESSIONQUALITY: "50",
        CAPTUREATTEMPTS: 1,
        MANUALTIMEOUT: 8,
      },
    },
    PARTFOUR: {
      SUBTITILE: "IDENTITY PART III: Selfie",
      ABOUTYOUINFO: "Lastly, you will need to upload a selfie.",
      SELFIELABEL: "Selfie",
    },
    PARTFIVE: {
      TITLE: "ABOUT YOU - REVIEW",
      REVIEWFORM: {
        PERSONALSECTION: "PERSONAL INFORMATION",
        FIRSTNAMELABEL: "First name",
        FIRSTNAMEREF: "firstName",
        MIDDLENAMELABEL: "Middle name",
        LASTNAMELABEL: "Last name",
        DOBLABEL: "Date of birth",
        DOBPLACEHOLDER: "YYYY-MM-DD",
        SCANLABEL: "whether information scanned correctly",
      },
      THANKYOUTITLE: "Thanks ",
      THANKYOUTEXT: "We are almost finished with setting up your ",
      REVIEWREQ: "Please review the information below and update if needed.",
      CONFIRMQUESTION:
        "Did your personal information, like names and date of birth, scan correctly?",
      ADDRESSFORM: {
        ADDRESSSECTION: "RESIDENTIAL ADDRESS",
        MAILINGSECTION: "MAILING ADDRESS",
        ADDRESS0: "Address",
        ADDRESS1: "Address1",
        ADDRESS2: "Address2",
        CITY: "City",
        PROVINCE: "Province / Territory",
        POSTALCODE: "Postal code",
        ADDRESSCONFIRMLABEL: "whether address is same as mailing address",
        COUNTRY: "Country",
        COUNTRIES: {
          CA: { NAME: "Canada", CODE: "CA" },
          US: { NAME: "United States", CODE: "US" },
        },
      },
      ADDRESSCONFIRMQUESTION:
        "Is this address the same as your mailing address?",
      FIELDNAME: {
        MAILINGSAMEASCIVIC: "mailingSameAsCivic",
        SCANCONFIRMATION: "scanConfirmation",
      },
    },
    UPLOADERRORMESSAGE:
      "Image quality issue. Retake the picture and try uploading again.",
    ALTERNATETEXT:
      "If this problem persists, try signing into your profile on another device, like a tablet or your mobile phone and then continue your application.",
    CONTACTINFO:
      "You can also use chatbot or call us at: 1 - 888 - 555 - 5555 to find a branch and come in to complete your application in person.",
    API_KEYS: {
      CREATE_APPLICATION: "createApplication",
      GET_ID_TYPE: "getIdType",
      GET_PROVINCE_LIST: "getProvinceList",
      INSTNT_DOC: "instntDoc",
      SUBMIT_VERIFY: "submitVerify",
      GET_APPLICANT: "getApplicant",
    },
  },

  // GETTING STARTED COMPONENT STRINGS
  GETTING_STARTED: {
    TITLE: "GETTING STARTED",
    RESIDENT_STATUS: "What is your Canadian residency status?",
    CONFIRM_JOINT_ACCOUNT: "Will this be a joint account?",
    SELECT_BRANCH: "Select a preferred home branch location",
    SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT:
      "How would you like to receive your statements and correspondence?",
    SELECT_CREDIT_REQUIRED: "Will you need [a debit card] [credit cards]?",
    OVERDRAFT_PROTECTION_REQUIRED: "Will you need overdraft protection?",
    PAPER: "Paper",
    EMAIL: "Email",
    ERROR: {
      NAME_ERROR: "name",
      EMAIL_ERROR: "valid email",
      RESIDENT_STATUS: "canadian recidency status",
      CONFIRM_JOINT_ACCOUNT: "whether joint account or not",
      SELECT_BRANCH: "home branch location",
      SELECT_CREDIT_REQUIRED: "whether you need [a debit card] [credit cards]",
      SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT_REQUIRED:
        "how would you like to receive your account statements [email] [paper]",
      OVERDRAFT_PROTECTION_REQUIRED: "Whether you need overdraft protection",
    },
    APPEND_DIV_ID: "appendDivID",
    FORM: {
      NAMEPLACEHOLDER: "Enter name",
      EMAILPLACEHOLDER: "Enter email",
    },
    API_KEYS: {
      BRANCH_LIST: "branchList",
      RESIDENT_LIST: "residentList",
      GETTING_STARTED: "gettingStarted",
      GETTING_STARTED_VAULT: "getGettingStartedVault",
    },
  },

  // confirm page constant start
  CONFIRM_CREATION: {
    TITLE: "CONFIRM & SUBMIT",
    FIRST_MSG: "Thank you ",
    SECOND_MSG: ", for completing your application.",
    REVIEW_DETAILS_MSG:
      "Please review your details before confirming and submitting your request.",
    EDIT_BTN_TEXT: "Edit",
    // getting started block
    JOINT_ACCOUNT_REQUIRED: "Joint account: ",
    BRNACH: "Branch: ",
    ACCOUNT_STATMENT_CHOICE: "Receive statements via:",
    OVER_DRAFTPROTECTION__REQUIRED: "Overdraft protection: ",
    RESIDENCY_STATUS: "Residency status: ",
    // employment and income block
    CURRENT_EMPLOYER: "Current employer: ",
    EMPLOYER_PHONE: "Employer phone: ",
    EMPLOYER_SALARY: "Salary: ",
    EMPLOYMENT_STATUS: "Employment status: ",
    INCOME_TYPE: "Income type: ",
    ANNUAL_GROSS_INCOME: "Gross annual income: ",
    MONTHLY_GROSS_INCOME: "Gross monthly income: ",
    SOURCE_OF_INCOME: "Source of income: ",
    JOB_TITLE: "Job title: ",
    OCCUPATION: "Occupation: ",
    TAX_RESIDENT_OF_CANADA: "Tax resident of Canada: ",
    TAX_RESIDENT_OTHER_COUNTRY: "Tax resident of another country: ",
    IS_CITIZEN_OF_TAX_SELECT_COUNTRY: "Citizen of tax selected country: ",
    TAX_SELECT_COUNTRY: "Country: ",
    TAX_SELECT_COUNTRY_LIST: "Countries: ",
    TAX_IDENTITY_NO: "TIN: ",
    SSN_NUMBER: "SSN",
    SIN_NUMBER: "SIN:",
    // compliance block
    ACCOUNT_PURPOSE: "Account purpose: ",
    ISTHIRDPARTY: "Third party usage: ",
    THIRDPARTYNAME: "Name of third party:",
    THIRDPARTYREL: "Relationship:",
    JURISDICTIONTYPE: "Jurisdiction:",
    CLOSEPERSONJURISDICTIONTYPE: "Close person jurisdiction type:",
    ISPEPORHIO: "HIO ? ",
    CLOSEPERSONPEPORHIO: "Known PEP or HIO: ",
    APPLICANTTYPE: "Applicant type:",
    CLOSEPERSONDETAILS: "Name of PEP or HIO:",
    PERSON_NAME: "Name: ",
    PERSON: "Person: ",
    HIO: "HIO ",
    PEP_DOMESTIC: "PEP Domestic",
    HIO_STATUS: "HIO",
    PEP_FOREGIN: "PEP Foregin",
    POSITION_HELD: "Position held: ",
    INDICATE_WHO: "Who:",
    SAME_ADDRESS_AS_APPLICANT: "Shared address w/ applicant:",
    // about you block
    ABOUT_YOU: "ABOUT YOU",
    ADDRESS1: "Address: ",
    ADDRESS2: "Address continued: ",
    CITY: "City: ",
    DOB: "Date of birth: ",
    FIRST_NAME: "First name: ",
    MIDDLE_NAME: "Middle name: ",
    SURNAME: "Last name: ",
    POSTAL_CODE: "Postal code: ",
    STATE: "Province / Territory: ",
    SAMEASMAILING: "Mailing address same as listed: ",
    MAILINGADDRESSLINE1: "Mailing address: ",
    MAILINGADDRESSLINE2: "Mailing address continued: ",
    MAILINGCITY: "City: ",
    MAILINGPROVINCE: "Province / Territory: ",
    MAILINGPOSTALCODE: "Postal code: ",
    MAILINGCOUNTRY: "Country:",
    ISDOBSCAN: "ID scanned correctly: ",
    // disclimer block
    ACCOUNT_DISCLAIMER: "You must agree to the following:",
    ACCOUNT_DISCLAIMER_LABEL: "I agree.",
    ACCOUNT_DISCLAIMER_ERROR: "Please accept the terms.",
    BUREAU_CHECK: "Credit Bureau Check",
    ACCOUNT_TERMS_CHECK:
      "To successfully fulfill this application, we require an up-to-date credit check. If we do not already have one on file, we may need to request a new one which can have an impact on your credit score. We will only request a credit check should we not already have one on file.",
    THIRDPARTY_ACCOUNT:
      "Will any of your accounts be used by or on behalf of a third party?:",
    MEMBER_PEP_HIO_CHOICE: "Are you considered to be a PEP or HIO?:",
    PEP_HIO_CHOICE: "Is anyone close to you considered to be a PEP or HIO?:",
    OFFICE_HELD_LABEL: "Office held:",
    JURISDICTION_LABEL: "Jurisdiction:",
    SOURCE_OF_FUNDS_LABEL: "Source of funds:",
    ACCOUNT_PURPOSE_LABEL: "What is the intended use of the account?",
    API_KEYS: {
      CONFIRM_ACCOUNT_CREATE: "confirmAccountCreate",
    },
  },

  // Online Banking

  ONLINE_BANKING: {
    TITLE: "ONLINE BANKING",
    ONLINE_BANKING_BREADCRUMB: "Online Banking",
    REGISTER_FOR_ONLINE_BANKING_LABEL:
      "You have indicated you would like to register for online banking. Please set a temporary password. Upon your first login, you'll be prompted to create a new online banking password.",
    PROVIDE_TEMPORARY_PASSWORD_LABEL: "Online banking password",
    RETYPEPASSWORDLABEL: "Retype online banking password",
    API_KEYS: {
      ENABLE_ONLINE_BANKING: "enableOnlineBanking",
    },
  },
  // confirm page constant end

  STEPS: {
    GETTING_STARTED: "GETTING STARTED",
    ABOUT_YOU: "ABOUT YOU",
    EMPLOYMENT_AND_INCOME: "EMPLOYMENT",
    COMPLIANCE: "COMPLIANCE",
    ACCOUNT_INFORMATION: "ACCOUNT INFORMATION",
    CONFIRM_ACCOUNT_CREATE: "CONFIRM",
    BENEFICIARIES: "BENEFICIARIES",
    OVERDRAFT_PROTECTION: "OVERDRAFT PROTECTION",
    SPOUSAL_INFO: "RRSP SPOUSAL INFO",
    DEBIT_CARD: "DEBIT CARD",
    ONLINE_BANKING: "ONLINE BANKING",
    SIGNATURE: "CONTRACT",
    JOINT_APPLICANT: "JOINT APPLICANT",
    JOINT_APPLICANT_STATUS: "JOINT APPLICANT STATUS",
    LOAN_DETAILS: "LOAN DETAILS",
    PROPERTY_DETAILS: "PROPERTY OWNERSHIP",
    SECURITY_VEHICLE: "VEHICLE DETAILS",
    INCOME: "INCOME",
    EXPENSE: "EXPENSES",
    ASSET: "ASSETS",
    LIABILITIES: "LIABILITIES",
    DOCUMENT_UPLOAD: "DOCUMENT UPLOAD",
    FEES: "FEES",
    COST_OF_BORROWING: "COST OF BORROWING",
    LOAN_SUMMARY: "LOAN SUMMARY",
    LOAN_ADJUDICATION_RESULTS: "LOAN RESULT",
    BEFORE_WE_START: "BEFORE WE START",
    DISBURSEMENT: "DISBURSEMENT",
    REPAYMENT: "REPAYMENT",
    VERIFICATION_CODE: "VERIFICATION",
    TERMS_AND_CONDITIONS: "TERMS AND CONDITIONS",
    LOAN_PURPOSE: "GETTING STARTED",
  },

  COMPLIANCE: {
    TITLE: "COMPLIANCE",
    FORM: {
      ACCOUNT_PURPOSE_LABEL: "Select membership purpose",
      ACCOUNT_PURPOSE_PLACEHOLDER: "Select the status that best describes you",
      ACCOUNT_PURPOSE_ERROR: "membership purpose",
      THIRDPARTY_TITLE: "THIRD PARTY",
      THIRDPARTY_ACCOUNT:
        "Will any of your accounts be used by or on behalf of a third party?",
      THIRDPARTY_ACCOUNT_ERROR: "third party account",
      THIRDPARTY_NAME: "Name",
      THIRDPARTY_NAME_PLACEHOLDER: "Enter third party name",
      THIRDPARTY_NAME_ERROR: "third party name",
      THIRDPARTY_RELATIONSHIP: "Relationship",
      THIRDPARTY_RELATIONSHIP_PLACEHOLDER: "Select relationship",
      THIRDPARTY_RELATIONSHIP_ERROR: "relationship",
      MEMBER_PEP_HIO_CHOICE: "Are you considered to be a PEP or HIO?",
      PEP_HIO_CHOICE: "Is anyone close to you considered to be a PEP or HIO?",
      PEP_HIO_CHOICE_ERROR: "PEP or HIO",
      PERSON_TITLE: "PEP AND/OR HIO",
      INDICATE_WHO_LABEL: "Who is considered a PEP or HIO",
      INDICATE_WHO_PLACEHOLDER:
        "select the relationship considered a PEP or HIO",
      INDICATE_WHO_ERROR: "considered a PEP or HIO",
      PERSON_NAME: "Name",
      PERSON_NAME_PLAEHOLDER: "Name",
      PERSON_NAME_ERROR: "name",
      PERSON_RELATIONSHIP: "Relationship",
      PERSON_RELATIONSHIP_PLACEHOLDER: "Relationship",
      PERSON_RELATIONSHIP_ERROR: "relationship",
      HOI_CHOICE: "Select all those that may apply",
      HIO_CHOICE_ERROR: "all those that may apply",
      OFFICE_HELD_LABEL: "Office held",
      OFFICE_HELD_PLACEHOLDER: "Enter office held",
      OFFICE_HELD_ERROR: "office held",
      JURISDICTION_LABEL: "Jurisdiction",
      JURISDICTION_PLACEHOLDER: "Enter jurisdiction",
      JURISDICTION_ERROR: "jurisdiction",
      JURISDICTION_TYPE_LABEL: "Jurisdiction Type",
      JURISDICTION_TYPE_PLACEHOLDER: "Enter jurisdiction type",
      JURISDICTION_TYPE_ERROR: "jurisdiction type",
      SOURCE_OF_FUNDS_LABEL: "Source of funds",
      SOURCE_OF_FUNDS_PLACEHOLDER: "Enter source of funds",
      SOURCE_OF_FUNDS_ERROR: "source of funds",
      ADD_ANOTHER_PERSON: "Add Another",
      FIELDNAME: {
        ISTHIRDPARTACCOUNT: "isThirdPartyAccount",
        AREYOUPEPORHIO: "areYouPEPorHIO",
        MEMBERPEPDOMESTIC: "memberPEPDomestic",
        MEMBERPEPFOREIGN: "memberPEPForeign",
        MEMBERHIO: "memberHIO",
        ISANYONECONSIDEREDPEPORHIO: "isAnyoneConsideredPEPHIO",
        PEPDOMESTIC: "PEPDomestic",
        PEPFOREIGN: "PEPForeign",
        HIO: "HIO",
      },
    },
    CHOICE: {
      PEP_DOMESTIC: "PEP-Domestic",
      PEP_FOREIGN: "PEP-Foreign",
      HOI: "HIO",
    },
    CONTINUE: "Continue",
    API_KEYS: {
      ACCOUNT_PURPOSE_LIST: "accountPurposeList",
      INDICATE_WHO_LIST: "indicateWhoList",
    },
  },

  FUND_ACCOUNT: {
    FUND_CARD: {
      FUND_ACCOUNT: "FUND ACCOUNT",
      ACCOUNT: "Account ",
      FUND_METHOD: "Funding method ",
      AMOUNT: "Amount ",
    },
    FUND_ACCOUNT_BREADCRUMB: "Activate Account",
    ACCOUNTOPEN_TEXT1: "Now, Let's fund your account.",
    ACCOUNTOPEN_TEXT2:
      "we encourage you to fund your account so that you may begin to use your account.",
    VAULT_TITLE: "YOUR CART",
    CHEQUING_ACCOUNT: "GO SIMPLE - CHEQUING ACCOUNT",
    VAULT_STATUS: "Status:",
    VAULT_INSTITUTION: "Institution",
    VAULT_TRANSIT: "Transit No:",
    VAULT_ACCOUNT_NO: "Full Account No:",
    FUND_BUTTON: "Fund Button",
    FUND_TITLE: "Fund Account",
    FUND_AMOUNT_TITLE: "FUNDING AMOUNT",
    FUND_DEPOSIT_AMOUNT: "Deposit Amount",
    FUND_AMOUNT: "Enter amount",
    FUND_AMOUNT_SYMBOL: "$00.00",
    FUND_TRANSFER: "Transfer Date -",

    METHOD: "FUND ACCOUNT METHOD",
    AMOUNT: "FUND ACCOUNT AMOUNT",

    PAYMENT_TYPE_CREDIT_CARD: "Credit Card",
    PAYMENT_TYPE_DIRECT_DEPOSIT: "Direct Deposit",

    FUND_ERROR_MSG: "Please enter/select amount",
    OTHER: "Other",
    CARDS: "Cards",
    DIRECT_DEBIT: "DirectDebit",
    FUND_COUNTRY: "CA",
    CREDIT_CARD: "creditCard",
    EFT: "eft",
    FUND_AMOUNT_ERROR_MSG: "Enter amount between($%min% to $%max%)",
    FUND_AMOUNT_NUMERIC_ERROR_MSG: "Fund amount provided must be numeric",
    FIINFO_ERROR_MSG: "Unable to FI Info from server",
    PAYSAFE_STAGE_BEFORE_PAYMENT: "BeforePayment",
    PAYSAFE_STAGE_DURING_PAYMENT: "DuringPayment",
    PAYSAFE_STAGE_AFTER_PAYMENT: "AfterPayment",
    FUND_TRANSACTION_ID: "Transaction ID: ",
    API_KEYS: {
      FUND_PAYMENT_DETAILS: "fundPaymentDetails",
      FUND_PROCESSOR: "fundProcessor",
    },
  },
  FUND_METHOD: {
    FUND_TITLE: "FUNDING METHOD",
    FUND_SUB_TITLE: "Select how you’d like to fund your account",
    FUND_ERROR_MSG: "Please select funding method",
    SKIP: "Skip for now",
  },
  INVERITE_IFRAME_MODAL: {
    TITLE: "Inverite",
    FOOTER: "Your bank account login provided by Inverite",
    API_KEYS: {
      CREATE: "create",
      FETCH: "fetch",
      CREATE_ACCOUNT_LIST: "create/accountlist",
      FETCH_ACCOUNT_LIST: "fetch/accountlist",
    },
  },
  MARKETPLACE: {
    TITILE: "Online Marketplace",
    HEADER: {
      CARD: {
        TITLE: "Returning to the marketplace?",
        BODY: {
          TITLE:
            "Sign in to your profile to continue your applications or check their statuses.",
          SIGNIN: "Sign In",
        },
        FOOTER: {
          TITLE: "New here? ",
          REGISTER: "Register Now",
        },
      },
    },
    PAGE_BANNER: {
      SIGN_IN: "SIGN_IN",
      REGISTER: "REGISTER",
      WELCOME_TEXT: "Welcome ",
    },
    BODY: {
      SEE_PRODUCTS: "See products for:",
      FILTER_BY: "Filter by:",
      PERSONAL_BANKING: "Personal Banking",
      BUSINESS_BANKING: "Business Banking (coming soon)",
      CATEGORY: "Category",
      ALL: "All",
      SEE_DETAILS: "See details",
      ADDED: "Added",
      ADD_TO_VAULT: "Add To Cart",
      ADDED_TO_VAULT: "Added to cart",
      REMOVE_FROM_VAULT: "Remove from Cart",
      CANCEL: "Cancel",
      BRANCH_DETAILS: "See branch for details",
      MULTIPLE_PRODUCT_ADD:
        "We notice that you have added the same product more than once. Please review your cart if this was unintentional.",
      PRODUCT_ADDED: "Product added to cart",
      SEE_BRANCH_DETAILS: "See branch for details",
    },
    API_KEYS: {
      PRODUCT_LIST: "productList",
      PRODUCT_VAULT: "product_vault",
      GET_PRODUCT_VAULT: "get_product_vault",
      GET_FI_INFO: "get_fi_info",
    },
  },
  VAULT_DASHBOARD: {
    TABS: [
      {
        NAME: "MY PROFILE",
        TAB_NAME: "myProfile",
      },
      {
        NAME: "MARKETPLACE",
        TAB_NAME: "marketPlace",
      },
    ],
    MY_PROFILE: "MY PROFILE",
    MARKETPALCE: "MARKETPLACE",
    SHOPPING_CART: "Shopping Cart",
    APPLICATION_COMPLETE: "Completed Applications",
    STATUS: "STATUS",
    YOUR_PRODUCT_OFFER: "Your Product Offers",
    CONTINUE: "Continue",
    SAVING_ACC: "Savings acc",
    ADD_TO_VAULT: "Add To Cart",
    PROCEED: "Proceed",
    SEE_DETAILS: "See details",
    DELETE_TITLE: "Remove Product",
    DELETE_DESCRIPTION:
      "Are you sure you want to remove this product from your cart?",
    NO_APPLICATION_COMPLETE: "0 applications complete",
    EMPTY_VAULT_TEXT: "0 items",
    COMPLETED: "Completed",
    IN_REVIEW: "In Review",
    REJECTED: "Rejected",
    IN_PROGRESS: "In Progress",
  },
  JOINT_APPLICANT: {
    JOINT_APPLICANT_BREADCRUMB: "Joint Applicants",
    TITLE: "JOINT APPLICANTS",
    FORM: {
      APPLICANT_NO: "CO-APPLICANT NO. ",
      CONFIRM_JOINT_APPLICANT: "Will this be a joint application?",
      EMAILLABEL: "Email",
      EMAILPLACEHOLDER: "Enter email",
      MOBILENOLABEL: "Mobile no.",
      MOBILENOPLACEHOLDER: "Enter mobile no.",
      APPLICANT_TYPE: "Applicant type",
      APPLICANT_TYPE_DEFAULT_SELECT:
        "Select the best description of your co-applicant",
      REMOVE_COAPPLICANT: "Remove Joint Applicant",
      RESEND_COAPPLICANT: "Resend Email Invite",
      ADD_BTN: "Add Another",
      JOINT_PRESENT_LABEL:
        "Are your joint applicant(s) physically present with you right now?",
    },
    ERROR: {
      EMAIL_ERROR: "valid email.",
      EMAIL_DUPLICATE: "Duplicate email address.",
      MOBILE_ERROR: "phone number.",
      MOBILE_DUPLICATE: "Duplicate mobile phone.",
      TYPE_REQUIRED: "applicant type.",
      CONFIRM_JOINT_ACCOUNT: "whether joint account or not",
      SELECT_BRANCH: "home branch location",
      SELECT_CREDIT_REQUIRED: "whether you need [a debit card] [credit cards]",
      SELECT_CHOICE_TO_RECIVE_ACCOUNT_STATEMENT_REQUIRED:
        "how would you like to receive your account statements [email] [paper]",
      OVERDRAFT_PROTECTION_REQUIRED: "Whether you need overdraft protection",
      PARTIAL_MATCH_TITLE: "Recheck your co-applicant's contact details",
      PARTIAL_MATCH_BODY1:
        "It appears your co-applicant has already registered an online marketplace profile.",
      PARTIAL_MATCH_BODY2:
        "To ensure they recieve their invite, be sure you are entering the same email and phone number that your co-applicant used for their own registration.",
    },
  },
  JOINT_APPLICANT_STATUS: {
    JOINT_APPLICANT_STATUS_BREADCRUMB: "Joint Applicant Status",
    TITLE: "Joint Application Status",
    SUBTITLE1:
      "Thank you for completing your portion of this joint application.",
    SUBTITLE2:
      "Your application status is PENDING until all participants have completed their portion of this application.",
    TABLE_DESCRIPTION: "Below is a list of your applicants.",
    REMOVE_COAPPLICANT: "Remove Joint Applicant",
    EMAIL_LABEL: "Email:",
    MOBILENO_LABEL: "Mobile no.:",
    APPLICANT_TYPE_LABEL: "Applicant type:",
    APPLICANT_TYPE_STATUS: "Status:",
    APPLICANT_INFO_TEXT1:
      "You will be notified by email when all applicants have completed their portion of the application and it is ready for you to complete your online agreement and signatures.",
    APPLICANT_INFO_TEXT2:
      "You can also check the progress of your co-applicants by opening the 'See Details' section of this application in your home dashboard.",
  },

  // OVERDRAFT PROTECTION COMPONENT STRINGS
  OVERDRAFT_PROTECTION: {
    TITLE: "OVERDRAFT PROTECTION",
    OVERDRAFT_PROTECTION_REQUIRED: "Will you need overdraft protection?",
    ERROR: {
      OVERDRAFT_PROTECTION_REQUIRED: "Whether you need overdraft protection",
    },
  },

  ACCOUNT_INFORMATION: {
    TITLE: "ACCOUNT INFORMATION",
    FORM: {
      ACCOUNT_PURPOSE_LABEL: "What is the intended use of the account?",
      ACCOUNT_PURPOSE_PLACEHOLDER: "Select the intended use of the account",
    },
    ERROR: {
      SELECT_INTENDED_USE: " intended use of the account",
      INTENDED_USE_ERROR: "Unable to fetch intended use list from server",
    },
    API_KEYS: {
      INTENDED_USE: "intendedUse",
      BANKING_PRODUCT_LIST: "bankingProductList",
    },
  },

  SPOUSAL_INFO: {
    SCREENTITLE: "RRSP Spousal Information",
    SPOUSE_QUI: "Is this RRSP for your Spouse?",
    API_KEYS: {
      SPOUSAL_DATA: "spousalData",
    },
  },

  DEBIT_CARD_BREADCRUMB: "Debit Card",
  BENEFICIARIES: {
    TITLE: "BENEFICIARIES",
    FORM: {
      RESIDENCE_ADDRESS: "Residence Address",
      BENEFICIARY: "Beneficiary #",
      BENEFICIARIES_PURPOSE_LABEL:
        "Do you wish to register beneficiaries for this account?",
      ADD_ANOTHER: "Add Another",
      PERSONAL_INFORMATION: "PERSONAL INFORMATION",
      FIRSTNAMELABEL: "First name",
      MIDDLENAMELABEL: "Middle name",
      LASTNAMELABEL: "Last name",
      DOBLABEL: "Date of birth",
      DOBPLACEHOLDER: "YYYY-MM-DD",
      RELATIONSHIP: "Relationship",
      RELATIONSHIP_PLACEHOLDER: "Select relationship",
      BENEFICIARY_TYPE: "Beneficiary Type",
      BENEFICIARY_PLACEHOLDER: "Select beneficiary",
      ADDRESSSECTION: "RESIDENTIAL ADDRESS",
      ADDRESS0: "Address",
      ADDRESS1: "Address1",
      ADDRESS2: "Address2",
      CITY: "City",
      PROVINCE: "Province / Territory",
      POSTALCODE: "Postal code",
      MOBILENOLABEL: "Mobile no.",
      MOBILENOPLACEHOLDER: "Enter mobile no.",
      SIN: "Social Insurance Number (SIN)",
      TAX_RESIDENCY: "TAX RESIDENCY",
      COUNTRY: "country",
      SELECT_COUNTRY: "Select country",
      SIN_PLACEHOLDER: "Enter social insurance number (SIN)",
      CANCEL: "CANCEL",
      SAVE: "SAVE",
      CLEAR: "CLEAR",
      DELETE_TITLE: "Remove Beneficiaries",
      DELETE_DESCRIPTION: "Are sure you want to remove these beneficiaries?",
      DELETE_SINGLE_DESCRIPTION:
        "Are sure you want to remove this beneficiary?",
      ADD_BENEFICIARY_TITLE: "Add Beneficiary",
      ADD_BENEFICIARY_DESCRIPTION: "Beneficiary added successfully.",
      UPDATE_BENEFICIARY_TITLE: "Edit Beneficiary",
      UPDATE_BENEFICIARY_DESCRIPTION: "Beneficiary updated Successfully.",
      DELETE_BENEFICIARY_TITLE: "Remove Beneficiary",
      DELETE_BENEFICIARY_DESCRIPTION: "Beneficiary deleted Successfully.",
      FIRSTNAMELABELCONFIRMPAGE: "First name:",
      MIDDLENAMELABELCONFIRMPAGE: "Middle name:",
      LASTNAMELABELCONFIRMPAGE: "Last name:",
      DOBLABELCONFIRMPAGE: "Date of birth:",
      RELATIONSHIPCONFIRMPAGE: "Relationship:",
      BENEFICIARY_TYPECONFIRMPAGE: "Beneficiary Type:",
      ADDRESS1CONFIRMPAGE: "Address1:",
      ADDRESS2CONFIRMPAGE: "Address2:",
      CITYCONFIRMPAGE: "City:",
      PROVINCECONFIRMPAGE: "Province / Territory:",
      POSTALCODECONFIRMPAGE: "Postal code:",
      MOBILENOLABELCONFIRMPAGE: "Mobile no:",
      COUNTRYCONFIRMPAGE: "Select country:",
      SINCONFIRMPAGE: "Social Insurance Number (SIN):",
    },
    ERROR: {
      BENEFICIARY_ERROR: " beneficiary choice",
      RELATIONSHIP_ERROR: "relationship",
      PRIMEARY_REQUIRED: "One primary beneficiry is required.",
      ONLYONE_PRIMERY_REQUIRED: "Only one beneficiary can be a primary.",
    },
    API_KEYS: {
      GET_BENEFICIARY_TYPE: "getBeneficiaryType",
      GET_BENEFICIARY_RELATION: "getBeneficiaryRelation",
      ADD_BENEFICIARIES: "addBeneficiaries",
      GET_BENEFICIARIES: "getBeneficiaries",
      DELETE_BENEFICIARY: "deleteBeneficiary",
      EDIT_BENEFICIARIES: "editBeneficiary",
    },
  },
  JOIN_APPLICANT: {
    INVITE_APPLY_URI: "/inviteapply",
    VAULT_KEY: "JOINT-APPLICANT",
    JOINT_STATUS_PENDING: "PENDING",
    JOINT_STATUS_FINISHED: "FINISHED",
    JOINT_STATUS_DELETED: "DELETED",
    IN_SESSION: "IN-SESSION",
    OUT_OF_SESSION: "OUT_OF_SESSION",
    JOINT_APPLICANT_SLOTID: "JOINT_APPLICANT_SLOTID",
    JOINT_APPLICANT_APPLICATION_ID: "JOINT_APPLICANT_APPLICATION_ID",
    API_KEYS: {
      ADD_APPLICATION_SLOT: "addApplicationSlot",
      ADD_JOINT_APPLICANTS: "addJointApplicants",
      INVITE_APPLICATION_SLOT: "inviteApplicationSlot",
      GET_APPLICANT_TYPE: "getApplicantType",
      GET_APPLICATION_PROPERTY: "getApplicationProperty",
      DELETE_APPLICATION_SLOT: "deleteApplicationSlot",
      ADD_GLOBAL_VAULT: "addGlobalVault",
      GET_GLOBAL_VAULT: "getGlobalVault",
      APPLICANT_CREATE_REQUEST: "applicantCreateRequest",
      APPLICANT_CREATE_SELF: "applicantCreateSelf",
      OTP_VERIFY_INVITE: "otpVerifyInvite",
      FILL_APPLICATION_SLOT: "fillApplicationSlot",
      CREATE_REQUEST_KEY_SELF: "request",
      CREATE_REQUEST_SELF: "self",
      APPLICANT_CREATE_CONFIRM: "confirmApplicantCreate",
    },
    PRODUCT_TEMPLATE: [
      {
        templateId: 1,
        flow: [
          {
            index: 0,
            pageId: "Page1",
            componentName: "GETTING_STARTED",
            componentType: "Applicant",
          },
          {
            index: 1,
            pageId: "Page2",
            componentName: "DOC_VERIFICATION",
            componentType: "Applicant",
          },
          {
            index: 2,
            pageId: "Page3",
            componentName: "ABOUT_YOU",
            componentType: "Applicant",
          },
          {
            index: 3,
            pageId: "Page4",
            componentName: "EMPLOYMENT_AND_INCOME",
            componentType: "Applicant",
          },
          {
            index: 4,
            pageId: "Page5",
            componentName: "COMPLIANCE",
            componentType: "Applicant",
          },
          {
            index: 5,
            pageId: "Page6",
            componentName: "CONFIRM_ACCOUNT_CREATE",
            componentType: "Application",
          },
        ],
      },
    ],
    PRODUCT_TEMPLATE_IN_SESSION: [
      {
        templateId: 1,
        flow: [
          {
            index: 0,
            pageId: "Page1",
            componentName: "GETTING_STARTED",
            componentType: "Applicant",
          },
          {
            index: 1,
            pageId: "Page2",
            componentName: "DOC_VERIFICATION",
            componentType: "Applicant",
          },
          {
            index: 2,
            pageId: "Page3",
            componentName: "ABOUT_YOU",
          },
          {
            index: 3,
            pageId: "Page15",
            componentName: "CONFIRM_ACCOUNT_CREATE",
            componentType: "Application",
          },
        ],
      },
    ],
  },
  LOAN_DETAILS: {
    TITLE: "Loan Details",
    SUBTITLE: "Enter your loan details here to begin.",
    BREADCRUMB: "Loan Details",
    LOAN_SUMMARY: "Loan Summary",
    COST_TO_BORROW: "Cost To Borrow",
    // LOAN DETAILS COMPONENT STRINGS
    FORM: {
      LOC: {
        LABEL_AMOUNT: "Line of credit amount",
        LABEL_ESTIMATED_RATE: "* Estimated interest rate for this loan",
        TABLE_TITLE: "Here's how your line of credit is looking so far...",
        HEADER: "Line of Credit Breakdown",
        MAXIMUM_CREDIT_AMOUNT: "Maximum credit limit:",
        REQUESTED_CREDIT_AMOUNT: "Requested credit limit:",
        CURRENT_INTEREST_RATE: "Current interest rate:",
        EXPECTED_PAYMENT_SCHEDULE: "Expected repayment schedule:",
        LOAN_TERM: "Loan term:",
        LOAN_TERM_REVOLVING: "Revolving",
        LABEL_MONTHLY_PAYMENT: "Monthly payment:",
        MONTHLY_PAYMENT: "Based on current rate and amount owed.",
        NOTE1:
          "In the next step you will be required to submit information about your property.",
        FINE_PRINT1:
          "* Interest rate is variable and will be prone to fluctuations over time. Initial interest rate will be determined at the time loan adjudication.",
        FINE_PRINT2:
          "** Monhtly payment amount is for display purposes only. It is based on a monthly payment frequency with the assumption the full amount of the line of credit is being used. Monthly payment is based on interest only.",
      },
      HELOC: {
        LABEL_ASSESSED_VALUE: "Your assessed property value",
        ASSESSED_AMOUNT_PLACEHOLDER: "$",
        LABEL_MORTGAGE_BALANCE: "Estimated outstanding mortgage balance",
        LABEL_HELOC_REQUEST_AMOUNT: "HELOC amount request",
        LABEL_HELOC_REQUEST_AMOUNT_DESCRIPTION:
          "Based on your property value and mortgage balance you may pre-qualify for a line of credit up to",
        LABEL_TERM: "HELOC term",
        TABLE_TITLE: "Here's how your line of credit is looking so far...",
        HEADER: "Line of Credit Breakdown",
        MAXIMUM_CREDIT_AMOUNT: "Maximum credit limit:",
        REQUESTED_CREDIT_AMOUNT: "Requested credit limit:",
        MAX_AMOUNT_HINT: "You may be able to pre-qualify for up to",
        CURRENT_INTEREST_RATE: "Current interest rate:",
        EXPECTED_PAYMENT_SCHEDULE: "Expected repayment schedule:",
        MAXIMUM_AMOUNT: "Maximum amount:",
        LOAN_TERM: "Loan term:",
        LOAN_TERM_REVOLVING: "Revolving",
        LABEL_MONTHLY_PAYMENT: "Monthly payment:",
        MONTHLY_PAYMENT: "Based on interest",
        NOTE1:
          "In the next step you will be required to submit information about your property.",
        FINE_PRINT1:
          "* Interest rate is variable and will be prone to fluctuations over time. Initial interest rate will be determined at the time loan adjudication.",
        FINE_PRINT2:
          "** Monhtly payment amount is for display purposes only. It is based on a monthly payment frequency with the assumption the full amount of the line of credit is being used. Monthly payment is based on interest only.",
        ERROR: {
          ASSESSED_VALUE_REQUIRED: "assessed property value.",
          MORTGAGE_BALANCE_REQUIRED: "mortgage balance.",
          HELOC_AMOUNT_REQUIRED: "heloc loan amount.",
        },
      },
      MORTGAGE: {
        ERROR: {
          VALUE_REQUIRED: "property value.",
        },
      },
      AUTO: {
        LABEL_VALUE: "Price of the vehicle",
        LABEL_DOWN_PAYMENT: "Down payment",
        LABEL_ESTIMATED_LOAN: "* Estimated loan amount",
        LABEL_TERM: "Loan term",
        LABEL_ESTIMATED_RATE: "* Estimated interest rate for this loan",
        TABLE_TITLE: "Here's how your vehicle loan is looking so far...",
        NOTE1:
          "In the next step you will be required to submit information about the vehicle.",
        NOTE2:
          "Required information will include make, model, Vehicle Identification Number (VIN).",
        FINE_PRINT1:
          "* Final interest rates are determined at the time of loan approval.",
        FINE_PRINT2:
          "** You will be able to set the payment frequency based on your needs once the loan has been approved.",
        ERROR: {
          VEHICLE_PRICE_REQUIRED: "vehicle price value.",
        },
      },
      PRODUCT_LABEL_LOAN_AMOUNT: "Loan amount",
      PRODUCT_LABEL_LOAN_TERM: "Loan term (months)",
      PRODUCT_LABEL_LOAN_TERM_YEARS: "Loan term (years)",
      MONTH: "mo.",
      MONTHS: "months",
      YEAR: "year",
      YEARS: "years",
      PRODUCT_LABEL_ESTIMATED_RATE: "* Estimated interest rate for this loan",
      PRODUCT_LABEL_REPAYMENT_SCHEDULE: "Payment frequency",
      PRODUCT_LABEL_REPAYMENT_SCHEDULE_DATE: "First payment date",
      PRODUCT_LABEL_REPAYMENT_SCHEDULE_DATE_HINT:
        "Enter the date for your first payment.",
      PRODUCT_LABEL_LOAN_DETAILS: "Your loan details: ",
      PRODUCT_LABEL_LOAN_DETAILS_BREAKDOWN: "This is your loan so far...",
      PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT: "Loan amount: ",
      PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM: "Loan term: ",
      PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE: "* Interest rate: ",
      PRODUCT_LABEL_LOAN_DETAILS_MONTHLY_PAYMENT: "** Monthly payment: ",
      PRODUCT_LABEL: "Loan product",
      PRODUCT_LABEL_DEFAULT_OPTION: "Select your loan product",
      PURPOSE_LABEL: "Loan purpose",
      PURPOSE_LABEL_DEFAULT_OPTION: "Select the purpose of the loan",
      PROPERTY_VALUE_LABEL: "Property value",
      DOWNPAYMENT_AMOUNT_LABEL: "Down payment amount",
      DOWNPAYMENT_AMOUNT_PLACEHOLDER: "Amount",
      DOWNPAYMENT_AMOUNT_HINT: "To the nearest whole dollar",
      DOWNPAYMENT_PERCENTAGE_PLACEHOLDER: "Percent",
      DOWNPAYMENT_PERCENTAGE_HINT: "Or enter a percentage",
      INSURANCE_LABEL: "Default mandatory mortgage insurance amount",
      AMOUNT_LABEL: "Amount",
      AMOUNT_PLACEHOLDER: "Enter amount",
      AMOUNT_HINT:
        "This is usually the purchase price minus your down payment.",
      TERM_LABEL: "Loan term",
      TERM_DEFAULT_OPTION: "Select the loan term",
      MORTGAGE_ESTIMATE_LABEL: "* Estimated mortgage required",
      LOAN_QUESTION1: "Is this loan for another person or third-party?",
      LOAN_QUESTION2: "What will the loan be used for?",
      AMORTIZATION_LABEL: "Loan amortization",
      AMORTIZATION: "Amortization",
      AMORTIZATION_DEFAULT_OPTION: "Choose an amortization period",
      REPAYMENT_FREQUENCY_LABEL: "Payment frequency",
      INTEREST_RATE_LABEL: "Interest rate",
      INTEREST_RATE_OTPION: "Choose an available rate option",
      OWN_INTEREST_RATE_LABEL: "Enter your rate",
      OWN_INTEREST_RATE_HINT:
        "The interest rate must be between 0% and 30%. *Note to developers, means we will stick a variable defined by the FI in there.",
      FIRST_REPAYMENT_DATE_LABEL: "First payment date",
      MONTHLY_PAYMENT: "Monthly payment",
      MORTGAGE_TABLE_TITLE: "Here's how your mortgage is looking so far...",
      PERSONAL_LOAN_FINE_PRINT1:
        "* Final interest rates are determined at the time of loan approval.",
      PERSONAL_LOAN_FINE_PRINT2:
        "** You will be able to set the payment frequency based on your needs once the loan has been approved.",
      MORTGAGE_FINE_PRINT1:
        "* Final interest rates are determined at the time of loan approval.",
      MORTGAGE_FINE_PRINT2:
        "** You will be able to set the payment frequency based on your needs once the loan has been approved.",
      NO_ACCOUNTS: "No accounts available.",
    },
    FORMAT: {
      CURRENCY: "###,###.00",
      DECIMAL: "",
    },
    API_KEYS: {
      GET_LOAN_PRODUCT: "GET_LOAN_PRODUCT",
      GET_LENDING_PRODUCT: "GET_LENDING_PRODUCT",
      UPDATE_LENDING_PRODUCT: "UPDATE_LENDING_PRODUCT",
      LOAN_DELETE_FINANCIAL: "LOAN_DELETE_FINANCIAL",
      LOAN_DELETE_LOAN_SECURITY: "LOAN_DELETE_LOAN_SECURITY",
      LOAN_UPDATE_LENDING_PRODUCT: "LOAN_UPDATE_LENDING_PRODUCT",
      LOAN_GET_LOAN_SECURITY: "LOAN_GET_LOAN_SECURITY",
      GET_FINANCIALANALYSYS_LIST: "GET_FINANCIALANALYSYS_LIST",
      GET_FINANCIALANALYSYS_FINANCIAL_LIST:
        "GET_FINANCIALANALYSYS_FINANCIAL_LIST",
    },
  },
  LOAN_PURPOSE: {
    TITLE: "Personal Loan - Premium Usecured",
    BODY_TITLE: "Getting Started",
    BREADCRUMB: "Getting Started",
    FORM: {
      PRODUCT_LABEL_PURPOSE: "Select the purpose of this loan",
      PRODUCT_LABEL_DEFAULT_OPTION: "Select your loan product",
      PURPOSE_LABEL: "Loan purpose",
      PURPOSE_LABEL_DEFAULT_OPTION: "Select the purpose of the loan",
      PURPOSE_LABEL_INPUT_OPTION: "What is the purpose of the loan?",
    },
    API_KEYS: {
      GET_LOAN_PRODUCT: "GET_LOAN_PRODUCT",
    },
  },
  PROPERTY_DETAILS: {
    TITLE: "Property",
    PAGE_DESCRIPTION: "Tell us about the property.",
    BREADCRUMB: "Property",
    FORM: {
      UNIT_LABEL: "Unit",
      ADDRESS_SUBTITLE: "PROPERTY ADDRESS",
      STREETNUMBER_LABEL: "Street No.",
      STREETNAME_LABEL: "Street name",
      STREETTYPE_LABEL: "Street type",
      CITY_LABEL: "City",
      PROVINCE_LABEL: "Province",
      POST_CODE_LABEL: "Postal code",
      PRIMARY_RESIDENCE: "Is this your primary residence? Select yes or no.",
      PROPERTY_LISTING_TYPE_LABEL: "Property listing type",
      VALUE_SUBTITLE: "PROPERTY VALUE",
      APPRAISED_VALUE_LABEL: "Appraised value",
      EXISTING_MORTGAGE_VALUE_LABEL: "Existing mortgage balance",
      PROPERTY_QUALIFYING_VALUE_LABEL: "Property qualifying value",
      ADD_BTN: "Add Another",
      VALUE_PLACEHOLDER: "Enter value",
    },
    API_KEYS: {
      LOAN_ADD_REAL_ESTATE: "AddLoanRealEstate",
      LOAN_GET_REAL_ESTATE: "GetLoanRealEstate",
      LOAN_UPDATE_REAL_ESTATE: "UpdateLoanRealEstate",
    },
  },
  ASSET_PROPERTY: {
    TITLE: "Property Ownership",
    PAGE_DESCRIPTION: "Add any properties you may own.",
    BREADCRUMB: "Properties",
    FORM: {
      CONFIRM_PROPERTY: "Do you own any properties?",
      PROPERTY_NO: "PROPERTY No.",
      REMOVE_PROPERTY: "Remove Property",
      UNIT_LABEL: "Unit",
      ADDRESS_SUBTITLE: "ADDRESS",
      STREETNUMBER_LABEL: "Street No.",
      STREETNAME_LABEL: "Street name",
      STREETTYPE_LABEL: "Street type",
      CITY_LABEL: "City",
      PROVINCE_LABEL: "Province",
      POST_CODE_LABEL: "Postal code",
      PRIMARY_RESIDENCE: "Is this your primary residence? Select yes or no.",
      PROPERTY_LISTING_TYPE_LABEL: "Property listing type",
      VALUE_SUBTITLE: "PROPERTY VALUE",
      APPRAISED_VALUE_LABEL: "Appraised value",
      EXISTING_MORTGAGE_VALUE_LABEL: "Existing mortgage balance",
      PROPERTY_QUALIFYING_VALUE_LABEL: "Property qualifying value",
      ADD_BTN: "Add Another",
      VALUE_PLACEHOLDER: "Enter value",
    },
  },
  SECURITY_VEHICLE: {
    TITLE: "Vehicle Details",
    PAGE_DESCRIPTION:
      "Add any vehicles you may own, lease or are currently financing.",
    BREADCRUMB: "Vehicles",
    // SECURITY VEHICLE COMPONENT STRINGS
    FORM: {
      CONFIRM_VEHICLE: "Do you own or lease a vehicle?",
      VEHICLE_NO: "Vehicle No.",
      REMOVE_VEHICLE: "Remove Vehicle",
      MAKE_LABEL: "Make",
      MAKE_DESCRIPTION: "Enter vehicle make",
      MODEL_LABEL: "Model",
      MODEL_DESCRIPTION: "Enter vehicle model type",
      YEAR_LABEL: "Year",
      VIN_LABEL: "VIN",
      CURRENT_VALUE_LABEL: "Current estimated value",
      QUESTION1:
        "Are there any current outstanding loans against this vehicle?",
      VALUE_PLACEHOLDER: "Enter value",
      ADD_BTN: "Add Another",
    },
    API_KEYS: {
      LOAN_ADD_VEHICLE: "AddLoanVehicle",
      LOAN_GET_VEHICLE: "GetLoanVehicle",
      LOAN_UPDATE_VEHICLE: "UpdateLoanVehicle",
    },
    ERROR: {
      YEAR_REQUIRED: "vehicle year",
      MAKE_REQUIRED: "make",
      MODEL_REQUIRED: "model",
      VIN_REQUIRED: "vin",
    },
  },
  INCOME: {
    TITLE: "ADDITIONAL SOURCES OF INCOME",
    SUBTITLE: "Income",
    PAGE_DESCRIPTION:
      "Apart from your employment income, add any additional sources of income.",
    BREADCRUMB: "Income",
    FORM: {
      INCOME_RADIO_LABEL: "Do you have any additional sources of income?",
      INCOME_TYPE_NO: "INCOME NO. ",
      INCOME_TYPE: "Income type",
      INCOME_TYPE_DEFAULT_SELECT: "Select income type",
      ADJUSTMENT: "Adjustmet",
      ADJUSTMENT_TYPE_DEFAULT_SELECT: "Select adjustment type",
      DESCRIPTION: "Description",
      MONTHLY_AMOUNT: "Monthly amount",
      MONTHLY_AMOUNT_PLACEHOLDER: "Amount before taxes",
      REMOVE_INCOME: "Remove income",
      ADD_BTN: "Add Another",
    },
    API_KEYS: {
      LOAN_ADD_INCOME: "AddLoanIncome",
      LOAN_GET_INCOME: "GetLoanIncome",
      LOAN_UPDATE_INCOME: "UpdateLoanIncome",
    },
    ERROR: {
      INCOME_TYPE_REQUIRED: "income type.",
      DESCRIPTION_REQUIRED: "description.",
      MONTHLY_AMOUNT_REQUIRED: "monthly amount.",
    },
  },
  EXPENSE: {
    TITLE: "EXPENSES",
    SUBTITLE: "Expense",
    PAGE_DESCRIPTION1: "List your monthly household expenses here.",
    PAGE_DESCRIPTION2: "(Like your phone bill, internet, cable...)",
    BREADCRUMB: "Expenses",
    FORM: {
      EXPENSE_RADIO_LABEL: "Do you have any monthly expenses?",
      EXPENSE_TYPE_NO: "EXPENSE NO.",
      EXPENSE_TYPE: "Expense",
      EXPENSE_TYPE_DEFAULT_SELECT: "Select expense type",
      DESCRIPTION: "Description",
      MONTHLY_AMOUNT: "Monthly amount",
      MONTHLY_AMOUNT_PLACEHOLDER: "Enter amount",
      ADJUSTMENT: "Adjustment",
      PRIMARY_RESIDENCE:
        "Is this expense related to your primary residence? Select yes or no.",
      PRIMARY_RESIDENCE_TYPE_DEFAULT_SELECT: "Select yes or no",
      REMOVE_EXPENSE: "Remove expense",
      ADD_BTN: "Add Another",
    },
    API_KEYS: {
      LOAN_ADD_EXPENSE: "AddLoanExpense",
      LOAN_GET_EXPENSE: "GetLoanExpense",
      LOAN_UPDATE_EXPENSE: "UpdateLoanExpense",
    },
    ERROR: {
      EXPENSE_TYPE_REQUIRED: "expense type.",
      DESCRIPTION_REQUIRED: "description.",
      MONTHLY_AMOUNT_REQUIRED: "monthly amount.",
    },
  },
  ASSET: {
    TITLE: "ASSETS",
    SUBTITLE: "Asset",
    BREADCRUMB: "Assets",
    PAGE_DESCRIPTION1:
      "Now let's list any assets you have to assist you with your application approval.",
    PAGE_DESCRIPTION2: "(Add as many as you like.)",
    FORM: {
      ASSET_RADIO_LABEL: "Do you have any assets?",
      ASSET_TYPE_NO: "ASSET NO.",
      ASSET_TYPE: "Asset type",
      ASSET_TYPE_DEFAULT_SELECT: "Select an Asset type",
      DESCRIPTION: "Description",
      VALUE: "Estimated value",
      VALUE_AMOUNT_PLACEHOLDER: "Enter amount",
      ORGANIZATION: "Indicate the organization your asset is connected with",
      ORGANIZATION_DEFAULT_SELECT: "Select organization",
      ORGANIZATION_TITLE: "Institution",
      ADJUSTMENT: "Adjustment",
      ADJUSTMENT_TYPE_DEFAULT_SELECT: "Select adjustment type",
      PRIMARY_RESIDENCE:
        "Is this asset related to your primary residence? Select yes or no.",
      PRIMARY_RESIDENCE_TITLE: "Primary Residence",
      REMOVE_ASSET: "Remove asset",
      ADD_BTN: "Add Another",
    },
    API_KEYS: {
      LOAN_ADD_ASSET: "AddLoanAsset",
      LOAN_GET_ASSET: "GetLoanAsset",
      LOAN_UPDATE_ASSET: "UpdateLoanAsset",
    },
    ERROR: {
      ASSET_TYPE_REQUIRED: "asset type.",
      ORGANIZATION_REQUIRED: "organization.",
      VALUE_REQUIRED: "amount.",
      DESCRIPTION_REQUIRED: "description.",
    },
  },
  LIABILITIES: {
    TITLE: "LIABILITIES",
    SUBTITLE: "Liability",
    BREADCRUMB: "Liabilities",
    PAGE_DESCRIPTION1:
      "List any regular monthly payments you make relating to other loans, credit facilities or any other financial obligations.",
    PAGE_DESCRIPTION2:
      "(This does not include your regular monthly household expenses like your utility payments.)",
    FORM: {
      LIABILITY_RADIO_LABEL: "Do you have any liabilites?",
      LIABILITY_TYPE: "Liability type",
      LIABILITY_TYPE_NO: "LIABILITY NO.",
      LIABILITY_TYPE_DEFAULT_SELECT: "Select the liability type",
      DESCRIPTION: "Description",
      VALUE: "Outstanding balance",
      VALUE_AMOUNT_PLACEHOLDER: "Enter amount",
      ORGANIZATION: "Indicate the organization your asset is connected with",
      ORGANIZATION_TITLE: "Institution",
      ORGANIZATION_TYPE_DEFAULT_SELECT: "Select organization",
      ADJUSTMENT: "Adjustment",
      ADJUSTMENT_TYPE_DEFAULT_SELECT: "Select adjustment",
      SECURED_LABEL: "Is this a secured or unsecured loan?",
      SECURED_TYPE: "Secured Type",
      SECURED: "Secured",
      UNSECURED: "Unsecured",
      PRIMARY_RESIDENCE:
        "Is this liability related to your primary residence? Select yes or no.",
      PRIMARY_RESIDENCE_TITLE: "Primary Residence",
      PRIMARY_RESIDENCE_TYPE_DEFAULT_SELECT: "Select Answer",
      LIMIT: "Original loan amount / Max credit limit",
      RATE: "Rate (%)",
      RATE_PLACEHOLDER: "Enter rate",
      PAYMENT_TYPE: "Payment type",
      PAYMENT_TYPE_DEFAULT_SELECT: "Select payment type",
      REMOVE_LIABILITY: "Remove liability",
      ADD_BTN: "Add Another",
      AVG_PAYMENT: "Average monthly payment",
      YES: "Yes",
      NO: "No",
      AMORTIZATION_TERM_OF_MORTGAGE: "Amortization term of mortgage",
      NEW_LOAN_USED_TO_PAY_OFF_LIABILITY_LABEL:
        "Will this new loan used to pay off this liability?",
      ENTER_AMOUNT_NEW_LOAN_APPLIED_TO_THIS_LIABILITY_LABEL:
        "Enter the amount of the new loan that will be applied to this liability",
      MORTGAGE: {
        VALUE: "Outstanding balance on mortgage",
        LIMIT: "Orignal Mortgage amount",
        DESCRIPTION: "Describe this mortgage",
        AMORTIZATION_TERM_OF_MORTGAGE: "Amortization term of mortgage",
      },
      VEHICLE_LOAN: {
        DESCRIPTION: "Make & Model",
        VALUE: "Value (balance remaining)",
        LIMIT: "Original limit",
        TERM_OF_LOAN: "Term of loan",
      },
      CREDIT_CARD: {
        DESCRIPTION: "Type (Visa, Mastercard, Amex...)",
        VALUE: "Balance",
        LIMIT: "Credit limit",
      },
      LINE_OF_CREDIT: {
        DESCRIPTION: "Type",
        VALUE: "Balance",
        LIMIT: "Credit limit",
      },
      LOAN: {
        DESCRIPTION: "Description",
        VALUE: "Balance",
        LIMIT: "Original limit",
      },
    },
    API_KEYS: {
      LOAN_ADD_LIABILITIES: "AddLoanLiabilities",
      LOAN_GET_LIABILITIES: "GetLoanLiabilities",
      LOAN_UPDATE_LIABILITIES: "UpdateLoanLiabilities",
    },
    ERROR: {
      SUB_TYPE_REQUIRED: "sub type.",
      ORGANIZATION_REQUIRED: "institution.",
      VALUE_REQUIRED: "value.",
      DESCRIPTION_REQUIRED: "description.",
      SECURED_TYPE_REQUIRED: "secured type",
      ADJUSTMENT_REQUIRED: "adjustment type.",
      LIMIT_REQUIRED: "limit.",
      AVG_PAYMENT_REQUIRED: "average payment.",
      RATE_REQUIRED: "rate.",
      PAYMENT_TYPE_REQUIRED: "payment type.",
      LOAN_FOR_DEBT_AMOUNT_REQUIRED: "amount of loan to apply.",
    },
  },
  DOCUMENT_UPLOAD: {
    TITLE: "DOCUMENT UPLOAD",
    SUBTITLE:
      "In order for us to facilitate your loan, we require you to upload the following documents:",
    BREADCRUMB: "Documents",
    UPLOAD_MESSAGE:
      "In order for us to facilitate your loan, we require you to upload the following documents:",
    FILES_FOR_UPLOAD: "Files Ready For Upload",
    UPLOAD_BUTTON: "Continue",
    SKIP_BUTTON: "Skip for now",
    UPLOAD_CANCEL: "Cancel",
    API_KEYS: {
      ADD_DOCUMENT: "AddDocument",
      GET_DOCUMENT_LIST: "GetDocumentList",
      DELETE_DOCUMENT: "DeleteDocument",
    },
  },
  FEES: {
    TITLE: "Fees",
    BREADCRUMB: "Fees",
    PSA_REGISTRATION_LABEL: "PSA registration fee",
    PSA_SEARCH_FEES_LABEL: "PSA search fees",
    PROPERTY_SEARCH_LABEL: "Property search fees",
    TITLE_INSURANCE_LEGAL_FEES_LABEL: "Title insurance legal fees",
    APPRAISAL_INSPECTION_LABEL: "Appraisal inspection fees",
    APPLICATION_DISBURSEMENT_LABEL: "Application disbursement fees",
    TOTAL_FEES_LABEL: "Total Fees",
  },
  BEFORE_WE_START: {
    TITLE: "Before we Start",
    BREADCRUMB: "Before we Start",
  },
  COST_OF_BORROWING: {
    TITLE: "Cost of Borrowing",
    TOTAL_COST_OF_CREDIT_TITLE: "Total Cost of Credit",
    BREADCRUMB: "Cost of Borrowing",
    FEES_LABEL: "Fees",
    TOTAL_AMOUNT_LABEL: "Total Amount",
    AMOUNT_REQUESTED_LABEL: "Amount Requested",
    TOTAL_TO_BE_FINANCED_LABEL: "Total to be Financed",
    TOTAL_PRINCIPAL_AMOUNT_LABEL: "Total Loan Amount",
    TOTAL_NO_OF_PAYMENTS_LABEL: "Total No. of Payments",
    FINAL_PAYMENT_AMOUNT_LABEL: "Final Payment Amount",
    APR_LABEL: "APR",
    EQUIVALENCY_RATE_LABEL: "Equivalency Rate",
    EQUIVALENCY_RATE_APR_LABEL: "Equivalency Rate APR",
    NEGATIVE_AM_RATE_LABEL: "Negative AM Rate",
    TOTAL_INTEREST_PAID_LABEL: "Total Interest Paid",
    TOTAL_COST_OF_BORROWING_LABEL: "Total Cost of Borrowing",
  },
  LOAN_SUMMARY: {
    TITLE: "Loan Summary",
    BREADCRUMB: "Loan Summary",
    SUBTITLE_SUCCESS1: "Congratulations!",
    SUBTITLE_SUCCESS2: "You are approved.",
    SUBTITLE_SUCCESS3: "Your finalized loan details are listed below.",
    SUBTITLE_FAIL:
      "Thank you [put Applicant first name here], for submitting your application.",
    BREAKDOWN: "Loan Breakdown",
    COST_TO_BORROW: "Cost to Borrow",
    PAYMENT: "payment",
    PAYMENTS: "Payments",
    MONTHLY_PAYMENT: "Monthly payment: ",
    INTEREST_RATE: "Interest Rate",
    INT_PAID_OT: "Interest paid over term",
    INT_PAID_OT_VALUE: "$1,800.00",
    LOAN_FEES: "Loan fees",
    LOAN_FEE_VALUE: "$5.00",
    TOTAL_COST_BORROW: "Total cost to borrow",
    TOTAL_COST_BORROW_VALUE: "$1,850.00",
    VIEW_ALL: "View all",
    FORM: {
      DESCRIPTION_REPAYMENT:
        "Select your desired payment frequency and first payment date to view your updated loan details.",
      PRODUCT_LABEL_REPAYMENT_SCHEDULE_DATE:
        "Select a date for your first payment",
      SCHEDULE_LABEL: "Payment frequency",
      SCHEDULE_OPTION_LABEL: "Select your payment frequency",
      FIRST_DATE_LABEL: "First payment date",
      REFRESH_BTN_LABEL: "Refresh Details",
      REFRESH_BTN_SPACER: "  ",
    },
    LOC: {
      LABEL_SUMMARY: "Your line of credit summary is outlined below.",
      LOAN_TERM: "Revolving",
      REPAYMENT_FREQUENCY: "Client's discretion",
    },
  },
  LOAN_ADJUDICATION_RESULTS: {
    TITLE: "Loan Result",
    BREADCRUMB: "Loan Result",
  },
  DISBURSEMENT: {
    TITLE: "Disbursement",
    SUBTITLE: "Indicate which account you would like us to deposit your funds.",
    LOCAL_ACCOUNTS_SUBTITLE: `Your ${FIInformation.FI_NAME} Accounts`,
    OTHER_ACCOUNTS: "Other Account",
    OTHER_FINANCIAL_ACCOUNTS: "Other Financial Institution",
    BREADCRUMB: "Disbursement",
    DEPOSIT_INTERNAL: "Deposit Internal",
    DEPOSIT_EXTERNAL: "Deposit External",
    CONSOLIDATION_INTERNAL: "Consolidation Internal",
    CONSOLIDATION_EXTERNAL: "Consolidation External",
    AUTO: "Auto",
    FORM: {
      ACCOUNT_NO: "Account No.",
      ACCOUNT_DESC: "Account Description",
      INSTITUTION_NO: "Institution No.",
      TRANSIT_NO: "Transit No.",
      AMOUNT: "Amount",
      COMMENTS: "Comments",
      COMMENTS_DESCRIPTION: "Add any comments, notes or instructions here",
      PAYEE: "Payee",
      ACCOUNT_TYPE: "Account type",
      ACCOUNT: "Account",
      ACCOUNT_TYPE_DEFAULT_SELECT: "Select the account type",
      ACCOUNT_DEFAULT_SELECT: "Select the account",
      CHANGE_FIN: "Change financial institution",
    },
    API_KEYS: {
      GET_LENDING_PRODUCT: "GetLendingProduct",
      UPDATE_LENDING_PRODUCT: "UpdateLendingProduct",
    },
    ERROR: {
      REQUIRED: "Please select disbursement info",
    },
  },
  REPAYMENT: {
    TITLE: "Repayment",
    SUBTITLE:
      "Now indicate which account we should withdraw your payments from.",
    LOCAL_ACCOUNTS_SUBTITLE: `Your ${FIInformation.FI_NAME} Accounts`,
    OTHER_ACCOUNTS: "Other Account",
    OTHER_FINANCIAL_ACCOUNTS: "Other Financial Institution",
    BREADCRUMB: "Repayment",
    FORM: {
      ACCOUNT_NO: "Account No.",
      ACCOUNT_DESC: "Account description",
      ACCOUNT_TYPE: "Account type",
      ACCOUNT_TYPE_DEFAULT_SELECT: "Select the account type",
      INSTITUTION_NO: "Institution No.",
      TRANSIT_NO: "Transit No.",
      AMOUNT: "Amount",
      COMMENTS: "Comments",
      COMMENTS_DESCRIPTION: "Add any comments, notes or instructions here.",
      PAYEE: "Payee",
      ACCOUNT: "Account",
      ACCOUNT_DEFAULT_SELECT: "Select the account",
      CHANGE_FIN: "Change financial institution",
    },
    ERROR: {
      REQUIRED: "Please select repayment info",
    },
  },

  // MODAL WINDOW TITLES
  WELCOME_TITLE1: "Welcome...",
  WELCOME_BACK_TITLE: "Welcome back...",
  WELCOME_BACK1: `Welcome back... we are just fetching the information we have on file for you.`,
  WELCOME_BACK2: `Before we continue with your application, please review, add to or update your information.`,
};

export default STRINGS;
