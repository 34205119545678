import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import NumberFormat from "react-number-format";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { format } from "date-fns";
import { LOANPRODUCT } from "../../Communication/Constants";
import checkErrors from "../../Utils/InputValidator";
import VALIDATIONS from "../../Constants/ValidationList";
import PopupModal from "../Common/PopupModal/PopupModal";
import * as ROUTES from "../../Constants/Routes";
import MODALMESSAGES from "../../Constants/Messages";

class LoanHeloc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loanProduct: {},
      loanDetails: {},
      loanTerms: [],
      loanPurposes: [],
      loanRepayments: [],
      assessedValue: 0,
      mortgageBalance: 0,
      loanAmount: 0,
      maxHELOC: 0,
      loanTerm: 0,
      loanRate: 0,
      monthlyPayment: 0,
      paymentType: "monthly",
      paymentTypes: [],
      loanConfig: {
        loanAmount: "",
        loanTerm: "",
        loanRate: "",
      },
      errors: {
        property: [],
        balance: [],
        amount: [],
      },
    };
  }

  componentDidMount() {
    const { getDataFromServer, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    getDataFromServer(AppConstants.APPLICATIONSTEP.LOAN_DETAILS, (response) => {
      this.setFormData(response);
    });
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { loanProducts, products } = this.props;

    if (loanProducts !== prevProps.loanProducts) {
      this.initialize();
    }
  }

  setFormData = () => {};

  handleChange = (prop) => (event) => {
    console.log("handleChange", prop);
    this.setState({ prop: event.target.value });
    this.doCalculation();
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    const { maxHELOC } = this.state;
    if (this.verifyData()) {
      if (maxHELOC <= 0) {
        this.setState({
          showModal: true,
          modal: {
            type: AppConstants.MODALTYPE.INFORMATION,
            title: MODALMESSAGES.HELOC.CANCEL_TITLE,
            description: MODALMESSAGES.HELOC.CANCEL_DESCRIPTION,
          },
        });
      } else {
        handleContinue(this.doUpdateLoanForm());
      }
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.LOAN_DETAILS);
  };

  initialize() {
    console.log("initialize");
    const { loanProducts, products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);

    if (!activeProduct) {
      return;
    }
    const productId = activeProduct.productId;
    let purposes = [];
    let paymentTypes = [];
    let loanConfig = {};
    let loanProduct = {};

    if (loanProducts) {
      loanProduct = loanProducts.find((product) => product.id === productId);
      purposes = loanProduct.purpose;
      paymentTypes = loanProduct.paymentTypes;
      loanConfig = {
        loanAmount: loanProduct.loanAmount,
        loanTerm: loanProduct.loanTerm,
        loanRate: loanProduct.loanRate,
      };
    }

    let loanDetails = activeProduct.loanDetails
      ? activeProduct.loanDetails
      : {};

    this.setState(
      {
        loanProduct,
        loanPurposes: purposes,
        paymentTypes: paymentTypes,
        loanConfig: loanConfig,
        loanAmount: loanDetails.loanAmount
          ? loanDetails.loanAmount
          : this.state.loanAmount,
        loanTerm: loanDetails.loanTerm
          ? loanDetails.loanTerm
          : this.state.loanTerm,
        loanRate: loanDetails.loanRate
          ? loanDetails.loanRate
          : loanProduct.loanRate,
        monthlyPayment: loanDetails.monthlyPayment
          ? loanDetails.monthlyPayment
          : this.state.monthlyPayment,
        paymentType: loanDetails.paymentType
          ? loanDetails.paymentType
          : this.state.paymentType,
        assessedValue: loanDetails.assessedValue
          ? loanDetails.assessedValue
          : this.state.assessedValue,
        mortgageBalance: loanDetails.assessedValue
          ? loanDetails.mortgageBalance
          : this.state.mortgageBalance,
      },
      () => {
        this.doCalculation();
      }
    );
  }

  setAssessedValue = (amount) => {
    console.log("setAssessedValue", amount);
    const { products, getActiveProduct } = this.props;
    const activeProduct = getActiveProduct(products);
    amount = amount.replaceAll("$", "");
    amount = amount.replaceAll(",", "");
    amount = parseFloat(amount);
    this.setState({ assessedValue: amount }, function () {
      this.doCalculation();
    });
  };

  setMortgageBalance = (amount) => {
    amount = amount.replaceAll("$", "");
    amount = amount.replaceAll(",", "");
    amount = parseFloat(amount);
    this.setState({ mortgageBalance: amount }, function () {
      this.doCalculation();
    });
  };

  setLoanAmountValue = (amount) => {
    const { products, getActiveProduct } = this.props;
    const { loanAmount, maxHELOC, loanConfig } = this.state;
    const activeProduct = getActiveProduct(products);
    amount = amount.replaceAll("$", "");
    amount = amount.replaceAll(",", "");
    amount = parseFloat(amount);
    this.setState({ loanAmount: amount }, function () {
      this.doCalculation();
    });
  };

  setLoanTermValue = (term) => {
    this.setState({ loanTerm: term }, function () {
      this.doCalculation();
    });
  };

  doCalculation() {
    const {
      assessedValue,
      mortgageBalance,
      loanTerm,
      loanConfig,
      loanProduct,
    } = this.state;
    let { loanAmount } = this.state;
    this.setState({
      errors: {
        property: [],
        balance: [],
        amount: [],
      },
    });
    console.log("doCalculation", assessedValue, mortgageBalance, loanAmount);
    const rates = loanConfig.loanRate;
    let loanRate = 0.0;
    const minimumLoanAmount = parseInt(loanConfig.loanAmount.min);

    const equity = assessedValue - mortgageBalance;
    console.log("equity", equity);
    console.log("loanConfig", loanConfig);
    let maxHELOC = 0;
    if (equity >= 0) {
      console.log("maxAllowedHELOC", loanProduct.maxAllowedHELOC);
      maxHELOC = equity * loanProduct.maxAllowedHELOC;
      console.log("maxHELOC", maxHELOC);
    }
    this.setState({
      maxHELOC,
    });

    if (maxHELOC >= minimumLoanAmount) {
      if (maxHELOC < loanAmount) {
        loanAmount = maxHELOC;
        this.setState({
          loanAmount,
        });
      } else if (maxHELOC > 0 && loanAmount === 0) {
        loanAmount = minimumLoanAmount;
        console.log("loan amount adjusted", loanAmount);
        this.setState({
          loanAmount,
        });
      }
      if (Array.isArray(rates)) {
        rates.forEach((raw, idx) => {
          const term = parseInt(loanTerm);
          const minTerm = parseInt(raw.min);
          const maxTerm = parseInt(raw.max);
          if (term >= minTerm && term <= maxTerm) {
            loanRate = raw.rate;
          }
        });
        this.setState({
          loanRate: loanRate,
        });
      } else {
        loanRate = rates;
      }

      console.log("loanRate", loanRate);

      const rate = loanRate / 100;

      let monthlyPayment = (loanAmount * rate) / 12;
      monthlyPayment = monthlyPayment;

      this.setState({
        monthlyPayment,
      });
    } else {
      this.setState({
        loanAmount: 0,
        monthlyPayment: 0,
      });
    }
  }

  // NOT YET CORRECT
  getRate(annualRate, paymentType, loanTerm) {
    console.log(annualRate, paymentType, loanTerm);
    switch (paymentType) {
      case "acc_biweekly":
        return (
          (annualRate / 100 / 26) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "acc_weekly":
        return (
          (annualRate / 100 / 52) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "biweekly":
        return (
          (annualRate / 100 / 26) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "monthly":
        return (annualRate / 100) * (loanTerm / 12);
      case "quarterly":
        return annualRate / 100 / 4;
      case "semiannually":
        return annualRate / 100 / 2;
      case "semimonthly":
        return annualRate / 100 / 12;
      case "weekly":
        return (
          (annualRate / 100 / 52) *
          this.getNumberOfPayments(loanTerm, paymentType)
        );
      case "annually":
        return (annualRate / 100) * (loanTerm / 12);
      default:
        return (annualRate / 100) * (loanTerm / 12);
    }
  }

  getNumberOfPayments(loanTerm, paymentType) {
    switch (paymentType) {
      case "acc_biweekly":
        return Math.floor(loanTerm * (26 / 12));
      case "acc_weekly":
        return Math.floor(loanTerm * (52 / 12));
      case "biweekly":
        return Math.floor(loanTerm * (26 / 12));
      case "monthly":
        return loanTerm;
      case "quarterly":
        return Math.floor(loanTerm * (4 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (4 / 12));
      case "semiannually":
        return Math.floor(loanTerm * (2 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (2 / 12));
      case "semimonthly":
        return Math.floor(loanTerm * (24 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (24 / 12));
      case "weekly":
        return Math.floor(loanTerm * (52 / 12));
      case "annually":
        return Math.floor(loanTerm * (1 / 12)) < 1
          ? 1
          : Math.floor(loanTerm * (1 / 12));
      default:
        return loanTerm;
    }
  }

  doUpdateLoanForm() {
    console.log("doUpdateLoanForm");
    const {
      loanAmount,
      loanTerm,
      loanRate,
      monthlyPayment,
      loanScheduleDate,
      paymentType,
      assessedValue,
      mortgageBalance,
    } = this.state;

    return {
      loanAmount: parseFloat(loanAmount),
      loanTerm: parseInt(loanTerm),
      loanRate: parseFloat(loanRate),
      monthlyPayment: parseFloat(monthlyPayment),
      loanScheduleDate: loanScheduleDate,
      paymentType,
      assessedValue,
      mortgageBalance,
    };
  }

  commaFormatted(amount) {
    if (amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }

  handlePopupModalBtnClick = () => {
    const { handleCancelApplication } = this.props;
    handleCancelApplication();
  };

  verifyData = () => {
    console.log("verifydata");
    const {
      errors,
      assessedValue,
      mortgageBalance,
      loanAmount,
      maxHELOC,
      loanConfig,
      loanProduct,
    } = this.state;
    let errorLength = 0;
    let formIsValid = true;

    let propertyError = [];
    let balanceError = [];
    let amountError = [];

    console.log("assessedValue", assessedValue);
    propertyError = checkErrors(
      assessedValue,
      VALIDATIONS.LOANS.DETAIL.PROPERTY
    );
    errorLength += propertyError.length;

    if (propertyError.length === 0) {
      if (maxHELOC < loanConfig.loanAmount.min) {
        propertyError.push(`Total equity does not qualify for HELOC.`);
        errorLength += propertyError.length;
      }
    }

    console.log("propertyError", propertyError);

    // balanceError = checkErrors(
    //   mortgageBalance,
    //   VALIDATIONS.LOANS.DETAIL.MORTAGE_BALANCE
    // );
    // errorLength += balanceError.length;
    // console.log("balanceError", balanceError);

    if (maxHELOC > 0) {
      amountError = checkErrors(
        loanAmount,
        VALIDATIONS.LOANS.DETAIL.HELOC_AMOUNT
      );
      errorLength += amountError.length;
    }

    if (amountError.length === 0) {
      if (loanAmount < loanConfig.loanAmount.min) {
        amountError.push(`Amount is less than minimum HELOC.`);
        errorLength += amountError.length;
      }
    }

    console.log("amountError", amountError);

    if (errorLength > 0) {
      formIsValid = false;
    }
    this.setState({
      formIsValid,
      errors: {
        ...errors,
        property: propertyError,
        balance: balanceError,
        amount: amountError,
      },
    });
    return formIsValid;
  };

  render() {
    const {
      assessedValue,
      mortgageBalance,
      loanAmount,
      downPayment,
      downPaymentPercent,
      loanTerm,
      loanRate,
      monthlyPayment,
      loanConfig,
      maxHELOC,
      errors,
      showModal,
      modal,
    } = this.state;
    const { steps, activeStepID } = this.props;

    const actionComponent = (
      <div className="[ loan-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.LOAN_DETAILS.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ row ]">
            <div className="[ col-lg-7 ]">
              <div className="[ form-subtitle ]">
                {STRINGS.LOAN_DETAILS.SUBTITLE}
              </div>
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.HELOC.LABEL_ASSESSED_VALUE}
                    </div>
                    <NumberFormat
                      maxLength={AppConstants.LOANS.SALARY_LENGTH}
                      type="text"
                      allowLeadingZeros={false}
                      allowNegative={false}
                      thousandSeparator
                      prefix={AppConstants.LOANS.SALARY_PREFIX}
                      name="propertyValue"
                      className="[ form-control ]"
                      placeholder={
                        STRINGS.LOAN_DETAILS.FORM.HELOC
                          .ASSESSED_AMOUNT_PLACEHOLDER
                      }
                      onChange={(e) => this.setAssessedValue(e.target.value)}
                      value={assessedValue}
                    />
                  </div>
                </div>
                <div className="[ error-msg ]">
                  {errors?.property && <>{errors?.property[0]}</>}
                </div>
              </div>
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.HELOC.LABEL_MORTGAGE_BALANCE}
                    </div>
                    <div className="[ row ]">
                      <div className="[ col-8 offset-2 col-lg-12 offset-lg-0 ]">
                        <NumberFormat
                          disabled={false}
                          maxLength={AppConstants.LOANS.SALARY_LENGTH}
                          type="text"
                          thousandSeparator
                          allowNegative={false}
                          prefix={AppConstants.LOANS.SALARY_PREFIX}
                          name="estimatedMortgage"
                          className="[ form-control ] "
                          placeholder={
                            STRINGS.LOAN_DETAILS.FORM.HELOC
                              .ASSESSED_AMOUNT_PLACEHOLDER
                          }
                          onChange={(e) =>
                            this.setMortgageBalance(e.target.value)
                          }
                          value={mortgageBalance}
                        />
                      </div>
                    </div>
                    <div className="[ error-msg ]">
                      {errors?.balance && <>{errors?.balance[0]}</>}
                    </div>
                  </div>
                </div>
              </div>

              {maxHELOC >= loanConfig.loanAmount.min && (
                <>
                  <div className="[ alert alert-info ]">
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ form-label ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM.HELOC
                              .LABEL_HELOC_REQUEST_AMOUNT_DESCRIPTION
                          }{" "}
                          {"$" +
                            this.commaFormatted(
                              maxHELOC == 0
                                ? loanConfig.loanAmount.max
                                : maxHELOC
                            )}
                          .
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="[ mb-3 ]">
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <div className="[ form-label ]">
                          {
                            STRINGS.LOAN_DETAILS.FORM.HELOC
                              .LABEL_HELOC_REQUEST_AMOUNT
                          }
                        </div>
                        <div className="[ details-section__item ] [ row ]">
                          <div className="[ col-12 ]">
                            <div className="[ row ]">
                              <div className="[ col-8 offset-2 col-lg-3 offset-lg-0 ] [ mb-4 ] [ pl-0 ]">
                                <div className="[ input-group ] [ loan-amount ]">
                                  <div className="[ input-group-prepend ]">
                                    <span className="[ input-group-text ]">
                                      $
                                    </span>
                                  </div>
                                  <NumberFormat
                                    maxLength={AppConstants.LOANS.SALARY_LENGTH}
                                    type="text"
                                    allowLeadingZeros={false}
                                    allowNegative={false}
                                    thousandSeparator
                                    // prefix={AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                                    className="[ form-control ] [ amount-active ]"
                                    // placeholder={
                                    //   STRINGS.INCOME.FORM.MONTHLY_AMOUNT_PLACEHOLDER
                                    // }
                                    onChange={(e) =>
                                      this.setLoanAmountValue(e.target.value)
                                    }
                                    value={loanAmount}
                                  />
                                </div>
                              </div>

                              <div className="[ col-12 col-lg-9 order-lg-first ]">
                                <div className="[ row ]">
                                  <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ]">
                                    ${" "}
                                    {this.commaFormatted(
                                      loanConfig.loanAmount.min
                                    )}
                                  </div>
                                  <div className="[ col-6 ]">
                                    <RangeSlider
                                      value={loanAmount}
                                      min={loanConfig.loanAmount.min}
                                      max={
                                        maxHELOC < loanConfig.loanAmount.min
                                          ? loanConfig.loanAmount.max
                                          : maxHELOC
                                      }
                                      onChange={(e) =>
                                        this.setLoanAmountValue(e.target.value)
                                      }
                                      tooltipLabel={(currentValue) =>
                                        `$ ${this.commaFormatted(currentValue)}`
                                      }
                                      tooltipPlacement="top"
                                      step={loanConfig.loanAmount.steps}
                                      className="[ w-100 ]"
                                    />
                                  </div>
                                  <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ]">
                                    ${" "}
                                    {this.commaFormatted(
                                      maxHELOC < loanConfig.loanAmount.min
                                        ? loanConfig.loanAmount.max
                                        : maxHELOC
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="[ error-msg ]">
                          {errors?.amount && <>{errors?.amount[0]}</>}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {true == false && (
                <div className="[ mb-3 ]">
                  <div className="[ form-label ]">
                    {STRINGS.LOAN_DETAILS.FORM.HELOC.LABEL_TERM}
                  </div>
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <div className="[ row ]">
                        <div className="[ col-8 offset-2 col-lg-4 offset-lg-0 ] [ mb-4 ]">
                          <input
                            type="text"
                            inputMode="numeric"
                            value={
                              loanTerm + " " + STRINGS.LOAN_DETAILS.FORM.YEARS
                            }
                            className="amount-inactive"
                            disabled
                          />
                        </div>

                        <div className="[ col-12 col-lg-8 order-lg-last ]">
                          <div className="[ row ]">
                            <div className="[ col-3 ] [text-right] [ form-label ] [ slider-label ] [ disabled ]">
                              {loanConfig.loanTerm.min}{" "}
                              {STRINGS.LOAN_DETAILS.FORM.YEAR}
                            </div>
                            <div className="[ col-6 ]">
                              <RangeSlider
                                disabled={false}
                                value={loanTerm}
                                min={loanConfig.loanTerm.min}
                                max={loanConfig.loanTerm.max}
                                onChange={(e) =>
                                  this.setLoanTermValue(e.target.value)
                                }
                                tooltipLabel={(currentValue) =>
                                  `${currentValue}`
                                }
                                tooltipPlacement="top"
                                tooltip="off"
                                alwaysShowTooltip={true}
                                step={loanConfig.loanTerm.steps}
                                className="[ w-100 ]"
                                readOnly
                              />
                            </div>
                            <div className="[ col-3 ] [text-left] [ form-label ] [ slider-label ] [ slider-label-right ] [ disabled ]">
                              {loanConfig.loanTerm.max}{" "}
                              {STRINGS.LOAN_DETAILS.FORM.YEAR}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="[ mb-3 ]">
                <div className="[ form-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.AUTO.LABEL_ESTIMATED_RATE}
                </div>
                <input
                  disabled={true}
                  value={parseFloat(loanRate).toFixed(2) + "%"}
                  type="text "
                  name="interestRate"
                  className="[ form-control ] [ rate ]"
                  placeholder=""
                />
              </div>
              <hr className="[ mb-5 ] [ d-lg-none ]" />
            </div>

            <div className="[ col-lg-5 ]">
              <div className="[ mb-3 ]">
                <div className="[ form-subtitle ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.TABLE_TITLE}
                </div>
                <div className="[ details-section ] [ summary-tab ]">
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.HELOC.REQUESTED_CREDIT_AMOUNT}
                    </div>
                    <div className="[ details-section__item-description ]">
                      $ {this.commaFormatted(loanAmount)}
                    </div>
                    <div className="[ col-12 ]">
                      {maxHELOC >= loanConfig.loanAmount.min && (
                        <small className="[ footnotes form-text text-muted ]">
                          {STRINGS.LOAN_DETAILS.FORM.HELOC.MAX_AMOUNT_HINT}{" "}
                          {"$" +
                            this.commaFormatted(
                              maxHELOC == 0
                                ? loanConfig.loanAmount.max
                                : maxHELOC
                            )}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.HELOC.LOAN_TERM}
                    </div>
                    <div className="[ details-section__item-description ]">
                      {STRINGS.LOAN_DETAILS.FORM.HELOC.LOAN_TERM_REVOLVING}
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE
                      }
                    </div>
                    <div className="[ details-section__item-description ]">
                      {parseFloat(loanRate).toFixed(2)}%
                    </div>
                  </div>
                  <div className="[ mb-3 ]">
                    <div className="[ row ]">
                      <div className="[ col-12 ]">
                        <small className="[ footnotes form-text text-muted ]">
                          {STRINGS.LOAN_DETAILS.FORM.HELOC.FINE_PRINT1}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ details-section ] [ summary-tab ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header ] [ mb-3 ]">
                  <div className="[ col-7 ]">
                    <h3 className="[ d-flex justify-content-start ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_MONTHLY_PAYMENT
                      }
                    </h3>
                  </div>
                  <div className="[ col-5 ]">
                    <h3 className="[ d-flex justify-content-end ]">
                      ${" "}
                      {this.commaFormatted(
                        parseFloat(monthlyPayment).toFixed(2)
                      )}
                    </h3>
                  </div>
                </div>
                <div className="[ mb-3 ]">
                  <div className="[ row ]">
                    <div className="[ col-12 ]">
                      <small className="[ footnotes form-text text-muted ]">
                        {STRINGS.LOAN_DETAILS.FORM.LOC.FINE_PRINT2}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="[ alert alert-dark ]">
                <div className="[ row ]">
                  <div className="[ col-12 ]">
                    <small>{STRINGS.LOAN_DETAILS.FORM.HELOC.NOTE1}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={modal.type}
            title={modal.title}
            description={modal.description}
            showModal={showModal}
            popupBtnClick={this.handlePopupModalBtnClick}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

LoanHeloc.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  loanProducts: PropTypes.oneOfType([PropTypes.array, PropTypes.array]),
  loanProductForm: PropTypes.oneOfType(PropTypes.object),
};

LoanHeloc.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  steps: [],
  activeStepID: -1,
  loanProducts: [],
  loanProductForm: {},
};
export default LoanHeloc;
