import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import STRINGS from "Constants/Strings";
import * as ROUTES from "Constants/Routes";
import { HTTP_STATUS, APPLICANTID } from "Communication/Constants";

import { doGetApplicantList } from "Redux/Actions/GetApplicantListAction";
import getAccount from "Redux/Actions/GetAccountAction";

import { callFetchInveriteAccountList } from "Components/Inverite/Actions/InveriteAction";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import { doConsent } from "../TermsAndConditions/Actions/TermsActions";
import AppConstants from "../../Constants/AppConstants";

class InveriteAccountList extends Component {
  componentDidMount() {
    if (window.addEventListener) {
      window.addEventListener("message", this.listener, false);
    } else {
      window.attachEvent("onmessage", this.listener);
    }
  }

  listener = (event) => {
    console.log(event);
    const {
      doGetApplicantListAction,
      history,
      doConsents,
      processInverite,
    } = this.props;
    if (
      event.origin === "https://sandbox.inverite.com" ||
      event.origin === "https://live.inverite.com" ||
      event.origin === "https://i1.inverite.com" ||
      event.origin === "https://www.inverite.com"
    ) {
      if (event.data === "success") {
        // perform success operations here
        const { doFetchInveriteAccountList } = this.props;

        const requestGuid = sessionStorage.getItem(
          AppConstants.SESSION.INVERITE_ACCOUNTLIST_REQUEST_GUID
        );

        doFetchInveriteAccountList(requestGuid, (response) => {
          console.log(response);
          if (response.status === HTTP_STATUS.OK) {
            processInverite(response.data);
          }
        });
      }
    }
  };

  getAccountDetails = () => {
    // call getaccount api to check terms and condition accepted or not
    const { doGetAccountAction, history } = this.props;
    doGetAccountAction((response) => {
      history.push(ROUTES.ABOUT_YOU);
    });
  };

  render() {
    const {
      iframeUrl,
      iframeModal,
      showModal,
      popupBtnClick,
      toggleModal,
      closeBtnClick,
    } = this.props;

    return (
      <PopupModal
        title={STRINGS.INVERITE_IFRAME_MODAL.TITLE}
        footer={STRINGS.INVERITE_IFRAME_MODAL.FOOTER}
        iframeUrl={iframeUrl}
        iframeModal={iframeModal}
        toggleModal={toggleModal}
        showModal={showModal}
        popupBtnClick={popupBtnClick}
        closeBtnClick={closeBtnClick}
      />
    );
  }
}

InveriteAccountList.propTypes = {
  iframeUrl: PropTypes.string,
  iframeModal: PropTypes.bool,
  showModal: PropTypes.bool,
  popupBtnClick: PropTypes.func,
  doFetchInverite: PropTypes.func.isRequired,
  doGetAccountAction: PropTypes.func,
  doGetApplicantListAction: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  toggleModal: PropTypes.func,
};
InveriteAccountList.defaultProps = {
  showModal: false,
  iframeUrl: PropTypes.string,
  iframeModal: false,
  popupBtnClick: () => {},
  doGetAccountAction: () => {},
  doGetApplicantListAction: () => {},
  history: {},
  toggleModal: () => {},
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    doFetchInveriteAccountList: (requestId, callback) =>
      dispatch(callFetchInveriteAccountList(requestId, callback)),
    doGetApplicantListAction: (callback) =>
      dispatch(doGetApplicantList(callback)),
    doGetAccountAction: (callback) => dispatch(getAccount(callback)),
    doConsents: (consentDetails, callback) =>
      dispatch(doConsent(consentDetails, callback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InveriteAccountList)
);
