import React, { Component } from "react";
import { Form } from "react-bootstrap";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import PropTypes from "prop-types";
import { PasswordInput } from "Components/Common/PasswordInput/PasswordInput";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
// import PropTypes from "prop-types";

class OnlineBanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noPasswordSpecified: true,
      password: "", // first user entered password
      confirmPasswordValue: "", // confirm password user entered value
      passwordChecksResult: {}, // object for storing run-time validations checks
      confirmPasswordError: false, // error message if passwords does not match
      isFormValid: false,
      passwordCriteriaMatch: true,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.ONLINE_BANKING,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  setFormData = (data) => {
    console.log(data);
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
  };

  validateInput = (event) => {
    const password = event.target.value;
    const { confirmPasswordValue } = this.state;
    if (password === "" && confirmPasswordValue === "") {
      this.setState({
        noPasswordSpecified: true,
      });
    } else {
      this.setState({
        noPasswordSpecified: false,
      });
    }
    if (password !== confirmPasswordValue) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordValue,
        isFormValid: false,
      });
    } else {
      this.setState({
        confirmPasswordError: false,
        confirmPasswordValue,
        isFormValid: true,
      });
    }
    const { value } = event.target;
    const { passwdCriteria } = this.props;
    const data = value;
    this.setState({
      password: data,
    });

    /**
     * passwordValidationRegex represents
     * object with regex and code values
     */

    const passwordValidationRegex = passwdCriteria.map(({ regex, code }) => ({
      regex,
      code,
    }));
    passwordValidationRegex.map((rx) => {
      const isValid = new RegExp(rx.regex).test(data);
      const { passwordChecksResult } = this.state;
      passwordChecksResult[rx.code] = isValid;
      return this.setState(
        {
          passwordChecksResult,
        },
        () => {
          const allTrue = Object.keys(passwordChecksResult).every((k) => {
            return passwordChecksResult[k];
          });
          if (allTrue) {
            this.setState({ passwordCriteriaMatch: true });
          } else {
            this.setState({ passwordCriteriaMatch: false });
          }
        }
      );
    });
  };
  /**
   *
   * @param {object} event represents
   * browser event on confirm password
   * text input
   */

  handleConfirmPassword = (event) => {
    const confirmPasswordValue = event.target.value;
    const { password } = this.state;
    if (confirmPasswordValue === "" && password === "") {
      this.setState({
        noPasswordSpecified: true,
      });
    } else {
      this.setState({
        noPasswordSpecified: false,
      });
    }
    if (password !== confirmPasswordValue) {
      this.setState({
        confirmPasswordError: true,
        confirmPasswordValue,
        isFormValid: false,
      });
    } else {
      this.setState({
        confirmPasswordError: false,
        confirmPasswordValue,
        isFormValid: true,
      });
    }
  };

  handleContinue = () => {
    const { handleSetPasswordRequest } = this.props;
    const {
      password,
      isFormValid,
      passwordCriteriaMatch,
      noPasswordSpecified,
    } = this.state;
    if (noPasswordSpecified || (isFormValid && passwordCriteriaMatch)) {
      handleSetPasswordRequest(password);
    }
  };

  render() {
    const { passwdCriteria, steps, activeStepID } = this.props;
    const {
      password,
      confirmPasswordError,
      passwordChecksResult,
      confirmPasswordValue,
    } = this.state;
    const onlineBankingContainer = (
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          this.handleContinue();
        }}
        className="[ set-online-banking-container ]"
      >
        {steps.length > 0 && <Stepper steps={steps} active={activeStepID} />}

        <div className="[ form-title ]">{STRINGS.ONLINE_BANKING.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ card-title ] ">
              {STRINGS.ONLINE_BANKING.REGISTER_FOR_ONLINE_BANKING_LABEL}
            </div>
            <div className="[ form-label ] ">
              {STRINGS.ONLINE_BANKING.PROVIDE_TEMPORARY_PASSWORD_LABEL}
            </div>

            <PasswordInput
              id="Password-1"
              changeHandler={this.validateInput}
              password={password}
            />

            <div className="[ drawer-content-container ] [ pasword-rules ]">
              <div className="[ drawer-content-container__title ]">
                {STRINGS.SETPASSWORD.FORM.PASSWORDCRITERIA}
              </div>

              <div className="[ validations-rules-wrapper ]">
                {passwdCriteria &&
                  typeof passwdCriteria !== "undefined" &&
                  passwdCriteria.map((record) => {
                    return (
                      <div
                        key={record.code}
                        className={
                          record.code &&
                          passwordChecksResult[record.code] &&
                          passwordChecksResult[record.code] === true
                            ? "[ icon check-icon ] [ set-password-validations-item ]"
                            : "[ icon invalid-icon ] [ set-password-validations-item ]"
                        }
                      >
                        {record.message}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ONLINE_BANKING.RETYPEPASSWORDLABEL}
            </div>

            <PasswordInput
              id="Password-2"
              changeHandler={this.handleConfirmPassword}
              password={confirmPasswordValue}
            />
            <div className="[ error-msg ]">
              {confirmPasswordError
                ? STRINGS.SETPASSWORD.ERROR.CONFIRM_PASSWORD_ERROR
                : null}
            </div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button type="submit" className="[ submit-btn btn ] [ w-100 ]">
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </Form>
    );
    return (
      // onlineBankingContainer

      <AuthBody
        actionComponent={onlineBankingContainer}
        memberCreation
        onlineBanking
        handleBack={this.handleBack}
      />
    );
  }
}

OnlineBanking.propTypes = {
  getDataFromServer: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  passwdCriteria: PropTypes.arrayOf(PropTypes.any),
  handleSetPasswordRequest: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};
OnlineBanking.defaultProps = {
  getDataFromServer: () => {},
  passwdCriteria: [],
  steps: [],
  activeStepID: -1,
};

export default OnlineBanking;
