/* eslint-disable no-nested-ternary */
import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

import checkErrors from "Utils/InputValidator";

import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import MODALMESSAGES from "Constants/Messages";

import AuthBody from "Components/Common/AuthBody/AuthBody";
import PopupModal from "Components/Common/PopupModal/PopupModal";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    // inputfield error object name  should be inputobject with suffix 'Error'
    //  For example input object: username, input error object: usernameError
    this.state = {
      email: "",
      mobileNo: "",
      errors: {
        emailErrorList: [],
        mobileNoErrorList: [],
      },
      emailSelect: false,
      textMessageSelected: false,
      continueEnable: false,
    };
    this.inputFocusEmail = React.createRef();
    this.inputFocusTextMessage = React.createRef();
  }

  componentDidUpdate() {
    const { emailSelect } = this.state;
    if (emailSelect) {
      this.inputFocusEmail.current.focus();
    }
    // Focus for TextMessage is not added due to some difficulties in adding ref to 'NumberFormat' third party library.
  }

  // email button click event
  emailClick = () => {
    this.setState({
      emailSelect: true,
      textMessageSelected: false,
      continueEnable: true,
    });
  };

  // text message click event
  textMessageClick = () => {
    this.setState({
      textMessageSelected: true,
      emailSelect: false,
      continueEnable: true,
    });
  };

  handleBack = () => {
    const { backClick } = this.props;
    backClick();
  };

  handleContinue = (e) => {
    e.preventDefault();
    const { emailSelect, mobileNo, email, textMessageSelected } = this.state;
    const { emailClick, textMessageClick } = this.props;
    const errors = this.verifyData();
    if (!errors) {
      if (emailSelect) {
        emailClick(email);
      }
      if (textMessageSelected) {
        textMessageClick(mobileNo);
      }
    }
  };

  changeHandler = (event) => {
    const { errors } = this.state;
    if (event.target) {
      const errorObject = `${event.target?.name}${STRINGS.COMMON.ERROR_LIST}`;
      this.setState({
        [event.target.name]: event.target.value,
        errors: {
          ...errors,
          [errorObject]: [],
        },
      });
    } else {
      this.setState({
        mobileNo: event.formattedValue,
        errors: {
          ...errors,
          mobileNoErrorList: [],
        },
      });
    }
  };

  verifyData = () => {
    let errorMsg = "";
    const { emailSelect, mobileNo, email, errors } = this.state;
    if (emailSelect) {
      const emailErrors = checkErrors(email, VALIDATIONS.REGISTRATION.email);
      errorMsg = emailErrors;
      this.setState({
        errors: {
          ...errors,
          emailErrorList: emailErrors,
        },
      });
    } else {
      const mobileRes = checkErrors(
        mobileNo,
        VALIDATIONS.REGISTRATION.mobileno
      );
      errorMsg = mobileRes;
      this.setState({
        errors: {
          ...errors,
          mobileNoErrorList: mobileRes,
        },
      });
    }
    return errorMsg.length;
  };

  render() {
    const {
      email,
      mobileNo,
      errors,
      emailSelect,
      textMessageSelected,
      continueEnable,
    } = this.state;
    // forget password screen
    const {
      showModal,
      error,
      description,
      toggleModal,
      showVault,
      showVaultClicked,
      title,
    } = this.props;

    // forget password screen
    const renderForgotPassword = (
      <form className="[ forgot-password-container ]">
        <div className="[ form-title-forgot-password ]">
          {STRINGS.FORGOTPASSWORD.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.FORGOTPASSWORD.VERIFICATIONCODEMESSAGE}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  className={
                    emailSelect
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  type="button"
                  onClick={this.emailClick}
                >
                  {STRINGS.FORGOTPASSWORD.EMAILLABEL}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  className={
                    textMessageSelected
                      ? "[ btn custom-radio-selected ] "
                      : "[ btn custom-radio-unselected ] "
                  }
                  type="button"
                  onClick={this.textMessageClick}
                >
                  {STRINGS.FORGOTPASSWORD.TEXTMESSAGE}
                </button>
              </div>
            </div>
          </div>

          {textMessageSelected ? (
            <>
              <div className="[ drawer-content-container ]">
                <div className="[ form-label ]">
                  {STRINGS.REGISTRATION.FORM.MOBILENOLABEL}
                </div>

                <NumberFormat
                  format={AppConstants.REGISTRATION.NUMBERFORMAT}
                  className={
                    errors?.mobileNoErrorList &&
                    errors?.mobileNoErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  type="tel"
                  name="mobileNo"
                  value={mobileNo}
                  placeholder={`${STRINGS.REGISTRATION.FORM.MOBILENOPLACEHOLDER}`}
                  onValueChange={(values) => this.changeHandler(values)}
                  onChange={this.changeVal}
                />
              </div>

              <div className="[ error-msg ]">
                {errors?.mobileNoErrorList[0]}
              </div>
            </>
          ) : emailSelect ? (
            <>
              <div className="[ drawer-content-container ]">
                <div className="[ form-label ]">
                  {STRINGS.SIGNIN.FORM.USERNAMELABEL}
                </div>

                <input
                  className={
                    errors?.emailErrorList && errors?.emailErrorList.length > 0
                      ? "[ form-control ] [ invalid-input ]"
                      : "[ form-control ]"
                  }
                  type="email"
                  name="email"
                  placeholder={`${STRINGS.REGISTRATION.FORM.EMAILPLACEHOLDER}`}
                  value={email}
                  onChange={this.changeHandler}
                  ref={this.inputFocusEmail}
                />
              </div>

              <div className="[ error-msg ]">{errors?.emailErrorList[0]}</div>
            </>
          ) : (
            <div className="[ forgot-password__input-sapce ]" />
          )}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                className="[ submit-btn btn ] [ w-100 ]"
                disabled={!continueEnable}
                type="button"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
    const infoComponent = (
      <div className="">
        <div className="[ info-title ]">
          {STRINGS.SETPASSWORD.INFOBLOCK.TITLE}
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          {STRINGS.SETPASSWORD.INFOBLOCK.DESCRIPTION}
        </div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={
              error
                ? AppConstants.MODALTYPE.FAILURE
                : AppConstants.MODALTYPE.SUCCESS
            }
            title={error ? MODALMESSAGES.FORGOT_PASSWORD.ERRORMSG : title}
            description={description}
            toggleModal={toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={renderForgotPassword}
          infoComponent={infoComponent}
          securityText={STRINGS.FORGOTPASSWORD.SECURESESSIONTEXT}
          showVault={showVault}
          showVaultClicked={() => showVaultClicked}
          handleBack={this.handleBack}
          renderInfoComponent
        />
      </>
    );
  }
}

ForgotPassword.propTypes = {
  showModal: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  description: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  showVault: PropTypes.bool,
  showVaultClicked: PropTypes.func,
  emailClick: PropTypes.func.isRequired,
  textMessageClick: PropTypes.func.isRequired,
  backClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

ForgotPassword.defaultProps = {
  error: false,
  showVault: false,
  showVaultClicked: PropTypes.func,
};

export default ForgotPassword;
