import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Breadcrumbs from "react-router-dynamic-breadcrumbs";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import STRINGS from "Constants/Strings";
import FIInformation from "Constants/FIInformation";
import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import {
  TOKEN_KEY,
  HTTP_STATUS,
  VAULT_PASSWORD,
  APPLICANTID,
} from "Communication/Constants";
import Vault from "Components/Vault/Vault";

import doLogout from "Redux/Actions/LogoutAction";
import FundingReviewWindow from "../FundingReviewWindow/FundingReviewWindow";

import "./Header.scss";
import { FUNDING_AMOUNT } from "../../../Constants/Routes";

// ToDo : Create another component for CustomToggle
const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <div
    ref={ref}
    onKeyDown={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    role="presentation"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className="[ icon header-elipse-icon ]" />
  </div>
));

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbs: ROUTES.BREADCRUMBS,
    };
  }

  componentDidMount() {
    this.removeDuplicatesFromBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    const { activeRoute } = this.props;
    if (prevProps.activeRoute !== activeRoute) {
      this.removeDuplicatesFromBreadcrumbs();
    }
  }

  /**
   * When we are on the Market place or Dashboard page we dont need the home
   * breadcrumb again but on other pages we need it.
   * So just on Market place and Dashboard pages we are removing the home breadcrumb
   */
  removeDuplicatesFromBreadcrumbs = () => {
    const { activeRoute } = this.props;
    let updatedBreadcrumbs = { ...ROUTES.BREADCRUMBS };

    if (
      activeRoute === ROUTES.MARKETPLACE ||
      activeRoute === ROUTES.VAULT_DASHBOARD
    ) {
      delete updatedBreadcrumbs[ROUTES.HOME];
    } else {
      const accessToken = sessionStorage.getItem("token");
      if (accessToken) {
        updatedBreadcrumbs = { ...ROUTES.BREADCRUMBS };
        if (activeRoute.includes(VAULT_PASSWORD)) {
          updatedBreadcrumbs[ROUTES.HOME] = STRINGS.MARKETPLACE.TITILE;
        } else {
          updatedBreadcrumbs[ROUTES.HOME] = STRINGS.HOME_BREADCRUMB;
        }
      }
    }
    this.setState({ breadcrumbs: updatedBreadcrumbs });
  };

  getActiveHeaderFromRoute = (activeRoute) => {
    if (activeRoute.includes(ROUTES.RECOVER_PASSWORD)) {
      return ROUTES.BREADCRUMBS[ROUTES.RECOVER_PASSWORD]?.toUpperCase();
    }
    return ROUTES.BREADCRUMBS[activeRoute]?.toUpperCase();
  };

  signout = () => {
    const { doLogoutAction, history } = this.props;
    doLogoutAction((res) => {
      if (res.status === HTTP_STATUS.OK) {
        history.push(ROUTES.MARKETPLACE);
      }
    });
  };

  closeMenu = () => {};

  getActiveProduct = (products) => {
    let activeProduct = products?.find((product) => product?.active);
    return activeProduct;
  };

  render() {
    const accessToken = sessionStorage.getItem(TOKEN_KEY);
    const {
      showVault,
      activeRoute,
      history,
      vaultProductList,
      activeTab,
      FundState,
      fundProduct,
      showFundAccount,
      showFundVaultClicked,
      showVaultClicked,
      products,
    } = this.props;

    const activeProduct = this.getActiveProduct(products);

    const { method, amount } = FundState;

    const { breadcrumbs } = this.state;
    let popUpVault = null;
    if (showVault) {
      popUpVault = (
        <div className="[ small-vault-container ]">
          <Vault
            showProceedBtn={
              activeRoute === ROUTES.MARKETPLACE ||
              activeRoute === ROUTES.VAULT_DASHBOARD
            }
            showVaultClicked={showVaultClicked}
            history={history}
          />
        </div>
      );
    } else if (showFundAccount) {
      popUpVault = (
        <div className="[ small-vault-container ]">
          <FundingReviewWindow
            fundMethod={method}
            fundAmount={amount}
            productName={fundProduct.productName}
            showFundVaultClicked={showFundVaultClicked}
          />
        </div>
        // <Backdrop show={showVault} clicked={showVaultClicked} top />
      );
    }
    let activeHeader = this.getActiveHeaderFromRoute(activeRoute);
    if (
      activeRoute === ROUTES.EXISTING_MEMBER ||
      activeRoute === ROUTES.MEMBER_CREATION ||
      activeRoute === ROUTES.BEFORE_WE_START ||
      activeRoute === ROUTES.GETTING_STARTED ||
      activeRoute === ROUTES.ABOUT_YOU ||
      activeRoute === ROUTES.EMPLOYMENT_AND_INCOME ||
      activeRoute === ROUTES.COMPLIANCE
    ) {
      activeHeader = STRINGS.HEADER.MEMBER_INFO;
    } else if (
      activeRoute === ROUTES.FUND_METHOD ||
      activeRoute === ROUTES.FUNDING_AMOUNT
    ) {
      activeHeader = fundProduct.productName;
    } else if (
      activeRoute === ROUTES.REGISTER ||
      activeRoute === ROUTES.VERIFICATION_CODE ||
      activeRoute === ROUTES.VAULT_PASSWORD
    ) {
      activeHeader = STRINGS.HEADER.REGISTRATION;
    } else if (activeRoute === ROUTES.SIGN_IN) {
      activeHeader = STRINGS.HEADER.SIGNIN;
    } else if (
      activeRoute === ROUTES.FORGOT_PASSWORD ||
      activeRoute === ROUTES.SIGNIN_FORGOT_VERIFICATION ||
      activeRoute === ROUTES.RECOVER_PASSWORD_BY_EMAIL ||
      activeRoute === ROUTES.SIGNIN_FORGOT_VERIFICATION_VAULT_PASSWORD
    ) {
      activeHeader = STRINGS.HEADER.FORGOTPASSWORD;
    } else if (
      activeProduct &&
      activeRoute !== ROUTES.MARKETPLACE &&
      activeRoute !== ROUTES.VAULT_DASHBOARD
    ) {
      activeHeader = activeProduct.productName;
    }

    let isShowVaultIcon = true;
    if (activeRoute === ROUTES.VAULT_DASHBOARD) {
      if (activeTab === 0) {
        isShowVaultIcon = false;
      }
    }

    const isShowSignOut = accessToken && !activeRoute.includes(VAULT_PASSWORD);

    return (
      <header>
        <div className="[ container-fluid ] [ header-container ]">
          <div className="[ row ]">
            <div className="[ d-flex ] [ w-100 ] [ justify-content-between align-items-xl-center ] [ header-row ]">
              <div className="[ logo ]">
                <div className="[ appVersion ]">
                  {process.env.REACT_APP_VERSION}
                </div>
              </div>
              <div className="[ page-header ]">
                {activeRoute || activeHeader !== ""
                  ? activeHeader
                  : STRINGS.HEADER.SIGNINTITLE}
              </div>

              <div className="[ d-flex flex-row d-xl-none ] [ pt-2 ] [ header-container__item vault-icon-container ]">
                {/* CHRIS NOTE: Hacks inplace to support existing global nav bar. Should and will refactor at later date to use button group + dropdowns. */}
                {(activeRoute === ROUTES.FUNDING_AMOUNT ||
                  activeRoute === ROUTES.FUND_METHOD) && (
                  <div
                    className={
                      showFundAccount
                        ? "[ fund-account-image opened ]"
                        : "[ fund-account-image ]"
                    }
                    onClick={showFundVaultClicked}
                    role="presentation"
                  />
                )}
                {activeRoute !== ROUTES.FUNDING_AMOUNT &&
                  activeRoute !== ROUTES.FUND_METHOD &&
                  isShowVaultIcon &&
                  vaultProductList &&
                  vaultProductList.length !== 0 && (
                    <span className="ic-circle vault-circle">
                      {vaultProductList.length}
                    </span>
                  )}
                {isShowVaultIcon && (
                  <div
                    className={
                      showVault ? "[ vault-image opened ]" : "[ vault-image ]"
                    }
                    onClick={showVaultClicked}
                    role="presentation"
                  />
                )}
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  />
                  {isShowSignOut && (
                    <Dropdown.Menu
                      size="sm"
                      title="Navigation options"
                      className="[ global_nav_drawer ]"
                    >
                      <div className="[ global_nav_drawer__inner-container ]">
                        <div className="[ vault-header__container ]">
                          <h4 className="[ form-subtitle ]">
                            <i className="[ fas fa-bars ]" />

                            {STRINGS.HEADER.GLOBALNAV.TITLE}
                            <Dropdown.Item
                              eventKey="1"
                              className="[ d-inline p-0 ]"
                            >
                              <button
                                type="button"
                                className="[ close ]"
                                onClick={this.closeMenu}
                              >
                                <span aria-hidden="true">×</span>
                                <span className="[ sr-only ]">Close</span>
                              </button>
                            </Dropdown.Item>
                          </h4>
                        </div>

                        <Dropdown.Item eventKey="1" onClick={this.signout}>
                          {STRINGS.HEADER.GLOBALNAV.SIGNOUT}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.SETTINGS}
                        </Dropdown.Header>

                        <Dropdown.Item>
                          {STRINGS.HEADER.GLOBALNAV.CHANGEPASSWORD}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.LEGALESE}
                        </Dropdown.Header>

                        <Dropdown.Item>
                          {STRINGS.HEADER.GLOBALNAV.PRIVACY}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Item>
                          {STRINGS.HEADER.GLOBALNAV.CONDITIONS}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Header>
                          {STRINGS.HEADER.GLOBALNAV.CONTACTUS}
                        </Dropdown.Header>

                        <Dropdown.Item tag={Link} href={FIInformation.FI_EMAIL}>
                          {STRINGS.HEADER.GLOBALNAV.CUSTOMERSERVICE}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>

                        <Dropdown.Item>
                          {STRINGS.HEADER.GLOBALNAV.COMPANYSITE}{" "}
                          <span className="[ icon ] [ product-navigation-icon ]" />
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </div>
              <div className="[ d-none d-xl-flex flex-row ] [ mr-xl-5 ] [ header-container__item drop-mobile-menu ]">
                <div className="[ pt-3 ] [ welcome-user-text ]">
                  <div className="[ icon welcome-user-icon ]">
                    {STRINGS.HEADER.WELCOME}
                  </div>
                </div>
                {isShowSignOut && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-basic"
                      className="[ pt-3 ]"
                    />

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="1" onClick={this.signout}>
                        {STRINGS.HEADER.GLOBALNAV.SIGNOUT}{" "}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>

                      <Dropdown.Divider />

                      <Dropdown.Header>
                        {STRINGS.HEADER.GLOBALNAV.SETTINGS}
                      </Dropdown.Header>

                      <Dropdown.Item>
                        {STRINGS.HEADER.GLOBALNAV.CHANGEPASSWORD}{" "}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>

                      <Dropdown.Divider />

                      <Dropdown.Header>
                        {STRINGS.HEADER.GLOBALNAV.LEGALESE}
                      </Dropdown.Header>

                      <Dropdown.Item>
                        {STRINGS.HEADER.GLOBALNAV.PRIVACY}{" "}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>

                      <Dropdown.Item>
                        {STRINGS.HEADER.GLOBALNAV.CONDITIONS}{" "}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>

                      <Dropdown.Divider />

                      <Dropdown.Header>
                        {STRINGS.HEADER.GLOBALNAV.CONTACTUS}
                      </Dropdown.Header>

                      <Dropdown.Item tag={Link} href={FIInformation.FI_EMAIL}>
                        {STRINGS.HEADER.GLOBALNAV.CUSTOMERSERVICE}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>

                      <Dropdown.Item>
                        {STRINGS.HEADER.GLOBALNAV.COMPANYSITE}{" "}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {!isShowSignOut && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-basic"
                      className="[ pt-3 ]"
                    />

                    <Dropdown.Menu>
                      <Dropdown.Header>
                        {STRINGS.HEADER.GLOBALNAV.LEGALESE}
                      </Dropdown.Header>

                      <Dropdown.Item>
                        {STRINGS.HEADER.GLOBALNAV.PRIVACY}{" "}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>

                      <Dropdown.Item>
                        {STRINGS.HEADER.GLOBALNAV.CONDITIONS}{" "}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>

                      <Dropdown.Divider />

                      <Dropdown.Header>
                        {STRINGS.HEADER.GLOBALNAV.CONTACTUS}
                      </Dropdown.Header>

                      <Dropdown.Item tag={Link} href={FIInformation.FI_EMAIL}>
                        {STRINGS.HEADER.GLOBALNAV.CUSTOMERSERVICE}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>

                      <Dropdown.Item>
                        {STRINGS.HEADER.GLOBALNAV.COMPANYSITE}{" "}
                        <span className="[ icon ] [ product-navigation-icon ]" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        </div>
        {popUpVault}
        <div className="[ breadcrumb-container ]">
          <Breadcrumbs
            WrapperComponent={(wrappedCompProps) => (
              <ol className="[ breadcrumb ]">{wrappedCompProps.children}</ol>
            )}
            ActiveLinkComponent={(activeLinkCompProps) => (
              <li className="[ breadcrumb-icon ] [ active ]">
                {activeLinkCompProps.children}
              </li>
            )}
            LinkComponent={(linkCompProps) => (
              <li className="[ breadcrumb-icon ]">{linkCompProps.children}</li>
            )}
            mappedRoutes={breadcrumbs}
            routeMatcherRegex={AppConstants.HEADER.matchRoutePattern}
          />
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  showVault: PropTypes.bool,
  showVaultClicked: PropTypes.func,
  activeRoute: PropTypes.string,
  doLogoutAction: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  vaultProductList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]).isRequired,
  activeTab: PropTypes.number.isRequired,
  FundState: PropTypes.objectOf(PropTypes.any),
  // getActiveProduct: PropTypes.func,
  fundProduct: PropTypes.objectOf(PropTypes.any),
  showFundAccount: PropTypes.bool,
  showFundVaultClicked: PropTypes.func,
};
Header.defaultProps = {
  history: {},
  showVault: false,
  showVaultClicked: () => {},
  activeRoute: "",
  doLogoutAction: () => {},
  FundState: {},
  fundProduct: {},
  showFundAccount: false,
  showFundVaultClicked: () => {},
};

CustomToggle.propTypes = { onClick: PropTypes.func };
CustomToggle.defaultProps = {
  onClick: () => {},
};

const mapStateToProps = (state) => ({
  vaultProductList: state.VaultReducer.vaultProductList,
  activeTab: state.MarketplaceReducer.activeTab,
  FundState: state.FundReducer,
  fundProduct: state.VaultReducer.fundProduct,
  products: state.VaultReducer.vaultProductList,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doLogoutAction: doLogout,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
