import React, { PureComponent } from "react";
import NumberFormat from "react-number-format";
import STRINGS from "Constants/Strings";
import FIInformation from "Constants/FIInformation";
import MODALMESSAGES from "Constants/Messages";
import AppConstants from "Constants/AppConstants";
import PropTypes from "prop-types";
import AuthBody from "../Common/AuthBody/AuthBody";

class Register extends PureComponent {
  render() {
    const {
      emailError,
      email,
      mobileNoError,
      mobileNo,
      termsConditionError,
      isChecked,
      showVaultClicked,
      signinHandler,
      submitData,
      onChangeHandler,
      handleChecked,
      inputFocus,
    } = this.props;
    const RegisterComponent = (
      <form className="[ register-container ]">
        <div className="[ form-title-register ]">
          {STRINGS.REGISTRATION.TITLE}
        </div>
        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.REGISTRATION.FORM.EMAILLABEL}
            </div>
            <input
              type="email"
              name="email"
              className={
                emailError && emailError.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              placeholder={`${STRINGS.REGISTRATION.FORM.EMAILPLACEHOLDER}`}
              value={email}
              ref={inputFocus}
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="[ error-msg ]">{emailError[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.REGISTRATION.FORM.MOBILENOLABEL}
            </div>
            <NumberFormat
              format={AppConstants.REGISTRATION.NUMBERFORMAT}
              className={
                mobileNoError && mobileNoError.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              type="tel"
              name="mobileNo"
              value={mobileNo}
              placeholder={`${STRINGS.REGISTRATION.FORM.MOBILENOPLACEHOLDER}`}
              onValueChange={(values) => onChangeHandler(values)}
              onChange={(event) => onChangeHandler(event)}
            />
            <div className="[ error-msg ]">{mobileNoError[0]}</div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ] [ align-items-center ]">
              <div className="[ col-12 ] [ d-flex align-items-center ]">
                <div className=" [ checkbox custom-control custom-checkbox ]">
                  <input
                    type="checkbox"
                    defaultChecked={isChecked}
                    onChange={handleChecked}
                    name="termscondition"
                    id="termsCheckbox"
                    className={
                      termsConditionError && termsConditionError.length > 0
                        ? "[ invalid-input ] [ custom-control-input ]"
                        : "[ custom-control-input ]"
                    }
                  />
                  <label
                    htmlFor="termsCheckbox"
                    className="[ custom-control-label ]"
                  >
                    <span className="[ form-checkbox__text ]">
                      {STRINGS.REGISTRATION.FORM.TERMSLABEL1}
                      <a
                        href={FIInformation.FI_TERMS}
                        className="[ text-primary ]"
                        target="_blank"
                      >
                        {STRINGS.REGISTRATION.FORM.TERMSLABEL2}
                      </a>
                      {STRINGS.REGISTRATION.FORM.TERMSLABEL3}
                    </span>
                  </label>
                </div>
              </div>

              <div className="[ error-msg ] [ col-12 ]">
                {termsConditionError[0]
                  ? MODALMESSAGES.REGISTRATION.TERMSERROR
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-xl-6 ] [ offset-xl-3 ]">
              <button
                type="submit"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={submitData}
              >
                {STRINGS.REGISTRATION.FORM.REGISTRATIONBTN}
              </button>
            </div>
          </div>
        </div>
      </form>
    );

    const infoContainer = (
      <div className="">
        <div className="[ info-title ]">
          {STRINGS.REGISTRATION.INFOBLOCK.SIGNINTOCONTINUE}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-12 ]">
              <button
                type="button"
                className="[ btn info-btn ] [ w-100 ]"
                onClick={signinHandler}
              >
                {STRINGS.REGISTRATION.INFOBLOCK.SIGNINTOCONTINUEBTN}
              </button>
            </div>
          </div>
        </div>

        <div className="[ row ][ info-message ] [ p-3 ]">
          {STRINGS.REGISTRATION.INFOBLOCK.SIGNINTOCONTINUEINFO1}
        </div>
      </div>
    );

    return (
      <AuthBody
        actionComponent={RegisterComponent}
        infoComponent={infoContainer}
        securityText={STRINGS.REGISTRATION.SECURESESSIONTEXT}
        showVaultClicked={() => showVaultClicked}
        handleBack={signinHandler}
      />
    );
  }
}
Register.propTypes = {
  email: PropTypes.string,
  mobileNo: PropTypes.string,
  emailError: PropTypes.arrayOf(PropTypes.string),
  mobileNoError: PropTypes.arrayOf(PropTypes.string),
  termsConditionError: PropTypes.arrayOf(PropTypes.string),
  onChangeHandler: PropTypes.func,
  isChecked: PropTypes.bool,
  showVaultClicked: PropTypes.func,
  signinHandler: PropTypes.func,
  submitData: PropTypes.func,
  handleChecked: PropTypes.func,
  inputFocus: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};
Register.defaultProps = {
  email: "",
  mobileNo: "",
  emailError: [],
  mobileNoError: [],
  termsConditionError: [],
  onChangeHandler: () => {},
  isChecked: false,
  showVaultClicked: () => {},
  signinHandler: () => {},
  submitData: () => {},
  handleChecked: () => {},
};

export default Register;
