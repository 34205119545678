import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import AppConstants from "Constants/AppConstants";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import SecurityProperty from "./SecurityProperty";
import { getLoanLoanSecurity } from "../LoanDetails/Actions/LoanDetailsAction";
import {
  addLoanRealEstate,
  addLoanVehicle,
  updateLoanRealEstate,
  updateLoanVehicle,
} from "../Asset/Actions/AssetActions";
import { doGetProvinceList } from "../AboutYou/Actions/AboutYouActions";
import { updateProductToVault } from "../MarketPlace/Action/MarketPlaceAction";

class SecurityPropertyContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      property: {},
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      products,
      getActiveProduct,
      continuehandler,
      actionBack,
      handleBack,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    console.log(activeProduct);
    if (
      activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.AUTO ||
      (activeProduct.category === AppConstants.LOAN_CATEGORY.MORTGAGE &&
        activeProduct.subCategory ===
          AppConstants.LOAN_SUB_CATEGORY.MORTGAGE_PRE_QUAL)
    ) {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS);
      } else {
        console.log("continue to next step");
        continuehandler(null, AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS);
      }
    } else {
      this.initialize(activeProduct);
    }
  }

  initialize(activeProduct) {
    this.setState({
      property: activeProduct.property ? activeProduct.property : {},
    });
    // const { doGetLoanLoanSecurity } = this.props;
    // doGetLoanLoanSecurity(activeProduct.applicationId, (res) => {
    //   console.log(res);
    //   const property = res.data.find(
    //     (item) => item.securityType === AppConstants.SECURITY_TYPE.REAL_ESTATE
    //   );
    //   console.log("property", property);
    //   if (property) {
    //     const arr = property.securityDescription.split(" ");
    //     property.streetNumber = arr[0];
    //     property.street = arr[1];
    //     property.streetType = arr[2];
    //     property.city = arr[4];
    //     this.setState({
    //       property,
    //     });
    //   }
    // });
  }

  handleChange = () => {};

  handleContinue = (data) => {
    console.log("handleContinue", data);
    const {
      products,
      getActiveProduct,
      continuehandler,
      doAddLoanRealEstate,
      doUpdateLoanRealEstate,
      doUpdateProductToVault,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    if (data.securityId) {
      doUpdateLoanRealEstate(
        activeProduct.applicationId,
        data.securityId,
        data,
        (res) => {
          console.log(res);
          products[0]["property"] = data;
          doUpdateProductToVault(products, () => {
            continuehandler(
              null,
              AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS
            );
          });
        }
      );
    } else {
      doAddLoanRealEstate(activeProduct.applicationId, data, (res) => {
        console.log(res);
        products[0]["property"] = data;
        doUpdateProductToVault(products, () => {
          continuehandler(null, AppConstants.APPLICATIONSTEP.PROPERTY_DETAILS);
        });
      });
    }
  };

  render() {
    const { property } = this.state;
    const {
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      provinceList,
      streetTypes,
    } = this.props;

    return (
      <>
        <SecurityProperty
          handleContinue={this.handleContinue}
          handleChange={this.handleChange}
          getDataFromServer={getDataFromServer}
          handleBack={handleBack}
          steps={steps}
          activeStepID={activeStepID}
          provinceList={provinceList}
          property={property}
          streetTypes={streetTypes}
        />
      </>
    );
  }
}

SecurityPropertyContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

SecurityPropertyContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  provinceList: state.AboutYouReducer.provinceList,
  actionBack: state.ApplicationReducer.actionBack,
  streetTypes: state.LoanDetailsReducer.streetTypes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetLoanLoanSecurity: (applicationId, callback) =>
      dispatch(getLoanLoanSecurity(applicationId, callback)),
    doAddLoanRealEstate: (applicationId, payLoad, callback) =>
      dispatch(addLoanRealEstate(applicationId, payLoad, callback)),
    doUpdateLoanRealEstate: (applicationId, securityId, payLoad, callback) =>
      dispatch(
        updateLoanRealEstate(applicationId, securityId, payLoad, callback)
      ),
    doUpdateProductToVault: (vaultProductList, callback) =>
      dispatch(updateProductToVault(vaultProductList, callback)),
  };
};

const SecurityPropertyWrapper = withApplicantHOC(SecurityPropertyContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityPropertyWrapper);
