import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";

import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import VALIDATIONS from "Constants/ValidationList";

import { HTTP_STATUS } from "Communication/Constants";

import checkErrors from "Utils/InputValidator";
import { getSelectedCountry } from "Utils/AboutYouUtilities";

import { getGettingStartedData } from "Redux/Actions/ApplicationAction";

import { getCountryList } from "../EmploymentAndIncome/Actions/EmploymentAndIncomeAction";
import { doGetProvinceList } from "./Actions/AboutYouActions";
import { getProduct } from "../../Utils/CommonUtilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subYears, parse, format, getYear, getMonth, sub } from "date-fns";

const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => end - i);
};

class Review extends Component {
  constructor(props) {
    super(props);
    let startDate = new Date();
    startDate = subYears(startDate, 100);
    let endDate = new Date();
    endDate = subYears(endDate, 18);
    const minDate = sub(new Date(), { years: 100 });
    const maxDate = sub(new Date(), { years: 18 });
    const years = range(getYear(minDate), getYear(maxDate));
    const {
      fName,
      mName,
      lName,
      dOB,
      address,
      addressLine2,
      city,
      province,
      postalCode,
      instntTxnId,
    } = this.props;
    this.state = {
      userInfo: null,
      firstName: fName,
      middleName: mName,
      lastName: lName,
      dateOfBirth: dOB,
      address,
      addressLine2,
      city,
      province,
      postalCode,
      instntTxnId,
      mailingAddressLine1: "",
      mailingAddressLine2: "",
      mailingCity: "",
      mailingProvince: "",
      mailingPostalCode: "",
      mailingCountry: "",
      scanConfirmation: "",
      mailingSameAsCivic: "",
      errors: {
        firstNameErrorList: [],
        lastNameErrorList: [],
        dateOfBirthErrorList: [],
        addressErrorList: [],
        cityErrorList: [],
        provinceErrorList: [],
        postalCodeErrorList: [],
        mailingAddressLine1ErrorList: [],
        mailingCityErrorList: [],
        mailingProvinceErrorList: [],
        mailingPostalCodeErrorList: [],
        mailingCountryErrorList: [],
        scanConfirmationErrorList: [],
        mailingSameAsCivicErrorList: [],
      },
      provinceList: [],
      branch: "",
      accountStatementType: "",
      editMode: true,
      fingerprint: "",
      countriesList: [],
      startDate,
      endDate,
      minDate,
      maxDate,
      years,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  }

  componentDidMount() {
    // Set fingerprint value from the Instnt hidden form.
    const fingerprint = document.getElementById("fingerprint_txt");
    this.setState({
      fingerprint:
        fingerprint?.value ||
        "{'requestId':'BNlaDlD81D73YicffEaq','visitorId':'AVjdbqbRKh3HywJ4QDU6','visitorFound':true}",
    });

    const { doGetGettingStartedVault } = this.props;
    doGetGettingStartedVault((res) => {
      this.setBranchAndOverderaft(res);
    });

    const { countryList } = this.props;
    if (countryList && countryList.length <= 0) {
      const { doGetCountryList } = this.props;
      doGetCountryList();
    } else {
      this.setState({ countriesList: countryList });
    }

    const { provinceList } = this.props;
    if (provinceList && provinceList.length <= 0) {
      const { doGetProvinceData } = this.props;
      doGetProvinceData();
    } else {
      this.setState({ provinceList });
    }

    const { getDataFromServer } = this.props;
    getDataFromServer(AppConstants.APPLICATIONSTEP.ABOUTYOU, (response) => {
      if (response.status === HTTP_STATUS.OK) {
        this.setStateFromProps(response.data);
      }
    });

    const { minDate, maxDate } = this.state;
  }

  componentDidUpdate(prevProps) {
    const { provinceList, countryList } = this.props;

    if (prevProps.provinceList !== provinceList) {
      this.setProvinceList(provinceList);
    }

    if (prevProps.countryList !== countryList) {
      this.setCountriesList(countryList);
    }
  }

  setStateFromProps = (response) => {
    const { member, address } = response;
    const { applicationCompleteList } = this.props;
    this.setState({
      firstName: member.name,
      middleName: member.middleName,
      lastName: member.surname,
      dateOfBirth: member.dob,
      address: address.currentAddressLine1,
      addressLine2: address.currentAddressLine2,
      city: address.currentAddressCity,
      province: address.currentAddressProvince,
      postalCode: address.currentAddressPostalCode,
      mailingAddressLine1: address.mailingAddressLine1,
      mailingAddressLine2: address.mailingAddressLine2,
      mailingCity: address.mailingCity,
      mailingProvince: address.mailingProvince,
      mailingPostalCode: address.mailingPostalCode,
      mailingCountry: address.mailingCountry,
      mailingSameAsCivic: address.mailingSameAsCivic,
      editMode: !(
        applicationCompleteList && applicationCompleteList.length > 0
      ),
    });
  };

  setCountriesList(countriesList) {
    this.setState({ countriesList });
  }

  setBranchAndOverderaft(gettingStarted) {
    this.setState({
      branch: gettingStarted.branch,
      accountStatementType: gettingStarted.accountStatementType,
    });
  }

  setProvinceList = (provinceList) => {
    this.setState({
      provinceList,
    });
  };

  setDOB = (dob) => {
    this.setState({
      ["dateOfBirth"]: format(dob, "yyyy-MM-dd"),
    });
  };

  onChangeHandler = (event) => {
    const { errors, editMode } = this.state;
    const inputField = event?.target.name;
    const index = AppConstants.ABOUT_YOU.EDIT_MODE_INPUTLIST.findIndex(
      (elem) => inputField === elem
    );
    if (editMode || index === -1) {
      this.setState(
        {
          [inputField]: event?.target.value,
          errors: {
            ...errors,
            [`${event?.target.name}ErrorList`]: [],
          },
        },
        () => {
          if (
            inputField === STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMEREF
          ) {
            this.textInput.focus();
          }
        }
      );
    }
  };

  submitData = () => {
    if (this.verifyData()) {
      const {
        firstName,
        lastName,
        middleName,
        dateOfBirth,
        address,
        addressLine2,
        city,
        province,
        postalCode,
        mailingAddressLine1,
        mailingAddressLine2,
        mailingCity,
        mailingProvince,
        mailingPostalCode,
        mailingCountry,
        mailingSameAsCivic,
        branch,
        fingerprint,
        instntTxnId,
        accountStatementType,
      } = this.state;

      const {
        isDesktop,
        vaultProductList,
        getActiveProduct,
        inSessionJointApplicant,
      } = this.props;

      let request = {};
      const activeProduct = getActiveProduct(vaultProductList);
      if (
        (activeProduct.applicationId && !inSessionJointApplicant) ||
        (inSessionJointApplicant && inSessionJointApplicant?.applicantId)
      ) {
        request = {
          member: {
            name: firstName,
            middleName,
            surname: lastName,
            dob: dateOfBirth,
            branch,
          },
          address: {
            currentAddressLine1: address,
            currentAddressLine2: addressLine2,
            currentAddressCity: city,
            currentAddressProvince: province,
            currentAddressPostalCode: postalCode,
            mailingSameAsCivic,
          },
          regulatory: {
            mailStmt:
              accountStatementType === AppConstants.GETTINGSTARTED.PAPER,
            eStmt: accountStatementType === AppConstants.GETTINGSTARTED.EMAIL,
          },
          bundleProductId: activeProduct ? activeProduct.productId : "",
        };

        if (!mailingSameAsCivic) {
          request = {
            ...request,
            address: {
              ...request.address,
              mailingAddressLineType1: "",
              mailingAddressLine1,
              mailingAddressLine2,
              mailingCity,
              mailingProvince,
              mailingPostalCode,
              mailingCountry,
            },
          };
        }
      } else {
        request = {
          firstName,
          middleName,
          surname: lastName,
          dateOfBirth,
          address,
          addressLine2,
          city,
          state: province,
          postalCode,
          branch,
          fingerprint,
          mailingSameAsCivic,
          bundleProductId: activeProduct ? activeProduct.productId : "",
        };

        if (!isDesktop) {
          request = { ...request, instntTxnId };
        }

        if (!mailingSameAsCivic) {
          request = {
            ...request,
            mailingAddressLine1,
            mailingAddressLineType1: "",
            mailingAddressLine2,
            mailingCity,
            mailingProvince,
            mailingPostalCode,
            mailingCountry,
          };
        }
      }
      const { continuehandler } = this.props;
      continuehandler(request, AppConstants.APPLICATIONSTEP.ABOUTYOU);
    }
  };

  verifyData = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      address,
      city,
      province,
      postalCode,
      mailingAddressLine1,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      mailingCountry,
      errors,
      scanConfirmation,
      mailingSameAsCivic,
    } = this.state;
    const { isDesktop } = this.props;
    let errorList = { ...errors };
    let valid = true;

    const firstNameErrorList = checkErrors(
      firstName,
      VALIDATIONS.ABOUTYOU.FIRSTNAME
    );
    errorList = { ...errorList, firstNameErrorList };
    if (firstNameErrorList && firstNameErrorList.length > 0) {
      valid = false;
    }

    const lastNameErrorList = checkErrors(
      lastName,
      VALIDATIONS.ABOUTYOU.LASTNAME
    );
    errorList = { ...errorList, lastNameErrorList };
    if (lastNameErrorList && lastNameErrorList.length > 0) {
      valid = false;
    }

    const dateOfBirthErrorList = checkErrors(
      dateOfBirth,
      VALIDATIONS.ABOUTYOU.DOB
    );
    errorList = { ...errorList, dateOfBirthErrorList };
    if (dateOfBirthErrorList && dateOfBirthErrorList.length > 0) {
      valid = false;
    }

    const addressErrorList = checkErrors(address, VALIDATIONS.ABOUTYOU.ADDRESS);
    errorList = { ...errorList, addressErrorList };
    if (addressErrorList && addressErrorList.length > 0) {
      valid = false;
    }

    const cityErrorList = checkErrors(city, VALIDATIONS.ABOUTYOU.CITY);
    errorList = { ...errorList, cityErrorList };
    if (cityErrorList && cityErrorList.length > 0) {
      valid = false;
    }

    const provinceErrorList = checkErrors(
      province,
      VALIDATIONS.ABOUTYOU.PROVINCE
    );
    errorList = { ...errorList, provinceErrorList };
    if (provinceErrorList && provinceErrorList.length > 0) {
      valid = false;
    }

    const postalCodeErrorList = checkErrors(
      postalCode,
      VALIDATIONS.ABOUTYOU.CA_POSTALCODE
    );
    errorList = { ...errorList, postalCodeErrorList };
    if (postalCodeErrorList && postalCodeErrorList.length > 0) {
      valid = false;
    }

    if (!mailingSameAsCivic) {
      const mailingAddressLine1ErrorList = checkErrors(
        mailingAddressLine1,
        VALIDATIONS.ABOUTYOU.ADDRESS
      );
      errorList = { ...errorList, mailingAddressLine1ErrorList };
      if (
        mailingAddressLine1ErrorList &&
        mailingAddressLine1ErrorList.length > 0
      ) {
        valid = false;
      }

      const mailingCityErrorList = checkErrors(
        mailingCity,
        VALIDATIONS.ABOUTYOU.CITY
      );
      errorList = { ...errorList, mailingCityErrorList };
      if (mailingCityErrorList && mailingCityErrorList.length > 0) {
        valid = false;
      }

      const mailingProvinceErrorList = checkErrors(
        mailingProvince,
        VALIDATIONS.ABOUTYOU.PROVINCE
      );

      errorList = { ...errorList, mailingProvinceErrorList };
      if (mailingProvinceErrorList && mailingProvinceErrorList.length > 0) {
        valid = false;
      }

      const mailingCountryErrorList = checkErrors(
        mailingCountry,
        VALIDATIONS.ABOUTYOU.COUNTRY
      );
      errorList = { ...errorList, mailingCountryErrorList };
      if (mailingCountryErrorList && mailingCountryErrorList.length > 0) {
        valid = false;
      }

      const postalCodePattern =
        VALIDATIONS.ABOUTYOU[
          `${getSelectedCountry(mailingCountry)}_POSTALCODE`
        ];
      const mailingPostalCodeErrorList = checkErrors(
        mailingPostalCode,
        postalCodePattern
      );
      errorList = { ...errorList, mailingPostalCodeErrorList };
      if (mailingPostalCodeErrorList && mailingPostalCodeErrorList.length > 0) {
        valid = false;
      }
    }

    if (!isDesktop) {
      const scanConfirmationErrorList = checkErrors(
        scanConfirmation,
        VALIDATIONS.ABOUTYOU.SCANCONFIRMATION
      );
      errorList = { ...errorList, scanConfirmationErrorList };
      if (scanConfirmationErrorList && scanConfirmationErrorList.length > 0) {
        valid = false;
      }
    }

    const mailingSameAsCivicErrorList = checkErrors(
      mailingSameAsCivic,
      VALIDATIONS.ABOUTYOU.ADDRESSCONFIRMATION
    );
    errorList = { ...errorList, mailingSameAsCivicErrorList };
    if (mailingSameAsCivicErrorList && mailingSameAsCivicErrorList.length > 0) {
      valid = false;
    }

    if (Object.keys(errorList).length > 0) {
      this.setState({ errors: errorList });
    }

    return valid;
  };

  toggleButton = (name, choice) => {
    const { errors } = this.state;
    this.setState({
      [name]: choice,
      errors: {
        ...errors,
        [`${name}ErrorList`]: [],
      },
    });
    if (name === STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.SCANCONFIRMATION) {
      this.setState({ editMode: !choice });
    }

    if (name === STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC) {
      this.setState({
        mailingAddressLine1: "",
        mailingAddressLine2: "",
        mailingCity: "",
        mailingProvince: "",
        mailingPostalCode: "",
        mailingCountry: "",
        errors: {
          ...errors,
          mailingAddressLine1ErrorList: [],
          mailingCityErrorList: [],
          mailingProvinceErrorList: [],
          mailingPostalCodeErrorList: [],
          mailingCountryErrorList: [],
          scanConfirmationErrorList: [],
          mailingSameAsCivicErrorList: [],
        },
      });
    }
  };

  render() {
    const {
      userInfo,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      address,
      addressLine2,
      city,
      province,
      postalCode,
      mailingAddressLine1,
      mailingAddressLine2,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      mailingCountry,
      scanConfirmation,
      mailingSameAsCivic,
      errors,
      provinceList,
      editMode,
      countriesList,
      startDate,
      endDate,
      minDate,
      maxDate,
      years,
      months,
    } = this.state;
    const {
      selfieImage,
      isDesktop,
      productList,
      vaultProductList,
    } = this.props;
    let selfie = null;
    const dob = dateOfBirth
      ? parse(dateOfBirth, "yyyy-MM-dd", new Date())
      : null;
    const userInformation = (
      <>
        <div className="[ col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL}
            </div>
            <>
              <input
                type="text"
                name="firstName"
                className="[ form-control ]"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL
                }
                value={firstName}
                onChange={this.onChangeHandler}
                readOnly={!editMode}
                ref={(input) => {
                  this.textInput = input;
                }}
              />
              <div className="[ error-msg ]">
                {errors?.firstNameErrorList[0]}
              </div>
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.MIDDLENAMELABEL}
            </div>
            <>
              <input
                type="text"
                name="middleName"
                className="[ form-control ]"
                placeholder={
                  STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.MIDDLENAMELABEL
                }
                value={middleName}
                onChange={this.onChangeHandler}
                readOnly={!editMode}
              />
              <div className="[ error-msg ]" />
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
            </div>
            <>
              <input
                type="text "
                name="lastName"
                className="[ form-control ]"
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
                value={lastName}
                onChange={this.onChangeHandler}
                readOnly={!editMode}
              />
              <div className="[ error-msg ]">
                {errors?.lastNameErrorList[0]}
              </div>
            </>
          </div>
        </div>

        <div className="[  col-12 col-lg-3 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL}
            </div>
            <>
              <DatePicker
                className="[ datepicker ]"
                dateFormat="yyyy-MM-dd"
                selected={dob}
                onChange={(date) => this.setDOB(date)}
                minDate={startDate}
                maxDate={endDate}
                readOnly={!editMode}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div>
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </button>
                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </button>
                  </div>
                )}
              />
              <div className="[ error-msg ]">
                {errors?.dateOfBirthErrorList[0]}
              </div>
            </>
          </div>
        </div>
      </>
    );
    if (selfieImage && selfieImage !== "") {
      selfie = (
        <img className="[ selfie-image ]" src={selfieImage} alt="selfie" />
      );
    } else {
      selfie = <div className="[ icon user-selfie-icon ]" />;
    }

    const product = getProduct(productList, vaultProductList);
    const thankYouCard = (
      <div className="[ thank-you-container ]">
        <div className="[ row ]">
          <div className="[ col-12 ]" />
          <div className="[ col-12 ] [ info-tile__title ]">
            {STRINGS.ABOUTYOU.PARTFIVE.THANKYOUTITLE + firstName}
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-12 ] [ info-tile__description ]">
            {STRINGS.ABOUTYOU.PARTFIVE.THANKYOUTEXT}
            {product.productName}
          </div>
        </div>
        <div className="[ row ]">
          <div className="[ col-12 ] [ info-tile__description ]">
            {STRINGS.ABOUTYOU.PARTFIVE.REVIEWREQ}
          </div>
        </div>
      </div>
    );

    const selectList = provinceList.map((elem) => (
      <option
        value={elem.code}
        key={elem.code}
        className="[ dropdown__option ]"
      >
        {elem.description}
      </option>
    ));

    const selectElement = (
      <select
        name="province"
        value={province}
        className={
          errors?.provinceErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
        </option>
        {selectList}
      </select>
    );

    const mailingSelectElement = (
      <select
        name="mailingProvince"
        value={mailingProvince}
        className={
          errors?.mailingProvinceErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
        </option>
        {selectList}
      </select>
    );

    const countryElem = countriesList.map((country) => (
      <option
        value={country.description}
        key={country.code}
        className="[ dropdown__option ]"
      >
        {country.description}
      </option>
    ));

    const countrySelectElem = (
      <select
        name="mailingCountry"
        value={mailingCountry}
        className={
          errors?.mailingCountryErrorList.length > 0
            ? "[ form-control ] [ invalid-input ] [ select__dropdown ]"
            : "[ form-control ] [ select__dropdown ]"
        }
        onChange={this.onChangeHandler}
      >
        <option value="" className="[ default ] [ dropdown__option ]">
          {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY}
        </option>
        {countryElem}
      </select>
    );

    const addressInputGroup = (
      <div className="[ row ]">
        <div className="[ col-12 ]">
          <div className="[ form-label ]">
            {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
          </div>
          <>
            <input
              type="text"
              name="address"
              className={
                errors?.addressErrorList.length > 0
                  ? "[ form-control ] [ invalid-input ]"
                  : "[ form-control ]"
              }
              placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS1}
              value={address}
              onChange={this.onChangeHandler}
            />
            <div className="[ error-msg ]">{errors?.addressErrorList[0]}</div>
          </>
        </div>

        <div className="[ col-12 ]">
          <div className="[ mb-3 ]">
            <>
              <input
                type="text"
                name="addressLine2"
                className="[ form-control ]"
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS2}
                value={addressLine2}
                onChange={this.onChangeHandler}
              />
              <div className="[ error-msg ]" />
            </>
          </div>
        </div>

        <div className="[ col-12 col-md-6 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
            </div>
            <>
              <input
                type="text"
                name="city"
                className={
                  errors?.cityErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ]"
                    : "[ form-control ]"
                }
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                value={city}
                onChange={this.onChangeHandler}
              />
              <div className="[ error-msg ]">{errors?.cityErrorList[0]}</div>
            </>
          </div>
        </div>

        <div className="[ col-12 col-md-6 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
            </div>
            {/* <div className="[ dropdown ]"> */}
            {selectElement}
            {/* </div> */}
            <div className="[ error-msg ]">{errors?.provinceErrorList[0]}</div>
          </div>
        </div>

        <div className="[ col-12 ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
            </div>
            <>
              <MaskedInput
                mask={AppConstants.ABOUT_YOU.CA_POSTALMASK}
                className={
                  errors?.postalCodeErrorList.length > 0
                    ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                    : "[ form-control ] [ postalCode ]"
                }
                placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                guide={false}
                id="postalCode"
                name="postalCode"
                value={postalCode}
                onChange={this.onChangeHandler}
              />
            </>
            <div className="[ error-msg ]">
              {errors?.postalCodeErrorList[0]}
            </div>
          </div>
        </div>
      </div>
    );

    const confirmQuestion = (
      <div className="[ mb-3 ]">
        <div className="[ col-12 ] [ alert alert-warning ]" role="alert">
          <i className="[ fas fa-exclamation-circle ]" />
          <span>{STRINGS.ABOUTYOU.PARTFIVE.CONFIRMQUESTION}</span>
        </div>

        <div className="[ row ] [ custom-radios ]">
          <div className="[ col-6 ]">
            <button
              type="button"
              className={
                scanConfirmation !== "" && scanConfirmation
                  ? "[ btn custom-radio-selected ] "
                  : "[ btn custom-radio-unselected ] "
              }
              onClick={(event) =>
                this.toggleButton(
                  STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.SCANCONFIRMATION,
                  true,
                  event
                )
              }
            >
              {STRINGS.COMMON.CHOICE.YES}
            </button>
          </div>

          <div className="[ col-6 ]">
            <button
              type="button"
              className={
                scanConfirmation !== "" && !scanConfirmation
                  ? "[ btn custom-radio-selected ] "
                  : "[ btn custom-radio-unselected ] "
              }
              onClick={(event) =>
                this.toggleButton(
                  STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.SCANCONFIRMATION,
                  false,
                  event
                )
              }
            >
              {STRINGS.COMMON.CHOICE.NO}
            </button>
          </div>
        </div>
        <div className="[ error-msg ]">
          {errors?.scanConfirmationErrorList[0]}
        </div>
      </div>
    );

    const countryCode = getSelectedCountry(mailingCountry);

    const mailingPostalCodePattern =
      AppConstants.ABOUT_YOU[`${countryCode}_POSTALMASK`];

    return (
      <>
        <div>{userInfo}</div>

        <div className="[ form-container ]">
          <div className="[ row ] [ d-xl-none ] [ mb-3 ]">
            <div className="[ col-12 col-lg-6 ]">
              <div className="[ selfie-container ] ">{selfie}</div>
            </div>
            <div className="[ col-12 col-lg-6 ]">
              {selfieImage ? thankYouCard : null}
            </div>
          </div>
          <div className="[ divider ] [ d-xl-none ]" />
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.PERSONALSECTION}
          </div>
          {firstName ? (
            <>
              <div className="[ row ]">{userInformation}</div>
            </>
          ) : (
            <div className="[ row ]">{userInformation}</div>
          )}

          {isDesktop ? null : confirmQuestion}
          <div className="[ divider ] [ d-xl-none ]" />
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESSSECTION}
          </div>
          {addressInputGroup}

          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSCONFIRMQUESTION}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    mailingSameAsCivic !== "" && mailingSameAsCivic
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={(event) =>
                    this.toggleButton(
                      STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC,
                      true,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    mailingSameAsCivic !== "" && !mailingSameAsCivic
                      ? "[ btn custom-radio-selected ] "
                      : "[ btn custom-radio-unselected ] "
                  }
                  onClick={(event) =>
                    this.toggleButton(
                      STRINGS.ABOUTYOU.PARTFIVE.FIELDNAME.MAILINGSAMEASCIVIC,
                      false,
                      event
                    )
                  }
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>
            <div className="[ error-msg ]">
              {errors?.mailingSameAsCivicErrorList[0]}
            </div>
          </div>

          {mailingSameAsCivic !== "" && !mailingSameAsCivic ? (
            <div className="[ drawer-content-container mb-3 ]">
              <div className="[ info-title ]">
                {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.MAILINGSECTION}
              </div>

              <div className="[ row ]">
                <div className="[ col-12 ]">
                  <div className="[ form-label ]">
                    {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS0}
                  </div>
                  <>
                    <input
                      type="text"
                      name="mailingAddressLine1"
                      className={
                        errors?.mailingAddressLine1ErrorList.length > 0
                          ? "[ form-control ] [ invalid-input ]"
                          : "[ form-control ]"
                      }
                      placeholder={
                        STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS1
                      }
                      value={mailingAddressLine1}
                      onChange={this.onChangeHandler}
                    />
                  </>
                  <div className="[ error-msg ]">
                    {errors?.mailingAddressLine1ErrorList[0]}
                  </div>
                </div>

                <div className="[ col-12 ]">
                  <div className="[ mb-3 ]">
                    <>
                      <input
                        type="text"
                        name="mailingAddressLine2"
                        className="[ form-control ]"
                        placeholder={
                          STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.ADDRESS2
                        }
                        value={mailingAddressLine2}
                        onChange={this.onChangeHandler}
                      />
                    </>
                    <div className="[ error-msg ]" />
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                    </div>
                    <>
                      <input
                        type="text"
                        name="mailingCity"
                        className={
                          errors?.mailingCityErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ]"
                            : "[ form-control ]"
                        }
                        placeholder={STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.CITY}
                        value={mailingCity}
                        onChange={this.onChangeHandler}
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingCityErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.PROVINCE}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {mailingSelectElement}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingProvinceErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE}
                    </div>
                    <>
                      <MaskedInput
                        mask={mailingPostalCodePattern}
                        className={
                          errors?.mailingPostalCodeErrorList.length > 0
                            ? "[ form-control ] [ invalid-input ] [ postalCode ]"
                            : "[ form-control ] [ postalCode ]"
                        }
                        placeholder={
                          STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.POSTALCODE
                        }
                        guide={false}
                        id="mailingPostalCode"
                        name="mailingPostalCode"
                        value={mailingPostalCode}
                        onChange={this.onChangeHandler}
                      />
                    </>
                    <div className="[ error-msg ]">
                      {errors?.mailingPostalCodeErrorList[0]}
                    </div>
                  </div>
                </div>

                <div className="[ col-12 col-md-6 ]">
                  <div className="[ mb-3 ]">
                    <div className="[ form-label ]">
                      {STRINGS.ABOUTYOU.PARTFIVE.ADDRESSFORM.COUNTRY}
                    </div>
                    {/* <div className="[ dropdown ]"> */}
                    {countrySelectElem}
                    {/* </div> */}
                    <div className="[ error-msg ]">
                      {errors?.mailingCountryErrorList[0]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                <button
                  type="button"
                  className="[ btn  submit-btn ] [ w-100 ] "
                  onClick={this.submitData}
                >
                  {STRINGS.COMMON.CONTINUEBTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Review.propTypes = {
  selfieImage: PropTypes.string,
  fName: PropTypes.string,
  mName: PropTypes.string,
  lName: PropTypes.string,
  dOB: PropTypes.string,
  address: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  postalCode: PropTypes.string,
  provinceList: PropTypes.arrayOf(PropTypes.object),
  isDesktop: PropTypes.bool,
  countryList: PropTypes.arrayOf(PropTypes.object),
  doGetCountryList: PropTypes.func.isRequired,
  doGetGettingStartedVault: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  doGetProvinceData: PropTypes.func.isRequired,
  instntTxnId: PropTypes.string,
  vaultProductList: PropTypes.arrayOf(PropTypes.object).isRequired,
  productList: PropTypes.arrayOf(PropTypes.object).isRequired,
  getActiveProduct: PropTypes.func,
  inSessionJointApplicant: PropTypes.object,
  applicationCompleteList: PropTypes.arrayOf(PropTypes.object),
};

Review.defaultProps = {
  selfieImage: "",
  fName: "",
  mName: "",
  lName: "",
  dOB: "",
  address: "",
  addressLine2: "",
  city: "",
  province: "",
  postalCode: "",
  isDesktop: true,
  countryList: [],
  provinceList: [],
  instntTxnId: "",
  getActiveProduct: () => {},
  inSessionJointApplicant: null,
  applicationCompleteList: [],
};

const mapStateToProps = (state) => ({
  provinceList: state.AboutYouReducer.provinceList,
  getIsApplicantUpdated: state.UpdateApplicantReducer,
  countryList: state.EmploymentAndIncomeReducer.countryList,
  vaultProductList: state.VaultReducer.vaultProductList,
  productList: state.MarketplaceReducer.productList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetCountryList: () => dispatch(getCountryList()),
    doGetGettingStartedVault: (callback) =>
      dispatch(getGettingStartedData(callback)),

    doGetProvinceData: () => dispatch(doGetProvinceList()),
  };
};

const ReviewWithRouter = withRouter(Review);
export default connect(mapStateToProps, mapDispatchToProps)(ReviewWithRouter);
