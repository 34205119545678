import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import * as ROUTES from "Constants/Routes";
import AppConstants from "../../Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import STRINGS from "../../Constants/Strings";
import ButtonGroup from "react-bootstrap/ButtonGroup";

class JointApplicationStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // default state for popup modal
      modalType: "", // popup modal type (success or error)
      modal: {
        title: "", // popup modal title
        description: "", // popup modal description
      },
      errorCode: null,
      applicants: [
        {
          emailAddress: "",
          mobilePhone: "",
          applicantType: "",
        },
      ],
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate() {}

  setFormData = () => {};

  toggleModal = () => {
    const { showModal, erroCode } = this.state;
    this.setState({
      showModal: !showModal,
    });
    if (erroCode) {
      const { history } = this.props;
      history.push(ROUTES.EXISTING_MEMBER);
    }
  };

  handleAddApplicant = () => {
    const applicant = {
      emailAddress: "",
      mobilePhone: "",
      applicantType: "",
    };

    const action = {
      type: "add",
      applicant,
    };
    this.updateApplicant(action);
  };

  handleDeleteApplicant = (slotId, idx, mobileNo) => {
    const { handleDeleteApplicant } = this.props;
    handleDeleteApplicant(slotId, idx, mobileNo);
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  handleChange = (raw, prop) => (event) => {
    const { applicants } = this.state;
    const applicant = raw;
    applicant[prop] = event.currentTarget.value;
    this.setState({ applicants });
  };

  handleContinue = () => {
    const {
      handleContinue,
      handlePopupModalBtnClick,
      inSessionPending,
    } = this.props;
    if (inSessionPending) {
      handlePopupModalBtnClick();
    } else {
      handleContinue();
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT_STATUS);
  };

  getApplicantType = (type) => {
    const { applicantTypes } = this.props;
    let description = "";
    applicantTypes.forEach((item) => {
      if (item.code === type) {
        description = item.description;
      }
    });
    return description;
  };

  getApplicantStatus = (applicant) => {
    let status = "";
    if (applicant.appliedAt) {
      status = "Completed";
    } else {
      if (applicant.inviteDetail) {
        status = "Invited";
      } else {
        status = "Pending";
      }
    }
    return status;
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

  render() {
    const {
      steps,
      activeStepID,
      showModal,
      type,
      modal,
      toggleModal,
      handlePopupModalBtnClick,
      appProperties,
      inSessionPending,
    } = this.props;

    const appPropertiesComp = appProperties.map(
      (raw, index) =>
        raw.type === "co" && (
          <div key={raw.id} className="[ details-section ]">
            <div className="[ row ] [ details-section__header">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.JOINT_APPLICANT.FORM.APPLICANT_NO} {index + 0}
                  {/* START CHRIS: This is to be hidden for now */}
                  {/* <ButtonGroup>
                    <button
                      type="button"
                      className="[ btn resend-btn ]"
                      aria-label="resend email invite"
                    >
                      <span className="[ resend-icon ]" />
                      <span className="[ resend-btn__label ]">
                        {STRINGS.JOINT_APPLICANT_STATUS.RESEND_COAPPLICANT}
                      </span>
                    </button>
                    <button
                      type="button"
                      className="[ btn trash-btn ]"
                      aria-label="remove applicant"
                      onClick={() =>
                        this.handleDeleteApplicant(
                          raw.id,
                          index,
                          raw.inviteDetail?.msisdn
                        )
                      }
                    >
                      <span className="[ trash-icon ]" />
                      <span className="[ trash-btn__label ]">
                        {STRINGS.JOINT_APPLICANT_STATUS.REMOVE_COAPPLICANT}
                      </span>
                    </button>
                  </ButtonGroup> */}
                  {/* END CHRIS: This is to be hidden for now */}
                </h3>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-lg-3 ]">
                <div className="[ details-section__item-description ]">
                  <div className="[ form-label ]">
                    {STRINGS.JOINT_APPLICANT_STATUS.EMAIL_LABEL}
                  </div>
                  <input
                    type="text"
                    className="[ form-control ]"
                    name="emailAddress"
                    placeholder="Email Address"
                    disabled
                    defaultValue={
                      raw.inviteDetail?.email
                        ? raw.inviteDetail.email
                        : raw.extra?.email
                    }
                  />
                </div>
              </div>
              <div className="[ col-lg-3 ]">
                <div className="[ details-section__item-description ]">
                  <div className="[ form-label ]">
                    {STRINGS.JOINT_APPLICANT_STATUS.MOBILENO_LABEL}
                  </div>
                  <input
                    type="tel"
                    className="[ form-control ]"
                    format={AppConstants.JOINT_APPLICANT.NUMBERFORMAT}
                    name="mobilePhone"
                    disabled
                    placeholder="Mobile Phone Number"
                    defaultValue={
                      raw.inviteDetail?.msisdn
                        ? this.formatPhoneNumber(
                            raw.inviteDetail.msisdn.substring(1)
                          )
                        : this.formatPhoneNumber(raw.extra?.msisdn.substring(1))
                    }
                  />
                </div>
              </div>
              <div className="[ col-lg-3 ]">
                <div className="[ details-section__item-description ]">
                  <div className="[ form-label ]">
                    {STRINGS.JOINT_APPLICANT_STATUS.APPLICANT_TYPE_LABEL}
                  </div>
                  <input
                    type="text"
                    className="[ form-control ]"
                    name="applicantType"
                    disabled
                    placeholder="Applicant Type"
                    defaultValue={
                      raw.joinInfo.joinType
                        ? this.getApplicantType(raw.joinInfo.joinType)
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="[ col-lg-3 ]">
                <div className="[ details-section__item-label ]">
                  <div className="[ form-label ]">
                    {STRINGS.JOINT_APPLICANT_STATUS.APPLICANT_TYPE_STATUS}
                  </div>
                  <input
                    type="text"
                    className="[ form-control ]"
                    name="status"
                    placeholder="Status"
                    disabled
                    defaultValue={this.getApplicantStatus(raw)}
                  />
                </div>
              </div>
            </div>
          </div>
        )
    );

    const jointApplicantStatusComponent = (
      <div className="[ joint-application-status-container ]">
        <Stepper steps={steps} active={activeStepID} />
        <div className="[ form-title ]">
          {STRINGS.JOINT_APPLICANT_STATUS.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-8 ]">
                <div className="[ form-subtitle ]">
                  {STRINGS.JOINT_APPLICANT_STATUS.SUBTITLE1}
                </div>
                {inSessionPending && (
                  <div className="[ form-description ]">
                    {modal.description}
                  </div>
                )}
                {!inSessionPending && (
                  <div className="[ form-description ]">
                    {STRINGS.JOINT_APPLICANT_STATUS.SUBTITLE2}{" "}
                    {STRINGS.JOINT_APPLICANT_STATUS.TABLE_DESCRIPTION}
                  </div>
                )}

                {!inSessionPending && appPropertiesComp}

                {appProperties.length === 1 && (
                  <div className="[ row ]">
                    <div className="[col-12 ]">No Joint Applicants</div>
                  </div>
                )}
              </div>
              {!inSessionPending && (
                <div className="[ col-lg-4 ]">
                  <div className="[ alert alert-info ]" role="alert">
                    <div className="[ form-description ]">
                      {STRINGS.JOINT_APPLICANT_STATUS.APPLICANT_INFO_TEXT1}
                    </div>
                    <div className="[ form-description ]">
                      {STRINGS.JOINT_APPLICANT_STATUS.APPLICANT_INFO_TEXT2}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        {showModal && !inSessionPending && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={handlePopupModalBtnClick}
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={jointApplicantStatusComponent}
          memberCreation
          handleBack={this.handleBack}
        />
      </div>
    );
  }
}

JointApplicationStatus.propTypes = {
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  handlePopupModalBtnClick: PropTypes.func,
  getDataFromServer: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  appProperties: PropTypes.arrayOf(PropTypes.object),
  handleDeleteApplicant: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
};
JointApplicationStatus.defaultProps = {
  getDataFromServer: () => {},
  handlePopupModalBtnClick: () => {},
  applicantTypes: [],
  appProperties: [],
  steps: [],
  activeStepID: -1,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(JointApplicationStatus);
