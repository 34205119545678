import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  response: null,
  gettingStarted: null,
  error: null,
  applicationProgress: [
    {
      productIndex: null,
      productId: null,
      productName: "",
      applicantId: null,
      applicationId: null,
      applicationStep: {
        index: null,
        step: "",
        active: false,
      },
    },
  ],
  isEdit: false,
  applicationCompleteList: [],
  applicationAppliedList: [],
  templates: [],
  processTemplate: { flow: [] },
  scanDocument: false,
  isNotFirst: false,
  sessionCompletedProducts: [],
  slots: [],
  doximProducts: [],
  isDebitCardEnabled: false,
  applicationId: null,
  eSignatureEnabled: false,
  applicationList: [],
  actionBack: false,
  inReview: false,
  uploadedAt: null,
};

const ApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_APPLICANT_RESPONSE:
      return {
        ...state,
        response: action.response,
        error: null,
      };
    case actionTypes.STORE_GETTING_STARTED:
      return {
        ...state,
        gettingStarted: action.data,
        error: null,
      };

    case actionTypes.STORE_APPLICATION_STEPS:
      return {
        ...state,
        applicationProgress:
          action.response && action.response.length > 0
            ? action.response
            : [...initialState.applicationProgress],
        error: null,
      };
    case actionTypes.STORE_APPLICATION_PROGRESS_REQUEST:
      return {
        ...state,
        applicationProgress: action.request,
      };
    case actionTypes.APPLICATION_STEPS_ERROR:
      return {
        ...state,
        error: action.error,
        response: null,
      };
    case actionTypes.GETTING_STARTED_ERROR:
      return {
        ...state,
        error: action.error,
        response: null,
      };
    case actionTypes.APPLICATION_ERROR:
      return {
        ...state,
        error: action.error,
        response: null,
      };
    case actionTypes.TOGGLE_EDIT_FLAG:
      return {
        ...state,
        isEdit: action.flag,
      };
    case actionTypes.STORE_APPLICATION_LIST:
      return {
        ...state,
        applicationCompleteList: action.data.filter(
          (application) =>
            application.inReview ||
            application.uploadedAt ||
            application.submittedToDecisionAt
        ),
        applicationAppliedList: action.data.filter(
          (application) => application.appliedAt
        ),
        applicationList: action.data,
      };
    case actionTypes.RESET:
      return initialState;
    case actionTypes.STORE_GET_PRODUCT_TEMPLATE:
      return {
        ...state,
        templates: action.data,
        processTemplate: action.data?.find(
          (template) => template.templateId === action.product.templateId
        ),
      };
    case actionTypes.UPDATE_PRODUCT_TEMPLATE:
      return {
        ...state,
        processTemplate: state.templates.find(
          (templateFlow) =>
            templateFlow.templateId === action.activeProduct.templateId
        ),
      };
    case actionTypes.TOGGLE_SCAN_FLAG:
      return {
        ...state,
        scanDocument: action.flag,
      };
    case actionTypes.SET_NOT_FIRST_FLAG:
      return {
        ...state,
        isNotFirst: action.flag,
      };
    case actionTypes.STORE_COMPLETED_PRODUCT_IN_A_SESSION:
      return {
        ...state,
        sessionCompletedProducts: state.sessionCompletedProducts.concat(
          action.product
        ),
      };
    case actionTypes.GET_APPLICATION_PROPERTY_SUCCESS:
      return {
        ...state,
        error: "",
        success: true,
        inReview: action.response.inReview,
        uploadedAt: action.response.uploadedAt,
        slots: action.response.slots,
        doximProducts: action.response.doximProducts,
        applicationId: action.applicationId,
      };
    case actionTypes.GET_APPLICATION_PROPERTY_ERROR:
      return {
        ...state,
        error: action.error,
        success: "",
        appProperties: [],
        doximProducts: [],
      };
    case actionTypes.SET_E_SIGNATURE_ENABLED:
      return {
        ...state,
        eSignatureEnabled: action.flag,
      };
    case actionTypes.STORE_PRODUCT_TEMPLATE:
      return {
        ...state,
        templates: action.templates,
        processTemplate: action.processTemplate,
      };
    case actionTypes.TOGGLE_BACK_FLAG:
      return {
        ...state,
        actionBack: action.flag,
      };
    default:
      return state;
  }
};

export default ApplicationReducer;
