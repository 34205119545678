import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import "./FundingReviewWindow.scss";

const FundingReviewWindow = (props) => {
  const { fundMethod, fundAmount, productName, showFundVaultClicked } = props;
  return (
    <div className="[ vault-container ] [ funding ]">
      <div className="[ vault-header__container ]">
        <h4 className="[ form-subtitle ]">
          <i className="fas fa-money-bill-wave" />
          {STRINGS.FUND_ACCOUNT.FUND_CARD.FUND_ACCOUNT}
          <button
            type="button"
            className="[ close ]"
            onClick={showFundVaultClicked}
          >
            <span aria-hidden="true">×</span>
            <span className="[ sr-only ]">Close</span>
          </button>
        </h4>
      </div>
      <div className="[ details-section ] [ mb-3 ]">
        <div className="[ row ] [ details-section__item ]">
          <div className="[ col-sm-6 ]">
            <div className="[ details-section__item-label ]">
              {STRINGS.FUND_ACCOUNT.FUND_CARD.ACCOUNT}
            </div>
          </div>
          <div className="[ col-sm-6 ]">
            <div className="[ details-section__item-description ]">
              {productName}
            </div>
          </div>
        </div>

        <div className="[ row ] [ details-section__item ]">
          <div className="[ col-sm-6 ]">
            <div className="[ details-section__item-label ]">
              {STRINGS.FUND_ACCOUNT.FUND_CARD.FUND_METHOD}
            </div>
          </div>
          <div className="[ col-sm-6 ]">
            <div className="[ details-section__item-description ]">
              {fundMethod}
            </div>
          </div>
        </div>

        <div className="[ row ] [ details-section__item ]">
          <div className="[ col-sm-6 ]">
            <div className="[ details-section__item-label ] [ amount ]">
              {STRINGS.FUND_ACCOUNT.FUND_CARD.AMOUNT}
            </div>
          </div>
          <div className="[ col-sm-6 ]">
            <div className="[ details-section__item-description ] [ amount ]">
              {fundAmount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FundingReviewWindow.propTypes = {
  fundMethod: PropTypes.string,
  fundAmount: PropTypes.string,
  productName: PropTypes.string,
  showFundVaultClicked: PropTypes.func,
};
FundingReviewWindow.defaultProps = {
  fundMethod: "",
  fundAmount: "",
  productName: "",
  showFundVaultClicked: () => {},
};

export default FundingReviewWindow;
