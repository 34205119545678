import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import AppConstants from "Constants/AppConstants";
import AccountInformation from "./AccountInformation";
import getIntendedUseList from "./Actions/AccountInformationAction";

class AccountInformationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { doGetIntendedUseList } = this.props;
    doGetIntendedUseList();
  }

  /**
   * Send the form data to server through API call
   */
  handleAccountInformation = (formdata) => {
    const { continuehandler } = this.props;

    // Create request body
    const requestBody = {
      intendedUse: formdata.intendedUse,
    };
    // Call common function from ApplicantHOC to send the data to server
    continuehandler(
      requestBody,
      AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION
    );
  };

  render() {
    const {
      steps,
      activeStepID,
      getDataFromServer,
      handleBack,
      intendedUseList,
    } = this.props;
    return (
      <AccountInformation
        steps={steps}
        handleBack={() =>
          handleBack(AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION)
        }
        activeStepID={activeStepID}
        getDataFromServer={getDataFromServer}
        intendedUseList={intendedUseList}
        handleAccountInformation={this.handleAccountInformation}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doGetIntendedUseList: () => dispatch(getIntendedUseList()),
  };
};

const mapStateToProps = (state) => ({
  intendedUseList: state.AccountInformationReducer.intendedUseList,
});

AccountInformationContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  doGetIntendedUseList: PropTypes.func.isRequired,
  intendedUseList: PropTypes.arrayOf(PropTypes.object).isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  continuehandler: PropTypes.func.isRequired,
};

AccountInformationContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const AccountInformationWrapper = withApplicantHOC(AccountInformationContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInformationWrapper);
