import BaseApiRequest from "./BaseApiRequest";
import { OTP_VERIFY_INVITE_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class OtpVerifyInvite extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    otpId: "",
    otp: "",
  };

  url = OTP_VERIFY_INVITE_URL;

  constructor(reqData) {
    super();

    const { otpId, otp } = reqData;
    this.body.otpId = otpId;
    this.body.otp = otp;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
