import React, { Component } from "react";
import PropTypes from "prop-types";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import checkErrors from "Utils/InputValidator";

class Selfie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanError: "",
      capture: null,
      capturedImage: null,
    };
  }

  componentDidMount() {
    this.setState({ capture: window.IDMetricsCaptureFramework });
  }

  scanSelfie = () => {
    const { capture } = this.state;
    const captureResult = new window.IDMetricsCaptureFramework.CaptureResult();
    const docSettings = new window.IDMetricsCaptureFramework.DocumentSettings();
    docSettings.captureMode = STRINGS.ABOUTYOU.COMMONPART.CAPTUREMODE;
    capture.scanSelfie(docSettings, captureResult);
    captureResult.setOnFinish(() => this.scanResult(captureResult));
    captureResult.setOnEvent(() => {
      captureResult.continue();
    });
  };

  scanResult = (captureResult) => {
    this.setState({ capturedImage: captureResult.result, scanError: "" });
  };

  submitData = (imgSrc) => {
    if (this.verifyData(imgSrc)) {
      const { selfieHandler } = this.props;
      selfieHandler(imgSrc);
    }
  };

  verifyData = (imgSrc) => {
    const imageSource = imgSrc || "";
    const idErrorsList = checkErrors(imageSource, VALIDATIONS.ABOUTYOU.SELFIE);
    if (idErrorsList && idErrorsList.length > 0) {
      this.setState({
        scanError: idErrorsList,
      });
      return false;
    }
    return true;
  };

  render() {
    const { scanError, capturedImage } = this.state;
    const { selfie } = this.props;
    let scanErrorDOM = null;
    if (scanError) {
      scanErrorDOM = (
        <div className="[ scan-error__card ]">
          {scanError.map((error) => (
            <div className="[ error-msg ]" key="error-msg">
              {error}
            </div>
          ))}
          <div className="[ row ] [ info-row ]" />
          <div className="[ row ] [ info-row ] " />
        </div>
      );
    }

    let imageDiv = (
      <div
        className="[ scan-id__card ]"
        onClick={this.scanSelfie}
        onKeyDown={this.handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <div className="[ flex-row justify-content-center text-center ]">
          <i className="[ fas fa-portrait ]" />
        </div>
        <div className="[ scan-id__text] ">{STRINGS.ABOUTYOU.CLICKTOBEGIN}</div>
      </div>
    );
    let imgSrc = null;
    if (selfie) {
      imgSrc = selfie;
    }
    if (capturedImage) {
      imgSrc = capturedImage;
    }
    if (imgSrc) {
      imageDiv = (
        <div className="[ scan-id__card ]">
          <img className="[ scan-id__card__img ]" src={imgSrc} alt="selfie" />
        </div>
      );
    }

    return (
      <>
        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.ABOUTYOU.PARTFOUR.SUBTITILE}
          </div>
          <div className="[ form-description ]">
            {STRINGS.ABOUTYOU.PARTFOUR.ABOUTYOUINFO}
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ] [ d-flex ] [ justify-content-center ]">
              <div className="[ col-12 ]">{imageDiv}</div>
              <div className="[ col-12 ]">{scanErrorDOM}</div>
            </div>
          </div>

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-12 ]">
                <button
                  type="button"
                  className="[ btn ] [ retake ]"
                  onClick={this.scanSelfie}
                  disabled={capturedImage === null}
                >
                  {STRINGS.ABOUTYOU.RETAKE}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={() => this.submitData(imgSrc)}
              >
                <span className="[ d-md-none d-block ]">
                  {STRINGS.COMMON.CONTINUEBTN}
                </span>
                <span className="[ d-none d-md-block ]">
                  {STRINGS.ABOUTYOU.UPLOADANDCONTINUE}
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Selfie.propTypes = {
  selfieHandler: PropTypes.func,
  selfie: PropTypes.string,
};

Selfie.defaultProps = {
  selfieHandler: () => {},
  selfie: null,
};

export default Selfie;
