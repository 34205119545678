import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import NumberFormat from "react-number-format";
import Alert from "react-bootstrap/Alert";
// eslint-disable-next-line spaced-comment
/*import checkErrors from "../../Utils/InputValidator";
import VALIDATIONS from "../../Constants/ValidationList";*/
import STRINGS from "../../Constants/Strings";
import { HTTP_STATUS } from "../../Communication/Constants";
import AppConstants from "../../Constants/AppConstants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";

class JointApplicant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicants: [],
      isAddApplicant: false,
      isApplicantPresent: false,
      appCnt: 3,
      applicationId: "",
      applicantId: "",
      jointAppVaultProducts: [],
      emailError: [],
      mobileError: [],
      typeError: [],
      formIsValid: true,
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;

    getDataFromServer(
      AppConstants.APPLICATIONSTEP.JOINT_APPLICANT,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  componentDidUpdate(prevProps) {
    const {
      products,
      jointAccounts,
      jointInSession,
      getActiveProduct,
    } = this.props;
    if (prevProps.jointAccounts !== jointAccounts) {
      this.updateFormData(jointAccounts, jointInSession);
    }

    if (prevProps.products !== products) {
      if (products.length > 0) {
        const activeProduct = getActiveProduct(products);
        this.state.applicationId = activeProduct.applicationId;
        this.state.applicantId = activeProduct.applicantId;

        this.state.jointAppVaultProducts = [
          {
            applicantId: null,
            applicationId: null,
            applicationStep: {
              index: null,
              step: "",
            },
            productId: activeProduct.productId,
            productIndex: activeProduct.productIndex,
            productName: activeProduct.productName,
            templateId: activeProduct.templateId,
            type: activeProduct.type,
            isJointProduct: true,
          },
        ];
      }
    }
  }

  setFormData = () => {};

  handleAddApplicantBtn = (choice) => {
    const { applicants, isApplicantPresent } = this.state;
    this.setState({ isAddApplicant: choice });
    if (choice) {
      if (applicants.length === 0) {
        this.setState({
          applicants: [
            {
              emailAddress: "",
              mobilePhone: "",
              applicantType: "",
            },
          ],
          isApplicantPresent,
          emailError: [],
          mobileError: [],
          typeError: [],
          formIsValid: true,
        });
      }
    } else {
      this.setState({ applicants: [], isApplicantPresent: false });
    }
  };

  handleApplicantPresentBtn = (choice) => {
    this.setState({ isApplicantPresent: choice });
  };

  validateForm = () => {
    let valid = true;
    const { applicants } = this.state;
    this.setState({
      emailError: [],
      mobileError: [],
      typeError: [],
      formIsValid: true,
    });

    if (applicants.length > 0) {
      let error = checkErrors(
        applicants[applicants.length - 1].emailAddress,
        VALIDATIONS.JOINT_APPLICANT.EMAIL
      );
      if (error.length) {
        valid = false;
        this.setState({ emailError: error });
      } else {
        const emails = applicants.filter(
          (joint) =>
            joint.emailAddress ===
            applicants[applicants.length - 1].emailAddress
        );
        if (emails.length > 1) {
          valid = false;
          this.setState({
            emailError: [STRINGS.JOINT_APPLICANT.ERROR.EMAIL_DUPLICATE],
          });
        }
      }
      error = checkErrors(
        applicants[applicants.length - 1].mobilePhone,
        VALIDATIONS.JOINT_APPLICANT.MOBILE
      );
      if (error.length) {
        valid = false;
        this.setState({ mobileError: error });
      }
      error = checkErrors(
        applicants[applicants.length - 1].applicantType,
        VALIDATIONS.JOINT_APPLICANT.TYPE
      );
      if (error.length) {
        valid = false;
        this.setState({ typeError: error });
      }

      this.setState({
        formIsValid: valid,
      });
    }

    return valid;
  };

  handleAddApplicant = () => {
    if (this.validateForm()) {
      const app = {
        emailAddress: "",
        mobilePhone: "",
        applicantType: "",
      };
      const action = {
        type: "add",
        applicant: app,
      };
      this.updateApplicant(action);
    }
  };

  handleDeleteApplicant = (idx) => {
    const action = {
      type: "remove",
      index: idx,
    };
    this.updateApplicant(action);
    const { applicants } = this.state;
    if (applicants.length === 0) {
      this.setState({
        isAddApplicant: false,
      });
    }
    this.validateForm();
  };

  clearChoice = () => {
    this.setState({ isAddApplicant: "", isApplicantPresent: "" });
  };

  getUniqueID = () => {
    this.id += 1;
    return this.id;
  };

  handleChange = (raw, prop) => (event) => {
    const { applicants } = this.state;
    const applicant = raw;
    applicant[prop] = event.currentTarget.value;
    this.setState({ applicants });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    if (this.validateForm()) {
      handleContinue(this.state);
    }
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT);
  };

  updateFormData(jointAccounts, jointInSession) {
    console.log("updateFormData", jointAccounts, jointInSession);
    this.setState({
      isApplicantPresent: jointInSession,
    });
    if (jointAccounts.length > 0) {
      this.setState({
        isAddApplicant: true,
      });
    }
    const joints = [];
    jointAccounts.forEach((joint) => {
      joints.push({
        emailAddress: joint.extra.email,
        mobilePhone: joint.extra.msisdn.substring(1),
        applicantType: joint.joinInfo.joinType,
        id: joint.id,
        inviteDetail: joint.inviteDetail,
      });
    });
    this.setState({
      applicants: joints,
    });
  }

  updateApplicant(action) {
    const { applicants, appCnt } = this.state;
    if (action.type === "add") {
      if (applicants.length < appCnt) {
        applicants.push(action.applicant);
      }
    } else if (action.type === "remove") {
      if (action.index > -1) {
        applicants.splice(action.index, 1);
      }
    }
    this.setState({ applicants });
  }

  render() {
    const {
      isAddApplicant,
      applicants,
      isApplicantPresent,
      emailError,
      mobileError,
      typeError,
    } = this.state;
    const { steps, activeStepID, applicantTypes } = this.props;

    const productTypesOptComp =
      applicantTypes &&
      applicantTypes.length > 0 &&
      applicantTypes.map((applicantType) => (
        <option value={applicantType.code} className="[ dropdown__option ]">
          {applicantType.description}
        </option>
      ));

    const addApplicantComp = applicants.map((applicant, index) => (
      <div key={applicant.id} className="[ drawer-content-container ] [ mb-3 ]">
        <div className="[ info-title ]">
          {STRINGS.JOINT_APPLICANT.FORM.APPLICANT_NO} {index + 1}
          <button
            type="button"
            className="[ fas fa-trash ]"
            name="isAddApplicant"
            onClick={() => this.handleDeleteApplicant(index)}
          >
            <span className="[ sr-only ]">
              {STRINGS.JOINT_APPLICANT.FORM.REMOVE_COAPPLICANT}
            </span>
          </button>
        </div>

        <div className="[ row ]">
          <div className="[ col-12 col-lg-4 ]">
            <div className="[ form-label ]">
              {STRINGS.JOINT_APPLICANT.FORM.EMAILLABEL}
            </div>
            <input
              type="email"
              name="emailAddress"
              className="[ form-control ]"
              placeholder={STRINGS.JOINT_APPLICANT.FORM.EMAILPLACEHOLDER}
              onChange={this.handleChange(applicant, "emailAddress")}
              value={applicant.emailAddress ? applicant.emailAddress : ""}
            />
            <div className="[ error-msg ]">
              {emailError
                ? index + 1 === applicants.length
                  ? emailError[0]
                  : ""
                : ""}
            </div>
          </div>

          <div className="[ col-12 col-lg-4 ]">
            <div className="[ form-label ]">
              {STRINGS.JOINT_APPLICANT.FORM.MOBILENOLABEL}
            </div>
            <NumberFormat
              format={AppConstants.JOINT_APPLICANT.NUMBERFORMAT}
              className="[ form-control ]"
              type="tel"
              name="mobilePhone"
              value={applicant.mobilePhone ? applicant.mobilePhone : ""}
              placeholder={STRINGS.JOINT_APPLICANT.FORM.MOBILENOPLACEHOLDER}
              onChange={this.handleChange(applicant, "mobilePhone")}
            />
            <div className="[ error-msg ]">
              {mobileError
                ? index + 1 === applicants.length
                  ? mobileError[0]
                  : ""
                : ""}
            </div>
          </div>

          <div className="[ col-12 col-lg-4 ]">
            <div className="[ form-label ]">
              {STRINGS.JOINT_APPLICANT.FORM.APPLICANT_TYPE}
            </div>
            <select
              value={applicant.applicantType ? applicant.applicantType : ""}
              name="applicantType"
              className="[ form-control ] [ select__dropdown ]"
              onChange={this.handleChange(applicant, "applicantType")}
            >
              <option value="" className="[ default ] [ dropdown__option ]">
                {STRINGS.JOINT_APPLICANT.FORM.APPLICANT_TYPE_DEFAULT_SELECT}
              </option>
              {productTypesOptComp}
            </select>
            <div className="[ error-msg ]">
              {typeError
                ? index + 1 === applicants.length
                  ? typeError[0]
                  : ""
                : ""}
            </div>
          </div>
          {applicant.id && !applicant.inviteDetail && (
            <div className="[ col-12 ]">
              <Alert variant="danger" dismissible>
                <Alert.Heading>
                  {STRINGS.JOINT_APPLICANT.ERROR.PARTIAL_MATCH_TITLE}
                </Alert.Heading>
                <p>{STRINGS.JOINT_APPLICANT.ERROR.PARTIAL_MATCH_BODY1}</p>
                <p>{STRINGS.JOINT_APPLICANT.ERROR.PARTIAL_MATCH_BODY2}</p>
              </Alert>
            </div>
          )}
        </div>
      </div>
    ));

    const jointApplicantComponent = (
      <div className="[ joint-application-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.JOINT_APPLICANT.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-subtitle ]">
              {STRINGS.JOINT_APPLICANT.FORM.CONFIRM_JOINT_APPLICANT}
            </div>
          </div>
          <div className="[ mb-3 ]">
            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isAddApplicant !== "" && isAddApplicant
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isAddApplicant"
                  onClick={(event) => this.handleAddApplicantBtn(true, event)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    isAddApplicant !== "" && !isAddApplicant
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  name="isAddApplicant"
                  onClick={(event) => this.handleAddApplicantBtn(false, event)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
              <div className="[ error-msg ]"></div>
            </div>

            {isAddApplicant !== "" && isAddApplicant ? addApplicantComp : ""}

            {isAddApplicant !== "" && isAddApplicant && (
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  <div className="[ col-lg-3 ] [ offset-lg-9 ] [ mb-3 ]">
                    <button
                      type="button"
                      className="[ btn info-btn ] [ w-100 ] [ add-btn ]"
                      onClick={(event) => this.handleAddApplicant(event)}
                    >
                      <span className="fas fa-plus"></span>
                      {STRINGS.JOINT_APPLICANT.FORM.ADD_BTN}
                    </button>
                  </div>
                </div>
                <div className="[ divider ]"></div>
              </div>
            )}
          </div>

          {isAddApplicant && (
            <div className="[ mb-3 ]">
              <div className="[ form-label ]">
                {STRINGS.JOINT_APPLICANT.FORM.JOINT_PRESENT_LABEL}
              </div>

              <div className="[ row ] [ custom-radios ]">
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      isApplicantPresent !== "" && isApplicantPresent
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="isApplicantPresent"
                    onClick={(event) =>
                      this.handleApplicantPresentBtn(true, event)
                    }
                  >
                    {STRINGS.COMMON.CHOICE.YES}
                  </button>
                </div>
                <div className="[ col-6 ]">
                  <button
                    type="button"
                    className={
                      isApplicantPresent !== "" && !isApplicantPresent
                        ? "[ btn custom-radio-selected ]"
                        : "[ btn custom-radio-unselected ]"
                    }
                    name="isApplicantPresent"
                    onClick={(event) =>
                      this.handleApplicantPresentBtn(false, event)
                    }
                  >
                    {STRINGS.COMMON.CHOICE.NO}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="[ mb-3 ]">
            <div className="[ row ]">
              <div className="[ col-lg-6 ] [ offset-lg-3 ]">
                <button
                  type="submit"
                  className="[ submit-btn btn ] [ w-100 ]"
                  onClick={this.handleContinue}
                >
                  {STRINGS.COMMON.CONTINUEBTN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <AuthBody
          actionComponent={jointApplicantComponent}
          memberCreation
          handleBack={this.handleBack}
        />
      </div>
    );
  }
}

JointApplicant.propTypes = {
  getDataFromServer: PropTypes.func,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.object),
  applicantTypes: PropTypes.arrayOf(PropTypes.object),
  doAddApplicationSlot: PropTypes.func.isRequired,
  doinviteApplicationSlot: PropTypes.func.isRequired,
  doAddGlobalVault: PropTypes.func.isRequired,
  doGetApplicationProperty: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  jointAccounts: PropTypes.arrayOf(PropTypes.object),
  jointInSession: PropTypes.bool,
};

JointApplicant.defaultProps = {
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
  applicantTypes: [],
  products: [],
  jointAccounts: [],
  jointInSession: false,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(JointApplicant);
