import * as actionTypes from "Redux/Actions/actionTypes";

const doRegistration = (userdata) => {
  return {
    type: actionTypes.REGISTER_REQUEST,
    data: userdata,
  };
};

export default doRegistration;
