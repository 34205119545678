import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import { HTTP_STATUS } from "Communication/Constants";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import * as ROUTES from "Constants/Routes";

import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";
import { doLoadingFinish } from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";

import { getApplication } from "Redux/Actions/ApplicationAction";

import {
  paymentProcess,
  getFundPaymentDetails,
  addFundAmount,
  addFundMethod,
} from "Components/FundingAmount/Action/FundAction";

import FundingAmount from "./FundingAmount";

class FundingAmountContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      type: "",
      title: "",
      description: "",
      fundAmountError: "",
      fundAmount: "",
      paysafeSuccess: false,
    };
  }

  componentDidMount() {
    const { fundProduct, doAddFundAmount } = this.props;
    if (
      Object.keys(fundProduct).length === 0 &&
      fundProduct.constructor === Object
    ) {
      const { history } = this.props;
      history.push(ROUTES.VAULT_DASHBOARD);
    } else {
      doAddFundAmount(STRINGS.FUND_ACCOUNT.FUND_AMOUNT_SYMBOL);
    }
  }

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
      title: "",
      description: "",
      fundAmountError: "",
      fundAmount: "",
      paysafeSuccess: false,
    });
  };

  handleBack = () => {
    const { history, doAddFundAmount } = this.props;
    doAddFundAmount("");
    history.push(ROUTES.FUND_METHOD);
  };

  getPaymentType = (paymentMethod) => {
    switch (paymentMethod) {
      case STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_CREDIT_CARD:
        return STRINGS.FUND_ACCOUNT.CREDIT_CARD;
      case STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_DIRECT_DEPOSIT:
        return STRINGS.FUND_ACCOUNT.EFT;
      default:
        return "";
    }
  };

  handlePaysafeCallback = (paysafeInstance, error, result) => {
    const { doPaymentProcess, FundState, doLoadingFinishAction } = this.props;
    const { fundAmount } = this.state;
    this.instance = paysafeInstance;
    doLoadingFinishAction(STRINGS.FUND_ACCOUNT.API_KEYS.FUND_PAYMENT_DETAILS);

    if (error) {
      this.setState({
        showModal: true,
        type: AppConstants.MODALTYPE.FAILURE,
        title: error.detailedMessage,
        description: error.displayMessage,
        fundAmountError: "",
      });
    } else {
      const { fundProduct } = this.props;
      const { applicationId } = fundProduct;
      const paymentType = this.getPaymentType(FundState.method);
      const paymentDetails = {
        paymentToken: result.token,
        applicationId,
        amount: Number(fundAmount),
        paymentType,
      };
      this.processResponse = null;
      doPaymentProcess(paymentDetails, (res, err) => {
        if (res && res.status === HTTP_STATUS.OK) {
          this.setState({ paysafeSuccess: true });
          this.instance.showSuccessScreen(
            STRINGS.FUND_ACCOUNT.FUND_TRANSACTION_ID + res.data.id
          );
        } else {
          this.instance.showFailureScreen(
            err.response.data.message,
            err.response.data.description
          );
        }
      });
    }
  };

  handleCloseCallback = (stage) => {
    const {
      doLoadingFinishAction,
      showNextProductPopUporRedirect,
      doAddFundMethod,
      doAddFundAmount,
    } = this.props;
    doLoadingFinishAction(STRINGS.FUND_ACCOUNT.API_KEYS.FUND_PAYMENT_DETAILS);
    if (stage === STRINGS.FUND_ACCOUNT.PAYSAFE_STAGE_BEFORE_PAYMENT) {
      // No payment has been made
    } else if (stage === STRINGS.FUND_ACCOUNT.PAYSAFE_STAGE_DURING_PAYMENT) {
      // Token has been issued, but the checkout overlay was closed from the user before instance flow control method was invoked
    } else if (stage === STRINGS.FUND_ACCOUNT.PAYSAFE_STAGE_AFTER_PAYMENT) {
      // Closed either via instance.close method or by the user from the success screen

      const { paysafeSuccess } = this.state;
      if (paysafeSuccess) {
        doAddFundMethod("");
        doAddFundAmount("");
        showNextProductPopUporRedirect();
      }
    }
  };

  getDisplayPaymentMethods = (fundMethod) => {
    switch (fundMethod) {
      case STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_CREDIT_CARD:
        return STRINGS.FUND_ACCOUNT.CARDS;
      case STRINGS.FUND_ACCOUNT.PAYMENT_TYPE_DIRECT_DEPOSIT:
        return STRINGS.FUND_ACCOUNT.DIRECT_DEBIT;
      default:
        return "";
    }
  };

  handleContinue = (fundAmount) => {
    const {
      FundState,
      doGetFundPaymentDetails,
      doGetApplicant,
      doLoadingFinishAction,
      fundProduct,
    } = this.props;

    this.setState(
      {
        fundAmount,
        fundAmountError: "",
      },
      () => {
        doGetFundPaymentDetails((response) => {
          if (response.status === HTTP_STATUS.OK) {
            if (
              fundAmount >= response.data.paysafeMinAmt &&
              fundAmount <= response.data.paysafeMaxAmt
            ) {
              doGetApplicant(fundProduct.applicantId, (res) => {
                const displayPaymentMethods = [];
                displayPaymentMethods.push(
                  this.getDisplayPaymentMethods(FundState.method)
                );
                const API_KEY = response.data.paysafeToken;
                let value = fundAmount;
                if (fundAmount.includes(".")) {
                  const decimalPos = fundAmount.indexOf(".");
                  const rightSide = fundAmount.substring(
                    decimalPos + 1,
                    fundAmount.length
                  );
                  value = fundAmount.replace(".", "");
                  if (rightSide.length === 1) {
                    // Added one extra zeros in paysafe checkout amount param,used for two digits after deicimal point.
                    value += "0";
                  }
                } else {
                  // Added two extra zeros in paysafe checkout amount param,used for two digits after deicimal point.
                  value += "00";
                }
                const amount = Number(value);
                const optionData = {
                  amount,
                  currency: response.data.currency,
                  environment: response.data.environment,
                  companyName: response.data.name,
                  buttonColor: response.data.btnColor,
                  imageUrl: response.data.logoURL,
                  holderName: `${res.data.member.name} ${res.data.member.surname}`,
                  displayPaymentMethods,
                  billingAddress: {
                    country: STRINGS.FUND_ACCOUNT.FUND_COUNTRY,
                    zip: res.data.address.currentAddressPostalCode,
                    city: res.data.address.currentAddressCity,
                    state: res.data.address.currentAddressProvince,
                    street: `${res.data.address.currentAddressLine1} ${res.data.address.currentAddressLine2}`,
                  },
                };
                window.paysafe.checkout.setup(
                  API_KEY,
                  optionData,
                  this.handlePaysafeCallback,
                  this.handleCloseCallback
                );
              });
            } else {
              doLoadingFinishAction(
                STRINGS.FUND_ACCOUNT.API_KEYS.FUND_PAYMENT_DETAILS
              );
              const replacements = {
                "%min%": response.data.paysafeMinAmt,
                "%max%": response.data.paysafeMaxAmt,
              };
              let amountErrorMsg = STRINGS.FUND_ACCOUNT.FUND_AMOUNT_ERROR_MSG;

              amountErrorMsg = amountErrorMsg.replace(
                /%\w+%/g,
                (all) => replacements[all] || all
              );
              this.setState({ fundAmountError: amountErrorMsg });
            }
          }
        });
      }
    );
  };

  render() {
    const { showModal, type, title, description, fundAmountError } = this.state;
    const { FundState, fundProduct, doAddFundAmount } = this.props;

    return (
      <div>
        <FundingAmount
          showModal={showModal}
          handleBack={this.handleBack}
          handleContinue={this.handleContinue}
          fundMethod={FundState.method}
          type={type}
          title={title}
          description={description}
          toggleModal={this.toggleModal}
          fundAmountError={fundAmountError}
          productName={fundProduct.productName}
          doAddFundAmount={doAddFundAmount}
        />
      </div>
    );
  }
}
FundingAmountContainer.defaultProps = {
  history: {},
  FundState: {},
  showNextProductPopUporRedirect: () => {},
  fundProduct: {},
};
FundingAmountContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  FundState: PropTypes.objectOf(PropTypes.any),
  doPaymentProcess: PropTypes.func.isRequired,
  doGetFundPaymentDetails: PropTypes.func.isRequired,
  doAddFundAmount: PropTypes.func.isRequired,
  doGetApplicant: PropTypes.func.isRequired,
  doLoadingFinishAction: PropTypes.func.isRequired,
  showNextProductPopUporRedirect: PropTypes.func,
  fundProduct: PropTypes.objectOf(PropTypes.any),
  doAddFundMethod: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  FundState: state.FundReducer,
  fundProduct: state.VaultReducer.fundProduct,
});
const mapDispatchToProps = (dispatch) => {
  return {
    doPaymentProcess: (paymentDetails, callback) =>
      dispatch(paymentProcess(paymentDetails, callback)),
    doGetFundPaymentDetails: (callback) =>
      dispatch(getFundPaymentDetails(callback)),
    doAddFundAmount: (fundAmount) => dispatch(addFundAmount(fundAmount)),
    doLoadingFinishAction: (loading) => dispatch(doLoadingFinish(loading)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
    doGetApplicant: (id, callback) => dispatch(getApplication(id, callback)),
    doAddFundMethod: (fundMethod) => dispatch(addFundMethod(fundMethod)),
  };
};

const FundingAmountWrapper = withApplicantHOC(FundingAmountContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundingAmountWrapper);
