import * as actionTypes from "Redux/Actions/actionTypes";

export const doLoadingStart = (loading = -1, message) => ({
  type: actionTypes.LOADING_START,
  loading,
  message,
});

export const doLoadingFinish = (loading = -1) => ({
  type: actionTypes.LOADING_FINISH,
  loading,
});
