import React from "react";
import "./Footer.scss";
import STRINGS from "Constants/Strings";

const Footer = () => {
  return (
    <footer className="[ footer ] [ container-fluid ]">
      <div className="[ row ] [ align-items-center ] [ p-2 pl-xl-4 pr-xl-3 ] [ ml-xl-3 ]">
        <div className="[ col-4 ] [ footer-container__item__text ]">
          {`${STRINGS.FOOTER.COPYRIGHT} ${process.env.REACT_APP_VERSION}`}
        </div>
        <div className="[ col-xl-3 col-md-3 col-sm-4 ] [ offset-xl-4 offset-md-4 offset-sm-3 ] [ text-right ] [ footer__chat-text ]">
          {STRINGS.FOOTER.HELP}
        </div>
        <div className="[ col-xl-1 col-md-1 col-sm-1 ] [ p-0 ] [ footer__chat-icon ]" />
      </div>
    </footer>
  );
};

export default Footer;
