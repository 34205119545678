import { TOKEN_KEY } from "Communication/Constants";

export const login = (token) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  let n = sessionStorage.length;
  while (n--) {
    let key = sessionStorage.key(n);
    sessionStorage.removeItem(key);
  }
};

export const isLogin = () => {
  if (sessionStorage.getItem(TOKEN_KEY)) {
    return true;
  }

  return false;
};
