import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withVaultHOC from "Hoc/VaultHOC/VaultHOC";
import { HTTP_STATUS } from "Communication/Constants";
import Signin from "./Signin";
import { doSignin, doReset } from "./Actions/SigninAction";
import STRINGS from "Constants/Strings";
import { getLoanProduct } from "../LoanDetails/Actions/LoanDetailsAction";
import { getFIInfo } from "../MarketPlace/Action/MarketPlaceAction";
import { doGetProvinceList } from "../AboutYou/Actions/AboutYouActions";

class SigninContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      isChecked: false,
    };
  }

  componentDidMount() {
    // const { doResetStore } = this.props;
    // doResetStore();
    sessionStorage.removeItem(
      STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_APPLICATION_ID
    );
    sessionStorage.removeItem(STRINGS.JOIN_APPLICANT.JOINT_APPLICANT_SLOTID);
  }

  componentDidUpdate(prevProps) {
    const { signInState } = this.props;
    const { isChecked, username } = this.state;
    if (prevProps !== this.props) {
      if (
        signInState.success &&
        signInState.success.status === HTTP_STATUS.OK
      ) {
        if (isChecked) {
          localStorage.removeItem("username");
          localStorage.setItem("checkbox", isChecked);
          localStorage.setItem("username", username);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("checkbox");
        }
      }
    }
  }

  handleSignin = (userDetails) => {
    const {
      doSignIn,
      prepareVaultProductList,
      doGetLoanProduct,
      doGetFinInfo,
      doGetProvinceList,
    } = this.props;
    this.setState({
      username: userDetails.credential,
      isChecked: userDetails.isChecked,
    });
    doSignIn(userDetails, (response) => {
      if (response.status === HTTP_STATUS.OK) {
        doGetLoanProduct();
        doGetFinInfo();
        doGetProvinceList();
        prepareVaultProductList(true);
      }
    });
  };

  render() {
    const { history } = this.props;
    return (
      <>
        <Signin handleSignin={this.handleSignin} history={history} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doSignIn: doSignin,
      doResetStore: doReset,
      doGetLoanProduct: getLoanProduct,
      doGetFinInfo: getFIInfo,
      doGetProvinceList: doGetProvinceList,
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  signInState: state.SigninReducer,
});

SigninContainer.propTypes = {
  doSignIn: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]),
  signInState: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  prepareVaultProductList: PropTypes.func.isRequired,
};
SigninContainer.defaultProps = {
  history: {},
};
const SigninWrapper = withVaultHOC(SigninContainer);

export default connect(mapStateToProps, mapDispatchToProps)(SigninWrapper);
