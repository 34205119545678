import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as ROUTES from "Constants/Routes";

import {
  getProductList,
  updateVaultProductList,
  getProductFromVault,
  updateProductToVault,
} from "./Action/MarketPlaceAction";
import MarketPlace from "./MarketPlace";
import STRINGS from "../../Constants/Strings";

class MarketPlaceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { doGetProductList } = this.props;
    doGetProductList(() => {});
  }

  onNavigationHandler = (route) => {
    const { history } = this.props;
    if (STRINGS.MARKETPLACE.PAGE_BANNER.SIGN_IN === route) {
      history.push(ROUTES.SIGN_IN);
    } else if (STRINGS.MARKETPLACE.PAGE_BANNER.REGISTER === route) {
      history.push(ROUTES.REGISTER);
    }
  };

  render() {
    const {
      doUpdateVaultProductList,
      doGetProductFromVault,
      doUpdateProductToVault,
    } = this.props;
    return (
      <MarketPlace
        navigateToSignIn={() =>
          this.onNavigationHandler(STRINGS.MARKETPLACE.PAGE_BANNER.SIGN_IN)
        }
        navigateToRegister={() =>
          this.onNavigationHandler(STRINGS.MARKETPLACE.PAGE_BANNER.REGISTER)
        }
        doUpdateVaultProductList={doUpdateVaultProductList}
        doGetProductFromVault={doGetProductFromVault}
        doUpdateProductToVault={doUpdateProductToVault}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      doGetProductList: getProductList,
      doUpdateVaultProductList: updateVaultProductList,
      doGetProductFromVault: getProductFromVault,
      doUpdateProductToVault: updateProductToVault,
    },
    dispatch
  );
};

MarketPlaceContainer.propTypes = {
  doGetProductList: PropTypes.func,
  doUpdateVaultProductList: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.object]),
  doGetProductFromVault: PropTypes.func,
  doUpdateProductToVault: PropTypes.func,
};

MarketPlaceContainer.defaultProps = {
  doGetProductList: () => {},
  doUpdateVaultProductList: () => {},
  doGetProductFromVault: () => {},
  doUpdateProductToVault: () => {},
  history: {},
};

export default connect(null, mapDispatchToProps)(MarketPlaceContainer);
