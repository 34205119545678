import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "Redux/Actions/actionTypes";
import AddBundleProduct from "Communication/ApiClasses/AddBundleProductRequest";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleAddBundleProduct(action) {
  yield put(doLoadingStart(STRINGS.DEBIT_CARD.API_KEYS.ADD_BUNDLE_PRODUCT));
  const addBundleProduct = new AddBundleProduct(
    action.data.applicationId,
    action.data.bundleProductId,
    action.data.demandProductId
  );
  try {
    const result = yield call(addBundleProduct.getData);
    yield put({
      type: actionTypes.ADD_BUNDLE_PRODUCT_SUCCESS,
      response: result,
    });
    yield call(action.callback, result);
  } catch (e) {
    yield put({
      type: actionTypes.ADD_BUNDLE_PRODUCT_ERROR,
      error: e.message,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.DEBIT_CARD.API_KEYS.ADD_BUNDLE_PRODUCT));
  }
}

// watcher
function* addBundleProductWatcherSaga() {
  yield takeLatest(actionTypes.ADD_BUNDLE_PRODUCT, handleAddBundleProduct);
}

export default addBundleProductWatcherSaga;
