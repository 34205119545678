import BaseApiRequest from "./BaseApiRequest";
import { REGISTER_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class RegistrationRequest extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {
    email: "",
    msisdn: "",
    otpTo: "",
  };

  constructor(userDetail) {
    super();
    this.body.email = userDetail.email;
    this.body.msisdn = userDetail.msisdn;
    this.body.otpTo = userDetail.otpTo;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return REGISTER_URL;
  };

  getBody = () => {
    return this.body;
  };
}
