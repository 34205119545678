import React, { Component } from "react";
import PropTypes from "prop-types";

import STRINGS from "Constants/Strings";

import { PRODUCT_IMAGE_URL, COMMON_BUCKET_URL } from "Communication/ApiUrls";

import withVaultHOC from "Hoc/VaultHOC/VaultHOC";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import ConfirmPopupModal from "Components/Common/ConfirmPopupModal/ConfirmPopupModal";

import AppConstants from "Constants/AppConstants";

class ProductCardWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        showModal: false,
        header: "",
        title: "",
        subtitle: "",
        description: "",
        productAddtoVault: true,
        tag: false,
      },
      confirmShowModal: false,
      confirmModal: {},
      selectCard: {},
    };
  }

  detailsClick = (card) => {
    const { addToVault, isShoppingCartList, isCardAddedToVault } = this.props;

    const modal = {};
    modal.modalType = AppConstants.MODALTYPE.CUSTOM;
    modal.showModal = true;
    modal.header = card.productName;
    modal.title = card.productName;
    modal.subtitle = card.productCategory;
    modal.productDescription = card.productDescription;
    modal.productAddtoVault =
      card.addToVault && (addToVault || isShoppingCartList);
    modal.description = (
      <div dangerouslySetInnerHTML={{ __html: card.productDetails }} />
    );
    modal.btnText = !addToVault
      ? STRINGS.MARKETPLACE.BODY.REMOVE_FROM_VAULT
      : STRINGS.MARKETPLACE.BODY.ADD_TO_VAULT;
    modal.tag = isCardAddedToVault(card);
    this.setState({ modal, selectCard: card });
  };

  handleProductDeleteClick = (card) => {
    const { confirmShowModal } = this.state;
    const prepareModal = {};
    prepareModal.title = STRINGS.VAULT_DASHBOARD.DELETE_TITLE;
    prepareModal.description = STRINGS.VAULT_DASHBOARD.DELETE_DESCRIPTION;
    this.setState({
      confirmShowModal: !confirmShowModal,
      confirmModal: prepareModal,
      selectCard: card,
    });
  };

  toggleModal = () => {
    this.setState({ modal: { showModal: false } });
  };

  /**
   * Handled Yes clicked event of delete product popup
   */
  handleYesClick = () => {
    const { deleteApplication } = this.props;
    const { selectCard } = this.state;
    console.log("edwin his here", selectCard);
    deleteApplication(selectCard);
  };

  /**
   * Handled No clicked event of delete product popup
   */
  handleNoClick = () => {};

  confirmToggleModal = () => {
    const { confirmShowModal } = this.state;
    this.setState({
      confirmShowModal: !confirmShowModal,
      confirmModal: {},
    });
  };

  handleAddToVault = (product) => {
    const { handleAddToVault } = this.props;
    const { selectCard } = this.state;
    handleAddToVault(product || selectCard);
  };

  render() {
    const {
      product,
      addToVault,
      isCardAddedToVault,
      isShoppingCartList,
      handleAddToVault,
      active,
    } = this.props;

    const { modal, confirmShowModal, confirmModal, selectCard } = this.state;
    const {
      modalType,
      showModal,
      title,
      description,
      btnText,
      subtitle,
      productAddtoVault,
      tag,
    } = modal;

    let productStatusContainerClass = "";
    let productStatusTitleClass = "";
    let productStatusText = "";
    if (product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IN_REVIEW) {
      productStatusContainerClass = "[ review-container ]";
      productStatusTitleClass = "[ review-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.IN_REVIEW;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IS_COMPLETED
    ) {
      productStatusContainerClass = "[ completed-container ]";
      productStatusTitleClass = "[ completed-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.COMPLETED;
    } else if (
      product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IS_REJECTED
    ) {
      productStatusContainerClass = "[ rejected-container ]";
      productStatusTitleClass = "[ rejected-title ]";
      productStatusText = STRINGS.VAULT_DASHBOARD.REJECTED;
    }

    // CHRIS: For when we identify IN PROGRESS
    // } else if (
    //   product.status === AppConstants.DASHBOARD.PRODUCT_STATUS.IN_PROGRESS
    // ) {
    //   productStatusContainerClass = "[ progress-container ]";
    //   productStatusTitleClass = "[ progress-title ]";
    //   productStatusText = STRINGS.VAULT_DASHBOARD.IN_PROGRESS;
    // }

    return (
      <>
        {confirmShowModal && (
          <ConfirmPopupModal
            title={confirmModal.title}
            description={confirmModal.description}
            toggleModal={this.confirmToggleModal}
            showModal={confirmShowModal}
            noBtnClick={this.handleNoClick}
            yesBtnClick={this.handleYesClick}
            closeBtnClick={this.handleNoClick}
          />
        )}
        {showModal && (
          <PopupModal
            modalType={modalType}
            title={title}
            subtitle={subtitle}
            description={description}
            popupBtnClick={
              addToVault
                ? this.handleAddToVault
                : () => {
                    this.handleProductDeleteClick(selectCard);
                  }
            }
            addToVault={productAddtoVault}
            showModal={showModal}
            btnText={btnText}
            showPopupModalButtons
            toggleModal={this.toggleModal}
            closeBtnClick={this.toggleModal}
            shortDescription={modal.productDescription}
            tag={tag}
            seeDetailsClick
            product={product}
          />
        )}

        <div key={product.productId} className="[ product ] [ card ]">
          <div className="[ wrapper ]">
            <img
              src={`${PRODUCT_IMAGE_URL}${product.productLogo}`}
              className="[ product-image ]"
              alt="Card"
            />

            {isCardAddedToVault(product) && !isShoppingCartList && (
              <div className="[ added-container ]">
                <span className="[ added-title ]">
                  {STRINGS.MARKETPLACE.BODY.ADDED}
                </span>
              </div>
            )}

            {isShoppingCartList && (
              <span
                role="presentation"
                className="[ fas fa-trash ]"
                onClick={() => {
                  const modal = {};
                  modal.title = STRINGS.VAULT.DELETE_TITLE;
                  modal.description = STRINGS.VAULT.DELETE_DESCRIPTION;
                  this.setState({
                    confirmShowModal: !confirmShowModal,
                    confirmModal: modal,
                    selectCard: product,
                  });
                }}
              />
            )}

            <div className={`${productStatusContainerClass}`}>
              <span className={`${productStatusTitleClass}`}>
                {productStatusText}
              </span>
            </div>

            <div className="[ data ]">
              <div
                className={`[ content ]${
                  active && isShoppingCartList
                    ? " [ card-container-active ]"
                    : " [ content_color ]"
                }`}
              >
                <div className="[ toggle-button ]">
                  <span className="[ sr-only ]">
                    Tap to see product actions
                  </span>
                  {/* CHRIS NOTE: On a mobile device, the user taps the card or this toggle button and the class 'fa-angle-up' is replaced with class 'fa-angle-down' */}
                  <i className="[ fas fa-angle-up ]" />
                </div>
                <div className="[ row ] [ pb-2 ]">
                  <div className="[  col-12 ] [ text-left ]">
                    <span
                      className={`[ card-title ]${
                        active && isShoppingCartList
                          ? " [ card-container-active-text ]"
                          : ""
                      }`}
                    >
                      {product.productName}
                    </span>
                  </div>
                </div>

                <div className="[ row ] [ pt-3 pt-md-2 ]">
                  <div className="[ col-12 ]">
                    <p
                      className={`[ text ]${
                        active && isShoppingCartList
                          ? " [ card-container-active-text ]"
                          : ""
                      }`}
                    >
                      {product.productDescription}
                    </p>
                  </div>
                </div>

                <div className="[ cta-container ]">
                  {product.addToVault && !isShoppingCartList && addToVault ? (
                    <button
                      type="button"
                      className="[ btn ] [ submit-btn ]"
                      onClick={() => handleAddToVault(product)}
                    >
                      {STRINGS.MARKETPLACE.BODY.ADD_TO_VAULT}
                    </button>
                  ) : (
                    !isShoppingCartList &&
                    addToVault && (
                      <div className="[ card-link see-details-link ]">
                        {STRINGS.MARKETPLACE.BODY.SEE_BRANCH_DETAILS}
                      </div>
                    )
                  )}
                  <span
                    className={`[ card-link see-details-link ]${
                      active && isShoppingCartList
                        ? " [ card-container-active-text ]"
                        : " [ see-details-link_color ]"
                    }`}
                    onClick={() => this.detailsClick(product)}
                    role="presentation"
                  >
                    {STRINGS.MARKETPLACE.BODY.SEE_DETAILS}{" "}
                    <span className="[ icon ] [ product-navigation-icon ]" />
                  </span>
                </div>

                <label htmlFor="show-menu" className="[ menu-button ]">
                  <span />
                </label>
              </div>
              <input type="checkbox" id="show-menu" />
              <ul className="[ menu-content ]">
                <li>
                  <div className="[ form-label ]">Status:</div>
                  {/* <a href="#" class="[ fa fa-bookmark-o ]"></a> */}
                </li>
                {/* <li><a href="#" class="[ fa fa-heart-o ]"><span>47</span></a></li>
          <li><a href="#" class="[ fa fa-comment-o ]"><span>8</span></a></li> */}
              </ul>
            </div>
          </div>
        </div>

        {/* Original */}
        {/*      <div key={product.productId} className={`[ product-card__container ] ${
              active && isShoppingCartList
                ? "[ card-container-active ]"
                : ""
            }`}
          >
            <div className="[ product-image__container ]">
              <img
                src={`${PRODUCT_IMAGE_URL}${product.productLogo}`}
                className="[ img-fluid img-responsive ] [ product-image ] "
                alt="Card"
              />
              {isCardAddedToVault(product) && !isShoppingCartList && (
                <span className="[ added_icon ]">
                  {STRINGS.MARKETPLACE.BODY.ADDED}
                </span>
              )}
              {isShoppingCartList && (
                <div
                  className="[ delete-button__icon ]"
                  role="presentation"
                  onClick={() => {
                    this.handleProductDeleteClick(product);
                  }}
                />
              )}
            </div>

            <div className="[ product-description__container] ">
              <h5 className="[ product-title ]">{product.productName}</h5>
              <p className="[ product-description-1 ]">
                {product.productDescription}
              </p>
            </div>

              <div className="[ product-cta__container ]">
                <span
                  className="[ card-link link ]"
                  onClick={() => this.detailsClick(product)}
                  role="presentation"
                >
                  {STRINGS.MARKETPLACE.BODY.SEE_DETAILS}
                  <span class="[ icon ] [ product-navigation-icon ]"></span>
                </span>
                {product.addToVault && !isShoppingCartList && addToVault ? (
                  <button
                    type="button"
                    className="[ btn ] [ submit-btn ]"
                    onClick={() => handleAddToVault(product)}
                  >
                    {STRINGS.MARKETPLACE.BODY.ADD_TO_VAULT}
                  </button>
                ) : (
                  !isShoppingCartList &&
                  addToVault && (
                    <div className="[ card-link link ]">
                      {STRINGS.MARKETPLACE.BODY.SEE_BRANCH_DETAILS}
                    </div>
                  )
                )}
              </div>
            </div> */}
      </>
    );
  }
}

ProductCardWrapper.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]),
  active: PropTypes.bool,
  handleAddToVault: PropTypes.func,
  addToVault: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  isCardAddedToVault: PropTypes.func,
  isShoppingCartList: PropTypes.bool,
  deleteApplication: PropTypes.func.isRequired,
};

ProductCardWrapper.defaultProps = {
  active: false,
  product: {},
  handleAddToVault: () => {},
  isCardAddedToVault: () => {},
  isShoppingCartList: false,
  deleteApplication: () => {},
};

const ProductCard = withVaultHOC(ProductCardWrapper);

export default ProductCard;
