import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";
import AppConstants from "Constants/AppConstants";
import { HTTP_STATUS } from "Communication/Constants";
import OnlineBanking from "./OnlineBanking";
import { getApplication } from "Redux/Actions/ApplicationAction";

import {
  doGetPasswordCriteria,
  enableOnlineBanking,
  setOnlineBanking,
} from "./Actions/OnlineBankingAction";
import STRINGS from "../../Constants/Strings";

class OnlineBankingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      continuehandler,
      doGetPswdCriteria,
      passwdCriteria,
      onlineBankingInfo,
      doGetApplicant,
      applicantData,
      actionBack,
      applicationCompleteList,
      handleBack,
    } = this.props;
    console.log("OnlineBankingContainer");
    if (
      !onlineBankingInfo.applicationId ||
      !onlineBankingInfo.debitCardProductId
    ) {
      if (actionBack) {
        handleBack(AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
      } else {
        continuehandler(null, AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
      }
    } else {
      if (applicationCompleteList.length > 0) {
        if (actionBack) {
          handleBack(AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
        } else {
          continuehandler(null, AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
        }
      } else {
        doGetApplicant(applicantData.id, (res) => {
          console.log(res);
          if (
            res.data.acquireType != "create" ||
            !res.data.addedToBankingAt ||
            res.data.submittedOnlineUserAt
          ) {
            if (actionBack) {
              handleBack(AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
            } else {
              continuehandler(
                null,
                AppConstants.APPLICATIONSTEP.ONLINE_BANKING
              );
            }
          }
        });
      }
    }

    if (!passwdCriteria) {
      doGetPswdCriteria();
    }
  };

  /**
   *
   * @param {string} password represents
   * password value to be set for the current
   * register user.
   */

  handleSetPasswordRequest = (password) => {
    const {
      continuehandler,
      doEnableOnlineBanking,
      applicantData,
      onlineBankingInfo,
      doSetOnlineBanking,
    } = this.props;
    if (password && password.length > 0) {
      doEnableOnlineBanking(
        applicantData.id,
        onlineBankingInfo.applicationId,
        onlineBankingInfo.debitCardProductId,
        password,
        (res) => {
          if (res && res.status === HTTP_STATUS.OK) {
            doSetOnlineBanking(null, null, null);
            continuehandler("", AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
          }
        }
      );
    } else {
      doSetOnlineBanking(null, null, null);
      continuehandler("", AppConstants.APPLICATIONSTEP.ONLINE_BANKING);
    }
  };

  render() {
    const {
      passwdCriteria,
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
    } = this.props;
    return (
      <OnlineBanking
        handleContinue={this.handleContinue}
        getDataFromServer={getDataFromServer}
        handleBack={handleBack}
        steps={steps}
        activeStepID={activeStepID}
        handleSetPasswordRequest={this.handleSetPasswordRequest}
        passwdCriteria={passwdCriteria.passwordCriteria}
      />
    );
  }
}

OnlineBankingContainer.propTypes = {
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  passwdCriteria: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  doGetPswdCriteria: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  doximProducts: PropTypes.arrayOf(PropTypes.object),
  doGetApplicationProperty: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  doEnableOnlineBanking: PropTypes.func.isRequired,
  enableDebitCard: PropTypes.bool,
  isDebitCardAlreadyEnabled: PropTypes.bool,
  applicationId: PropTypes.string,
  applicantData: PropTypes.object,
  onlineBankingInfo: PropTypes.object.isRequired,
  doSetOnlineBanking: PropTypes.func.isRequired,
  doGetApplicant: PropTypes.func.isRequired,
};

OnlineBankingContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
  passwdCriteria: {},
  doximProducts: [],
  products: [],
  enableDebitCard: false,
  isDebitCardAlreadyEnabled: false,
  applicationId: null,
  applicantData: null,
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetPswdCriteria: () => dispatch(doGetPasswordCriteria()),
    doEnableOnlineBanking: (
      applicantId,
      applicationId,
      debitCardProductId,
      password,
      callback
    ) =>
      dispatch(
        enableOnlineBanking(
          applicantId,
          applicationId,
          debitCardProductId,
          password,
          callback
        )
      ),
    doSetOnlineBanking: (
      applicationId,
      debitCardProductId,
      templateId,
      callback
    ) =>
      dispatch(
        setOnlineBanking(
          applicationId,
          debitCardProductId,
          templateId,
          callback
        )
      ),
    doGetApplicant: (id, callback) => dispatch(getApplication(id, callback)),
  };
};
const mapStateToProps = (state) => ({
  updatePasswordStatus: state.UpdatePasswordReducer,
  products: state.VaultReducer.vaultProductList,
  passwdCriteria: state.GetPasswordCriteriaReducer.success,
  doximProducts: state.ApplicationReducer.doximProducts,
  enableDebitCard: state.DebitCardReducer.enableDebitCard,
  isDebitCardAlreadyEnabled: state.DebitCardReducer.isDebitCardEnabled,
  applicationId: state.ApplicationReducer.applicationId,
  applicantData: state.ApplicationReducer.response,
  onlineBankingInfo: state.OnlineBankingReducer,
  actionBack: state.ApplicationReducer.actionBack,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
});

const OnlineBankingWrapper = withApplicantHOC(OnlineBankingContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineBankingWrapper);
// export default OnlineBankingContainer;
