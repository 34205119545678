import FIInformation from "Constants/FIInformation";

export const Title = FIInformation.FI_NAME;
export const SEOConfig = [
  {
    name: "description",
    content: "Description as per FI",
  },
  {
    name: "robots",
    content: "noindex, nofollow",
  },
  {
    name: "viewport",
    content: "width=device-width, initial-scale=1",
  },
];
