import * as actionTypes from "Redux/Actions/actionTypes";

const initialState = {
  success: null,
  error: null,
  idTypes: [],
  provinceList: [],
  formData: null,
};

const AboutYouReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ID_TYPE_SUCCESS:
      return {
        ...state,
        idTypes: action.response.data,
        error: null,
      };
    case actionTypes.GET_ID_TYPE_ERROR:
      return {
        ...state,
        idTypes: [],
        error: action.error,
      };
    case actionTypes.GET_PROVINCE_LIST_SUCCESS:
      return {
        ...state,
        provinceList: action.response.data,
        error: null,
      };
    case actionTypes.GET_PROVINCE_LIST_ERROR:
      return {
        ...state,
        provinceList: [],
        error: action.error,
      };
    case actionTypes.SAVE_CREATE_APP_STATUS:
      return {
        ...state,
        formData: action.formData,
      };
    default:
      return state;
  }
};

export default AboutYouReducer;
