import React from "react";
import PropTypes from "prop-types";

import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";

const PageBanner = (props) => {
  const { handleSignIn, handleRegister, loggedInUser, userName } = props;

  return (
    <>
      {!loggedInUser ? (
        <section className="[ fi-brand__container ] [ jumbotron-fluid ]">
          <div className="[ fi-brand__logo ]" />

          <div className="[ fi-brand__tagline-container ]">
            <h2 className="[ tagline__title-1 ]">
              {AppConstants.MARKETPLACE.HEADER.TITLE1}
            </h2>
            <h3 className="[ tagline__title-2 ]">
              {AppConstants.MARKETPLACE.HEADER.TITLE2}
            </h3>
            <div className="[ row d-xl-none ]">
              <div className="[ mobile-signin__button ]">
                <button
                  type="button"
                  className="[ btn info-btn ] [ w-100 ]"
                  onClick={handleSignIn}
                >
                  {AppConstants.MARKETPLACE.HEADER.SIGNINREGEBTN}
                </button>
              </div>
            </div>
            <p className="[ tagline__description-1 ]">
              {AppConstants.MARKETPLACE.HEADER.TITLE3}
            </p>
            <p className="[ tagline__description-2 ]">
              {AppConstants.MARKETPLACE.HEADER.TITLE4}
            </p>
          </div>

          <div className="[ fi-brand__cta-container ]">
            <h3 className="[ cta__header ]">
              {STRINGS.MARKETPLACE.HEADER.CARD.TITLE}
            </h3>
            <p className="[ cta__body ]">
              {STRINGS.MARKETPLACE.HEADER.CARD.BODY.TITLE}
            </p>

            <button
              type="button"
              className="[ cta__primary-action-call btn submit-btn ]"
              onClick={handleSignIn}
            >
              {STRINGS.MARKETPLACE.HEADER.CARD.BODY.SIGNIN}
            </button>

            <p className="[ cta__body ]">
              {STRINGS.MARKETPLACE.HEADER.CARD.FOOTER.TITLE}
              <span
                className="[ cta__secondary-action-call ] [ link ]"
                role="presentation"
                onClick={handleRegister}
              >
                {STRINGS.MARKETPLACE.HEADER.CARD.FOOTER.REGISTER}
              </span>
            </p>
          </div>
        </section>
      ) : (
        <section className="[ fi-brand__container ] [ jumbotron-fluid ]">
          <div className="[ fi-brand__logo ]" />

          <div className="[ fi-brand__tagline-container ]">
            <h2 className="[ tagline__title-1 ]">{userName}</h2>
            <h3 className="[ tagline__title-2 ]">
              {AppConstants.DASHBOARD.HEADER.TITLE2}
            </h3>
          </div>
        </section>
      )}
    </>
  );
};

PageBanner.propTypes = {
  handleSignIn: PropTypes.func,
  handleRegister: PropTypes.func,
  loggedInUser: PropTypes.bool,
  userName: PropTypes.string,
};

PageBanner.defaultProps = {
  handleSignIn: () => {},
  handleRegister: () => {},
  loggedInUser: false,
  userName: "",
};

export default PageBanner;
