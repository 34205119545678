import React from "react";
import PropTypes from "prop-types";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
// import helper methods
import {
  getDescriptionFromArray,
  getGettingStarted,
} from "Utils/CommonUtilities";
import { HTTP_STATUS, LOANPRODUCT } from "Communication/Constants";
import AuthBody from "../Common/AuthBody/AuthBody";
import Stepper from "../Common/Stepper/Stepper";
import * as ROUTES from "Constants/Routes";
import NumberFormat from "react-number-format";
import PopupModal from "Components/Common/PopupModal/PopupModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { paymentProcess } from "../FundingAmount/Action/FundAction";
import { add, format, parse } from "date-fns";
import { capitalize } from "../../Utils/CommonUtilities";

class LoanSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      isViewLoanDetails: false,
      isAcceptedTerms: false,
      termsConditionError: "",
      assetList: [],
      incomeList: [],
      expenseList: [],
      liabilitiesList: [],
      aboutYouData: {},
      gettingStartedData: {
        accountStatementType: "",
        branch: "",
      },
      loanDetails: {
        hasAdditionalSourceIncome: false,
        loanAmount: 0,
        loanRate: 0,
        loanScheduleDate: new Date(),
        loanTerm: 0,
        monthlyPayment: "",
        paymentType: "",
        purposeId: "",
      },
      errors: {
        paymentTypeErrorList: [],
      },
      paymentTypes: [],
      paymentDate: new Date(),
      startDate: new Date(),
      endDate: null,
    };
  }

  componentDidMount() {
    console.log("test");
    const { getDataFromServer, activeProduct } = this.props;
    // getDataFromServer(AppConstants.APPLICATIONSTEP.ABOUTYOU, (response) => {
    //   if (response.status === HTTP_STATUS.OK) {
    //     console.log(response);
    //     this.setAboutData(response.data);
    //   }
    // });
    getDataFromServer(AppConstants.APPLICATIONSTEP.LOAN_SUMMARY, (response) => {
      console.log(response);
      if (response.status === HTTP_STATUS.OK) {
        this.setAboutYouData(response.data);
        this.saveGettingStartedData(response.data);
      }
      this.setFormData(response);
    });
  }

  setFormData = () => {
    const { loanProducts, activeProduct, loanProductForm } = this.props;
    let paymentTypes = [];
    let purposes = [];
    let loanConfig = {};

    const productId = activeProduct.productId;
    if (loanProducts) {
      loanProducts.forEach((raw, idx) => {
        if (raw.id === productId) {
          purposes = raw.purpose;
          paymentTypes = raw.paymentTypes;
          loanConfig = {
            loanAmount: raw.loanAmount,
            loanTerm: raw.loanTerm,
            loanRate: raw.loanRate,
          };
        }
      });
    }
    console.log("paymentTypes", paymentTypes);
    let loanDetails = activeProduct.loanDetails
      ? activeProduct.loanDetails
      : {};

    let paymentDate = loanDetails.loanScheduleDate
      ? parse(loanDetails.loanScheduleDate, "yyyy-MM-dd", new Date())
      : this.state.loanScheduleDate;

    const paymentType = loanDetails.paymentType
      ? loanDetails.paymentType
      : this.state.paymentType;

    this.setState({
      loanPurposes: purposes,
      paymentTypes: paymentTypes,
      loanConfig: loanConfig,
      loanAmount: loanDetails.loanAmount
        ? loanDetails.loanAmount
        : this.state.loanAmount,
      loanTerm: loanDetails.loanTerm
        ? loanDetails.loanTerm
        : this.state.loanTerm,
      loanRate: loanDetails.loanRate
        ? loanDetails.loanRate
        : this.state.loanRate,
      monthlyPayment: loanDetails.monthlyPayment
        ? loanDetails.monthlyPayment
        : this.state.monthlyPayment,
      paymentDate,
      paymentType,
      endDate: this.getEndDate(paymentType),
    });
  };

  getEndDate(paymentType) {
    console.log("getEndDate", paymentType);
    let endDate = new Date();
    if (paymentType === "weekly") {
      endDate = add(endDate, { days: 7 });
    } else if (paymentType === "biweekly") {
      endDate = add(endDate, { days: 14 });
    } else {
      endDate = add(endDate, { days: 30 });
    }
    console.log("endDate", endDate);
    return endDate;
  }

  commaFormatted(amount) {
    if (amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }

  componentDidUpdate(prevProps) {
    console.log("component did update");
    console.log(prevProps);
    const {
      liabilitiesList,
      assetList,
      expenseList,
      incomeList,
      loanDetails,
      choice,
      gettingStartedBranchState,
      gettingStartedResidentState,
    } = this.props;

    console.log(gettingStartedBranchState);
    if (prevProps.incomeList !== incomeList) {
      this.updateIncomeListData(incomeList, choice);
      // this.getAssetList(prevProps.assets);
    }
    if (prevProps.liabilitiesList !== liabilitiesList) {
      this.updateLiabilitiesData(liabilitiesList, choice);
      // this.getAssetList(prevProps.assets);
    }
    if (prevProps.assetList !== assetList) {
      this.updateAssetListData(assetList, choice);
      // this.getAssetList(prevProps.assets);
    }
    if (prevProps.expenseList !== expenseList) {
      this.updateExpenseListData(expenseList, choice);
      // this.getAssetList(prevProps.assets);
    }
    if (prevProps.loanDetails !== loanDetails) {
      this.updateLoanDetailsData(loanDetails);
    }
  }

  saveGettingStartedData = (response) => {
    getGettingStarted(response, (gettingStartedData) => {
      console.log(gettingStartedData);
      this.setState({ gettingStartedData });
    });
  };

  setAboutYouData(response) {
    const { member, address } = response;
    console.log(response);
    this.setState({
      aboutYouData: {
        firstName: member.name,
        middleName: member.middleName,
        lastName: member.surname,
        dateOfBirth: member.dob,
        address: address.currentAddressLine1,
        addressLine2: address.currentAddressLine2,
        city: address.currentAddressCity,
        province: address.currentAddressProvince,
        postalCode: address.currentAddressPostalCode,
        mailingAddressLine1: address.mailingAddressLine1,
        mailingAddressLine2: address.mailingAddressLine2,
        mailingCity: address.mailingCity,
        mailingProvince: address.mailingProvince,
        mailingPostalCode: address.mailingPostalCode,
        mailingCountry: address.mailingCountry,
        mailingSameAsCivic: address.mailingSameAsCivic,
        editMode: !(member.name && member.surname && member.dob),
      },
    });
  }

  updateLoanDetailsData(loanDetails) {
    this.setState({
      loanDetails: loanDetails,
    });
  }

  updateIncomeListData(dataList, choice) {
    console.log("update Income List Data", dataList, choice);

    const newDataList = [];
    dataList.forEach((data) => {
      newDataList.push({
        subType: data.subType,
        description: data.description,
        monthly: data.monthly,
      });
    });
    this.setState({
      incomeList: newDataList,
    });
  }

  updateAssetListData(dataList, choice) {
    console.log("update Asset List Data", dataList, choice);

    const newDataList = [];
    dataList.forEach((data) => {
      newDataList.push({
        subType: data.subType,
        description: data.description,
        value: data.value,
        institution: data.institution,
        primaryResidence: data.primaryResidence,
      });
    });
    this.setState({
      assetList: newDataList,
    });
  }

  updateExpenseListData(dataList, choice) {
    console.log("update Expense List Data", dataList, choice);

    const newDataList = [];
    dataList.forEach((data) => {
      newDataList.push({
        subType: data.subType,
        description: data.description,
        monthly: data.monthly,
        primaryResidence: data.primaryResidence,
      });
    });
    this.setState({
      expenseList: newDataList,
    });
  }

  updateLiabilitiesData(liabilitiesList, choice) {
    console.log("updateFormData", liabilitiesList, choice);

    const newLiabilitiesList = [];
    liabilitiesList.forEach((liability) => {
      newLiabilitiesList.push({
        subType: liability.subType,
        description: liability.description,
        value: liability.value,
        institution: liability.institution,
        primaryResidence: liability.primaryResidence,
        financialId: liability.financialId,
        secType: liability.secType,
        adjustment: liability.adjustment,
        locLimit: liability.locLimit,
        intRate: liability.intRate,
        paymentType: liability.paymentType,
        monthly: liability.monthly,
      });
    });
    this.setState({
      liabilitiesList: newLiabilitiesList,
    });
  }

  handleViewAll = (value) => {
    // const { handleViewAll } = this.props;
    // handleViewAll(value);

    this.setState({ isViewLoanDetails: value });
  };

  handleChecked = () => {
    const { isAcceptedTerms } = this.state;
    this.setState({
      isAcceptedTerms: !isAcceptedTerms,
      termsConditionError: "",
    });
  };

  setPaymentTypeValue = (value) => {
    console.log("setPaymentTypeValue", value);
    const { paymentDate, endDate, startDate } = this.state;
    const newEndDate = this.getEndDate(value);
    console.log("newEndDate", newEndDate);
    this.setState({
      paymentType: value,
      endDate: newEndDate,
    });
    if (paymentDate.getTime() > newEndDate.getTime()) {
      this.setState({
        paymentDate: newEndDate,
      });
    }
  };

  setPaymentDateValue = (value) => {
    console.log("setPaymentDateValue", value);
    this.setState({ paymentDate: value });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;
    handleContinue({});
  };

  handleBack = () => {
    const { handleBack } = this.props;
    handleBack(AppConstants.APPLICATIONSTEP.LOAN_SUMMARY);
  };

  handleChange = (prop) => (event) => {
    console.log("handleChange", prop);
    this.setState({ prop: event.target.value });
    this.doCalculation();
  };

  doUpdatePaymentInfo() {
    const { updatePaymentInfo } = this.props;
    const { paymentType, paymentDate } = this.state;
    updatePaymentInfo({
      paymentType,
      paymentDate,
    });
  }

  doCalculation() {
    const {
      loanAmount,
      loanTerm,
      loanConfig,
      loanScheduleDate,
      paymentType,
    } = this.state;
    console.log("doCalculation", loanAmount, paymentType);
    const rates = loanConfig.loanRate;
    let loanRate = 5.0;

    rates.forEach((raw, idx) => {
      const term = parseInt(loanTerm);
      const minTerm = parseInt(raw.min);
      const maxTerm = parseInt(raw.max);
      if (term >= minTerm && term <= maxTerm) {
        loanRate = raw.rate;
      }
    });

    const newRate = loanRate / 100 / 12;
    const numberOfPayments = this.getNumberOfPayments(loanTerm, paymentType);
    console.log("newRate", newRate);
    console.log("numberOfPayments", numberOfPayments);
    const payment =
      (newRate * loanAmount * Math.pow(1 + newRate, numberOfPayments)) /
      (Math.pow(1 + newRate, numberOfPayments) - 1);

    this.setState({
      loanRate: loanRate,
      monthlyPayment: payment,
      loanScheduleDate: loanScheduleDate,
    });
  }

  capitalize = (s) => {
    if (typeof s !== "string") return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const {
      choice,
      liabilitiesList,
      assetList,
      incomeList,
      expenseList,
      aboutYouData,
      gettingStartedData,
      isAcceptedTerms,
      termsConditionError,
      isViewLoanDetails,
      paymentType,
      loanScheduleDate,
      paymentTypes,
      loanTerm,
      loanRate,
      monthlyPayment,
      startDate,
      endDate,
      paymentDate,
    } = this.state;
    const {
      steps,
      activeStepID,
      provinceList,
      handleEditPage,
      activeProduct,
      showModal,
      type,
      modal,
      toggleModal,
      handlePopupModalBtnClick,
      loanDetails,
    } = this.props;

    const loanPaymentTypeOptions =
      paymentTypes &&
      paymentTypes.length !== 0 &&
      paymentTypes.map((paymentType) => (
        <option
          value={paymentType.paymentTypeCodes}
          className="[ dropdown__option ]"
        >
          {paymentType.description}
        </option>
      ));

    const personaLoanDetailsComponent = (
      <div className="[ details-section ] [ mb-3 ]">
        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.BREAKDOWN}
              </h3>
            </div>
            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {
                    STRINGS.LOAN_DETAILS.FORM
                      .PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT
                  }
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.loanAmount)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {
                    STRINGS.LOAN_DETAILS.FORM
                      .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                  }
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {loanDetails.loanTerm} {STRINGS.LOAN_SUMMARY.MONTHLY_LABEL}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_SUMMARY.INTEREST_RATE}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {parseFloat(loanDetails.loanRate).toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.COST_TO_BORROW}
              </h3>
            </div>
            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_SUMMARY.INT_PAID_OT}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.totalInterestPaid)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_SUMMARY.LOAN_FEES}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.totalFinancedFees)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_SUMMARY.TOTAL_COST_BORROW}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.totalCob)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.PAYMENTS}
              </h3>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {capitalize(loanDetails.paymentType)}{" "}
                  {STRINGS.LOAN_SUMMARY.PAYMENT}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.monthlyPayment)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.REPAYMENT_FREQUENCY_LABEL}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {this.capitalize(loanDetails.paymentType)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const locDetailsComponent = (
      <div className="[ details-section ] [ mb-3 ]">
        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.BREAKDOWN}
              </h3>
            </div>
            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.LOC.MAXIMUM_CREDIT_AMOUNT}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.loanAmount)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {
                    STRINGS.LOAN_DETAILS.FORM
                      .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                  }
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {STRINGS.LOAN_SUMMARY.LOC.LOAN_TERM}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.LOC.CURRENT_INTEREST_RATE}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {parseFloat(loanRate).toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.PAYMENTS}
              </h3>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.LOC.EXPECTED_PAYMENT_SCHEDULE}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {STRINGS.LOAN_SUMMARY.LOC.REPAYMENT_FREQUENCY}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.LOC.LABEL_MONTHLY_PAYMENT}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {STRINGS.LOAN_DETAILS.FORM.LOC.MONTHLY_PAYMENT}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const helocDetailsComponent = (
      <div className="[ details-section ] [ mb-3 ]">
        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.BREAKDOWN}
              </h3>
            </div>
            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.REQUESTED_CREDIT_AMOUNT}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.loanAmount)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.LOAN_TERM}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.LOAN_TERM_REVOLVING}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.CURRENT_INTEREST_RATE}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {parseFloat(loanDetails.loanRate).toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.PAYMENTS}
              </h3>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.EXPECTED_PAYMENT_SCHEDULE}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.LOAN_TERM_REVOLVING}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.LABEL_MONTHLY_PAYMENT}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {STRINGS.LOAN_DETAILS.FORM.HELOC.MONTHLY_PAYMENT}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const mortgageLoanDetailsComponent = (
      <div className="[ details-section ] [ mb-3 ]">
        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.BREAKDOWN}
              </h3>
            </div>
            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {
                    STRINGS.LOAN_DETAILS.FORM
                      .PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT
                  }
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.estimatedMortgage)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {
                    STRINGS.LOAN_DETAILS.FORM
                      .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                  }
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {loanDetails.loanTerm} years
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.AMORTIZATION_LABEL}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {loanDetails.amortization} years
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_SUMMARY.INTEREST_RATE}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {parseFloat(loanDetails.loanRate).toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.COST_TO_BORROW}
              </h3>
            </div>
            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_SUMMARY.INT_PAID_OT}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.totalInterestPaid)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_SUMMARY.LOAN_FEES}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.totalFinancedFees)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_SUMMARY.TOTAL_COST_BORROW}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.totalCob)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="[ details-section ] [ mb-3 ]">
          <div className="[ row ] [ details-section__header">
            <div className="[ col-12 ]">
              <h3 className="[ d-flex justify-content-between align-items-center ]">
                {STRINGS.LOAN_SUMMARY.PAYMENTS}
              </h3>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {capitalize(loanDetails.paymentType)}{" "}
                  {STRINGS.LOAN_SUMMARY.PAYMENT}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {AppConstants.PREFIX.DOLLAR}
                  {this.commaFormatted(loanDetails.monthlyPayment)}
                </div>
              </div>
            </div>

            <div className="[ row ] [ details-section__item ]">
              <div className="[ col-6 ]">
                <div className="[ details-section__item-label ]">
                  {STRINGS.LOAN_DETAILS.FORM.REPAYMENT_FREQUENCY_LABEL}
                </div>
              </div>
              <div className="[ col-6 ]">
                <div className="[ details-section__item-description ]">
                  {this.capitalize(loanDetails.paymentType)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const liabilitiesComponent = liabilitiesList.map((liabilities, idx) => (
      <div className="[ details-section ] [ mb-3 ]">
        <div className="[ row ] [ details-section__header">
          <div className="[ form-subtitle ]">Liability #{idx + 1}</div>
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.LIABILITIES.FORM.LIABILITY_TYPE}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {liabilities && liabilities.subType ? liabilities.subType : ""}
              </div>
            </div>
          </div>
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.LIABILITIES.FORM.DESCRIPTION}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {liabilities && liabilities.description
                  ? liabilities.description
                  : ""}
              </div>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.LIABILITIES.FORM.VALUE}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {AppConstants.PREFIX.DOLLAR}
                {liabilities && liabilities.value
                  ? this.commaFormatted(liabilities.value)
                  : ""}
              </div>
            </div>
          </div>

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.LIABILITIES.FORM.ORGANIZATION_TITLE}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {liabilities && liabilities.institution*/}
          {/*        ? liabilities.institution*/}
          {/*        : ""}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.LIABILITIES.FORM.ADJUSTMENT}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {liabilities && liabilities.adjustment*/}
          {/*        ? liabilities.adjustment*/}
          {/*        : ""}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.LIABILITIES.FORM.SECURED_LABEL}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {liabilities && liabilities.secType ? liabilities.secType : ""}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.LIABILITIES.FORM.LIMIT}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {AppConstants.PREFIX.DOLLAR}
                {liabilities && liabilities.locLimit
                  ? this.commaFormatted(liabilities.locLimit)
                  : ""}
              </div>
            </div>
          </div>

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.LIABILITIES.FORM.RATE}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {liabilities && liabilities.intRate ? liabilities.intRate : ""}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.LIABILITIES.FORM.PAYMENT_TYPE}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {AppConstants.PREFIX.DOLLAR}*/}
          {/*      {liabilities && liabilities.paymentType*/}
          {/*        ? liabilities.paymentType*/}
          {/*        : ""}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {AppConstants.PREFIX.DOLLAR}
                {liabilities && liabilities.monthly
                  ? this.commaFormatted(liabilities.monthly)
                  : ""}
              </div>
            </div>
          </div>

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.LIABILITIES.FORM.PRIMARY_RESIDENCE_TITLE}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {liabilities && liabilities.primaryResidence ? "Yes" : "No"}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    ));
    const assetListComponent = assetList.map((asset, idx) => (
      <div className="[ details-section ] [ mb-3 ]">
        <div className="[ row ] [ details-section__header">
          <div className="[ form-subtitle ]">
            {STRINGS.ASSET.SUBTITLE} #{idx + 1}
          </div>
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.ASSET.FORM.ASSET_TYPE}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {asset && asset.subType ? asset.subType : ""}
              </div>
            </div>
          </div>
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.ASSET.FORM.DESCRIPTION}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {asset && asset.description ? asset.description : ""}
              </div>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.ASSET.FORM.VALUE}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {AppConstants.PREFIX.DOLLAR}
                {asset && asset.value ? this.commaFormatted(asset.value) : ""}
              </div>
            </div>
          </div>

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.ASSET.FORM.ORGANIZATION_TITLE}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {asset && asset.institution ? asset.institution : ""}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.ASSET.FORM.PRIMARY_RESIDENCE_TITLE}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {asset && asset.primaryResidence ? "Yes" : "No"}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    ));

    const incomeListComponent = incomeList.map((income, idx) => (
      <div className="[ details-section ] [ mb-3 ]" key={idx}>
        <div className="[ row ] [ details-section__header">
          <div className="[ form-subtitle ]">
            {STRINGS.INCOME.SUBTITLE} #{idx + 1}
          </div>
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.INCOME.FORM.INCOME_TYPE}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {income && income.subType ? income.subType : ""}
              </div>
            </div>
          </div>
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.INCOME.FORM.DESCRIPTION}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {income && income.description ? income.description : ""}
              </div>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.INCOME.FORM.MONTHLY_AMOUNT}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {AppConstants.PREFIX.DOLLAR}
                {income && income.monthly
                  ? this.commaFormatted(income.monthly)
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

    const expenseListComponent = expenseList.map((expense, idx) => (
      <div className="[ details-section ] [ mb-3 ]">
        <div className="[ row ] [ details-section__header">
          <div className="[ form-subtitle ]">
            {STRINGS.EXPENSE.SUBTITLE} #{idx + 1}
          </div>
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.EXPENSE.FORM.EXPENSE_TYPE}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {expense && expense.subType ? expense.subType : ""}
              </div>
            </div>
          </div>
          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.EXPENSE.FORM.DESCRIPTION}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {expense && expense.description ? expense.description : ""}
              </div>
            </div>
          </div>

          <div className="[ row ] [ details-section__item ]">
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-label ]">
                {STRINGS.EXPENSE.FORM.MONTHLY_AMOUNT}
              </div>
            </div>
            <div className="[ col-sm-6 ]">
              <div className="[ details-section__item-description ]">
                {AppConstants.PREFIX.DOLLAR}
                {expense && expense.monthly
                  ? this.commaFormatted(expense.monthly)
                  : ""}
              </div>
            </div>
          </div>

          {/*<div className="[ row ] [ details-section__item ]">*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-label ]">*/}
          {/*      {STRINGS.EXPENSE.FORM.PRIMARY_RESIDENCE_TITLE}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="[ col-sm-6 ]">*/}
          {/*    <div className="[ details-section__item-description ]">*/}
          {/*      {expense && expense.primaryResidence ? "Yes" : "No"}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    ));
    const finAnalysisComponent = (
      <div>
        {incomeListComponent.length > 0 && (
          <div className="[ details-section ] [ mb-3 ]">
            <div className="[ row ] [ details-section__header">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.INCOME.TITLE}
                </h3>
              </div>
            </div>
            {incomeListComponent}
          </div>
        )}

        {assetListComponent.length > 0 && (
          <div className="[ details-section ] [ mb-3 ]">
            <div className="[ row ] [ details-section__header">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.ASSET.TITLE}
                </h3>
              </div>
            </div>
            {assetListComponent}
          </div>
        )}

        {expenseListComponent.length > 0 && (
          <div className="[ details-section ] [ mb-3 ]">
            <div className="[ row ] [ details-section__header">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.EXPENSE.TITLE}
                </h3>
              </div>
            </div>
            {expenseListComponent}
          </div>
        )}

        {liabilitiesComponent.length > 0 && (
          <div className="[ details-section ] [ mb-3 ]">
            <div className="[ row ] [ details-section__header">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.LIABILITIES.TITLE}
                </h3>
              </div>
            </div>
            {liabilitiesComponent}
          </div>
        )}
      </div>
    );
    const termsAndConditionComponent = (
      <div className="[ mb-3 ] [ alert alert-warning ]" role="alert">
        <div className="[ row ] [ mb-3 ]">
          <div className="[ col-12 ]">
            <i className="[ fas fa-exclamation-circle ]" />
            <span>
              {STRINGS.CONFIRM_CREATION.ACCOUNT_DISCLAIMER}
              <ul>
                <li>{STRINGS.CONFIRM_CREATION.BUREAU_CHECK}</li>
              </ul>
              {STRINGS.CONFIRM_CREATION.ACCOUNT_TERMS_CHECK}
            </span>
          </div>
        </div>
        <div className="[ row ] [ align-items-center ]">
          <div className="[ col-12 ] [ d-flex align-items-center ]">
            <div className=" [ checkbox custom-control custom-checkbox ]">
              <input
                type="checkbox"
                defaultChecked={isAcceptedTerms}
                onChange={this.handleChecked}
                name="termscondition"
                id="termsCheckbox"
                className={
                  termsConditionError && termsConditionError.length > 0
                    ? "[ invalid-input ] [ custom-control-input ]"
                    : "[ custom-control-input ]"
                }
              />
              <label
                htmlFor="termsCheckbox"
                className="[ custom-control-label ]"
              >
                <span className="[ form-checkbox__text ]">
                  {STRINGS.CONFIRM_CREATION.ACCOUNT_DISCLAIMER_LABEL}
                </span>
              </label>
            </div>
          </div>

          <div className="[ error-msg ] [ col-12 ]">
            {termsConditionError || ""}
          </div>
        </div>
      </div>
    );
    const actionComponent = (
      <div className="[ loan-details-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.LOAN_SUMMARY.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ alert alert-success ]" role="alert">
            <div className="[ form-subtitle ]">
              {STRINGS.LOAN_SUMMARY.SUBTITLE_SUCCESS1}
            </div>
            <div className="[ form-description ]">
              {STRINGS.LOAN_SUMMARY.SUBTITLE_SUCCESS2}
            </div>
          </div>
          {activeProduct.category === AppConstants.LOAN_CATEGORY.LOC && (
            <>
              <div class="[ divider ] [ mb-3 ]"></div>
              <div className="[ form-description ]">
                {STRINGS.LOAN_SUMMARY.LOC.LABEL_SUMMARY}
              </div>
            </>
          )}
          {(activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
            activeProduct.category === AppConstants.LOAN_CATEGORY.AUTO) && (
            <>
              <div class="[ divider ] [ mb-3 ]"></div>
              <div className="[ form-description ]">
                {STRINGS.LOAN_SUMMARY.FORM.DESCRIPTION_REPAYMENT}
              </div>
              <div className="[ mb-3 ]">
                <div className="[ row ]">
                  {/* REPAYMENT SCHEDULE*/}
                  <div className="[ col-sm-5 ]">
                    <div className="[ form-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_REPAYMENT_SCHEDULE
                      }
                    </div>
                    <select
                      name="paymentType"
                      value={paymentType}
                      className="[ form-control ] [ select__dropdown ]"
                      onChange={(e) => this.setPaymentTypeValue(e.target.value)}
                    >
                      <option
                        value=""
                        className="[ default ] [ dropdown__option ]"
                      >
                        {STRINGS.LOAN_SUMMARY.FORM.SCHEDULE_OPTION_LABEL}
                      </option>
                      {loanPaymentTypeOptions}
                    </select>
                    <div className="[ error-msg ]"></div>
                  </div>
                  {/* REPAYMENT DATE*/}
                  <div className="[ col-sm-5 ]">
                    <div className="[ form-label ]">
                      {STRINGS.LOAN_SUMMARY.FORM.FIRST_DATE_LABEL}
                    </div>
                    <DatePicker
                      className="[ datepicker ]"
                      dateFormat="yyyy-MM-dd"
                      selected={paymentDate}
                      onChange={(date) => this.setPaymentDateValue(date)}
                      minDate={startDate}
                      maxDate={endDate}
                    />
                    <div className="[ error-msg ]"></div>
                  </div>
                  <div class="[ col-sm-2 ] [ align-items-center ]">
                    <div class="[ form-label ] [ d-none ] [ d-sm-block ]">
                      &nbsp;
                    </div>
                    <button
                      type="button"
                      class="[ btn ] [ info-btn ] [ refresh ]"
                      onClick={() => this.doUpdatePaymentInfo()}
                    >
                      <span class="[ fas fa-sync-alt ] [ d-none ] [ d-sm-block ]" />
                      <span class="[ d-block ] [ d-sm-none ] [ fas fa-sync-alt ]">
                        {STRINGS.LOAN_SUMMARY.FORM.REFRESH_BTN_LABEL}
                      </span>
                      <span class="[ sr-only ]">
                        {STRINGS.LOAN_SUMMARY.FORM.REFRESH_BTN_LABEL}
                      </span>
                    </button>
                  </div>
                </div>
                <div class="[ divider ] [ d-xl-none ]"></div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-label ]">
                      Expected {paymentType} payment:
                    </div>
                  </div>
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      {AppConstants.PREFIX.DOLLAR}
                      {this.commaFormatted(loanDetails.monthlyPayment)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeProduct.templateId === 7 && (
            <>
              <div className="[ form-subtitle ]">
                You have been pre-qualified for the amount below!
              </div>
              <div className="[ form-description ]">
                Your loan summary is outlined below. Hit continue to finish your
                loan pre-qualification.
              </div>
            </>
          )}

          {(activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
            activeProduct.category === AppConstants.LOAN_CATEGORY.AUTO) &&
            personaLoanDetailsComponent}
          {activeProduct.category === AppConstants.LOAN_CATEGORY.LOC &&
            locDetailsComponent}
          {activeProduct.category === AppConstants.LOAN_CATEGORY.HELOC &&
            helocDetailsComponent}
          {activeProduct.category === AppConstants.LOAN_CATEGORY.MORTGAGE &&
            mortgageLoanDetailsComponent}

          {/* CHRIS: HIDDEN FOR NOW... too much extraneous info and when the user was signing back in from a previous session, info was not being relayed. */}
          {/* <div className="[ details-section ] [ mb-3 ]">
            <div className="[ row ] [ details-section__header">
              <div className="[ col-12 ]">
                <h3
                  id="viewAll"
                  className="[ d-flex justify-content-between align-items-center ]"
                >
                  {STRINGS.LOAN_SUMMARY.TITLE}
                  <a
                    href="javascript:;"
                    onClick={(event) => this.handleViewAll(!isViewLoanDetails)}
                    aria-label="view"
                  >
                    <span className="[ arrow-down ]" />
                    <span className="[ edit-btn__label ]">
                      {STRINGS.LOAN_SUMMARY.VIEW_ALL}
                    </span>
                  </a>
                </h3>
              </div>
            </div>

            {isViewLoanDetails ? (
              <div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT
                      }
                    </div>
                  </div>
                  {activeProduct.templateId === 6 && (
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-description ]">
                        {AppConstants.PREFIX.DOLLAR}
                        {this.commaFormatted(loanDetails.loanAmount)}
                      </div>
                    </div>
                  )}
                  {activeProduct.templateId === 7 && (
                    <div className="[ col-6 ]">
                      {activeProduct.category ===
                        AppConstants.LOAN_CATEGORY.HELOC && (
                        <div className="[ details-section__item-description ]">
                          {AppConstants.PREFIX.DOLLAR}
                          {this.commaFormatted(loanDetails.loanAmount)}
                        </div>
                      )}
                      {activeProduct.category !==
                        AppConstants.LOAN_CATEGORY.HELOC && (
                        <div className="[ details-section__item-description ]">
                          {AppConstants.PREFIX.DOLLAR}
                          {this.commaFormatted(loanDetails.estimatedMortgage)}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                      }
                    </div>
                  </div>
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      {activeProduct.category ===
                        AppConstants.LOAN_CATEGORY.LOC && (
                        <>{STRINGS.LOAN_DETAILS.FORM.LOC.LOAN_TERM}</>
                      )}
                      {activeProduct.category ===
                        AppConstants.LOAN_CATEGORY.HELOC && (
                        <>{STRINGS.LOAN_DETAILS.FORM.HELOC.LOAN_TERM}</>
                      )}
                      {activeProduct.category !==
                        AppConstants.LOAN_CATEGORY.LOC &&
                        activeProduct.category !==
                          AppConstants.LOAN_CATEGORY.HELOC && (
                          <>
                            {loanDetails.loanTerm}{" "}
                            {STRINGS.LOAN_SUMMARY.MONTHLY_LABEL}
                          </>
                        )}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.LOAN_SUMMARY.INTEREST_RATE}
                    </div>
                  </div>
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      {parseFloat(loanDetails.loanRate).toFixed(2)}%
                    </div>
                  </div>
                </div>

                {activeProduct.category !==
                  AppConstants.LOAN_CATEGORY.HELOC && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-label ]">
                        {capitalize(loanDetails.paymentType)}{" "}
                        {STRINGS.LOAN_SUMMARY.PAYMENT}
                      </div>
                    </div>
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-description ]">
                        {AppConstants.PREFIX.DOLLAR}
                        {this.commaFormatted(loanDetails.monthlyPayment)}
                      </div>
                    </div>
                  </div>
                )}

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.LOAN_DETAILS.FORM.REPAYMENT_FREQUENCY_LABEL}
                    </div>
                  </div>
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      {this.capitalize(loanDetails.paymentType)}
                    </div>
                  </div>
                </div>
                {finAnalysisComponent}
              </div>
            ) : (
              ""
            )}
          </div> */}
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ btn  submit-btn ] [ w-100 ]"
                onClick={this.handleContinue}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={actionComponent}
          memberCreation
          handleBack={this.handleBack}
        />
      </>
    );
  }
}

LoanSummary.propTypes = {
  getDataFromServer: PropTypes.func,
  handleContinue: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleEditPage: PropTypes.func,
  provinceList: PropTypes.arrayOf(PropTypes.any),
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  updatePaymentInfo: PropTypes.func,
};

LoanSummary.defaultProps = {
  getDataFromServer: () => {},
  handleContinue: () => {},
  handleEditPage: () => {},
  provinceList: [],
  steps: [],
  activeStepID: -1,
};

export default LoanSummary;
