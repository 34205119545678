import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getAccount from "Redux/Actions/GetAccountAction";

import * as ROUTES from "Constants/Routes";
import AppConstants from "Constants/AppConstants";
import STRINGS from "Constants/Strings";
import MODALMESSAGES from "Constants/Messages";

import PopupModal from "Components/Common/PopupModal/PopupModal";
import { HTTP_STATUS, APPLICANTID } from "Communication/Constants";

import { callCreateInverite } from "Components/Inverite/Actions/InveriteAction";
import { getProductFromVault } from "Components/MarketPlace/Action/MarketPlaceAction";

import Inverite from "Components/Inverite/Inverite";
import ExistingMember from "./ExistingMember";

class ExistingMemberContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choice: "",
      showModal: false,
      iframeModal: false,
      iframeUrl: "",
      modalType: "",
      modal: {
        title: "",
        description: "",
      },
    };
  }

  componentDidMount() {
    const { doGetProductFromVault, products } = this.props;

    if (products.length <= 0) {
      doGetProductFromVault((response) => {
        if (
          !response ||
          response.data?.length <= 0 ||
          sessionStorage.getItem(APPLICANTID)
        ) {
          const { history } = this.props;
          history.push(ROUTES.VAULT_DASHBOARD);
        }
      });
    }
  }

  showModal = () => {
    this.setState({
      showModal: true,
      modalType: AppConstants.MODALTYPE.INFORMATION,
      modal: {
        title: MODALMESSAGES.EXISTINGMEMBER.SUCCESS_TITLE,
        description: MODALMESSAGES.EXISTINGMEMBER.SUCCESS_MSG,
      },
    });
  };

  handleContinue = (choice) => {
    if (!choice) {
      const {
        history,
        doGetAcoountDetails,
        inSessionJointApplicant,
      } = this.props;
      if (inSessionJointApplicant) {
        history.push(ROUTES.MEMBER_CREATION);
      } else {
        doGetAcoountDetails((accountRes) => {
          if (accountRes.data.consent === null) {
            history.push(ROUTES.MEMBER_CREATION);
          } else {
            history.push(ROUTES.GETTING_STARTED);
          }
        });
      }
    } else {
      const { doCreateInverite } = this.props;
      doCreateInverite((response) => {
        if (response.status === HTTP_STATUS.OK) {
          this.setState({
            iframeModal: true,
            iframeUrl: response.data.iframeUrl,
          });
        }
      });
    }
  };

  toggleModal = () => {
    this.setState({ showModal: false, iframeModal: false });
  };

  render() {
    const {
      showModal,
      modalType,
      modal,
      iframeModal,
      iframeUrl,
      choice,
    } = this.state;

    return (
      <>
        <ExistingMember handleContinue={this.handleContinue} choice={choice} />
        {showModal && (
          <PopupModal
            type={modalType}
            title={modal.title}
            description={modal.description}
            toggleModal={this.toggleModal}
            showModal={showModal}
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
            popupBtnClick={this.toggleModal}
          />
        )}
        {iframeModal && (
          <Inverite
            iframeUrl={iframeUrl}
            iframeModal={iframeModal}
            toggleModal={this.toggleModal}
            showModal={iframeModal}
            popupBtnClick={this.toggleModal}
          />
        )}
      </>
    );
  }
}

ExistingMemberContainer.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]),
  doGetAcoountDetails: PropTypes.func.isRequired,
  doCreateInverite: PropTypes.func.isRequired,
  doGetProductFromVault: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  inSessionJointApplicant: PropTypes.object,
};

ExistingMemberContainer.defaultProps = {
  history: null,
  products: [],
  inSessionJointApplicant: null,
};

const mapStateToProps = (state) => ({
  MemberExists: state.MemberExistsReducer,
  products: state.VaultReducer.vaultProductList,
  inSessionJointApplicant: state.JoinApplicantReducer.inSessionJointApplicant,
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAcoountDetails: (callback) => dispatch(getAccount(callback)),
    doCreateInverite: (callback) => dispatch(callCreateInverite(callback)),
    doGetProductFromVault: (callback) =>
      dispatch(getProductFromVault(callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingMemberContainer);
