import { call, put, takeLatest } from "redux-saga/effects";
import GetApplicationRequest from "Communication/ApiClasses/GetApplicationRequest";
import * as actionTypes from "Redux/Actions/actionTypes";
import {
  doLoadingStart,
  doLoadingFinish,
} from "Components/Common/ProgressIndicator/Actions/ProgressIndicatorActions";
import STRINGS from "Constants/Strings";

// worker
function* handleApplication(action) {
  yield put(doLoadingStart(STRINGS.ABOUTYOU.API_KEYS.GET_APPLICANT));
  const getApplication = new GetApplicationRequest(action.id);
  try {
    const result = yield call(getApplication.getData);
    yield put({
      type: actionTypes.STORE_APPLICANT_RESPONSE,
      response: result.data,
    });
    yield call(action.callback, result);
  } catch (error) {
    yield put({
      type: actionTypes.APPLICATION_ERROR,
      error,
    });
  } finally {
    yield put(doLoadingFinish(STRINGS.ABOUTYOU.API_KEYS.GET_APPLICANT));
  }
}

// watcher
export default function* applicationWatcherSaga() {
  yield takeLatest(actionTypes.CALL_APPLICANT_API, handleApplication);
}
