import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withApplicantHOC from "Hoc/ApplicantHOC/ApplicantHOC";

import AppConstants from "Constants/AppConstants";

import EmploymentAndIncome from "./EmploymentAndIncome";
import {
  getCountryList,
  getStatusList,
  getIncomeSourcesList,
} from "./Actions/EmploymentAndIncomeAction";

class EmploymentAndIncomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      newMember: true,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    const {
      countryList,
      statusList,
      incomeSourcesList,
      applicantData,
      applicationCompleteList,
    } = this.props;
    countryList();
    statusList();
    incomeSourcesList();
    if (applicantData?.acquireType === "search") {
      this.setState({
        editMode: false,
      });
    }
    if (
      applicantData?.acquireType === "search" ||
      (applicationCompleteList && applicationCompleteList.length > 0) ||
      sessionStorage.getItem("TEMP_HIDE") === "true"
    ) {
      this.setState({
        newMember: false,
      });
    }
  }

  onSubmitHandler = (formDetails) => {
    const { continuehandler } = this.props;
    const { countryOption } = formDetails;

    const selectedOption =
      countryOption.length > 1
        ? countryOption.find(
            (obj) => obj.taxResidentOtherName === formDetails.countryName
          )
        : countryOption[0];

    const { taxResidency } = selectedOption.taxResidencyResult;

    const validMobileNo =
      formDetails &&
      formDetails.employerContactNumber.replace(
        AppConstants.EMPLOYMENTANDINCOME.CONTACT_NO_PATTERN,
        ""
      ); // For Removing special characters

    const PhoneArea = validMobileNo.substring(0, 3); // To get first 10 digit for employerPhoneArea
    const PhoneNumber = validMobileNo.substring(3); // To get first last 7 digit for employerPhone

    const SalaryFormat =
      formDetails &&
      formDetails.monthlyGrossIncome.replace(
        AppConstants.EMPLOYMENTANDINCOME.SALARY_PATTERN,
        ""
      ); // Removing special characters for salary

    const despatchObject = {
      // TODO:remove it as it is work around from mobetize
      address: {
        mailingAddressLineType1: " ",
      },
      member: {
        sin: formDetails && formDetails.sin ? formDetails.sin : "",
      },
      regulatory: {
        taxResidency,
        ssn: formDetails && formDetails.ssn ? formDetails.ssn : "",
        taxResidencyOther: formDetails.countryList,
      },
      employment: {
        currentEmployer: formDetails.employerName,
        employerPhoneArea: PhoneArea,
        employerPhone: PhoneNumber,
        employerSalary: SalaryFormat,
        employmentStatus: formDetails.employmentStatus,
        incomeType: formDetails.sourceOfIncome,
        jobTitle: formDetails.jobTitle,
      },
    };

    continuehandler(despatchObject, AppConstants.APPLICATIONSTEP.EMPLOYMENT);
  };

  render() {
    const { editMode, newMember } = this.state;
    const {
      EmploymentAndIncomeStatus,
      getDataFromServer,
      handleBack,
      steps,
      activeStepID,
      products,
      getActiveProduct,
      config,
    } = this.props;
    const activeProduct = getActiveProduct(products);
    return (
      <EmploymentAndIncome
        editMode={editMode}
        statusList={EmploymentAndIncomeStatus.statusList}
        incomeSourcesList={EmploymentAndIncomeStatus.incomeSourcesList}
        getOptions={this.getOptions}
        onSubmitHandler={this.onSubmitHandler}
        getDataFromServer={getDataFromServer}
        handleBack={() => handleBack(AppConstants.APPLICATIONSTEP.EMPLOYMENT)}
        steps={steps}
        activeStepID={activeStepID}
        newMember={newMember}
        activeProduct={activeProduct}
        config={config}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      countryList: getCountryList,
      statusList: getStatusList,
      incomeSourcesList: getIncomeSourcesList,
    },
    dispatch
  );
};
const mapStateToProps = (state) => ({
  EmploymentAndIncomeStatus: state.EmploymentAndIncomeReducer,
  applicantData: state.ApplicationReducer.response,
  applicationCompleteList: state.ApplicationReducer.applicationCompleteList,
  products: state.VaultReducer.vaultProductList,
  config: state.MarketplaceReducer.finInfo?.config,
});

EmploymentAndIncomeContainer.propTypes = {
  EmploymentAndIncomeStatus: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  countryList: PropTypes.func.isRequired,
  statusList: PropTypes.func.isRequired,
  incomeSourcesList: PropTypes.func.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  continuehandler: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
};

EmploymentAndIncomeContainer.defaultProps = {
  steps: [],
  activeStepID: -1,
};

const EmploymentAndIncomeWrapper = withApplicantHOC(
  EmploymentAndIncomeContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmploymentAndIncomeWrapper);
