/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";
import checkErrors from "Utils/InputValidator";
import VALIDATIONS from "Constants/ValidationList";
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";
import AuthBody from "Components/Common/AuthBody/AuthBody";
import Stepper from "Components/Common/Stepper/Stepper";

class OverdraftProtection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needProtection: "",
      needProtectionError: "",
    };
  }

  componentDidMount() {
    const { getDataFromServer } = this.props;
    getDataFromServer(
      AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION,
      (response) => {
        this.setFormData(response);
      }
    );
  }

  setFormData = (response) => {
    const { hasOverdraftProtection } = response;
    this.setState({
      needProtection: hasOverdraftProtection,
    });
  };

  verifyData = () => {
    let valid = true;
    const { needProtection } = this.state;

    const error = checkErrors(
      needProtection,
      VALIDATIONS.OVERDRAFT_PROTECTION.overdraftProtection
    );

    if (error.length) {
      valid = false;
      this.setState({ needProtectionError: error });
    }

    if (valid) {
      const { submitRequest } = this.props;
      const request = {
        hasOverdraftProtection: needProtection,
      };
      submitRequest(request);
    }
  };

  // handle need protection click
  needProtectionClick = (value) => {
    this.setState({ needProtection: value, needProtectionError: "" });
  };

  render() {
    const { needProtectionError, needProtection } = this.state;

    const { steps, activeStepID, handleBack } = this.props;

    const overdraftProtectionComponent = (
      <div className="[ overdraft-protection-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">
          {STRINGS.OVERDRAFT_PROTECTION.TITLE}
        </div>

        <div className="[ form-container ]">
          <div className="[ mb-3 ]">
            <div className="[ form-label ]">
              {STRINGS.OVERDRAFT_PROTECTION.OVERDRAFT_PROTECTION_REQUIRED}
            </div>

            <div className="[ row ] [ custom-radios ]">
              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    needProtection !== "" && needProtection
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() => this.needProtectionClick(true)}
                >
                  {STRINGS.COMMON.CHOICE.YES}
                </button>
              </div>

              <div className="[ col-6 ]">
                <button
                  type="button"
                  className={
                    needProtection !== "" && needProtection === false
                      ? "[ btn custom-radio-selected ]"
                      : "[ btn custom-radio-unselected ]"
                  }
                  onClick={() => this.needProtectionClick(false)}
                >
                  {STRINGS.COMMON.CHOICE.NO}
                </button>
              </div>
            </div>

            <div className="[ error-msg ] ">{needProtectionError[0]}</div>
          </div>
        </div>

        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.verifyData}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <AuthBody
          actionComponent={overdraftProtectionComponent}
          handleBack={handleBack}
          memberCreation
        />
      </>
    );
  }
}

OverdraftProtection.propTypes = {
  submitRequest: PropTypes.func,
  getDataFromServer: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  handleBack: PropTypes.func.isRequired,
};
OverdraftProtection.defaultProps = {
  submitRequest: () => {},
  getDataFromServer: () => {},
  steps: [],
  activeStepID: -1,
};

export default OverdraftProtection;
