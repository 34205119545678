import AppConstants from "Constants/AppConstants";

export const showIncomePage = (activeProduct) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    return false;
  } else {
    return true;
  }
};

export const showAssetPage = (activeProduct) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    return false;
  } else {
    return true;
  }
};

export const showExpensePage = (activeProduct) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    return false;
  } else {
    return true;
  }
};

export const showLiabilityPage = (activeProduct) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    return false;
  } else {
    return true;
  }
};

export const showDocumentUpload = (activeProduct) => {
  if (
    (activeProduct.category === AppConstants.LOAN_CATEGORY.PERSONAL ||
      activeProduct.category === AppConstants.LOAN_CATEGORY.LOC) &&
    activeProduct.loanDetails.loanAmount <= activeProduct.cutoff
  ) {
    return false;
  } else {
    return true;
  }
};

export const showDebitCard = (
  activeProduct,
  applicationCompleteList,
  applicantData
) => {
  console.log("showDebitCard");
  let result = false;
  if (
    applicantData?.acquireType == "create" &&
    applicationCompleteList?.length === 0
  ) {
    result = true;
  }
  return result;
};

export const showOnlineBanking = (
  activeProduct,
  applicationCompleteList,
  applicantData
) => {
  console.log("showOnlineBanking");
  let result = false;
  if (
    applicantData.acquireType == "create" ||
    applicantData.addedToBankingAt ||
    applicantData.submittedOnlineUserAt
  ) {
    if (activeProduct) {
      // there is an application in process
      if (applicationCompleteList?.length > 0) {
        if (applicationCompleteList.length === 1) {
          if (applicationCompleteList[0].id === activeProduct.applicationId) {
            result = true;
          }
        }
      } else {
        result = true;
      }
    } else {
      if (applicationCompleteList?.length === 1) {
        result = true;
      }
    }
  }
  return result;
};

export const showError = (error) => {
  let result = true;

  const accessToken = sessionStorage.getItem("token");

  if (accessToken) {
  } else {
  }

  return result;
};
