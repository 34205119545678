import React from "react";
import PropTypes from "prop-types";
// import constants
import STRINGS from "Constants/Strings";
import AppConstants from "Constants/AppConstants";

import {
  HTTP_STATUS,
  REGISTERCONTRACTPRODUCT,
  TERMDEPOSITEPRODUCT,
} from "Communication/Constants";

// import helper methods
import {
  getDescriptionFromArray,
  getGettingStarted,
} from "Utils/CommonUtilities";
// import utils
import { setOptions } from "Utils/EmploymentAndIncomeUtilities";

import AuthBody from "Components/Common/AuthBody/AuthBody";
// import common components
import PopupModal from "Components/Common/PopupModal/PopupModal";
import Stepper from "Components/Common/Stepper/Stepper";
import { connect } from "react-redux";
import * as ROUTES from "Constants/Routes";
import App from "../../App";
import {
  showAssetPage,
  showDebitCard,
  showDocumentUpload,
  showExpensePage,
  showIncomePage,
  showLiabilityPage,
} from "../../Utils/LogicUtilities";
import { findCode } from "../../Utils/CommonUtilities";

class ConfirmAccountCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAcceptedTerms: false, // boolean value to check user has accepted terms
      gettingStartedData: {},
      aboutYouData: {},
      employmentData: {},
      complianceData: {},
      spousalInfoData: {},
      accountInformationData: {},
      beneficiaryData: [],
      show: [],
      termsConditionError: [],
      jointApplicants: [],
      applicantTypes: [],
      assetList: [],
      incomeList: [],
      expenseList: [],
      liabilitiesList: [],
      loanDetails: {},
      documentList: [],
      showDebitCardSection: false,
    };
  }

  componentDidMount() {
    const {
      doGetProductInformation,
      doGetBeneficiaryData,
      doGetSpousalInformation,
      doGetApplicationProperty,
      doGetApplicantType,
      inSessionJointApplicant,
      doGetFinancialAnalysisFinancialList,
      doGetDocumentList,
      doGetLoanLoanSecurity,
      applicationCompleteList,
      applicantData,
    } = this.props;
    const { getDataFromServer, processFlow } = this.props;
    getDataFromServer(AppConstants.APPLICATIONSTEP.CONFIRM, (applicantRes) => {
      if (applicantRes.status === HTTP_STATUS.OK) {
        this.updateStateFromResponse(applicantRes.data);
        const { getActiveProduct, vaultProductList } = this.props;
        const activeProduct = getActiveProduct(vaultProductList);

        if (
          this.isPagePresent(AppConstants.APPLICATIONSTEP.DEBIT_CARD) !== -1
        ) {
          if (
            showDebitCard(activeProduct, applicationCompleteList, applicantData)
          ) {
            this.setState({
              showDebitCardSection: true,
            });
          }
        }

        if (activeProduct.type === TERMDEPOSITEPRODUCT) {
          if (
            this.isPagePresent(AppConstants.APPLICATIONSTEP.SPOUSAL_INFO) !== -1
          ) {
            doGetSpousalInformation(
              activeProduct.applicationId,
              REGISTERCONTRACTPRODUCT,
              activeProduct.contractProductId,
              (getSpousalInformationRes) => {
                if (getSpousalInformationRes.status === HTTP_STATUS.OK) {
                  this.setState({
                    spousalInfoData: getSpousalInformationRes.data,
                  });
                }
              }
            );
          }

          if (
            this.isPagePresent(AppConstants.APPLICATIONSTEP.BENEFICIARIES) !==
            -1
          ) {
            doGetBeneficiaryData(
              activeProduct.applicationId,
              activeProduct.contractProductId,
              (getBeneficiaryDataRes) => {
                if (getBeneficiaryDataRes.status === HTTP_STATUS.OK) {
                  this.setState({
                    beneficiaryData: getBeneficiaryDataRes.data,
                  });
                }
              }
            );
          }
        }

        if (
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION
          ) !== -1 ||
          this.isPagePresent(
            AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION
          ) !== -1
        ) {
          doGetProductInformation(
            activeProduct.applicationId,
            activeProduct.type,
            activeProduct.doximProductId,
            (getProductInformationRes) => {
              if (getProductInformationRes.status === HTTP_STATUS.OK) {
                this.setState({
                  accountInformationData: getProductInformationRes.data,
                });
              }
            }
          );
        }

        if (
          this.isPagePresent(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT) !==
            -1 &&
          !activeProduct.isJointProduct
        ) {
          doGetApplicationProperty(
            { applicationId: activeProduct.applicationId },
            (res) => {
              if (res.slots.length > 1) {
                this.setState({
                  jointApplicants: res.slots.filter(
                    (slot) => slot.type === AppConstants.JOINT_APPLICANT.TYPE
                  ),
                });
              }
            }
          );
          doGetApplicantType((res) => {
            console.log("applicantTypes", res);
            this.setState({
              applicantTypes: res,
            });
          });
        }

        if (inSessionJointApplicant) {
          const show = [];
          AppConstants.JOINT_APPLICANT.FLOW.forEach((flow) =>
            show.push(flow.componentName)
          );
          this.setState({ show });
        } else if (processFlow && processFlow.length > 0) {
          const show = [];
          processFlow.forEach((flow) => {
            if (flow.componentName === AppConstants.APPLICATIONSTEP.INCOME) {
              if (showIncomePage(activeProduct)) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName === AppConstants.APPLICATIONSTEP.ASSET
            ) {
              if (showAssetPage(activeProduct)) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName === AppConstants.APPLICATIONSTEP.EXPENSE
            ) {
              if (showExpensePage(activeProduct)) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName === AppConstants.APPLICATIONSTEP.LIABILITIES
            ) {
              if (showLiabilityPage(activeProduct)) {
                show.push(flow.componentName);
              }
            } else if (
              flow.componentName ===
              AppConstants.APPLICATIONSTEP.DOCUMENT_UPLOAD
            ) {
              if (showDocumentUpload(activeProduct)) {
                show.push(flow.componentName);
                // doGetDocumentList(activeProduct.applicationId, (res) => {
                this.setState({
                  documentList: activeProduct.docs,
                });
                // });
              }
            } else {
              show.push(flow.componentName);
            }
          });
          this.setState({ show });
        }
        if (
          this.isPagePresent(AppConstants.APPLICATIONSTEP.ASSET) !== -1 ||
          this.isPagePresent(AppConstants.APPLICATIONSTEP.INCOME) !== -1 ||
          this.isPagePresent(AppConstants.APPLICATIONSTEP.EXPENSE) !== -1 ||
          this.isPagePresent(AppConstants.APPLICATIONSTEP.LIABILITIES) !== -1
        ) {
          console.log("Asset");
          doGetFinancialAnalysisFinancialList(
            {
              applicantId: activeProduct.applicantId,
              finAnalysisId: activeProduct.finAnalysisId,
            },
            (res) => {
              this.saveFinancialAnalysisFinancialList(res);
              // console.log(this.state.assets);
            }
          );
        }

        if (
          this.isPagePresent(AppConstants.APPLICATIONSTEP.LOAN_DETAILS) !== -1
        ) {
          console.log("Loan Details");
          this.saveLoanDetailsData(activeProduct.loanDetails);
        }
        /* Loan Details Here */
        if (activeProduct.templateId === 6 || activeProduct.templateId === 7) {
          console.log("loan details here");
        } else {
          console.log("no loan details");
        }
      }
    });
  }

  updateStateFromResponse = (applicantRes) => {
    this.saveGettingStartedData(applicantRes);
    this.saveAboutYouData(applicantRes);
    this.saveemploymentData(applicantRes);
    this.saveComplianceData(applicantRes);
  };

  saveGettingStartedData = (response) => {
    getGettingStarted(response, (gettingStartedData) => {
      this.setState({ gettingStartedData });
    });
  };

  commaFormatted(amount) {
    if (amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }

  saveAboutYouData = (response) => {
    const { member, address } = response;
    this.setState({
      aboutYouData: {
        firstName: member.name,
        middleName: member.middleName,
        surname: member.surname,
        dateOfBirth: member.dob,
        address: address.currentAddressLine1,
        addressLine2: address.currentAddressLine2,
        city: address.currentAddressCity,
        state: address.currentAddressProvince,
        postalCode: address.currentAddressPostalCode,
        mailingAddressLine1: address.mailingAddressLine1,
        mailingAddressLine2: address.mailingAddressLine2,
        mailingCity: address.mailingCity,
        mailingProvince: address.mailingProvince,
        mailingPostalCode: address.mailingPostalCode,
        mailingCountry: address.mailingCountry,
        mailingSameAsCivic: address.mailingSameAsCivic,
      },
    });
  };

  isPagePresent = (pageName) => {
    const { processFlow, inSessionJointApplicant } = this.props;

    let pageIndex = -1;

    if (inSessionJointApplicant) {
      pageIndex = AppConstants.JOINT_APPLICANT.FLOW.findIndex(
        (process) => process.componentName === pageName
      );
    } else {
      pageIndex = processFlow.findIndex(
        (process) => process.componentName === pageName
      );
    }

    return pageIndex;
  };

  saveemploymentData = (response) => {
    const { employment, regulatory, member } = response;

    const resOptions = setOptions(regulatory?.taxResidency);

    const selectedOption = resOptions?.options.find(
      (obj) => obj.taxResidencyResult?.taxResidency === regulatory?.taxResidency
    );

    this.setState({
      employmentData: {
        ...employment,
        employerName: employment.currentEmployer,
        employerContactNumber:
          employment.employerPhoneArea + employment.employerPhone,
        annualGrossIncome: employment.employerSalary,
        sourceOfIncome: employment.incomeType,
        sin: member.sin,
        ssn: regulatory?.ssn,
        areYouTaxResidentOfCanada:
          resOptions?.taxResidentHome !== ""
            ? resOptions?.taxResidentHome
            : false,
        areYouTaxResidentOfOtherCountry:
          resOptions?.taxResidentOther !== ""
            ? resOptions?.taxResidentOther
            : false,
        countryOption: resOptions?.options,
        countryList: regulatory?.taxResidencyOther,
        countryName: selectedOption?.taxResidentOtherName,
      },
    });
  };

  saveComplianceData = (response) => {
    const { peps, member, regulatory } = response;
    this.setState({
      complianceData: {
        ...response,
        isAnyoneConsideredPEPHIO: !!(peps && peps.length > 0),
        member: {
          ...member,
          pep:
            regulatory?.memberIsPedp ||
            regulatory?.memberIsPefp ||
            regulatory?.memberIsHio,
        },
      },
    });
  };

  saveLoanDetailsData = (data) => {
    console.log(data);
    this.setState({
      loanDetails: data,
    });
  };

  saveFinancialAnalysisFinancialList(response) {
    let tempList = this.filterFinancialAnalysis(
      response,
      AppConstants.INCOME.TYPE
    );

    this.setState({
      assetList: this.filterFinancialAnalysis(
        response,
        AppConstants.ASSET.TYPE
      ),
      incomeList: tempList,
      expenseList: this.filterFinancialAnalysis(
        response,
        AppConstants.EXPENSE.TYPE
      ),
      liabilitiesList: this.filterFinancialAnalysis(
        response,
        AppConstants.LIABILITIES.TYPE
      ),
    });
  }

  filterFinancialAnalysis(data, type) {
    return data.filter((financial) => financial.type === type);
  }

  capitalize = (s) => {
    if (typeof s !== "string") return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  handleChecked = () => {
    const { isAcceptedTerms } = this.state;
    this.setState({
      isAcceptedTerms: !isAcceptedTerms,
      termsConditionError: "",
    });
  };

  handleContinueClick = () => {
    const { isAcceptedTerms } = this.state;
    const { handleSubmitDetails } = this.props;
    if (isAcceptedTerms) {
      handleSubmitDetails();
    } else {
      this.setState({
        termsConditionError: STRINGS.CONFIRM_CREATION.ACCOUNT_DISCLAIMER_ERROR,
      });
    }
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return "";
  }

  getApplicantType = (type) => {
    const { applicantTypes } = this.state;
    const appType = applicantTypes.find((item) => item.code === type);
    if (appType) {
      return appType.description;
    } else {
      return type;
    }
  };

  render() {
    const {
      showModal,
      type,
      modal,
      toggleModal,
      showVaultClicked,
      showVault,
      handleEditPage,
      gettingStartedBranchData,
      incomeSourcesList,
      statusList,
      provinceList,
      accountPurposeList,
      handlePopupModalBtnClick,
      handleBack,
      steps,
      activeStepID,
      isDebitCardEnabled,
      enableDebitCard,
      debitCardShown,
      activeProduct,
      applicationCompleteList,
      applicantData,
      inSessionJointApplicant,
      finInfo,
      streetTypes,
    } = this.props;

    const {
      gettingStartedData,
      aboutYouData,
      employmentData,
      complianceData,
      spousalInfoData,
      accountInformationData,
      beneficiaryData,
      show,
      jointApplicants,
      loanDetails,
      assetList,
      incomeList,
      expenseList,
      liabilitiesList,
      documentList,
      showDebitCardSection,
    } = this.state;
    console.log("applicationCompleteList", applicationCompleteList);
    const { isAcceptedTerms, termsConditionError } = this.state;

    const actionCompnent = (
      <div className="[ confirm-account-container ]">
        <Stepper steps={steps} active={activeStepID} />

        <div className="[ form-title ]">{STRINGS.CONFIRM_CREATION.TITLE}</div>

        <div className="[ form-container ]">
          <div className="[ form-subtitle ]">
            {STRINGS.CONFIRM_CREATION.FIRST_MSG}
            {""}
            {aboutYouData && aboutYouData.firstName
              ? aboutYouData.firstName
              : ""}
            {STRINGS.CONFIRM_CREATION.SECOND_MSG}
          </div>
          <div className="[ form-description ]">
            {STRINGS.CONFIRM_CREATION.REVIEW_DETAILS_MSG}
          </div>

          {/* Getting started block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.GETTINGSTARTED) &&
            applicationCompleteList.length === 0 &&
            applicantData?.acquireType !== "search" && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.GETTING_STARTED.TITLE}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.GETTING_STARTED)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.BRNACH}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {gettingStartedData && gettingStartedData.branch
                        ? getDescriptionFromArray(
                            gettingStartedBranchData,
                            gettingStartedData.branch
                          )
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.ACCOUNT_STATMENT_CHOICE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {gettingStartedData &&
                      gettingStartedData.accountStatementType
                        ? gettingStartedData.accountStatementType.toUpperCase()
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {/* Getting started block end */}

          {/* About you block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.ABOUTYOU) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.CONFIRM_CREATION.ABOUT_YOU}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.ABOUT_YOU)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.FIRST_NAME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.firstName
                      ? aboutYouData.firstName
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.MIDDLE_NAME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.middleName
                      ? aboutYouData.middleName
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.SURNAME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.surname
                      ? aboutYouData.surname
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.DOB}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.dateOfBirth
                      ? aboutYouData.dateOfBirth
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.ADDRESS1}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.address
                      ? aboutYouData.address
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.ADDRESS2}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.addressLine2
                      ? aboutYouData.addressLine2
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.CITY}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.city ? aboutYouData.city : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.STATE}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.state
                      ? getDescriptionFromArray(
                          provinceList,
                          aboutYouData.state
                        )
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.POSTAL_CODE}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]  [ postalCode ]">
                    {aboutYouData && aboutYouData.postalCode
                      ? aboutYouData.postalCode
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.SAMEASMAILING}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {aboutYouData && aboutYouData.mailingSameAsCivic
                      ? STRINGS.COMMON.CHOICE.YES
                      : STRINGS.COMMON.CHOICE.NO}
                  </div>
                </div>
              </div>

              {aboutYouData && !aboutYouData.mailingSameAsCivic ? (
                <>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGADDRESSLINE1}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.mailingAddressLine1
                          ? aboutYouData.mailingAddressLine1
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGADDRESSLINE2}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.mailingAddressLine2
                          ? aboutYouData.mailingAddressLine2
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGCITY}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.mailingCity
                          ? aboutYouData.mailingCity
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGPROVINCE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.mailingProvince
                          ? getDescriptionFromArray(
                              provinceList,
                              aboutYouData.mailingProvince
                            )
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGPOSTALCODE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ] [ postalCode ]">
                        {aboutYouData && aboutYouData.mailingPostalCode
                          ? aboutYouData.mailingPostalCode
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MAILINGCOUNTRY}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {aboutYouData && aboutYouData.mailingCountry
                          ? aboutYouData.mailingCountry
                          : ""}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          )}
          {/* About you block end */}

          {/* Employment and income block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.EMPLOYMENT) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.EMPLOYMENT_AND_INCOME.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() =>
                        handleEditPage(ROUTES.EMPLOYMENT_AND_INCOME)
                      }
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.EMPLOYMENT_STATUS}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {employmentData && employmentData.employmentStatus
                      ? getDescriptionFromArray(
                          statusList,
                          employmentData.employmentStatus
                        )
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.OCCUPATION}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {employmentData && employmentData.jobTitle
                      ? findCode(
                          employmentData.jobTitle,
                          finInfo?.config?.occupations
                        )
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.CURRENT_EMPLOYER}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {employmentData && employmentData.employerName
                      ? employmentData.employerName
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.EMPLOYER_PHONE}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {employmentData && employmentData.employerContactNumber
                      ? this.formatPhoneNumber(
                          employmentData.employerContactNumber
                        )
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.MONTHLY_GROSS_INCOME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    ${" "}
                    {employmentData && employmentData.annualGrossIncome
                      ? this.commaFormatted(employmentData.annualGrossIncome)
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.SOURCE_OF_INCOME}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {employmentData && employmentData.sourceOfIncome
                      ? getDescriptionFromArray(
                          incomeSourcesList,
                          employmentData.sourceOfIncome
                        )
                      : ""}
                  </div>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.TAX_RESIDENT_OF_CANADA}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {employmentData && employmentData.areYouTaxResidentOfCanada
                      ? STRINGS.COMMON.CHOICE.YES
                      : STRINGS.COMMON.CHOICE.NO}
                  </div>
                </div>
              </div>

              {employmentData && employmentData.sin && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.SIN_NUMBER}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      ****&nbsp;
                      {employmentData && employmentData.sin
                        ? employmentData.sin.substr(
                            employmentData.sin.length - 3
                          )
                        : null}
                    </div>
                  </div>
                </div>
              )}

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.TAX_RESIDENT_OTHER_COUNTRY}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {employmentData &&
                    employmentData.areYouTaxResidentOfOtherCountry
                      ? STRINGS.COMMON.CHOICE.YES
                      : STRINGS.COMMON.CHOICE.NO}
                  </div>
                </div>
              </div>

              {employmentData &&
                employmentData.areYouTaxResidentOfOtherCountry && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.TAX_SELECT_COUNTRY}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {employmentData && employmentData.countryName
                          ? employmentData.countryName
                          : null}
                      </div>
                    </div>
                  </div>
                )}

              {employmentData && employmentData.ssn && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.SSN_NUMBER}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      ****&nbsp;
                      {employmentData && employmentData.ssn
                        ? employmentData.ssn.substr(
                            employmentData.ssn.length - 3
                          )
                        : null}
                    </div>
                  </div>
                </div>
              )}

              {employmentData &&
                employmentData.areYouTaxResidentOfOtherCountry &&
                employmentData.countryList && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.TAX_SELECT_COUNTRY_LIST}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {employmentData && employmentData.countryList
                          ? employmentData.countryList
                          : null}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
          {/* Employment and income block end */}

          {/* Compliance block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.COMPLIANCE) &&
            complianceData?.member?.intendedUseOfMembership &&
            applicationCompleteList.length === 0 &&
            applicantData?.acquireType !== "search" && (
              <div className="[ details-section ] [ mb-3 ]">
                <div className="[ row ] [ details-section__header">
                  <div className="[ col-12 ]">
                    <h3 className="[ d-flex justify-content-between align-items-center ]">
                      {STRINGS.COMPLIANCE.TITLE}
                      <button
                        type="button"
                        className="[ btn edit-btn ]"
                        onClick={() => handleEditPage(ROUTES.COMPLIANCE)}
                        aria-label="edit"
                      >
                        <span className="[ edit-icon ]" />
                        <span className="[ edit-btn__label ]">
                          {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                        </span>
                      </button>
                    </h3>
                  </div>
                </div>

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.CONFIRM_CREATION.ACCOUNT_PURPOSE}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {complianceData &&
                      complianceData.member &&
                      complianceData.member.intendedUseOfMembership
                        ? getDescriptionFromArray(
                            accountPurposeList,
                            complianceData.member.intendedUseOfMembership
                          )
                        : ""}
                    </div>
                  </div>
                </div>

                {complianceData &&
                complianceData.regulatory?.memberHasThirdParty ? (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.THIRDPARTY_ACCOUNT}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    {complianceData.thirdParties.map((record) => (
                      <div key={record}>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYNAME}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {record.name}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYREL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {record.relationship}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.THIRDPARTY_ACCOUNT}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}

                {/* is applicant pep or hio start  */}
                {complianceData && complianceData.member?.pep ? (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.MEMBER_PEP_HIO_CHOICE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.APPLICANTTYPE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberIsHio && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.HIO_STATUS}
                            </div>
                          )}

                        {complianceData &&
                          complianceData.regulatory?.memberIsPedp && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.PEP_DOMESTIC}
                            </div>
                          )}
                        {complianceData &&
                          complianceData.regulatory?.memberIsPefp && (
                            <div className="[ details-section__item-description ]">
                              {STRINGS.CONFIRM_CREATION.PEP_FOREGIN}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.OFFICE_HELD_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberOfficeHeld && (
                            <div className="[ details-section__item-description ]">
                              {complianceData.regulatory.memberOfficeHeld}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.JURISDICTION_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberFundsSource && (
                            <div className="[ details-section__item-description ]">
                              {complianceData.regulatory.memberJurisdiction}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.SOURCE_OF_FUNDS_LABEL}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        {complianceData &&
                          complianceData.regulatory?.memberFundsSource && (
                            <div className="[ details-section__item-description ]">
                              {complianceData.regulatory.memberFundsSource}
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.MEMBER_PEP_HIO_CHOICE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}
                {/* is applicant pep or hio end */}
                {/* PEP details start */}
                {complianceData && complianceData.peps?.length > 0 ? (
                  <>
                    {/* applicant details start */}

                    {/* applicant details end */}

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.CONFIRM_CREATION.PEP_HIO_CHOICE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {STRINGS.COMMON.CHOICE.YES}
                        </div>
                      </div>
                    </div>

                    {complianceData.peps.map((data) => (
                      <div key={data}>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.CLOSEPERSONDETAILS}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.name}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.THIRDPARTYREL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.relationship}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {
                                STRINGS.CONFIRM_CREATION
                                  .CLOSEPERSONJURISDICTIONTYPE
                              }
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.jurisdictionType}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.ISPEPORHIO}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.hio ? "true" : "false"}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.OFFICE_HELD_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.officeHeld}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.JURISDICTION_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.jurisdiction}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.CONFIRM_CREATION.SOURCE_OF_FUNDS_LABEL}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {data.fundsSource}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.CONFIRM_CREATION.PEP_HIO_CHOICE}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {STRINGS.COMMON.CHOICE.NO}
                      </div>
                    </div>
                  </div>
                )}
                {/* PEP details end */}
              </div>
            )}
          {/* Compliance block end */}
          {/* Account Information block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.ACCOUNT_INFORMATION) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ col-12 ]">
                <h3 className="[ d-flex justify-content-between align-items-center ]">
                  {STRINGS.ACCOUNT_INFORMATION.TITLE}
                  <button
                    type="button"
                    className="[ btn edit-btn ]"
                    onClick={() => handleEditPage(ROUTES.ACCOUNT_INFORMATION)}
                    aria-label="edit"
                  >
                    <span className="[ edit-icon ]" />
                    <span className="[ edit-btn__label ]">
                      {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                    </span>
                  </button>
                </h3>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.ACCOUNT_PURPOSE_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {accountInformationData &&
                      accountInformationData.intendedUse?.length > 0 && (
                        <>{accountInformationData.intendedUse}</>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Account Information block end */}

          {/* Overdraft Protection block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.OVERDRAFT_PROTECTION) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.OVERDRAFT_PROTECTION.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() =>
                        handleEditPage(ROUTES.OVERDRAFT_PROTECTION)
                      }
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.CONFIRM_CREATION.OVER_DRAFTPROTECTION__REQUIRED}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {accountInformationData &&
                    accountInformationData.hasOverdraftProtection
                      ? STRINGS.COMMON.CHOICE.YES
                      : STRINGS.COMMON.CHOICE.NO}
                  </div>
                </div>
              </div>
            </div>
          )}
          {showDebitCardSection && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.DEBIT_CARD.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.DEBIT_CARD)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.DEBIT_CARD.CHOICE_MESSAGE}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.enableDebitCard
                      ? STRINGS.COMMON.CHOICE.YES
                      : STRINGS.COMMON.CHOICE.NO}
                  </div>
                </div>
              </div>
            </div>
          )}

          {inSessionJointApplicant && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.JOINT_APPLICANT.TITLE}
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.JOINT_APPLICANT.FORM.CONFIRM_JOINT_APPLICANT}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {STRINGS.COMMON.CHOICE.YES}
                  </div>
                </div>
              </div>
            </div>
          )}

          {show.includes(AppConstants.APPLICATIONSTEP.JOINT_APPLICANT) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.JOINT_APPLICANT.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.JOINT_APPLICANT)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.JOINT_APPLICANT.FORM.CONFIRM_JOINT_APPLICANT}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  {activeProduct.isJointProduct && (
                    <div className="[ details-section__item-description ]">
                      {STRINGS.COMMON.CHOICE.YES}
                    </div>
                  )}
                  {!activeProduct.isJointProduct && (
                    <div className="[ details-section__item-description ]">
                      {jointApplicants.length > 0
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  )}
                </div>
              </div>

              {jointApplicants.map((joint, index) => (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-3 ]">
                    <div className="[ details-section__item-description ]">
                      CO-APPLICANT NO. {index + 1}
                    </div>
                  </div>
                  <div className="[ col-sm-3 ]">
                    <div className="[ details-section__item-description email ]">
                      {joint.extra.email}
                    </div>
                  </div>
                  <div className="[ col-sm-3 ]">
                    <div className="[ details-section__item-description ]">
                      {this.formatPhoneNumber(joint.extra.msisdn.substring(1))}
                    </div>
                  </div>
                  <div className="[ col-sm-3 ]">
                    <div className="[ details-section__item-description ]">
                      {this.getApplicantType(joint.joinInfo.joinType)}
                    </div>
                  </div>
                </div>
              ))}

              {jointApplicants.length > 0 && (
                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-label ]">
                      {STRINGS.JOINT_APPLICANT.FORM.JOINT_PRESENT_LABEL}
                    </div>
                  </div>
                  <div className="[ col-sm-6 ]">
                    <div className="[ details-section__item-description ]">
                      {jointApplicants[0].extra.inSession
                        ? STRINGS.COMMON.CHOICE.YES
                        : STRINGS.COMMON.CHOICE.NO}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Overdraft Protection block end */}
          {/* Spousal Info block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.SPOUSAL_INFO) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.SPOUSAL_INFO.SCREENTITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.SPOUSAL_INFO)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SPOUSAL_INFO.SPOUSE_QUI}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {spousalInfoData && spousalInfoData.isSpousal
                      ? STRINGS.COMMON.CHOICE.YES
                      : STRINGS.COMMON.CHOICE.NO}
                  </div>
                </div>
              </div>
              {spousalInfoData && spousalInfoData.isSpousal && (
                <div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.FIRSTNAMELABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {spousalInfoData && spousalInfoData.spouseFirstName
                          ? spousalInfoData.spouseFirstName
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.LASTNAMELABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {spousalInfoData && spousalInfoData.spouseLastName
                          ? spousalInfoData.spouseLastName
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.ABOUTYOU.PARTFIVE.REVIEWFORM.DOBLABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {spousalInfoData && spousalInfoData.spouseDob
                          ? spousalInfoData.spouseDob
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.EMPLOYMENT_AND_INCOME.FORM.SIN}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {spousalInfoData && spousalInfoData.spouseSin
                          ? "******" +
                            spousalInfoData.spouseSin.substr(
                              employmentData.sin.length - 3
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* Spousal Info block end */}
          {/* Beneficiary Data block start */}
          {show.includes(AppConstants.APPLICATIONSTEP.BENEFICIARIES) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.BENEFICIARIES_BREADCRUMB}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.BENEFICIARIES)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.BENEFICIARIES.FORM.BENEFICIARIES_PURPOSE_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {beneficiaryData.length === 0
                      ? STRINGS.COMMON.CHOICE.NO
                      : STRINGS.COMMON.CHOICE.YES}
                  </div>
                </div>
              </div>

              {beneficiaryData.length > 0 &&
                beneficiaryData.map((record, index) => (
                  <div key={record}>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.BENEFICIARY}
                          {index + 1}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.FIRSTNAMELABELCONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.firstName ? record.firstName : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.BENEFICIARIES.FORM
                              .MIDDLENAMELABELCONFIRMPAGE
                          }
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.middleName ? record.middleName : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.LASTNAMELABELCONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.lastName ? record.lastName : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.DOBLABELCONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.dateOfBirth
                            ? record.dateOfBirth
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.RELATIONSHIPCONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.beneficiaryRelation
                            ? record.beneficiaryRelation
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {
                            STRINGS.BENEFICIARIES.FORM
                              .BENEFICIARY_TYPECONFIRMPAGE
                          }
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.beneficiaryType
                            ? record.beneficiaryType
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.ADDRESS1CONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.address1 ? record.address1 : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.ADDRESS2CONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.address2 ? record.address2 : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.CITYCONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.city ? record.city : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.PROVINCECONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.province
                            ? getDescriptionFromArray(
                                provinceList,
                                record.province
                              )
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.POSTALCODECONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.postalCode ? record.postalCode : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.MOBILENOLABELCONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.businessArea
                            ? this.formatPhoneNumber(
                                record.businessArea + "" + record.businessPhone
                              )
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.COUNTRYCONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.country ? record.country : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.BENEFICIARIES.FORM.SINCONFIRMPAGE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {record && record.sin
                            ? "******" +
                              record.sin.substr(employmentData.sin.length - 3)
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {/* Beneficiary Data block end */}

          {/* Loan Details block start*/}
          {show.includes(AppConstants.APPLICATIONSTEP.LOAN_DETAILS) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center capitalize ]">
                    {STRINGS.LOAN_DETAILS.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.LOAN_DETAILS)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
                <div className="[ row ] [ details-section__item ]">
                  {activeProduct.category !==
                    AppConstants.LOAN_CATEGORY.HELOC && (
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-label ]">
                        {
                          STRINGS.LOAN_DETAILS.FORM
                            .PRODUCT_LABEL_LOAN_DETAILS_PRINCIPAL_AMOUNT
                        }
                      </div>
                    </div>
                  )}
                  {activeProduct.category ===
                    AppConstants.LOAN_CATEGORY.HELOC && (
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-label ]">
                        {
                          STRINGS.LOAN_DETAILS.FORM.HELOC
                            .REQUESTED_CREDIT_AMOUNT
                        }
                      </div>
                    </div>
                  )}
                  {activeProduct.category !==
                    AppConstants.LOAN_CATEGORY.MORTGAGE && (
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-description ]">
                        $ {this.commaFormatted(loanDetails.loanAmount)}
                      </div>
                    </div>
                  )}
                  {activeProduct.category ===
                    AppConstants.LOAN_CATEGORY.MORTGAGE && (
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-description ]">
                        $ {this.commaFormatted(loanDetails.estimatedMortgage)}
                      </div>
                    </div>
                  )}
                </div>

                {activeProduct.category !== AppConstants.LOAN_CATEGORY.LOC && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-label ]">
                        {
                          STRINGS.LOAN_DETAILS.FORM
                            .PRODUCT_LABEL_LOAN_DETAILS_LOAN_TERM
                        }
                      </div>
                    </div>
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-description ]">
                        {loanDetails.loanTerm === 0
                          ? AppConstants.LOANS.REVOLVING
                          : loanDetails.loanTerm}
                      </div>
                    </div>
                  </div>
                )}

                {activeProduct.category ===
                  AppConstants.LOAN_CATEGORY.MORTGAGE && (
                  <>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.LOAN_DETAILS.FORM.AMORTIZATION}
                        </div>
                      </div>
                      <div className="[ col-6 ]">
                        <div className="[ details-section__item-description ]">
                          {loanDetails.amortization} years
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="[ row ] [ details-section__item ]">
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-label ]">
                      {
                        STRINGS.LOAN_DETAILS.FORM
                          .PRODUCT_LABEL_LOAN_DETAILS_INTEREST_RATE
                      }
                    </div>
                  </div>
                  <div className="[ col-6 ]">
                    <div className="[ details-section__item-description ]">
                      {parseFloat(loanDetails.loanRate).toFixed(2)}%
                    </div>
                  </div>
                </div>

                {activeProduct.category !== AppConstants.LOAN_CATEGORY.LOC && (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.LOAN_DETAILS.FORM.REPAYMENT_FREQUENCY_LABEL}
                      </div>
                    </div>
                    <div className="[ col-6 ]">
                      <div className="[ details-section__item-description ]">
                        {this.capitalize(loanDetails.paymentType)}
                      </div>
                    </div>
                  </div>
                )}

                {activeProduct.templateId === 6 &&
                  activeProduct.category !== AppConstants.LOAN_CATEGORY.LOC && (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.LOAN_DETAILS.FORM.FIRST_REPAYMENT_DATE_LABEL}
                        </div>
                      </div>
                      <div className="[ col-6 ]">
                        <div className="[ details-section__item-description ]">
                          {loanDetails.loanScheduleDate}
                        </div>
                      </div>
                    </div>
                  )}

                {activeProduct.category !== AppConstants.LOAN_CATEGORY.LOC &&
                  activeProduct.category !==
                    AppConstants.LOAN_CATEGORY.HELOC && (
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.LOAN_DETAILS.FORM.MONTHLY_PAYMENT}
                        </div>
                      </div>
                      <div className="[ col-6 ]">
                        <div className="[ details-section__item-description ]">
                          ${" "}
                          {loanDetails?.monthlyPayment
                            ? this.commaFormatted(
                                loanDetails.monthlyPayment.toFixed(2)
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          {/* Loan Details  block end */}

          {activeProduct.property && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center capitalize ]">
                    {STRINGS.PROPERTY_DETAILS.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.PROPERTY_DETAILS)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>
              {!(
                activeProduct.property.unitNumber ||
                activeProduct.property.streetNumber ||
                activeProduct.property.streetType ||
                activeProduct.property.street ||
                activeProduct.property.city ||
                activeProduct.property.province ||
                activeProduct.property.postalCode
              ) && (
                <div className="[ details-section__item ] [ mb-3 ]">
                  No {STRINGS.PROPERTY_DETAILS.TITLE.toLowerCase()} were added.
                </div>
              )}
              {(activeProduct.property.unitNumber ||
                activeProduct.property.streetNumber ||
                activeProduct.property.streetType ||
                activeProduct.property.street ||
                activeProduct.property.city ||
                activeProduct.property.province ||
                activeProduct.property.postalCode) && (
                <>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.UNIT_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.unitNumber}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.STREETNUMBER_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.streetNumber}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.STREETNAME_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.street}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.STREETTYPE_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {findCode(
                          activeProduct.property.streetType,
                          streetTypes
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.CITY_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.city}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.PROVINCE_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.province}
                      </div>
                    </div>
                  </div>
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-label ]">
                        {STRINGS.PROPERTY_DETAILS.FORM.POST_CODE_LABEL}
                      </div>
                    </div>
                    <div className="[ col-sm-6 ]">
                      <div className="[ details-section__item-description ]">
                        {activeProduct.property.postalCode}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {activeProduct.vehicle && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center capitalize ]">
                    {STRINGS.SECURITY_VEHICLE.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.SECURITY_VEHICLE)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.YEAR_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.vehicle.year}
                  </div>
                </div>
              </div>
              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.MAKE_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.vehicle.make}
                  </div>
                </div>
              </div>
              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.MODEL_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.vehicle.model}
                  </div>
                </div>
              </div>
              <div className="[ row ] [ details-section__item ]">
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-label ]">
                    {STRINGS.SECURITY_VEHICLE.FORM.VIN_LABEL}
                  </div>
                </div>
                <div className="[ col-sm-6 ]">
                  <div className="[ details-section__item-description ]">
                    {activeProduct.vehicle.serial}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Financial Analysis List block start*/}

          {/* INCOME block start*/}
          {show.includes(AppConstants.APPLICATIONSTEP.INCOME) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.INCOME.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.INCOME)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>
              {incomeList.length === 1 && (
                <div className="[ details-section__item ] [ mb-3 ]">
                  No {STRINGS.INCOME.TITLE.toLowerCase()} were added.
                </div>
              )}
              {incomeList.length > 1 &&
                incomeList.map((income, idx) => (
                  <>
                    {idx > 0 && (
                      <div className="[ details-section ] [ mb-3 ]" key={idx}>
                        <div className="[ row ] [ details-section__header">
                          <div className="[ form-subtitle ]">
                            {STRINGS.INCOME.SUBTITLE} #{idx}
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.INCOME.FORM.INCOME_TYPE}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {income && income.subType ? income.subType : ""}
                            </div>
                          </div>
                        </div>
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.INCOME.FORM.DESCRIPTION}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {income && income.description
                                ? income.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.INCOME.FORM.MONTHLY_AMOUNT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {income && income.monthly
                                ? this.commaFormatted(income.monthly)
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </div>
          )}

          {/* ASSET block start*/}
          {show.includes(AppConstants.APPLICATIONSTEP.ASSET) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.ASSET.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.ASSET)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              {assetList.length === 0 && (
                <div className="[ details-section__item ] [ mb-3 ]">
                  No {STRINGS.ASSET.TITLE.toLowerCase()} were added.
                </div>
              )}
              {assetList.length > 0 &&
                assetList.map((asset, idx) => (
                  <div className="[ details-section ] [ mb-3 ]">
                    <div className="[ row ] [ details-section__header">
                      <div className="[ form-subtitle ]">
                        {STRINGS.ASSET.TITLE} #{idx + 1}
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ASSET.FORM.ASSET_TYPE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {asset && asset.subType ? asset.subType : ""}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ASSET.FORM.DESCRIPTION}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {asset && asset.description ? asset.description : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.ASSET.FORM.VALUE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                          {asset && asset.value
                            ? this.commaFormatted(asset.value)
                            : ""}
                        </div>
                      </div>
                    </div>

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.ASSET.FORM.ORGANIZATION_TITLE}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {asset && asset.institution ? asset.institution : ""}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.ASSET.FORM.PRIMARY_RESIDENCE_TITLE}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {asset && asset.primaryResidence ? "Yes" : "No"}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                ))}
              {/* End Loop */}
            </div>
          )}

          {/* EXPENSE block start*/}
          {show.includes(AppConstants.APPLICATIONSTEP.EXPENSE) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.EXPENSE.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.EXPENSE)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              {expenseList.length === 0 && (
                <div className="[ details-section__item ] [ mb-3 ]">
                  No {STRINGS.EXPENSE.TITLE.toLowerCase()} were added.
                </div>
              )}
              {expenseList.length > 0 &&
                expenseList.map((expense, idx) => (
                  <div className="[ details-section ] [ mb-3 ]">
                    <div className="[ row ] [ details-section__header ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ form-subtitle ]">
                          {STRINGS.EXPENSE.SUBTITLE} #{idx + 1}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EXPENSE.FORM.EXPENSE_TYPE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {expense && expense.subType ? expense.subType : ""}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EXPENSE.FORM.DESCRIPTION}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {expense && expense.description
                            ? expense.description
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.EXPENSE.FORM.MONTHLY_AMOUNT}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                          {expense && expense.monthly
                            ? this.commaFormatted(expense.monthly)
                            : ""}
                        </div>
                      </div>
                    </div>

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.EXPENSE.FORM.PRIMARY_RESIDENCE_TITLE}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {expense && expense.primaryResidence ? "Yes" : "No"}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                ))}
              {/* End Loop */}
            </div>
          )}

          {/* LIABILITIES block start*/}
          {show.includes(AppConstants.APPLICATIONSTEP.LIABILITIES) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.LIABILITIES.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.LIABILITIES)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              {liabilitiesList.length === 0 && (
                <div className="[ details-section__item ] [ mb-3 ]">
                  No {STRINGS.LIABILITIES.TITLE.toLowerCase()} were added.
                </div>
              )}
              {liabilitiesList.length > 0 &&
                liabilitiesList.map((liabilities, idx) => (
                  <div className="[ details-section ] [ mb-3 ]">
                    <div className="[ row ] [ details-section__header ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ form-subtitle ]">
                          Liability #{idx + 1}
                        </div>
                      </div>
                    </div>
                    <div className="[ row ] [ details-section__item ]">
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-label ]">
                          {STRINGS.LIABILITIES.FORM.LIABILITY_TYPE}
                        </div>
                      </div>
                      <div className="[ col-sm-6 ]">
                        <div className="[ details-section__item-description ]">
                          {liabilities && liabilities.subType
                            ? liabilities.subType
                            : ""}
                        </div>
                      </div>
                    </div>

                    {liabilities.subType === "Mortgage" ||
                    liabilities.subType === "Mortage" ? (
                      <div class="[ mb-3 ]">
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.MORTGAGE.DESCRIPTION}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {liabilities && liabilities.description
                                ? liabilities.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.MORTGAGE.VALUE}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.value
                                ? this.commaFormatted(liabilities.value)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.MORTGAGE.LIMIT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.locLimit
                                ? this.commaFormatted(liabilities.locLimit)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {
                                STRINGS.LIABILITIES.FORM.MORTGAGE
                                  .AMORTIZATION_TERM_OF_MORTGAGE
                              }
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {liabilities && liabilities.description
                                ? liabilities.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.monthly
                                ? this.commaFormatted(liabilities.monthly)
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Vehicle Loan */}
                    {liabilities.subType === "Vehicle Loan" ? (
                      <div class="[ mb-3 ]">
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {
                                STRINGS.LIABILITIES.FORM.VEHICLE_LOAN
                                  .DESCRIPTION
                              }
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {liabilities && liabilities.description
                                ? liabilities.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.VEHICLE_LOAN.VALUE}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.value
                                ? this.commaFormatted(liabilities.value)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.VEHICLE_LOAN.LIMIT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.locLimit
                                ? this.commaFormatted(liabilities.locLimit)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {
                                STRINGS.LIABILITIES.FORM.VEHICLE_LOAN
                                  .TERM_OF_LOAN
                              }
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {liabilities && liabilities.description
                                ? liabilities.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.monthly
                                ? this.commaFormatted(liabilities.monthly)
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Personal Loan */}
                    {liabilities.subType === "Loan" ||
                    liabilities.subType === "Personal Loan" ? (
                      <div class="[ mb-3 ]">
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.LOAN.DESCRIPTION}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {liabilities && liabilities.description
                                ? liabilities.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.LOAN.VALUE}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.value
                                ? this.commaFormatted(liabilities.value)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.LOAN.LIMIT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.locLimit
                                ? this.commaFormatted(liabilities.locLimit)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.monthly
                                ? this.commaFormatted(liabilities.monthly)
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Credit Card */}
                    {liabilities.subType === "CCard" ||
                    liabilities.subType === "Credit Card" ? (
                      <div class="[ mb-3 ]">
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.CREDIT_CARD.DESCRIPTION}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {liabilities && liabilities.description
                                ? liabilities.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.CREDIT_CARD.VALUE}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.value
                                ? this.commaFormatted(liabilities.value)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.CREDIT_CARD.LIMIT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.locLimit
                                ? this.commaFormatted(liabilities.locLimit)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.monthly
                                ? this.commaFormatted(liabilities.monthly)
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Line of Credit or LOC */}
                    {liabilities.subType === "Line of Credit" ||
                    liabilities.subType === "LOC" ? (
                      <div class="[ mb-3 ]">
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {
                                STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT
                                  .DESCRIPTION
                              }
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {liabilities && liabilities.description
                                ? liabilities.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT.VALUE}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.value
                                ? this.commaFormatted(liabilities.value)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.LINE_OF_CREDIT.LIMIT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.locLimit
                                ? this.commaFormatted(liabilities.locLimit)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.monthly
                                ? this.commaFormatted(liabilities.monthly)
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Line of Credit or LOC */}
                    {liabilities.subType === "Other" ||
                    liabilities.subType === "Others" ||
                    liabilities.subType === "Guarantor" ||
                    liabilities.subType === "Guar" ? (
                      <div class="[ mb-3 ]">
                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.DESCRIPTION}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {liabilities && liabilities.description
                                ? liabilities.description
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.VALUE}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.value
                                ? this.commaFormatted(liabilities.value)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.LIMIT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.locLimit
                                ? this.commaFormatted(liabilities.locLimit)
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="[ row ] [ details-section__item ]">
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-label ]">
                              {STRINGS.LIABILITIES.FORM.AVG_PAYMENT}
                            </div>
                          </div>
                          <div className="[ col-sm-6 ]">
                            <div className="[ details-section__item-description ]">
                              {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}
                              {liabilities && liabilities.monthly
                                ? this.commaFormatted(liabilities.monthly)
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.LIABILITIES.FORM.ORGANIZATION_TITLE}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {liabilities && liabilities.institution*/}
                    {/*        ? liabilities.institution*/}
                    {/*        : ""}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.LIABILITIES.FORM.ADJUSTMENT}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {liabilities && liabilities.adjustment*/}
                    {/*        ? liabilities.adjustment*/}
                    {/*        : ""}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.LIABILITIES.FORM.SECURED_LABEL}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {liabilities && liabilities.secType*/}
                    {/*        ? liabilities.secType*/}
                    {/*        : ""}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.LIABILITIES.FORM.RATE}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {AppConstants.EMPLOYMENTANDINCOME.SALARY_PREFIX}*/}
                    {/*      {liabilities && liabilities.intRate*/}
                    {/*        ? liabilities.intRate*/}
                    {/*        : ""}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.LIABILITIES.FORM.PAYMENT_TYPE}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {liabilities && liabilities.paymentType*/}
                    {/*        ? liabilities.paymentType*/}
                    {/*        : ""}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className="[ row ] [ details-section__item ]">*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-label ]">*/}
                    {/*      {STRINGS.LIABILITIES.FORM.PRIMARY_RESIDENCE_TITLE}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="[ col-sm-6 ]">*/}
                    {/*    <div className="[ details-section__item-description ]">*/}
                    {/*      {liabilities && liabilities.primaryResidence*/}
                    {/*        ? "Yes"*/}
                    {/*        : "No"}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                ))}
              {/* End Loop */}
            </div>
          )}

          {show.includes(AppConstants.APPLICATIONSTEP.DOCUMENT_UPLOAD) && (
            <div className="[ details-section ] [ mb-3 ]">
              <div className="[ row ] [ details-section__header">
                <div className="[ col-12 ]">
                  <h3 className="[ d-flex justify-content-between align-items-center ]">
                    {STRINGS.DOCUMENT_UPLOAD.TITLE}
                    <button
                      type="button"
                      className="[ btn edit-btn ]"
                      onClick={() => handleEditPage(ROUTES.DOCUMENT_UPLOAD)}
                      aria-label="edit"
                    >
                      <span className="[ edit-icon ]" />
                      <span className="[ edit-btn__label ]">
                        {STRINGS.CONFIRM_CREATION.EDIT_BTN_TEXT}
                      </span>
                    </button>
                  </h3>
                </div>
              </div>

              {(!documentList || documentList?.length === 0) && (
                <div className="[ details-section__item ] [ mb-3 ]">
                  No documents uploaded.
                </div>
              )}
              {documentList?.length > 0 &&
                documentList.map((doc, idx) => (
                  <div className="[ row ] [ details-section__item ]">
                    <div className="[ col-3 ]">
                      <div className="[ details-section__item-label ]">
                        Document #{idx + 1}
                      </div>
                    </div>
                    <div className="[ col-9 ]">
                      <div className="[ details-section__item-label ]">
                        {doc.documentName}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {/* Financial Analysis List block end */}

          {/* check notice start */}

          <div className="[ mb-3 ] [ alert alert-warning ]" role="alert">
            <div className="[ row ] [ mb-3 ]">
              <div className="[ col-12 ]">
                <i className="[ fas fa-exclamation-circle ]" />
                <span>
                  {STRINGS.CONFIRM_CREATION.ACCOUNT_DISCLAIMER}
                  <ul>
                    <li>{STRINGS.CONFIRM_CREATION.BUREAU_CHECK}</li>
                  </ul>
                  {STRINGS.CONFIRM_CREATION.ACCOUNT_TERMS_CHECK}
                </span>
              </div>
            </div>
            <div className="[ row ] [ align-items-center ]">
              <div className="[ col-12 ] [ d-flex align-items-center ]">
                <div className=" [ checkbox custom-control custom-checkbox ]">
                  <input
                    type="checkbox"
                    defaultChecked={isAcceptedTerms}
                    onChange={this.handleChecked}
                    name="termscondition"
                    id="termsCheckbox"
                    className={
                      termsConditionError && termsConditionError.length > 0
                        ? "[ invalid-input ] [ custom-control-input ]"
                        : "[ custom-control-input ]"
                    }
                  />
                  <label
                    htmlFor="termsCheckbox"
                    className="[ custom-control-label ]"
                  >
                    <span className="[ form-checkbox__text ]">
                      {STRINGS.CONFIRM_CREATION.ACCOUNT_DISCLAIMER_LABEL}
                    </span>
                  </label>
                </div>
              </div>

              <div className="[ error-msg ] [ col-12 ]">
                {termsConditionError || ""}
              </div>
            </div>
          </div>

          <div className="[ confirm-details__footer ]">
            <div className="[ confirm-details-notice-wrapper ]">
              <div className="[ col-12 ] [ confirm-account-opening ]" />
              <div className="[ col-12 ] [ confirm-account-opening ]" />
              <div className="[ col-12 ] [ confirm-account-opening-question ]" />
            </div>

            {/* check notice end */}
          </div>
        </div>
        <div className="[ mb-3 ]">
          <div className="[ row ]">
            <div className="[ col-lg-6 ] [ offset-lg-3 ]">
              <button
                type="button"
                className="[ submit-btn btn ] [ w-100 ]"
                onClick={this.handleContinueClick}
              >
                {STRINGS.COMMON.CONTINUEBTN}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <>
        {showModal && (
          <PopupModal
            type={type}
            title={modal.title}
            description={modal.description}
            toggleModal={toggleModal}
            showModal={showModal}
            popupBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            closeBtnClick={
              type === AppConstants.MODALTYPE.SUCCESS
                ? () => handlePopupModalBtnClick()
                : () => {}
            }
            btnText={STRINGS.POPUPMODAL.OKBUTTON}
          />
        )}
        <AuthBody
          actionComponent={actionCompnent}
          memberCreation
          showVault={showVault}
          showVaultClicked={() => showVaultClicked}
          handleBack={handleBack}
        />
      </>
    );
  }
}

ConfirmAccountCreate.propTypes = {
  showModal: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  showVaultClicked: PropTypes.func,
  showVault: PropTypes.bool,
  handleSubmitDetails: PropTypes.func,
  handleEditPage: PropTypes.func,
  gettingStartedBranchData: PropTypes.arrayOf(PropTypes.any),
  incomeSourcesList: PropTypes.arrayOf(PropTypes.any),
  statusList: PropTypes.arrayOf(PropTypes.any),
  provinceList: PropTypes.arrayOf(PropTypes.any),
  accountPurposeList: PropTypes.arrayOf(PropTypes.any),
  handlePopupModalBtnClick: PropTypes.func,
  handleBack: PropTypes.func,
  getDataFromServer: PropTypes.func.isRequired,
  processFlow: PropTypes.arrayOf(PropTypes.object),
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStepID: PropTypes.number,
  doGetProductInformation: PropTypes.func.isRequired,
  getActiveProduct: PropTypes.func.isRequired,
  vaultProductList: PropTypes.arrayOf(PropTypes.object).isRequired,
  doGetBeneficiaryData: PropTypes.func.isRequired,
  doGetSpousalInformation: PropTypes.func.isRequired,
  isDebitCardEnabled: PropTypes.bool,
  enableDebitCard: PropTypes.bool,
  doGetApplicationProperty: PropTypes.func.isRequired,
  doGetApplicantType: PropTypes.func.isRequired,
  debitCardShown: PropTypes.bool,
  inSessionJointApplicant: PropTypes.object,
  applicationCompleteList: PropTypes.arrayOf(PropTypes.object),
};
ConfirmAccountCreate.defaultProps = {
  showVaultClicked: () => {},
  handleSubmitDetails: () => {},
  showVault: null,
  handleEditPage: () => {},
  handlePopupModalBtnClick: () => {},
  handleBack: () => {},
  gettingStartedBranchData: [],
  incomeSourcesList: [],
  statusList: [],
  provinceList: [],
  accountPurposeList: [],
  processFlow: [],
  steps: [],
  activeStepID: -1,
  isDebitCardEnabled: false,
  enableDebitCard: false,
  debitCardShown: false,
};

const mapStateToProps = (state) => ({
  processFlow: state.ApplicationReducer.processTemplate.flow,
  vaultProductList: state.VaultReducer.vaultProductList,
  isDebitCardEnabled: state.DebitCardReducer.isDebitCardEnabled,
  enableDebitCard: state.DebitCardReducer.enableDebitCard,
  debitCardShown: state.DebitCardReducer.debitCardShown,
  applicantData: state.ApplicationReducer.response,
  finInfo: state.MarketplaceReducer.finInfo,
  streetTypes: state.LoanDetailsReducer.streetTypes,
});

export default connect(mapStateToProps)(ConfirmAccountCreate);
