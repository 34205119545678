import * as actionTypes from "Redux/Actions/actionTypes";

export const addLoanAsset = (
  applicantId,
  finAnalysisId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_ADD_ASSET,
  data: {
    applicantId,
    finAnalysisId,
    payLoad,
  },
  callback,
});

export const addLoanVehicle = (
  applicationId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_ADD_VEHICLE,
  data: {
    applicationId,
    payLoad,
  },
  callback,
});

export const addLoanRealEstate = (
  applicationId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_ADD_REAL_ESTATE,
  data: {
    applicationId,
    payLoad,
  },
  callback,
});

// TODO update (has third param)
export const updateLoanAsset = (
  applicantId,
  finAnalysisId,
  financialId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_UPDATE_ASSET,
  data: {
    applicantId,
    finAnalysisId,
    financialId,
    payLoad,
  },
  callback,
});

export const updateLoanVehicle = (
  applicationId,
  securityId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_UPDATE_VEHICLE,
  data: {
    applicationId,
    securityId,
    payLoad,
  },
  callback,
});

export const updateLoanRealEstate = (
  applicationId,
  securityId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_UPDATE_REAL_ESTATE,
  data: {
    applicationId,
    securityId,
    payLoad,
  },
  callback,
});

export const getLoanAsset = (
  applicantId,
  finAnalysisId,
  financialId,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_GET_ASSET,
  data: {
    applicantId,
    finAnalysisId,
    financialId,
  },
  callback,
});

export const getLoanVehicle = (
  applicationId,
  securityId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_GET_VEHICLE,
  data: {
    applicationId,
    securityId,
    payLoad,
  },
  callback,
});

export const getLoanRealEstate = (
  applicationId,
  securityId,
  payLoad,
  callback = () => {}
) => ({
  type: actionTypes.LOAN_GET_REAL_ESTATE,
  data: {
    applicationId,
    securityId,
    payLoad,
  },
  callback,
});
